import React from 'react';
import { Button, Grow } from '@mui/material';
import '../../App.css';
import { calculateScreeningTotal } from '../../utils/calculateScores';

const MultiChoiceQuestion = ({
  question,
  updateFinalResults,
  finalResults,
  questionDisplayed,
  handleAnswerClick,
  questionIndex,
  riskRatios,
  setScreeningScore,
}) => {
  const scrollToNext = (nextIndex) => {
    setTimeout(() => {
      const element = document.getElementById(`multichoice-${nextIndex}`);
      if (element) {
        const y = element.getBoundingClientRect().top + window.scrollY;
        window.scroll({
          top: y,
          behavior: 'smooth',
        });
      } else {
        window.scrollTo({ top: window.scrollY + 400, behavior: 'smooth' });
      }
    }, '100');
  };
  return (
    <>
      <Grow
        className={questionDisplayed ? 'height100' : 'height0 opacity0'}
        in={questionDisplayed}
      >
        <div id={`multichoice-${questionIndex}`}>
          <h3 className="mb10 mt30 multiquestion-h3">{question.question}</h3>
          <div className="dflex answer-btn-group flexwrap alignitemscenter">
            {question.options.map((answerItem, index) => {
              var selectedAnswer = '';
              if (finalResults[question.id] !== undefined) {
                selectedAnswer = finalResults[question.id].answer;
              }
              return (
                <Button
                  key={answerItem.answer}
                  variant="contained"
                  className={
                    'answer-btn ' +
                    (answerItem.answer === selectedAnswer
                      ? 'selected-answer'
                      : '')
                  }
                  size="small"
                  onClick={() => {
                    handleAnswerClick(questionIndex);
                    updateFinalResults(
                      {
                        [question.id]: {
                          question_id: question.id,
                          factor: question.factor,
                          question: question.question,
                          answer: answerItem.answer,
                          response: answerItem.response,
                          recommendation: answerItem.recommendation,
                          good_response: answerItem.good_response,
                        },
                      },
                      {
                        [question.id]: {
                          question_id: question.id,
                          factor: question.factor,
                          good_response: answerItem.good_response,
                          risk_ratios: [
                            { skin: answerItem.skin_rr },
                            { lung: answerItem.lung_rr },
                            { breast: answerItem.breast_rr },
                            { prostate: answerItem.prostate_rr },
                            { bowel: answerItem.bowel_rr },
                          ],
                        },
                      },
                    );
                    scrollToNext(questionIndex + 1);
                  }}
                  value={answerItem.answer}
                  id={answerItem.answer_guid}
                >
                  {answerItem.answer}
                </Button>
              );
            })}
          </div>
        </div>
      </Grow>
    </>
  );
};

export default MultiChoiceQuestion;
