import React from 'react';
import bowel from './bowel-img.png';
import cervical from './cervical-img.png';
import breast from './breast-img.png';
import { FaPhoneAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { TbArrowRight } from 'react-icons/tb';

const ScreeningPrograms = ({ handleOpen }) => {
  return (
    <div className="m10">
      <h4 className="learnMoreHeader mt20 mb10">
        Australia's National Screening Programs
      </h4>
      <div className="screening-programs w100">
        <div className="screening-img">
          <Link
            target="_blank"
            style={{ cursor: 'pointer' }}
            to="https://www.health.gov.au/our-work/national-bowel-cancer-screening-program"
          >
            <img alt="Bowel Screening" src={bowel} />
          </Link>
        </div>
        <p>
          <Link
            className="link w100 screening-title-desktop"
            target="_blank"
            to="https://www.health.gov.au/our-work/national-bowel-cancer-screening-program"
            style={{ fontSize: 18 }}
          >
            National Bowel Screening Program
          </Link>{' '}
          <br></br>
          Eligible Australians aged 45 to 74 can do a free test at home every 2
          years. This program aims to reduce deaths from bowel cancer by
          detecting early signs of the disease. If found early, more than 90% of
          cases can be successfully treated.
          <FaPhoneAlt
            className="number-screening"
            style={{
              color: '#4d8ecd',
              fontSize: 13,
              marginRight: 2,
              marginLeft: 3,
            }}
          />
          <Link
            className="link number-screening"
            target="_blank"
            to="tel:1800627701"
          >
            1800 627 701
          </Link>
          <br></br>
          <Link className="link" onClick={() => handleOpen('bowel')}>
            Read our factsheet
          </Link>
        </p>
        <p>
          <TbArrowRight className="arrow" />
        </p>
      </div>

      <div className="screening-programs w100">
        <div className="screening-img">
          <Link
            target="_blank"
            style={{ cursor: 'pointer' }}
            to="https://www.health.gov.au/our-work/national-cervical-screening-program"
          >
            <img alt="Cervical Screening" src={cervical} />
          </Link>
        </div>
        <p>
          <Link
            className="link screening-title-desktop"
            target="_blank"
            to="https://www.health.gov.au/our-work/national-cervical-screening-program"
            style={{ fontSize: 18 }}
          >
            National Cervical Screening Program
          </Link>{' '}
          <br></br>
          Eligible Australians aged 25 to 74 are invited to have a Cervical
          Screening. All participants now have the choice to self-collect their
          own Cervical Screening Test sample.
          <FaPhoneAlt
            className="number-screening"
            style={{
              color: '#4d8ecd',
              fontSize: 13,
              marginRight: 2,
              marginLeft: 3,
            }}
          />
          <Link
            className="link number-screening"
            target="_blank"
            to="tel:1800930998"
          >
            1800 627 701
          </Link>{' '}
          <br></br>
          <Link className="link" onClick={() => handleOpen('cervical')}>
            Read our factsheet
          </Link>
        </p>
        <p>
          <TbArrowRight className="arrow" />
        </p>
      </div>

      <div className="screening-programs w100">
        <div className="screening-img">
          <Link
            target="_blank"
            style={{ cursor: 'pointer' }}
            to="https://www.health.gov.au/our-work/breastscreen-australia-program"
          >
            <img alt="Breast Screening" src={breast} />
          </Link>
        </div>
        <p>
          <Link
            style={{ fontSize: 18 }}
            className="link screening-title-desktop"
            target="_blank"
            to="https://www.health.gov.au/our-work/breastscreen-australia-program"
          >
            BreastScreen Australia Program
          </Link>
          <br></br>
          Eligible Australians aged 40 and over can have a free mammogram (x-ray
          of the breasts) every 2 years. People aged 50-74 are actively invited.
          <FaPhoneAlt
            className="number-screening"
            style={{ color: '#4d8ecd', fontSize: 13, marginRight: 3 }}
          />
          <Link
            className="link number-screening"
            target="_blank"
            to="tel:1800930998"
          >
            13 20 50
          </Link>{' '}
          <br></br>
          <Link className="link" onClick={() => handleOpen('breast')}>
            Read our factsheet
          </Link>
        </p>
        <p>
          <TbArrowRight className="arrow" />
        </p>
      </div>
    </div>
  );
};

export default ScreeningPrograms;
