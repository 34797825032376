import React, { useState } from 'react';
import '../LearnMore.css';
import AnswerResponse from './AnswerResponse';
import { FaCheckCircle, FaDotCircle, FaQuestionCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const AlcoholAnswers = ({ finalResults, factor }) => {
  const answers = Object.values(finalResults).filter(
    (res) => res.factor === 'alcohol',
  );
  return (
    <div className="subtext">
      <h4 className="learnMoreHeader mt0 mb10">Did you know:</h4>
      <li>
        Alcohol is a Group 1 carcinogen which means it is known to cause cancer.
        There is strong evidence to say it causes seven types of cancer. Reduce
        your drinking to reduce your risk.
      </li>
      <li>
        Australian{' '}
        <Link
          to="https://www.nhmrc.gov.au/health-advice/alcohol"
          target="_blank"
          className="link "
        >
          alcohol guidelines
        </Link>{' '}
        recommend to have no more than 10 standard drinks a week and no more
        than 4 standard drinks a day.
      </li>
      <li>
        Drinking alcohol and smoking at the same time has a synergistic effect.
        This means the risk of cancer is higher when both are combined than if
        each behaviour was done separately.
      </li>
      <li>
        Drinking alcohol can contribute to weight gain. Overweight and obesity
        has been found to be a risk factor for some cancers.
      </li>
      <div className="answersSection">
        <h4 className="learnMoreHeader mt20 mb0">Your Answers:</h4>

        {finalResults.alcohol_status.answer === 'Never' ? (
          <div className="dflex gap10 mt10 alignitemscenter">
            <FaCheckCircle className="checkIcon" />
            <p className="learnMoreInfo question mt10">
              {finalResults.alcohol_status.response}
            </p>
          </div>
        ) : (
          answers.map((item, index) => {
            if (
              item.question_id === 'alcohol_standard_drinks' &&
              finalResults.alcohol_status.answer != 'Never'
            ) {
              return (
                <>
                  <div className="dflex gap10 mt10 alignitemscenter">
                    <FaDotCircle
                      className="answerpoints"
                      style={{ color: '#435685', minWidth: 20 }}
                    />
                    <p className="learnMoreInfo question mt10">
                      In one sitting, I typically drink approximately{' '}
                      {item.standard_drinks_total} standard drinks
                    </p>
                  </div>
                  {item.standard_drinks_total > 0 && (
                    <p
                      style={{ fontSize: 16, marginLeft: 30 }}
                      className="learnMoreRec subtext"
                    >
                      When it comes to alcohol and cancer, there is no safe
                      level. If you choose to drink, try to limit your intake.
                    </p>
                  )}
                </>
              );
            }
            return (
              <>
                <AnswerResponse key={index} item={item} />
              </>
            );
          })
        )}
      </div>

      <div>
        <h4 className="learnMoreHeader mt30 mb10">Tips & Resources:</h4>
        <p className="mt0 mb5">
          Some ways to help cut back on drinking include:
        </p>
        <li>
          Set small, realistic goals that are achievable. Try incorporating
          alcohol-free days into your week.
        </li>
        <li>
          When you're out with friends, choose drinks without alcohol. For
          example, choosing mocktails or non-alcoholic beers.
        </li>
        <li>
          On days you do drink, try to pace yourself. Consider drinking water or
          non-alcoholic options between drinks.
        </li>
        <li>
          Reach out to friends, family or your doctor for support and guidance.
          They can help you on your journey.
        </li>
        <p className="subtext mt10">
          There are{' '}
          <Link
            className="link "
            target="_blank"
            to="https://www.health.gov.au/our-work/drug-help/how-to-find-help"
          >
            services and supports
          </Link>{' '}
          available if you or someone you care about is experiencing problems
          with alcohol. Call the National Alcohol and Other Drug Hotline on{' '}
          <Link className="link " to="tel:1800250015">
            1800 250 015.
          </Link>{' '}
        </p>
      </div>
    </div>
  );
};

export default AlcoholAnswers;
