import React, { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Container from '@mui/material/Container';
import { GrClose } from 'react-icons/gr';

import Slop from '../../../../src/assets/icons/CC_Slop.png';
import Slip from '../../../../src/assets/icons/CC_Slip.png';
import Seek from '../../../../src/assets/icons/CC_Seek.png';
import Slide from '../../../../src/assets/icons/CC_Slide.png';
import Slap from '../../../../src/assets/icons/CC_Slap.png';
import { Button } from '@mui/material';

export default function SlipSlopSlapModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  return (
    <div className="dflex justifycontentcenter">
      <p
        className="textaligncenter"
        style={{ fontSize: 23, fontFamily: 'Foco CC' }}
      >
        {' '}
        <a onClick={handleOpen} className="link secondaryLink">
          Learn more
        </a>{' '}
        about the five ways to protect yourself from skin cancer
      </p>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Container maxWidth="lg" className="modalStyles">
            <GrClose
              style={{
                position: 'absolute',
                right: 20,
                top: 20,
                cursor: 'pointer',
              }}
              onClick={handleClose}
            />
            <h2 className="mt20 fontBlack slipSlopTitle">
              Slip, Slop, Slap, Seek and Slide
            </h2>

            <div className="slipSlopSlapContainer ml20">
              <div className="slipSlopIconsSection dflex">
                <div
                  className="slipSlopIcons dflex justifycontentcenter"
                  style={{ width: 140, minWidth: 140 }}
                >
                  <img src={Slip} alt="Slip" style={{ height: 110 }} />
                </div>
                <div className="cc-section">
                  <p
                    style={{ fontSize: 20 }}
                    className="secondaryColor cc-title"
                  >
                    SLIP ON PROTECTIVE CLOTHING
                  </p>
                  <p style={{ fontWeight: 600 }}>Choose clothing that:</p>
                  <li>Covers as much skin as possible</li>
                  <li>
                    Is made from close weave materials such as cotton,
                    polyester/cotton and linen
                  </li>
                  <li>
                    Is dark in colour to absorb UV radiation (white and lighter
                    colours reflect UV radiation onto skin)
                  </li>
                  <li>
                    If used for swimming, is made from materials such as lycra,
                    which stays sun protective when wet
                  </li>
                </div>
              </div>

              <div className="mt20 dflex ">
                <div
                  className="slipSlopIcons dflex justifycontentcenter"
                  style={{ width: 140 }}
                >
                  <img src={Slop} alt="Slop" style={{ height: 108 }} />
                </div>

                <div className="cc-section">
                  <p
                    style={{ fontSize: 20 }}
                    className="secondaryColor cc-title"
                  >
                    SLOP ON SPF50 or SPF50+ SUNSCREEN
                  </p>
                  <li>Use Broad spectrum and water-resistant sunscreen</li>
                  <li>
                    Apply it liberally to clean, dry skin at least 20 minutes
                    before going outside
                  </li>
                  <li>
                    Reapply it every two hours, or more often if swimming,
                    sweating or after towel drying
                  </li>
                  <li>
                    Also use other forms of protection such as hats and shade
                  </li>
                </div>
              </div>

              <div className="mt20 dflex">
                <div
                  className="slipSlopIcons dflex justifycontentcenter"
                  style={{ width: 140 }}
                >
                  <img src={Slap} alt="Slap" style={{ height: 110 }} />
                </div>

                <div className="cc-section">
                  <p
                    style={{ fontSize: 20 }}
                    className="secondaryColor cc-title"
                  >
                    SLAP ON A HAT
                  </p>
                  <li>
                    Choose a broad-brimmed hat that provides good protection for
                    the face, nose, neck and ears
                  </li>
                  <li>
                    Make sure the hat is made with closely woven fabric – if you
                    can see through it, UV radiation will get through
                  </li>
                  <li>
                    Also wear sunglasses and sunscreen to increase your level of
                    protection
                  </li>
                </div>
              </div>

              <div className="mt20 dflex">
                <div
                  className="slipSlopIcons dflex justifycontentcenter"
                  style={{ width: 140 }}
                >
                  <img src={Seek} alt="Seek" style={{ height: 110 }} />
                </div>

                <div className="cc-section">
                  <p
                    style={{ fontSize: 20 }}
                    className="secondaryColor cc-title"
                  >
                    SEEK SHADE
                  </p>
                  <li>Make use of trees or shade structures</li>
                  <li>Bring your own pop-up tent or umbrellas</li>
                  <li>
                    You still need to use other protection (i.e., clothing,
                    hats, sunglasses and sunscreen) to avoid reflected UV
                    radiation from nearby surfaces
                  </li>
                </div>
              </div>

              <div className="mt20 dflex">
                <div
                  className="slipSlopIcons dflex justifycontentcenter"
                  style={{ width: 140 }}
                >
                  <img src={Slide} alt="Slide" style={{ height: 110 }} />
                </div>

                <div className="cc-section">
                  <p
                    style={{ fontSize: 20 }}
                    className="secondaryColor cc-title"
                  >
                    SLIDE ON SUNGLASSES
                  </p>
                  <li>
                    Choose close-fitting wrap-around style sunglasses that meet
                    the AS 1067 and provide an Eye Protection Factor (EPF) of 9
                    or above
                  </li>
                  <li>
                    If you combine sunglasses with a broad-brimmed hat you can
                    reduce UV radiation exposure to the eyes by up to 98 per
                    cent
                  </li>
                  <li>
                    Children should also wear sunglasses that meet the AS 1067
                  </li>
                </div>
              </div>
              <Button
                variant="contained"
                onClick={handleClose}
                fullWidth
                className="mt30 closeSlip"
              >
                Close
              </Button>
            </div>
          </Container>
        </Fade>
      </Modal>
    </div>
  );
}
