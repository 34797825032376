import React from 'react';
import { Button } from '@mui/material';
import Chart from 'react-apexcharts';
import '../../../components/scorecard/charts/Results.css';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';

const AverageScores = ({ factor, factorSection, color, total }) => {
  var trackColor = color;
  if (factorSection === 'uv') {
    trackColor = '#ffd500';
  }
  const options = {
    chart: {
      type: 'radialBar',
    },
    parentHeightOffset: 0,

    labels: [factor],
    colors: [trackColor],
    stroke: { lineCap: 'round' },
    grid: {
      padding: {
        top: 0,
        bottom: 0,
      },
      margin: {
        top: 0,
      },
    },
    plotOptions: {
      radialBar: {
        track: {
          className: 'totalscores-radial-track',
        },
        startAngle: 0,
        endAngle: 360,
        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: false,
            color: '#606669',
            fontSize: '27px',
            fontFamily: 'Foco CC Bd',
          },

          value: {
            color: color,
            fontFamily: 'Foco CC Black',
            fontWeight: 700,
            fontSize: '40px',
            marginTop: '-20px',
            show: true,
            offsetY: 5,
            formatter: function (val) {
              return val;
            },
          },
        },
        hollow: {
          margin: 0,
          size: '66%',
          background: '#fff',
          position: 'front',
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.1,
          },
          track: {
            background: '#fff',
            strokeWidth: '70%',
            margin: 0,
            dropShadow: {
              enabled: false,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },
        },
      },
    },
  };

  const series = [total];

  return (
    <div
      style={{ minWidth: 110, maxWidth: 250, height: 195, margin: 5 }}
      className={`totalScoreSection `}
    >
      <p style={{ color: color }} className="totalScore100_events">
        /100
      </p>
      <Chart
        height="200"
        options={options}
        series={series}
        className="dflex justifycontentcenter"
        type="radialBar"
      />
      <div className="totalScoreLabels">
        <div style={{ height: 80 }} className="dflex alignitemscenter">
          <h1 style={{ color: color, fontSize: 24 }}>
            {factorSection === 'uv' ? 'UV & Sun' : factor}{' '}
          </h1>
        </div>
      </div>
    </div>
  );
};
export default AverageScores;
