import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container,
  Button,
  Tabs,
  Tab,
  useMediaQuery,
  Box,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import FeedbackModal from '../components/scorecard/feedback/FeedbackModal';
import Lifestyle6 from '../components/body/other/Lifestyle6';
import physicalIcon from '../assets/icons/physical-activity-white.png';
import screeningIcon from '../assets/icons/screening-white.png';
import nutritionIcon from '../assets/icons/nutrition-white.png';
import weightIcon from '../assets/icons/weight-white.png';
import alcoholIcon from '../assets/icons/alcohol-white.png';
import uvIcon from '../assets/icons/uv-white.png';
import smokingIcon from '../assets/icons/smoking-white.png';
import TotalScoreChart from '../components/scorecard/charts/TotalScoreChart';
import ImageCarousel from '../components/scorecard/components/ImageCarousel';
import CompareResults from '../components/scorecard/components/CompareResults';
import '../App.css';
import SocialShare from '../components/body/socialShare/SocialShare';
import LearnMoreDropdown from '../components/scorecard/components/LearnMore';
import DonationModal from '../components/scorecard/components/DonationModal';
import Loading from '../components/body/other/Loading';
import ScoresFaqModal from '../components/scorecard/components/ScoresFaqModal';
import EnterEmail2 from '../components/scorecard/components/EnterEmail2';
import HistoryChart from '../components/scorecard/charts/HistoryChart';
import { BiSolidChevronUp, BiSolidChevronDown } from 'react-icons/bi';
import { BsFillCloudDownloadFill } from 'react-icons/bs';
import PdfResults from '../components/scorecard/components/PdfResults';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Results = () => {
  let query = useQuery();
  const [finalResults, setFinalResults] = useState({
    uv_total: 0,
    smoking_total: 0,
    alcohol_total: 0,
    nutrition_total: 0,
    physical_total: 0,
    screening_total: 0,
  });
  const [resultsView, setResultsView] = React.useState(0);
  const [notSignedUp, setNotSignedUp] = React.useState(false);
  const [errorView, setErrorView] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [displayHistory, setDisplayHistory] = useState(false);
  const [previousTotals, setPreviousTotals] = useState(null);
  const [compareTotals, setCompareTotals] = useState(null);
  const [demo, setDemo] = useState(false);
  const [timestamp, setTimestamp] = useState('');

  const mobileView = useMediaQuery('(max-width:850px)');

  useEffect(() => {
    var redirect = '';
    setLoading(true);
    const user_id = query.get('user');
    const redirected = query.get('re');
    if (redirected !== undefined && redirected !== '') {
      redirect = redirected;
    }
    if (user_id === null || user_id === undefined || user_id === '') {
      setErrorView(true);
      setLoading(false);
      return;
    }

    //Show demo bubble
    if (user_id == 'demo-results') {
      setDemo(true);
    }

    setTimeout(() => {
      retrieveResults(user_id, redirect);
    }, '2000');
    setTimeout(() => {
      window.scrollTo({ top: 0 });
    }, '500');
  }, []);

  //Retrieve results
  const retrieveResults = async (user_id, redirect) => {
    try {
      const response = await axios.post(`/api/retrieve`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: user_id, re: redirect }),
      });
      if (response.data.count < 1 || response.data.success === false) {
        setErrorView(true);
      } else {
        setFinalResults(response.data.data);
        setTimestamp(response.data.timestamp);

        setPreviousTotals(JSON.parse(response.data.previous_totals));
        if (response.data.show_previous) {
          setDisplayHistory(true);
          setCompareTotals(response.data.compare_totals);
        }
        if (response.data.data.email_provided) {
          setNotSignedUp(false);
        } else {
          setNotSignedUp(true);
        }
      }
      setLoading(false);
      //Scroll to bottom if redirected to share calculator on load of results
      var share = query.get('share');
      setTimeout(() => {
        if (share === 'true') {
          window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth',
          });
        }
      }, '800');
    } catch (error) {
      setLoading(false);
      setErrorView(true);
      console.error('Error: ', error);
    }
  };

  const handleChange = (event, newValue) => {
    if (notSignedUp) {
      const element = document.getElementById(`enter_email_res`);
      if (element) {
        const y = element.getBoundingClientRect().top + window.scrollY;
        window.scroll({
          top: y,
          behavior: 'smooth',
        });
      }
    } else {
      setResultsView(newValue);
    }
  };

  const [expanded, setExpanded] = React.useState('');
  const handleAccordianChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    const element = document.getElementById(`accordian_${panel}`);
    if (element && newExpanded) {
      setTimeout(() => {
        element.scrollIntoView();
      }, '500');
    }
  };

  const learnMoreObject = [
    {
      factor: 'UV & Sun Exposure',
      factorSection: 'uv',
      color: '#c9a800',
      total: finalResults.uv_total,
      icon: <img alt="UV" src={uvIcon} />,
    },
    {
      factor: 'Smoking',
      factorSection: 'smoking',
      color: '#878888',
      total: finalResults.smoking_total,
      icon: <img alt="Smoking" src={smokingIcon} />,
    },
    {
      factor: 'Alcohol',
      factorSection: 'alcohol',
      color: '#f05029',
      total: finalResults.alcohol_total,
      icon: <img alt="Alcohol" style={{ height: '35px' }} src={alcoholIcon} />,
    },
    {
      factor: 'Nutrition',
      factorSection: 'nutrition',
      color: '#73c04b',
      total: finalResults.nutrition_total,
      icon: <img alt="Nutrition" src={nutritionIcon} />,
    },
    {
      factor: 'Weight',
      factorSection: 'weight',
      color: '#199bd7',
      total: '',
      icon: <img alt="Weight" src={weightIcon} />,
    },
    {
      factor: 'Physical Activity',
      factorSection: 'physical',
      color: '#199bd7',
      total: finalResults.physical_total,
      icon: <img alt="Physical" src={physicalIcon} />,
    },
    {
      factor: 'Screening & Early Detection',
      factorSection: 'screening',
      color: '#7d489c',
      total: finalResults.screening_total,
      icon: <img alt="Screening" src={screeningIcon} />,
    },
  ];
  return (
    <div className="container-wave">
      <div className="bg-blob"></div>
      <div className="bg-blob two"></div>
      {demo && (
        <div className="demo-bubble">
          <h5>Demo / Sandbox</h5>
        </div>
      )}
      {loading && <Loading />}
      {!errorView && !loading && (
        <>
          <Container
            className="parent-container results-container"
            maxWidth="lg"
          >
            <Box className="tabs-res-container">
              <Tabs
                variant="fullWidth"
                value={resultsView}
                onChange={handleChange}
                aria-label="icon label tabs example"
                TabIndicatorProps={{ hidden: true }}
              >
                <Tab className="scorecardTab" label="Results" />
                <Tab className="scorecardTab" label="View More" />
              </Tabs>
            </Box>

            {!demo && <DonationModal />}

            {resultsView === 0 ? (
              <>
                <div className="dflex justifycontentspacebetween m30 ml0 mb0 mobile5">
                  <div className="resultsHeader">
                    <h1>Your Results</h1>
                    <h3>
                      The higher your score, the better you are doing at
                      currently reducing your cancer risk!
                    </h3>
                  </div>
                  {!mobileView && <Lifestyle6 activeColor="all" />}
                </div>
                <div className="dflex flexwrap gap10 mobile5">
                  {learnMoreObject.map((item) => {
                    if (item.factorSection === 'weight') {
                      return;
                    }
                    return (
                      <TotalScoreChart
                        factor={item.factor}
                        factorSection={item.factorSection}
                        color={item.color}
                        total={item.total}
                        setExpanded={setExpanded}
                        expanded={expanded}
                        notSignedUp={notSignedUp}
                        compareTotals={compareTotals}
                      />
                    );
                  })}
                </div>
                <div>
                  {displayHistory && compareTotals !== null && (
                    <p
                      style={{
                        marginBottom: '-15px',
                        textAlign: 'right',
                        marginTop: '10px',
                      }}
                    >
                      Check if your score has increased{' '}
                      <BiSolidChevronUp className="score-chevron increase-color" />{' '}
                      or deceased{' '}
                      <BiSolidChevronDown className="score-chevron decrease-color" />{' '}
                      since your previous attempt on{' '}
                      {compareTotals.previous_timestamp_formatted}.
                    </p>
                  )}
                  <ScoresFaqModal />
                </div>

                {notSignedUp && (
                  <EnterEmail2
                    finalResults={finalResults}
                    setFinalResults={setFinalResults}
                    setNotSignedUp={setNotSignedUp}
                  />
                )}

                <div className={notSignedUp ? ' disabled-overlay mt30' : ''}>
                  <div className="mt30 mobile5">
                    <h3 style={{ marginTop: '-10px', marginBottom: '10px' }}>
                      Select the factors below to learn more
                    </h3>

                    {learnMoreObject.map((item) => {
                      return (
                        <LearnMoreDropdown
                          expanded={expanded}
                          handleAccordianChange={handleAccordianChange}
                          factor={item}
                          notSignedUp={notSignedUp}
                          finalResults={finalResults}
                        />
                      );
                    })}
                  </div>

                  {/* Download PDF Results */}
                  <PdfResults results={finalResults} timestamp={timestamp} />

                  {displayHistory && (
                    <HistoryChart previousTotals={previousTotals} />
                  )}
                  <ImageCarousel />
                </div>
                <div className="mt30 w100 flexwrap dflex justifycontentcenter mobile5 gap10">
                  <FeedbackModal
                    finalResults={finalResults}
                    style={{ zIndex: 3 }}
                  />
                  <Link
                    to="https://donate.cancerqld.org.au/donate-crc"
                    target="_blank"
                  >
                    <Button
                      fullWidth
                      className="donationBtn borderRadius8"
                      variant="contained"
                    >
                      Donate Now
                    </Button>
                  </Link>
                  <Button
                    onClick={() => {
                      window.location.href = '/';
                    }}
                    variant="outlined"
                    className="borderRadius8"
                  >
                    Re-take Calculator
                  </Button>
                  <SocialShare />
                </div>
              </>
            ) : (
              <CompareResults finalResults={finalResults} />
            )}
          </Container>
        </>
      )}
      {errorView && (
        <Container
          className="textaligncenter mt30"
          style={{
            background: 'white',
            width: 'fit-content',
            borderRadius: '20px',
            padding: '30px',
          }}
          maxWidth="lg"
        >
          <p style={{ fontWeight: 600, fontSize: 22 }}>
            Sorry, your results could not be retrieved at this time.
          </p>
          <p style={{ fontWeight: 600, fontSize: 22 }}>
            Please check the link and try again.
          </p>
        </Container>
      )}
    </div>
  );
};

export default Results;
