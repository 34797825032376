import { Button, Container, Grow, Slider, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SectionHeader from '../other/SectionHeader';
import Apple from '../../../assets/illustrations/nutrition/apple.png';
import Cucumber from '../../../assets/illustrations/nutrition/cucumber.png';
import Bread from '../../../assets/illustrations/nutrition/bread.png';
import Steak from '../../../assets/illustrations/nutrition/steak.png';
import Bacon from '../../../assets/illustrations/nutrition/bacon.png';
import '../nutrition/nutrition.css';
import { risk_ratios_nutrition } from '../../../utils/config';
import { calculateTotalScore } from '../../../utils/calculateScores';

function Nutrition({
  setActiveView,
  finalResults,
  updateFinalResults,
  handleCompletedSteps,
  riskRatios,
}) {
  const [fruitIndex, setFruitIndex] = useState(finalResults.fruit.answer);
  const [veggieIndex, setVeggieIndex] = useState(
    finalResults.vegetables.answer,
  );
  const [wholegrainIndex, setWholegrainIndex] = useState(
    finalResults.wholegrains.answer,
  );
  const [meatIndex, setMeatIndex] = useState(finalResults.red_meat.answer);
  const [processedMeatIndex, setProcessedMeatIndex] = useState(
    finalResults.processed_meat.answer,
  );

  const [changes, setChanges] = useState('');

  const handleChange = (newValue, setIndexType) => {
    setIndexType(newValue);
    setChanges(newValue);
  };

  useEffect(() => {
    var fruitCheckIndex = fruitIndex;
    if (fruitIndex >= 2) {
      fruitCheckIndex = 2;
    }
    const find_fruit = risk_ratios_nutrition.fruit.find(
      (item) => item.serves === fruitCheckIndex,
    );
    const risk_ratios_fruit = find_fruit.risk_ratios;

    var vegCheckIndex = veggieIndex;
    if (veggieIndex >= 5) {
      vegCheckIndex = 5;
    }
    const find_veg = risk_ratios_nutrition.vegetables.find(
      (item) => item.serves === vegCheckIndex,
    );
    const risk_ratios_veg = find_veg.risk_ratios;

    var wholegrainsCheckIndex = wholegrainIndex;
    if (wholegrainIndex >= 6) {
      wholegrainsCheckIndex = 6;
    }
    const find_wholegrains = risk_ratios_nutrition.wholegrains.find(
      (item) => item.serves === wholegrainsCheckIndex,
    );
    const risk_ratios_wholegrains = find_wholegrains.risk_ratios;

    var redMeatCheckIndex = meatIndex;
    if (meatIndex < 7) {
      redMeatCheckIndex = 0;
    }
    const find_red_meat = risk_ratios_nutrition.red_meat.find(
      (item) => item.serves === redMeatCheckIndex,
    );
    const risk_ratios_red_meat = find_red_meat.risk_ratios;

    const find_processed_meat = risk_ratios_nutrition.processed_meat.find(
      (item) => item.serves === processedMeatIndex,
    );
    const risk_ratios_processed_meat = find_processed_meat.risk_ratios;

    updateFinalResults(
      {
        fruit: {
          question: `In a typical day, how many serves of fruit do you usually have?`,
          question_id: `fruit`,
          factor: 'nutrition',
          answer: fruitIndex,
        },
        vegetables: {
          question: `In a typical day, how many serves of vegetables (non-starchy) do you usually have?`,
          question_id: `vegetables`,
          factor: 'nutrition',
          answer: veggieIndex,
        },
        wholegrains: {
          question: `In a typical day, how many serves of wholegrain foods do you usually have?`,
          question_id: `wholegrains`,
          factor: 'nutrition',
          answer: wholegrainIndex,
        },
        red_meat: {
          question: `In a typical week, how many serves of red meat do you usually have?`,
          question_id: `red_meat`,
          factor: 'nutrition',
          answer: meatIndex,
        },
        processed_meat: {
          question: `In a typical week, how many serves of processed meat do you usually have?`,
          question_id: `processed_meat`,
          factor: 'nutrition',
          answer: processedMeatIndex,
        },
      },
      {
        fruit: {
          question_id: `fruit`,
          factor: 'nutrition',
          risk_ratios: risk_ratios_fruit,
        },
        vegetables: {
          question_id: `vegetables`,
          factor: 'nutrition',
          risk_ratios: risk_ratios_veg,
        },
        wholegrains: {
          question_id: `wholegrains`,
          factor: 'nutrition',
          risk_ratios: risk_ratios_wholegrains,
        },
        red_meat: {
          question_id: `red_meat`,
          factor: 'nutrition',
          risk_ratios: risk_ratios_red_meat,
        },
        processed_meat: {
          question_id: `processed_meat`,
          factor: 'nutrition',
          risk_ratios: risk_ratios_processed_meat,
        },
      },
    );
  }, [changes]);

  const nutritionOptions = [
    {
      id: 'fruit',
      label: 'Fruits',
      lowercase_label: 'fruit',
      example: `A standard serve is about 150g, e.g. 1x medium apple or 1x cup of tinned fruit (no added sugar)`,
      img: Apple,
      actualIndex: fruitIndex,
      settingIndex: setFruitIndex,
      max: 5,
      finalResults: finalResults.fruit.answer,
      type: 'everyday',
    },
    {
      id: 'vegetables',
      label: 'Vegetables (Non-starchy)',
      lowercase_label: 'veg',
      example: `A standard serve is about 75g, e.g. 1x cup of salad or 1/2 cup cooked veggies`,
      img: Cucumber,
      actualIndex: veggieIndex,
      settingIndex: setVeggieIndex,
      max: 7,
      finalResults: finalResults.vegetables.answer,
      type: 'everyday',
    },
    {
      id: 'wholegrains',
      label: 'Wholegrain Foods',
      lowercase_label: 'wholegrain',
      example: `A standard serve is about 500kJ, e.g. 1/2 cup (120g) cooked porridge or 1x slice of wholemeal bread`,
      img: Bread,
      actualIndex: wholegrainIndex,
      settingIndex: setWholegrainIndex,
      max: 7,
      finalResults: finalResults.wholegrains.answer,
      type: 'everyday',
    },
    {
      id: 'red_meat',
      label: 'Red Meat',
      lowercase_label: 'red meat',
      example: `A standard serve is 65g of cooked lean red meat (about 90-100g raw), e.g. of two serves: 1x porterhouse/scotch fillet steak`,
      img: Steak,
      actualIndex: meatIndex,
      settingIndex: setMeatIndex,
      max: 10,
      finalResults: finalResults.red_meat.answer,
      type: 'each week',
    },
    {
      id: 'processed_meat',
      label: 'Processed Meat',
      lowercase_label: 'processed meats',
      example: `A standard serve is about 50-60g of processed meats, e.g. 2x slices of bacon or 2x slices of salami or ham`,
      img: Bacon,
      actualIndex: processedMeatIndex,
      settingIndex: setProcessedMeatIndex,
      max: 10,
      finalResults: finalResults.processed_meat.answer,
      type: 'each week',
    },
  ];
  const [totalScore, setTotalScore] = useState(0);

  const mobileView = useMediaQuery('(max-width:850px)');
  return (
    <>
      <Grow in={true}>
        <Container>
          <SectionHeader
            title="Nutrition & Food Habits"
            subtext="Getting your daily fruit and veg?"
            activeColor="#73c04b"
            factor="nutrition"
          />

          <h3 className="mt30 mb0">
            In a typical <span className="importantText">day</span>, how many
            serves do you usually have?
          </h3>

          {nutritionOptions
            .filter((item) => item.type === 'everyday')
            .map((item, index) => {
              return (
                <div key={index} className="mt20 nutritionSection">
                  <div className="dflex flexwrap alignitemscenter justifycontentcenter gap10">
                    <h2 className="dflex mb5 gap10 alignitemscenter">
                      {item.label}{' '}
                      <img
                        style={{ height: 23, marginTop: '-9px' }}
                        src={item.img}
                        alt={item.label}
                      />
                    </h2>
                  </div>
                  <p className="sliderSubtext dflex flexwrap justifycontentcenter mt0 mb0 alignitemscenter gap10 textaligncenter">
                    {item.example}
                  </p>

                  <div
                    className={
                      (mobileView ? 'mobileViewStepper ' : '') +
                      'dflex flexwrap mt20 alignitemsnormal justifycontentcenter alignitemscenter'
                    }
                  >
                    <Slider
                      key={'slider_' + item.id}
                      aria-label={item.label}
                      defaultValue={0}
                      onChange={(e, newValue) => {
                        handleChange(newValue, item.settingIndex);
                      }}
                      value={
                        typeof item.actualIndex === 'number'
                          ? item.actualIndex
                          : 0
                      }
                      className="nutritionSliders"
                      valueLabelDisplay="auto"
                      step={1}
                      marks
                      min={0}
                      max={item.max}
                    />
                    <h3 className="ml10 mt5">
                      {item.actualIndex === item.max
                        ? `${item.actualIndex}+ serves ${item.type}`
                        : `${item.actualIndex} ${item.actualIndex === 1 ? 'serve' : 'serves'} ${item.type}`}
                    </h3>
                  </div>
                </div>
              );
            })}

          <h3 style={{ marginTop: 40 }}>
            In a typical <span className="importantText">week</span>, how many
            serves do you usually have?
          </h3>

          {nutritionOptions
            .filter((item) => item.type === 'each week')
            .map((item, index) => {
              return (
                <div key={index} className="mt20 nutritionSection">
                  <div className="dflex flexwrap alignitemscenter justifycontentcenter gap10">
                    <h2 className="dflex gap10 mb5 alignitemscenter">
                      {item.label}{' '}
                      <img
                        style={{ height: 23, marginTop: '-9px' }}
                        src={item.img}
                        alt={item.label}
                      />
                    </h2>
                  </div>
                  <p className="sliderSubtext dflex flexwrap justifycontentcenter mt0 mb0 alignitemscenter gap10 textaligncenter">
                    {item.example}
                  </p>
                  <div
                    className={
                      (mobileView ? 'mobileViewStepper ' : '') +
                      'dflex flexwrap mt20 alignitemsnormal justifycontentcenter alignitemscenter'
                    }
                  >
                    {' '}
                    <Slider
                      key={'slider_' + item.id}
                      aria-label={item.label}
                      defaultValue={0}
                      onChange={(e, newValue) => {
                        handleChange(newValue, item.settingIndex);
                      }}
                      value={
                        typeof item.actualIndex === 'number'
                          ? item.actualIndex
                          : 0
                      }
                      className="nutritionSliders"
                      valueLabelDisplay="auto"
                      step={1}
                      marks
                      min={0}
                      max={item.max}
                    />
                    <h3 className="ml10 mt5">
                      {item.actualIndex === item.max
                        ? `${item.actualIndex}+ serves ${item.type}`
                        : `${item.actualIndex} ${item.actualIndex === 1 ? 'serve' : 'serves'} ${item.type}`}
                    </h3>
                  </div>
                </div>
              );
            })}

          <div className="dflex mb10 mt30 justifycontentspacebetween">
            <Button
              variant="contained"
              className="prevBtn"
              onClick={() => {
                setActiveView('Alcohol');
              }}
            >
              Back
            </Button>

            <Grow in={true}>
              <Button
                variant="contained"
                color="primary"
                className={'nextBtn'}
                onClick={() => {
                  let score = calculateTotalScore(riskRatios, 'nutrition');
                  updateFinalResults({ nutrition_total: score });
                  setActiveView('Weight');
                  handleCompletedSteps('Nutrition');
                }}
              >
                Next
              </Button>
            </Grow>
          </div>
        </Container>
      </Grow>
    </>
  );
}

export default Nutrition;
