import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { Autocomplete, MenuItem, Paper, Select } from '@mui/material';

export default function BirthYear({
  updateFinalResults,
  finalResults,
  error,
  setError,
}) {
  //Build years that are greater than 18+ years
  const [years, setYears] = useState([]);
  const [open, setOpen] = useState(false);
  const closePopper = () => setOpen(false);
  const openPopper = () => setOpen(true);

  useEffect(() => {
    const getCurrentYear = () => new Date().getFullYear();
    const buildYearsList = () => {
      const currentYear = getCurrentYear();
      const startYear = currentYear - 18; // 18 years ago
      const endYear = currentYear - 110; //110 years ago

      const yearsList = [];
      for (let year = startYear; year >= endYear; year--) {
        yearsList.push(year.toString());
      }

      return yearsList;
    };

    setYears(buildYearsList());
  }, []);

  //Autoselect and close if user inputs found value into autocomplete
  const handleTextChange = (birth_year) => {
    var birthyear = birth_year.toString();
    var found_year = years.indexOf(birthyear) > -1;
    if (found_year) {
      handleBirthChange(birthyear);
      closePopper();
    }
  };
  const handleBirthChange = (birth_year) => {
    if (birth_year === null) {
      setError(true);
      updateFinalResults({ birth_year: '' });
      return;
    }
    setError(false);
    updateFinalResults({ birth_year: birth_year });
  };

  useEffect(() => {
    let birth_year = finalResults.birth_year;

    if (birth_year === null) {
      updateFinalResults({ age: '' });
      return;
    }
    //Calculate current age
    const currentYear = new Date().getFullYear();
    var current_age = 0;
    current_age = currentYear - birth_year;

    //Calculate age group
    var age_group = '';
    if (current_age <= 24) {
      age_group = '18-24';
    } else if (current_age <= 29) {
      age_group = '25-29';
    } else if (current_age <= 39) {
      age_group = '30-39';
    } else if (current_age <= 49) {
      age_group = '40-49';
    } else if (current_age <= 64) {
      age_group = '50-64';
    } else if (current_age <= 74) {
      age_group = '65-74';
    } else if (current_age >= 75) {
      age_group = '75+';
    }

    updateFinalResults({ age: current_age, age_group: age_group });
  }, [finalResults.birth_year]);

  return (
    <>
      <h3 className="mb10 personal-subheaders">Birth year</h3>
      <div className="mt10 alignitemscenter dflex">
        <Autocomplete
          value={finalResults.birth_year || ''}
          fullWidth
          open={open}
          onOpen={openPopper}
          onClose={closePopper}
          PaperComponent={({ children }) => (
            <Paper
              sx={{
                '& .MuiAutocomplete-listbox': { overflowX: 'hidden' },
                '.MuiInputBase-input': { fontSize: 14 },
                '& .MuiAutocomplete-listbox > li::before': {
                  color: 'transparent',
                },
              }}
              className="autocomplete"
            >
              {children}
            </Paper>
          )}
          className="personal-inputs"
          onChange={(e, newValue) => handleBirthChange(newValue)}
          options={years}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={(e) => handleTextChange(e.target.value)}
              sx={{
                '.MuiInputBase-input': { fontSize: 15 },
              }}
              InputLabelProps={{
                style: { fontSize: 15 },
                autocomplete: 'off',
                form: {
                  autocomplete: 'off',
                },
              }}
              error={error}
              label="Select *"
            />
          )}
        />
      </div>
    </>
  );
}
