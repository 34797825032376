import { TextField } from '@mui/material';
import React from 'react';

const Email = ({ finalResults, updateFinalResults, error, setError }) => {
  //Email regex and validation
  var emailRegex = /^\S+@\S+\.\S+$/;
  const handleEmailChange = (e) => {
    const email = e.target.value;
    updateFinalResults({ email_provided: true });
    setError(false);
    updateFinalResults({ email: email });
  };

  const handleBlur = (e) => {
    const email = e.target.value;

    if (email === '') {
      setError(false);
      updateFinalResults({ email_provided: false });
      return;
    }

    if (!emailRegex.test(email)) {
      setError(true);
      updateFinalResults({ email_provided: false });
      return;
    }
  };

  return (
    <div>
      <h3 className="mt0 mb10 personal-subheaders">Email</h3>
      <TextField
        sx={{
          '.MuiInputBase-input': { fontSize: 15 },
        }}
        InputLabelProps={{ style: { fontSize: 15 } }}
        id="email_input"
        style={{ width: '100%' }}
        onChange={handleEmailChange}
        onBlur={handleBlur}
        value={finalResults.email}
        error={error}
        label="Email"
        className="personal-inputs"
      />
    </div>
  );
};

export default Email;
