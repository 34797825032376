import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import physical from './pdfs/Physical.png';
import reduceRisk from './pdfs/ReduceRisk.png';
import screening from './pdfs/Screening.png';
import smoking from './pdfs/Smoking.png';
import sunSmart from './pdfs/SunSmart.png';
import alcohol from './pdfs/Alcohol.png';
import nutrition from './pdfs/Nutrition.png';

import alcohol_Korean from './pdfs/Korean/Alcohol.png';
import uv_Korean from './pdfs/Korean/UV.png';
import nutrition_Korean from './pdfs/Korean/Nutrition.png';
import physical_Korean from './pdfs/Korean/Physical.png';
import screening_Korean from './pdfs/Korean/Screening.png';
import smoking_Korean from './pdfs/Korean/Smoking.png';

import Alcohol_SimpChinese from './pdfs/SimplifiedChinese/Alcohol.png';
import Uv_SimpChinese from './pdfs/SimplifiedChinese/UV.png';
import Nutrition_SimpChinese from './pdfs/SimplifiedChinese/Nutrition.png';
import Physical_SimpChinese from './pdfs/SimplifiedChinese/Physical.png';
import Screening_SimpChinese from './pdfs/SimplifiedChinese/Screening.png';
import Smoking_SimpChinese from './pdfs/SimplifiedChinese/Smoking.png';

import Alcohol_TradChinese from './pdfs/TraditionalChinese/Alcohol.png';
import Uv_TradChinese from './pdfs/TraditionalChinese/UV.png';
import Nutrition_TradChinese from './pdfs/TraditionalChinese/Nutrition.png';
import Physical_TradChinese from './pdfs/TraditionalChinese/Physical.png';
import Screening_TradChinese from './pdfs/TraditionalChinese/Screening.png';
import Smoking_TradChinese from './pdfs/TraditionalChinese/Smoking.png';

import Alcohol_Viet from './pdfs/Vietnamese/Alcohol.png';
import Uv_Viet from './pdfs/Vietnamese/UV.png';
import Nutrition_Viet from './pdfs/Vietnamese/Nutrition.png';
import Physical_Viet from './pdfs/Vietnamese/Physical.png';
import Screening_Viet from './pdfs/Vietnamese/Screening.png';
import Smoking_Viet from './pdfs/Vietnamese/Smoking.png';

import Alcohol_Punjabi from './pdfs/Punjabi/Alcohol.png';
import Uv_Punjabi from './pdfs/Punjabi/UV.png';
import Nutrition_Punjabi from './pdfs/Punjabi/Nutrition.png';
import Physical_Punjabi from './pdfs/Punjabi/Physical.png';
import Screening_Punjabi from './pdfs/Punjabi/Screening.png';
import Smoking_Punjabi from './pdfs/Punjabi/Smoking.png';

import './ImageCarousel.css';
import { BsArrowRight } from 'react-icons/bs';
import PdfModal from './PdfModal';
import { Link } from 'react-router-dom';
const ImageCarousel = () => {
  const additionalResources = [
    {
      id: 'ReduceRisk',
      language: 'English',
      read_more: 'Read more',
      image: reduceRisk,
      url: `https://cancerqld.org.au/wp-content/uploads/2023/02/22041_Lifestyle_6_Reducing-Your-Cancer-Risk_A5.pdf`,
    },
    {
      id: 'Uv',
      language: 'English',
      read_more: 'Read more',
      image: sunSmart,
      url: `https://cancerqld.org.au/content/resources/library/Lifestyle_6_Factsheet_Protecting_your_skin_from_skin_cancer_A4.pdf`,
    },
    {
      id: 'Smoking',
      language: 'English',
      read_more: 'Read more',
      image: smoking,
      url: `https://cancerqld.org.au/content/resources/library/Lifestyle_6_Factsheet_Smoking_and_cancer_risk_A4.pdf`,
    },
    {
      id: 'Alcohol',
      language: 'English',
      read_more: 'Read more',
      image: alcohol,
      url: `https://cancerqld.org.au/content/resources/library/Lifestyle_6_Factsheet_Alcohol_and_cancer_risk_A4.pdf`,
    },
    {
      id: 'Nutrition',
      language: 'English',
      read_more: 'Read more',
      image: nutrition,
      url: `https://cancerqld.org.au/content/resources/library/Lifestyle_6_Factsheet_Healthy_eating_and_reducing_your_cancer_risk_A4.pdf`,
    },
    {
      id: 'Physical',
      language: 'English',
      read_more: 'Read more',
      image: physical,
      url: `https://cancerqld.org.au/content/resources/library/Lifestyle_6_Factsheet_Physical_activity_to_reduce_your_cancer_risk_A4.pdf`,
    },
    {
      id: 'Screening',
      language: 'English',
      read_more: 'Read more',
      image: screening,
      url: `https://cancerqld.org.au/content/resources/library/Lifestyle_6_Factsheet_Bowel_cancer_screening_A4.pdf`,
    },
    {
      id: 'Uv_Korean',
      language: 'Korean',
      read_more: '',
      image: uv_Korean,
      url: `https://cancerqld.org.au/content/resources/library/22041_Lifestyle_6_Factsheet_Protecting%20your_skin_from_skin_cancer_A4_Korean%20-%20Web.pdf`,
    },
    {
      id: 'Smoking_Korean',
      language: 'Korean',
      read_more: '',
      image: smoking_Korean,
      url: `https://cancerqld.org.au/content/resources/library/22041_Lifestyle_6_Factsheet_Smoking_and_cancer_risk_A4_Korean%20-%20Web.pdf`,
    },
    {
      id: 'Alcohol_Korean',
      language: 'Korean',
      read_more: '',
      image: alcohol_Korean,
      url: `https://cancerqld.org.au/content/resources/library/22041_Lifestyle_6_Factsheet_Alcohol_and_cancer_risk_A4_Korean%20-%20Web.pdf`,
    },
    {
      id: 'Nutrition_Korean',
      language: 'Korean',
      read_more: '',
      image: nutrition_Korean,
      url: `https://cancerqld.org.au/content/resources/library/22041_Lifestyle_6_Factsheet_Healthy_eating_and_reducing_A4_Korean%20-%20Web.pdf`,
    },
    {
      id: 'Physical_Korean',
      language: 'Korean',
      read_more: '',
      image: physical_Korean,
      url: `https://cancerqld.org.au/content/resources/library/22041_Lifestyle_6_Factsheet_Physical_activity_to_reduce_your_cancer_risk_A4_Korean%20-%20Web.pdf`,
    },
    {
      id: 'Screening_Korean',
      language: 'Korean',
      read_more: '',
      image: screening_Korean,
      url: `https://cancerqld.org.au/content/resources/library/22041_Lifestyle_6_Factsheet_Bowel_cancer_screening_A4_Korean%20-%20Web.pdf`,
    },
    {
      id: 'Uv_SimpChinese',
      language: 'SimplifiedChinese',
      read_more: '',
      image: Uv_SimpChinese,
      url: `https://cancerqld.org.au/content/resources/library/22041_Lifestyle_6_Factsheet_Protecting%20your_skin_from_skin_cancer_A4_Chinese%20(simplified_%20-%20Web.PDF`,
    },
    {
      id: 'Smoking_SimpChinese',
      language: 'SimplifiedChinese',
      read_more: '',
      image: Smoking_SimpChinese,
      url: `https://cancerqld.org.au/content/resources/library/22041_Lifestyle_6_Factsheet_Smoking_and_cancer_risk_A4_Chinese%20(Simplified)%20-%20Web.pdf`,
    },
    {
      id: 'Alcohol_SimpChinese',
      language: 'SimplifiedChinese',
      read_more: '',
      image: Alcohol_SimpChinese,
      url: `https://cancerqld.org.au/content/resources/library/22041_Lifestyle_6_Factsheet_Alcohol_and_cancer_risk_A4_Chinese%20(Simplified)%20-%20Web.pdf`,
    },
    {
      id: 'Nutrition_SimpChinese',
      language: 'SimplifiedChinese',
      read_more: '',
      image: Nutrition_SimpChinese,
      url: `https://cancerqld.org.au/content/resources/library/22041_Lifestyle_6_Factsheet_Healthy_eating_and_reducing_A4_Chinese%20(Simplified)%20-%20Web.pdf`,
    },
    {
      id: 'Physical_SimpChinese',
      language: 'SimplifiedChinese',
      read_more: '',
      image: Physical_SimpChinese,
      url: `https://cancerqld.org.au/content/resources/library/22041_Lifestyle_6_Physical_activity_to_reduce_your_cancer_risk_A4_Chinese(Simplified)%20-%20Web.PDF`,
    },
    {
      id: 'Screening_SimpChinese',
      language: 'SimplifiedChinese',
      read_more: '',
      image: Screening_SimpChinese,
      url: `https://cancerqld.org.au/content/resources/library/22041_Lifestyle_6_Factsheet_Bowel_cancer_screening_A4_Chinese%20(Simplified)%20-%20Web.pdf`,
    },
    {
      id: 'Uv_TradChinese',
      language: 'TraditionalChinese',
      read_more: '',
      image: Uv_TradChinese,
      url: `https://cancerqld.org.au/content/resources/library/22041_Lifestyle_6_Factsheet_Protecting%20your%20_skin_from_skin_cancer_A4_Chinese%20(Traditional)%20-%20Web.PDF`,
    },
    {
      id: 'Smoking_TradChinese',
      language: 'TraditionalChinese',
      read_more: '',
      image: Smoking_TradChinese,
      url: `https://cancerqld.org.au/content/resources/library/22041_Lifestyle_6_Factsheet_Smoking_and_cancer_risk_A4_Chinese%20(Traditional)%20-%20Web.pdf`,
    },
    {
      id: 'Alcohol_TradChinese',
      language: 'TraditionalChinese',
      read_more: '',
      image: Alcohol_TradChinese,
      url: `https://cancerqld.org.au/content/resources/library/22041_Lifestyle_6_Factsheet_Alcohol_and_cancer_risk_A4_Chinese%20(Traditional)%20-%20Web.pdf`,
    },
    {
      id: 'Nutrition_TradChinese',
      language: 'TraditionalChinese',
      read_more: '',
      image: Nutrition_TradChinese,
      url: `https://cancerqld.org.au/content/resources/library/22041_Lifestyle_6_Factsheet_Healthy_eating_and_reducing_A4_Chinese%20(Traditional)%20-%20Web.pdf`,
    },
    {
      id: 'Physical_TradChinese',
      language: 'TraditionalChinese',
      read_more: '',
      image: Physical_TradChinese,
      url: `https://cancerqld.org.au/content/resources/library/22041_Lifestyle_6_Factsheet_Physical_activity_to_reduce_your_cancer_ris_A4_Chinese%20(Traditional)%20-%20Web.PDF`,
    },
    {
      id: 'Screening_TradChinese',
      language: 'TraditionalChinese',
      read_more: '',
      image: Screening_TradChinese,
      url: `https://cancerqld.org.au/content/resources/library/22041_Lifestyle_6_Factsheet_Bowel_cancer_screening_A4_Chinese%20(Traditional)%20-%20Web.pdf`,
    },
    {
      id: 'Uv_Viet',
      language: 'Vietnamese',
      read_more: '',
      image: Uv_Viet,
      url: `https://cancerqld.org.au/content/resources/library/22041_Lifestyle_6_Factsheet_Protecting%20your_skin_from_skin_cancer_A4_Vietnamese%20-%20Web.pdf`,
    },
    {
      id: 'Smoking_Viet',
      language: 'Vietnamese',
      read_more: '',
      image: Smoking_Viet,
      url: `https://cancerqld.org.au/content/resources/library/22041_Lifestyle_6_Factsheet_Smoking_and_cancer_risk_A4_Vietnamese%20-%20Web.pdf`,
    },
    {
      id: 'Alcohol_Viet',
      language: 'Vietnamese',
      read_more: '',
      image: Alcohol_Viet,
      url: `https://cancerqld.org.au/content/resources/library/22041_Lifestyle_6_Factsheet_Alcohol_and_cancer_risk_A4_Vietnamese%20-%20Web.pdf`,
    },
    {
      id: 'Nutrition_Viet',
      language: 'Vietnamese',
      read_more: '',
      image: Nutrition_Viet,
      url: `https://cancerqld.org.au/content/resources/library/22041_Lifestyle_6_Factsheet_Healthy_eating_and_reducing_A4_Vietnamese%20-%20Web.pdf`,
    },
    {
      id: 'Physical_Viet',
      language: 'Vietnamese',
      read_more: '',
      image: Physical_Viet,
      url: `https://cancerqld.org.au/content/resources/library/22041_Lifestyle_6_Factsheet_Physical_activity_to_reduce_your_cancer_risk_A4_Vietnamese%20-%20Web.pdf`,
    },
    {
      id: 'Screening_Viet',
      language: 'Vietnamese',
      read_more: '',
      image: Screening_Viet,
      url: `https://cancerqld.org.au/content/resources/library/22041_Lifestyle_6_Factsheet_Bowel_cancer_screening_A4_Vietnamese%20-%20Web.pdf`,
    },
    {
      id: 'Uv_Punjabi',
      language: 'Punjabi',
      read_more: '',
      image: Uv_Punjabi,
      url: `https://cancerqld.org.au/content/resources/library/22041_Lifestyle_6_Factsheet_Protecting%20your_skin_from_skin_cancer_A4_Punjabi%20-%20Web.pdf`,
    },
    {
      id: 'Smoking_Punjabi',
      language: 'Punjabi',
      read_more: '',
      image: Smoking_Punjabi,
      url: `https://cancerqld.org.au/content/resources/library/22041_Lifestyle_6_Factsheet_Smoking_and_cancer_risk_A4_Punjabi%20-%20Web.pdf`,
    },
    {
      id: 'Alcohol_Punjabi',
      language: 'Punjabi',
      read_more: '',
      image: Alcohol_Punjabi,
      url: `https://cancerqld.org.au/content/resources/library/22041_Lifestyle_6_Factsheet_Alcohol_and_cancer_risk_A4_Punjabi%20-%20Web.pdf`,
    },
    {
      id: 'Nutrition_Punjabi',
      language: 'Punjabi',
      read_more: '',
      image: Nutrition_Punjabi,
      url: `https://cancerqld.org.au/content/resources/library/22041_Lifestyle_6_Factsheet_Healthy_eating_and_reducing_A4_Punjabi%20-%20Web.pdf`,
    },
    {
      id: 'Physical_Punjabi',
      language: 'Punjabi',
      read_more: '',
      image: Physical_Punjabi,
      url: `https://cancerqld.org.au/content/resources/library/22041_Lifestyle_6_Factsheet_Physical_activity_to_reduce_your_cancer_risk_A4_Punjabi%20-%20Web.pdf`,
    },
    {
      id: 'Screening_Punjabi',
      language: 'Punjabi',
      read_more: '',
      image: Screening_Punjabi,
      url: `https://cancerqld.org.au/content/resources/library/22041_Lifestyle_6_Factsheet_Bowel_cancer_screening_A4_Punjabi%20-%20Web.pdf`,
    },
  ];

  const scrollContainerRef = useRef(null);
  const [openModal, setOpen] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState('English');

  const handleOpen = (id) => {
    setOpen(id);
  };
  const handleClose = () => {
    setOpen(null);
  };

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);
  };

  return (
    <div className="mobile5">
      <div className="dflex alignitemscenter justifycontentspacebetween resourcesHeader">
        <h1 style={{ marginTop: 50 }}>Resources & Information</h1>
        <FormControl className="languageselector">
          <InputLabel>Select a language</InputLabel>{' '}
          <Select
            value={selectedLanguage}
            onChange={handleLanguageChange}
            style={{ width: 180, fontSize: 14 }}
            label="Select a language"
          >
            <MenuItem value="English">English</MenuItem>
            <MenuItem value="Korean">Korean</MenuItem>
            <MenuItem value="SimplifiedChinese">Simplified Chinese</MenuItem>
            <MenuItem value="TraditionalChinese">Traditional Chinese</MenuItem>
            <MenuItem value="Vietnamese">Vietnamese</MenuItem>
            <MenuItem value="Punjabi">Punjabi</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div className="image-carousel-container">
        <div ref={scrollContainerRef} className="image-carousel">
          {additionalResources
            .filter((item) => item.language === selectedLanguage)
            .map((image, index) => (
              <>
                <div
                  onClick={() => handleOpen(image.id)}
                  className="pdf-item"
                  key={index}
                >
                  <img
                    height="300"
                    component="img"
                    src={image.image}
                    alt={image.id}
                  />
                  <div className="dflex justifycontentstart pdf-bottom">
                    <p style={{ marginLeft: 10 }} className=" pdf-btn">
                      {image.read_more}
                      <BsArrowRight className="pdf-arrow-right" />
                    </p>
                  </div>
                </div>
                <PdfModal
                  open={openModal === image.id}
                  url={image.url}
                  handleClose={handleClose}
                />
              </>
            ))}
        </div>
        <p style={{ textAlign: 'right' }} className="mt10">
          View all our fact sheets and translated resource library{' '}
          <Link
            target="_blank"
            to="https://cancerqld.org.au/cancer-prevention/programs-resources/lifestyle6/"
            className="link "
          >
            here.
          </Link>
        </p>
      </div>
    </div>
  );
};

export default ImageCarousel;
