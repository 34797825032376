import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { FormControl, InputAdornment } from '@mui/material';
import BmiModal from './components/BmiModal';

const QuestionsWeight = ({ updateFinalResults, finalResults }) => {
  const handleChange = (key, event) => {
    var value = event.target.value;
    if (value.length > 5) {
      return;
    }
    updateFinalResults({
      [key]: value,
    });
  };

  const [bmiText, setBmiText] = useState('BMI:');

  const calculateBMI = () => {
    const weight = finalResults.weight;
    const height = finalResults.height;
    var bmi = '';
    if (height === '' || isNaN(height) || height == 0 || height < 40) {
      setBmiText('BMI:');
    } else if (weight === '' || isNaN(weight) || weight == 0 || weight < 20) {
      setBmiText('BMI:');
    } else {
      //Caculate BMI Classification
      bmi = (weight / ((height * height) / 10000)).toFixed(2);

      setBmiText(`BMI: ${bmi}`);
    }

    updateFinalResults({
      bmi: bmi,
    });
  };

  useEffect(() => {
    calculateBMI();
  }, [finalResults.weight, finalResults.height]);

  return (
    <>
      <div className="mt20 alignitemscenter flexwrap justifycontentcenter dflex">
        <TextField
          sx={{ width: '200px', '.MuiInputBase-input': { fontSize: 15 } }}
          InputProps={{
            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            maxLength: 5,
            inputProps: { min: 0, max: 500 },
          }}
          InputLabelProps={{ style: { fontSize: 15 } }}
          type="number"
          id="height"
          onKeyUp={calculateBMI}
          onChange={(event) => {
            handleChange('height', event);
          }}
          value={finalResults.height}
          label="Height"
        />
        <TextField
          sx={{ width: '200px', '.MuiInputBase-input': { fontSize: 15 } }}
          InputProps={{
            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            maxLength: 10,
            inputProps: { min: 0, max: 500 },
          }}
          InputLabelProps={{ style: { fontSize: 15 } }}
          type="number"
          id="weight"
          onChange={(event) => {
            handleChange('weight', event);
          }}
          onKeyUp={calculateBMI}
          value={finalResults.weight}
          label="Weight"
        />
      </div>
      {/* <BmiModal /> */}
      <h3 className="textaligncenter">{bmiText}</h3>
    </>
  );
};

export default QuestionsWeight;
