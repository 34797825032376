import React, { useState } from 'react';
import '../LearnMore.css';
import AnswerResponse from './AnswerResponse';
import { Link } from 'react-router-dom';
import PdfModal from '../PdfModal';
import SmokingChart from '../../charts/SmokingChart';

const SmokingAnswers = ({ finalResults, factor }) => {
  const answers = Object.values(finalResults).filter(
    (res) => res.factor === 'smoking',
  );

  const [openModal, setOpen] = useState(null);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(null);
  };
  return (
    <div className="subtext">
      <h4 className="learnMoreHeader mt0 mb10">Did you know:</h4>
      <li>
        Cigarette smoke contains over 7,000 chemicals and 70 of them can cause
        cancer.
      </li>
      <li>
        Quitting smoking is one of the most important things you can do to
        reduce your risk of cancer.
      </li>
      <li>
        Smoking around others can expose them to the same dangeous chemicals.{' '}
        <span onClick={handleOpen} className="link ">
          Learn more
        </span>{' '}
        about second-hand smoke.
      </li>
      <li>
        E-cigarettes (vapes) are not "just water vapor". They can contain
        chemicals like propylene glycol and glycerin. We know when these
        chemicals get heated, they can make chemicals like formaldehyde, which
        can cause cancer. We also know that non-smokers are more likely to start
        smoking if they use vapes. The long-term health effects of e-cigarettes
        are still unknown.
      </li>
      <PdfModal
        open={openModal}
        url="https://cancerqld.org.au/content/resources/library/Lifestyle_6_Factsheet_Second-hand_smoke_A4.pdf"
        handleClose={handleClose}
      />

      <div className="answersSection">
        <h4 className="learnMoreHeader mt20 mb0">Your Answers:</h4>
        {answers.map((item) => {
          return (
            <>
              <AnswerResponse item={item} />
            </>
          );
        })}
      </div>

      <div>
        <h4 className="learnMoreHeader mt30 mb10">Tips & Resources:</h4>
        <p className="subtext mt0 mb10">
          Remember, quitting smoking is a journey, not a destination. Some
          smokers may need up to 30 quit attempts before they give up for good.
          Be patient, offer unconditional support, and celebrate any progress
          made along the way.
        </p>
        <p className="mt0">
          Some tips to help you or a friend to quit smoking:
        </p>
        <li>
          Stay motivated. Write down reasons for quitting and refer to them when
          having strong cravings.
        </li>
        <li>
          Remove temptations. Dispose of anything that might trigger the urge to
          smoke. This includes cigarettes, vapes, lighters and, ashtrays. Also
          consider places and people that might trigger the urge to smoke.
        </li>
        <li>
          Prepare mentally. Set a quit date and record smoking behaviours to
          understand triggers and habits.
        </li>
        <li>
          Seek support. Reach out to family, friends or your doctor for support
          and encouragement.
        </li>
        <p className="mt10">
          If you or someone you know would like to quit, call{' '}
          <Link
            className="link "
            target="_blank"
            to="https://www.quithq.initiatives.qld.gov.au/"
          >
            Quitline
          </Link>{' '}
          today on{' '}
          <Link className="link " to="tel:137848">
            137 848.
          </Link>{' '}
          Quitline also offers a free quit smoking program for all Aboriginal
          and Torres Strait Islanders in Queensland -{' '}
          <Link
            className="link "
            target="_blank"
            to="https://www.health.qld.gov.au/__data/assets/pdf_file/0020/443243/quitline-aboriginal-torresstrait.pdf"
          >
            Yarn it up with Quitline.
          </Link>{' '}
        </p>
      </div>

      <SmokingChart />
    </div>
  );
};

export default SmokingAnswers;
