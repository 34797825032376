import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import './LandingPage.css';
import './LandingPageMobile.css';
import petal1 from './assets/petal-darkblue.png';
import petal2 from './assets/petal-lightblue.png';
import petal3 from './assets/petal-yellow.png';

import { Autocomplete, Grow, Paper, TextField } from '@mui/material';
import AustraliaMap from './components/AustraliaMap';
import EmailModal from './components/EmailModal';
import CCQ_Logo2 from '../../../assets/images/CCQ_Blue_Logo.png';
import CCQ_Logo from '../../../assets/images/Cancer-Council-Logo.png';

import { generateGuid } from '../../../utils/util';
import { Link, useLocation } from 'react-router-dom';
import { finalResultsObject } from '../../../utils/finalResults';
import { countries } from './components/Countries';
import { TbArrowRight } from 'react-icons/tb';
import IconPetalMobile from '../../navbar/IconBlobPetalMobile';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function LandingPageMobile({
  setActiveView,
  finalResults,
  updateFinalResults,
  setfinalResults,
}) {
  let query = useQuery();
  const [emailReOpen, setEmailReOpen] = useState(false);
  const [error, setError] = useState(false);

  const handleViewChange = async () => {
    if (
      (finalResults.state === '' && finalResults.country === 'Australia') ||
      (finalResults.country !== 'Australia' && finalResults.country === '')
    ) {
      setError(true);
      return;
    }
    //Change view
    setActiveView('Personal');

    //Create user guid
    const finalResultsId = generateGuid();
    var event_code = query.get('event');
    if (event_code === null || event_code === undefined) {
      event_code = '';
    }
    //Log to Logic App
    updateFinalResults({
      id: finalResultsId,
      event_code: event_code,
      accepted_disclaimer: true,
    });
  };

  useEffect(() => {
    setfinalResults(finalResultsObject);
    var returning = query.get('returning');
    if (returning === 'true') {
      setEmailReOpen(true);
    }
  }, []);

  useEffect(() => {
    setError(false);
  }, [finalResults.state, finalResults.country]);

  const australianStates = [
    {
      state_code: 'QLD',
      state: 'Queensland',
    },
    {
      state_code: 'NSW',
      state: 'New South Wales',
    },
    {
      state_code: 'VIC',
      state: 'Victoria',
    },
    {
      state_code: 'TAS',
      state: 'Tasmania',
    },
    {
      state_code: 'SA',
      state: 'South Australia',
    },
    {
      state_code: 'WA',
      state: 'Western Australia',
    },
    {
      state_code: 'NT',
      state: 'Northern Territory',
    },
    {
      state_code: 'ACT',
      state: 'Australian Capital Territory',
    },
  ];

  const [australiaState, setAustraliaState] = useState('');
  const [australiaStateFull, setAustraliaStateFull] = useState('');

  const unselectedState = 'Select your state or territory';
  const selectedState = `${finalResults.state}`;

  //If state is found in URL parameters, autoselect the state
  //For example cancer-risk-calculator.org?state=QLD
  useEffect(() => {
    var preselectedState = query.get('state');
    if (preselectedState !== null && preselectedState !== undefined) {
      preselectedState = preselectedState.toUpperCase();
      const stateData = australianStates.find(
        (item) => item.state_code === preselectedState,
      );
      if (stateData) {
        setAustraliaState(preselectedState);
        setAustraliaStateFull(stateData.state);
      }
    }
  }, []);

  useEffect(() => {
    updateFinalResults({
      state_code: australiaState,
      state: australiaStateFull,
      country: 'Australia',
    });
  }, [australiaStateFull]);

  const handleCountryChange = (country) => {
    if (country === null) {
      updateFinalResults({
        country: 'Australia',
      });
      return;
    }

    updateFinalResults({
      country: country,
      state_code: '',
      state: '',
    });
    setAustraliaState('');
  };

  const CancerCouncilPrivacyLinks = (data) => {
    const state = data.selectedState;
    const link = data.hrefLink;
    return (
      <>
        Cancer Council {state}{' '}
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="link "
        >
          Privacy Policies.
        </a>
      </>
    );
  };

  return (
    <div>
      <div className="topMobileSection ">
        <img
          className="ccq-logo-mobile mt20"
          alt="Cancer Council Logo"
          src={CCQ_Logo}
          style={{ marginLeft: '12px' }}
        />
        <img
          className="ccq-logo-mobile mt20"
          alt="CCQ Logo"
          src={CCQ_Logo2}
          style={{ zIndex: 2 }}
        />

        <img className="petalone" alt="p" src={petal1} />
        <img className="petaltwo" alt="p" src={petal2} />

        <div>
          <Grow in={true} className="landingpage-main">
            <div>
              <div
                style={{ marginTop: '-50px' }}
                className="dflex gap10 alignitemscenter mobileIconPetal"
              >
                <IconPetalMobile
                  className="mobileIconPetal"
                  activeView="LandingPage"
                />
                <h1 className="landing-page-crc">Cancer Risk Calculator</h1>
              </div>

              <div className="welcome-container">
                <h2 className="hello-title">
                  Hello, kia ora, 你好 <span class="wave">👋</span>
                </h2>
                <p className="mb20">
                  Over the next few minutes, we will ask you some questions
                  across the Lifestyle6 factors to help you lower your risk of
                  cancer and detect it early.
                </p>
              </div>
            </div>
          </Grow>
        </div>

        <button
          onClick={(e) => {
            document.getElementById('bottomMobileSection').scrollIntoView({
              behavior: 'smooth',
            });
          }}
          className="begin-mobile mobile-lp-btn"
          variant="contained"
        >
          Let's Begin
        </button>
      </div>

      <div id="bottomMobileSection">
        <img className="petalyellow" alt="p" src={petal3} />

        <Container disableGutters className="country-form ">
          <h1 className="mobileSel">
            {australiaState !== '' ? selectedState : unselectedState}
          </h1>
          <div className="dflex textaligncenter alignitemscenter flexwrap justifycontentcenter">
            <AustraliaMap
              updateFinalResults={updateFinalResults}
              finalResults={finalResults}
              australiaState={australiaState}
              australianStates={australianStates}
              setAustraliaState={setAustraliaState}
            />
            <div style={{ marginBottom: '20px' }} className="dflex flexwrap">
              <h2 style={{ marginTop: 20, fontSize: 17 }}>OR</h2>
              <Autocomplete
                value={
                  finalResults.country === 'Australia'
                    ? ''
                    : finalResults.country || null
                }
                openOnFocus
                style={{ width: 200 }}
                defaultValue=""
                onChange={(e, newValue) => {
                  handleCountryChange(newValue);
                }}
                PaperComponent={({ children }) => (
                  <Paper
                    sx={{
                      '.MuiInputBase-input': { fontSize: 14 },
                      '& .MuiAutocomplete-listbox > li::before': {
                        color: 'transparent',
                      },
                      '& .MuiAutocomplete-listbox': { overflowX: 'hidden' },
                    }}
                    className="autocomplete-items"
                  >
                    {children}
                  </Paper>
                )}
                sty
                className="autocomplete"
                options={countries}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="autocomplete-textfield"
                    label="Select Country"
                    sx={{
                      '.MuiInputBase-input': { fontSize: '14px' },
                    }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                  />
                )}
              />
            </div>
          </div>
        </Container>
        <div>
          <p className="mb30 mt30 p5 textaligncenter">
            The Cancer Risk Calculator is a free online tool that is for
            informational and educational purposes only, providing generalised
            recommendations for individuals aged{' '}
            <span className="highlighted-text">18 years and older</span>. It
            does not constitute as medical health advice and does not predict
            whether you will get cancer. Read our{' '}
            <Link
              className="link "
              target="_blank"
              to="https://cancerqld.org.au/about-us/our-disclaimer/"
            >
              Disclaimer.{' '}
            </Link>{' '}
            {australiaState === 'TAS' && (
              <CancerCouncilPrivacyLinks
                selectedState="Tasmania"
                hrefLink="https://www.cancer.org.au/about-us/about-cancer-council/tas/privacy-policy"
              />
            )}
          </p>

          {error && (
            <div
              style={{ marginBottom: '-30px' }}
              className="error-text dflex ml20 justifycontentstart "
            >
              <p>Please select a state, territory or country</p>
            </div>
          )}

          <div style={{ marginBottom: 60, marginTop: 50 }} className="dflex">
            <button
              type="submit"
              onClick={handleViewChange}
              className="mobile-lp-btn continue-btn dflex justifycontentcenter alignitemscenter"
              fullWidth
            >
              Accept and Continue{' '}
              <TbArrowRight
                style={{ fontSize: 25, marginLeft: -10, marginTop: -6 }}
              />
            </button>
          </div>
          <div className="ml20">
            <EmailModal
              emailReOpen={emailReOpen}
              setEmailReOpen={setEmailReOpen}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPageMobile;
