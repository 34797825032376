// Questions and risk ratios found below
// Please refer to documentation before changing any questions or risk ratios
// All risk ratios decided upon by research team - any changes must be consulted with research
// Additional population scores are needed for the equation to calculate final score (found in documentation + calculateScores.js)
export const configQuestionsObject = [
  {
    id: `sex`,
    factor: `personal`,
    question: `What was your sex assigned at birth?`,
    displayed: () => true,
    options: [{ answer: `Male` }, { answer: `Female` }, { answer: `Other` }],
  },
  {
    id: `gender`,
    factor: `personal`,
    question: `What best describes your gender identity?`,
    displayed: () => true,
    options: [
      { answer: `Male` },
      { answer: `Female` },
      { answer: `Non-binary` },
      { answer: `Other` },
    ],
  },
  {
    id: `aboriginal_torres_strait_identity`,
    factor: `personal`,
    question: `Are you of Aboriginal and/or Torres Strait Islander origin?`,
    displayed: () => true,
    options: [
      { answer: `No` },
      { answer: `Aboriginal` },
      { answer: `Torres Strait Islander` },
      { answer: `Both` },
      { answer: `Prefer not to answer` },
    ],
  },
  {
    id: `birth_country`,
    factor: `personal`,
    question: `Where were you born?`,
    displayed: () => true,
    options: [],
  },
  {
    id: `primary_language`,
    factor: `personal`,
    question: `What is your primary language? Ie: the language you speak most at home`,
    displayed: () => true,
    options: [],
  },
  {
    id: `uv_sunscreen`,
    factor: `uv`,
    question: `When you're outside in the sun, how often do you slop on sunscreen (SPF50 or above)?`,
    displayed: () => true,
    options: [
      {
        answer: `Every 2 hours`,
        response: `I slop on sunscreen every 2 hours`,
        recommendation: `Great! It's important to apply 20 minutes before heading outside and re-apply every two hours.`,
        good_response: true,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `Every 2-4 hours`,
        response: `I slop on sunscreen every 2-4 hours`,
        recommendation: `You should apply 20 minutes before heading outside and re-apply every two hours.`,
        good_response: false,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `Every 4+ hours`,
        response: `I slop on sunscreen every 4+ hours`,
        recommendation: `You should apply 20 minutes before heading outside and re-apply every two hours.`,
        good_response: false,
        skin_rr: 0.57,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `I don't slop on sunscreen`,
        response: `I don't slop on sunscreen`,
        recommendation: `You should apply 20 minutes before heading outside and re-apply every two hours.`,
        good_response: false,
        skin_rr: 0.57,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
    ],
  },
  {
    id: `uv_tanning`,
    factor: `uv`,
    question: `How often do you sunbathe with the intention to get tanned?`,
    displayed: () => true,
    options: [
      {
        answer: `Never`,
        response: `I don't sunbathe/tan.`,
        recommendation: ``,
        good_response: true,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `Rarely`,
        response: `I rarely sunbathe/tan`,
        recommendation: `Avoid tanning. UV radiation from the sun can change your skin cells and cause lasting damage over time.`,
        good_response: false,
        skin_rr: 0.5,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `Occasionally`,
        response: `I occasionally sunbathe/tan`,
        recommendation: `Avoid tanning. UV radiation from the sun can change your skin cells and cause lasting damage over time.`,
        good_response: false,
        skin_rr: 0.57,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `Often`,
        response: `I regularly sunbathe/tan`,
        recommendation: `Avoid tanning. UV radiation from the sun can change your skin cells and cause lasting damage over time.`,
        good_response: false,
        skin_rr: 0.57,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
    ],
  },
  {
    id: `uv_outside_duration`,
    factor: `uv`,
    question: `How long do you typically stay in the sun between 10am and 3pm?`,
    displayed: () => true,
    options: [
      {
        answer: `Less than 30 minutes`,
        response: `I typically stay in the sun less than 30 minutes between 10am and 3pm.`,
        recommendation: `UV levels are highest during midday hours. Remember all 5 sun protective behaviours when you’re outside.`,
        good_response: true,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `30-60 minutes`,
        response: `I typically stay in the sun for 30-60mins between 10am and 3pm.`,
        recommendation: `UV levels are highest during midday hours. Remember all 5 sun protective behaviours when you’re outside.`,
        good_response: true,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `1-3 hours`,
        response: `I typically stay in the sun for 1-3 hours between 10am and 3pm.`,
        recommendation: `UV levels are highest during midday hours. Remember all 5 sun protective behaviours when you’re outside.`,
        good_response: false,
        skin_rr: 0.161,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `More than 3 hours`,
        response: `I typically stay in the sun for 3+ hours between 10am and 3pm`,
        recommendation: `UV levels are highest during midday hours. Remember all 5 sun protective behaviours when you’re outside.`,
        good_response: false,
        skin_rr: 0.161,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
    ],
  },
  {
    id: `smoking_status`,
    factor: `smoking`,
    question: `Do you smoke cigarettes?`,
    displayed: () => true,
    options: [
      {
        answer: `No, never smoked`,
        response: `I don't smoke cigarettes.`,
        recommendation: ``,
        good_response: true,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `I quit smoking`,
        answer_info: `Less than 1 year ago`,
        response: `I quit smoking less than 1 year ago.`,
        recommendation: `Congratulations on quitting! Every year you quit, your risk decreases. Due to your history, there is a risk present. It's important to have regular health checks with your doctor.`,
        good_response: true,
        skin_rr: -0.143,
        bowel_rr: 0.068,
        lung_rr: 0.754171,
        prostate_rr: 0.152,
        breast_rr: 0.041,
      },
      {
        answer: `Yes, occasionally`,
        response: `I smoke cigarettes occasionally.`,
        recommendation: `There's is no safe level when it comes to smoking and cancer. A variety of supports are available to quit. We encourage you to find what supports are suitable for you and your situation.`,
        good_response: false,
        skin_rr: -0.143,
        bowel_rr: 0.057,
        lung_rr: 0.9265,
        prostate_rr: 0.152,
        breast_rr: 0.041,
      },
      {
        answer: `Yes, regularly`,
        response: `I smoke cigarettes regularly.`,
        recommendation: `A variety of supports are available to quit. We encourage you to find what supports are suitable for your situation and quit. Quitting can be hard, but every try teaches you something and brings you closer to quitting for good.`,
        good_response: false,
        skin_rr: -0.143,
        bowel_rr: 0.057,
        lung_rr: 0.9265,
        prostate_rr: 0.152,
        breast_rr: 0.041,
      },
    ],
  },
  {
    id: `smoking_household`,
    factor: `smoking`,
    question: `Is your household smoke-free?`,
    displayed: () => true,
    options: [
      {
        answer: `Yes`,
        response: `My household is smoke-free.`,
        recommendation: ``,
        good_response: true,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `No`,
        response: `My household is not smoke-free.`,
        recommendation: `Chemicals from smoking can remain on surfaces up to 3 months. If you choose to smoke, smoke outside. Encourage other smokers in the household to do the same. Consider starting a conversation around quitting.`,
        good_response: false,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0.107,
        prostate_rr: 0,
        breast_rr: 0,
      },
    ],
  },
  {
    id: `vaping_status`,
    factor: `smoking`,
    question: `Do you vape e-cigarettes?`,
    displayed: () => true,
    options: [
      {
        answer: `No, never vaped`,
        response: `I don't vape e-cigarettes.`,
        recommendation: ``,
        good_response: true,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `I quit vaping`,
        response: `I quit vaping less than 1 year ago.`,
        answer_info: `Less than 1 year ago`,
        recommendation: `Congratulations on quitting! It's important to have regular health checks with your doctor as the long-term effects of smoking e-cigarettes are still unknown.`,
        good_response: true,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `Yes, occasionally`,
        response: `I vape e-cigarettes occasionally.`,
        recommendation: `Vaping isn't included in your smoking score as we still don’t know all the long-term health effects of e-cigarettes yet. We do know that vapes can harm health and may lead to nicotine addiction. A variety of supports are available to quit.`,
        good_response: false,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `Yes, regularly`,
        response: `I vape e-cigarettes regularly.`,
        recommendation: `Vaping isn't included in your smoking score as we still don’t know all the long-term health effects of e-cigarettes yet. We do know that vapes can harm health and may lead to nicotine addiction. A variety of supports are available to quit.`,
        good_response: false,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
    ],
  },
  {
    id: `vaping_household`,
    factor: `smoking`,
    question: `Is your household vape-free?`,
    displayed: () => true,
    options: [
      {
        answer: `Yes`,
        response: `My household is vape-free.`,
        recommendation: ``,
        good_response: true,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `No`,
        response: `My household is not vape-free.`,
        recommendation: `Chemicals from e-cigarettes can remain on surfaces for some time. If you choose to vape, vape outside. Encourage other vapers in the household to do the same. It is not included in your score as we still don’t know all the long-term health effects of vapes.`,
        good_response: false,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
    ],
  },
  {
    id: `alcohol_status`,
    factor: `alcohol`,
    question: `How often do you have a drink containing alcohol?`,
    displayed: () => true,
    options: [
      {
        answer: `Never`,
        response: `I don't drink alcohol.`,
        amount: 0,
        recommendation: ``,
        good_response: true,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: -0.051,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `Monthly or less`,
        response: `I drink alcohol monthly or less.`,
        recommendation: ``,
        amount: 0.3,
        good_response: 'blue',
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `2-4 times a month`,
        response: `I drink alcohol about 2-4 times a month.`,
        recommendation: ``,
        good_response: 'blue',
        amount: 0.8,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `2-3 times a week`,
        response: `I drink alcohol about 2-3 times a week.`,
        recommendation: ``,
        good_response: 'blue',
        amount: 2.5,
        skin_rr: 0.158,
        bowel_rr: 0.029,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0.017,
      },
      {
        answer: `Every day/Almost every day`,
        response: `I drink alcohol every day or almost every day.`,
        recommendation: ``,
        good_response: 'blue',
        amount: 6,
        skin_rr: 0.248,
        bowel_rr: 0.22,
        lung_rr: 0,
        prostate_rr: 0.064,
        breast_rr: 0.053,
      },
    ],
  },
  {
    id: `alcohol_standard_drinks`,
    factor: `alcohol`,
    question: `How many standard drinks do you typically have in one sitting?`,
    displayed: () => true,
    options: [],
  },
  {
    id: `alcohol_binge`,
    factor: `alcohol`,
    question: `How often do you drink more than 4 standard drinks on any one day?`,
    displayed: () => true,
    options: [
      {
        answer: `Never`,
        response: `I never drink more than 4 standard drinks on any one day.`,
        recommendation: `Great! Drinking no more than 4 standard drinks on any one day can reduce the risk of harm from accidents and injury.`,
        good_response: true,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `Rarely`,
        response: `I rarely drink more than 4 standard drinks on any one day.`,
        recommendation: `Try to limit yourself to drinking no more than 4 alcoholic drinks on any one day. This can reduce the risk of harm from accidents and injury.`,
        good_response: 'blue',
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `Occasionally`,
        response: `I occasionally drink more than 4 standard drinks on any one day.`,
        recommendation: `Try to limit yourself to drinking no more than 4 alcoholic drinks on any one day. This can reduce the risk of harm from accidents and injury.`,
        good_response: 'blue',
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `Often`,
        response: `I regularly drink more than 4 standard drinks on any one day.`,
        recommendation: `Try to limit yourself to drinking no more than 4 alcoholic drinks on any one day. This can reduce the risk of harm from accidents and injury.`,
        good_response: false,
        skin_rr: 0.19,
        bowel_rr: 0.12,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0.017,
      },
    ],
  },
  {
    id: `check_skin_personal`,
    factor: `screening`,
    question: `Do you regularly check your skin for moles, freckles or lumps that are new or changing in size, colour or shape?`,
    answer: null,
    score: null,
    displayed: () => true,
    options: [
      {
        answer: `Yes`,
        response: `I regularly check my skin for new spots and changes.`,
        recommendation: `Great! Knowing your body and checking your skin for any new spots or changes can help detect cancer early.`,
        good_response: true,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `No`,
        response: `I don't regularly check my skin for new spots and changes.`,
        recommendation: `Knowing your body and checking your skin for any new spots or changes can help detect cancer early.`,
        good_response: false,
        score: 0,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
    ],
  },
  {
    id: `check_skin_doctor`,
    factor: `screening`,
    question: `Do you regularly have your skin checked by a health professional?`,
    displayed: () => true,
    options: [
      {
        answer: `Yes`,
        response: `I regularly get my skin checked by a health professional.`,
        recommendation: `Fantastic! Remember to discuss your skin cancer risk with your doctor at your next appointment.`,
        good_response: true,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `No`,
        response: `I don't regularly get my skin checked by a health professional.`,
        recommendation: `Have you thought about getting your skin checked? Make an appointment with your doctor today to check your skin and talk about your skin cancer risk.`,
        good_response: false,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
    ],
  },
  {
    id: `follow_doctor_recommendations`,
    factor: `screening`,
    question: `Do you follow your health professional’s advice for reducing your cancer risk?`,
    displayed: () => true,
    options: [
      {
        answer: `Yes`,
        response: `I follow my health professional's advice for reducing my cancer risk.`,
        recommendation: `Great! It's important to follow recommendations to best reduce your cancer risk.`,
        good_response: true,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `No`,
        response: `I don't follow my health professional's advice for reducing my cancer risk.`,
        recommendation: `It's important to follow recommendations to best reduce your cancer risk. Have a chat with your doctor today.`,
        good_response: false,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
    ],
  },
  {
    id: `bowel_screening`,
    factor: `screening`,
    question: `Do you participate in the National Bowel Cancer Screening Program?`,
    displayed: (finalResults) =>
      finalResults.age >= 45 && finalResults.age <= 74,
    options: [
      {
        answer: `Yes, at least every 2 years`,
        response: `I participate in the National Bowel Cancer Screening Program at least every 2 years.`,
        recommendation: `Fantastic! Regular bowel screening can help detect early signs of the disease. If found early, more than 90% of cases can be successfully treated.`,
        good_response: true,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `Yes, but not every 2 years`,
        response: `I participate in the National Bowel Cancer Screening Program, but not every 2 years.`,
        recommendation: `Bowel screening is recommended every 2 years for people aged 45-74. If found early, more than 90% of cases can be successfully treated.`,
        good_response: false,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `I regularly screen outside the program, E.g. colonoscopies every 5 years`,
        response: `I regularly screen outside the National Bowel Cancer Screening Program, eg: colonoscopies every 5 years.`,
        recommendation: `Fantastic! Regular bowel screening can help detect early signs of the disease. If found early, more than 90% of cases can be successfully treated.`,
        good_response: true,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `No, I don't require bowel screening due to medical history`,
        response: `I don't require bowel screening due to medical history.`,
        recommendation: ``,
        good_response: true,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `No, I don't participate in regular bowel screening`,
        response: `I don't participate in regular bowel screening.`,
        recommendation: `Bowel screening is recommended every 2 years for people aged 45-74. If found early, more than 90% of cases can be successfully treated.`,
        good_response: false,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
    ],
  },
  {
    id: `breast_screening`,
    factor: `screening`,
    question: `Do you participate in the National Breast Cancer Screening Program?`,
    displayed: (finalResults) =>
      finalResults.age >= 40 &&
      finalResults.age <= 74 &&
      (finalResults.sex.answer === 'Female' ||
        finalResults.sex.answer === 'Other' ||
        finalResults.gender.answer === 'Female'),
    options: [
      {
        answer: `Yes, at least every 2 years`,
        response: `I participate in the National Breast Cancer Screen Program at least every 2 years.`,
        recommendation: `Great! Screening is one of the most effective ways to detect early signs of cancer. Breast screening is recommended every 2 years for women aged 40 to 74.`,
        good_response: true,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `Yes, but not every 2 years`,
        response: `I participate in the National Breast Cancer Screen Program, but not every 2 years.`,
        recommendation: `Breast screening is recommended every 2 years for women aged 40 to 74. Book in your next appointment today.`,
        good_response: false,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `I regularly screen outside the program`,
        response: `I regularly screen outside the National Breast Cancer Screen Program.`,
        recommendation: `Great! Screening is one of the most effective ways to detect early signs of cancer. Breast screening is recommended every 2 years for women aged 40 to 74.`,
        good_response: true,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `No, I don't require breast screening due to medical history`,
        response: `I don't require breast screening due to medical history.`,
        recommendation: ``,
        good_response: true,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `No, I don't participate in breast screening`,
        response: `I don't participate in breast screening.`,
        recommendation: `Breast screening is recommended every 2 years for women aged 40 to 74. Book in an appointment for a free mammogram today.`,
        good_response: false,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
    ],
  },
  {
    id: `cervical_screening`,
    factor: `screening`,
    question: `Do you participate in the National Cervical Screening Program?`,
    displayed: (finalResults) =>
      finalResults.age >= 25 &&
      finalResults.age <= 74 &&
      (finalResults.sex.answer === 'Female' ||
        finalResults.sex.answer === 'Other'),
    options: [
      {
        answer: `Yes, at least every 5 years`,
        response: `I participate in the National Cervical Screening Program at least every 5 years.`,
        recommendation: `Great! Did you know self-collection is now available for Cervical Screening Tests?`,
        good_response: true,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `Yes, but not every 5 years`,
        response: `I participate in the National Cervical Screening Program, but not every 5 years.`,
        recommendation: `Cervical screening is recommended every 5 years for women aged 25-74. Did you know self-collection is now available? Book in your next cervical screen today.`,
        good_response: false,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `I regularly screen outside the program`,
        response: `I regularly participate outside the National Cervical Screening Program.`,
        recommendation: `Great! Did you know self-collection is now available for Cervical Screening Tests?`,
        good_response: true,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `No, I don't require cervical screening due to medical history`,
        response: `I don't require cervical screening due to medical history.`,
        recommendation: ``,
        good_response: true,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
      {
        answer: `No, I don't participate in cervical screening`,
        response: `I don't participate in cervical screening.`,
        recommendation: `Cervical screening is recommended every 5 years for women aged 25 to 74. Did you know self-collection is now available? Book an appointment today.`,
        good_response: false,
        skin_rr: 0,
        bowel_rr: 0,
        lung_rr: 0,
        prostate_rr: 0,
        breast_rr: 0,
      },
    ],
  },
  {
    id: `submit`,
    factor: `screening`,
    question: ``,
    answer: null,
    score: null,
    displayed: () => true,
    options: [],
  },
];

export const risk_ratios_nutrition = {
  fruit: [
    {
      serves: 0,
      risk_ratios: [
        { skin: 0 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
    {
      serves: 1,
      risk_ratios: [
        { skin: 0 },
        { bowel: -0.027 },
        { lung: -0.03 },
        { prostate: 0 },
        { breast: -0.0195 },
      ],
    },
    {
      serves: 2,
      risk_ratios: [
        { skin: 0 },
        { bowel: -0.054 },
        { lung: -0.06 },
        { prostate: 0 },
        { breast: -0.039 },
      ],
    },
  ],
  vegetables: [
    {
      serves: 0,
      risk_ratios: [
        { skin: 0 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
    {
      serves: 1,
      risk_ratios: [
        { skin: 0 },
        { bowel: -0.00675 },
        { lung: 0 },
        { prostate: 0 },
        { breast: -0.00975 },
      ],
    },
    {
      serves: 2,
      risk_ratios: [
        { skin: 0 },
        { bowel: -0.0135 },
        { lung: 0 },
        { prostate: 0 },
        { breast: -0.0195 },
      ],
    },
    {
      serves: 3,
      risk_ratios: [
        { skin: 0 },
        { bowel: -0.02025 },
        { lung: 0 },
        { prostate: 0 },
        { breast: -0.02925 },
      ],
    },
    {
      serves: 4,
      risk_ratios: [
        { skin: 0 },
        { bowel: -0.027 },
        { lung: 0 },
        { prostate: 0 },
        { breast: -0.039 },
      ],
    },
    {
      serves: 5,
      risk_ratios: [
        { skin: 0 },
        { bowel: -0.03375 },
        { lung: 0 },
        { prostate: 0 },
        { breast: -0.04875 },
      ],
    },
  ],
  wholegrains: [
    {
      serves: 0,
      risk_ratios: [
        { skin: 0 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
    {
      serves: 1,
      risk_ratios: [
        { skin: 0 },
        { bowel: -0.01377 },
        { lung: 0 },
        { prostate: 0 },
        { breast: -0.02592 },
      ],
    },
    {
      serves: 2,
      risk_ratios: [
        { skin: 0 },
        { bowel: -0.02754 },
        { lung: 0 },
        { prostate: 0 },
        { breast: -0.05184 },
      ],
    },
    {
      serves: 3,
      risk_ratios: [
        { skin: 0 },
        { bowel: -0.04131 },
        { lung: 0 },
        { prostate: 0 },
        { breast: -0.07776 },
      ],
    },
    {
      serves: 4,
      risk_ratios: [
        { skin: 0 },
        { bowel: -0.05508 },
        { lung: 0 },
        { prostate: 0 },
        { breast: -0.10368 },
      ],
    },
    {
      serves: 5,
      risk_ratios: [
        { skin: 0 },
        { bowel: -0.06885 },
        { lung: 0 },
        { prostate: 0 },
        { breast: -0.1296 },
      ],
    },
    {
      serves: 6,
      risk_ratios: [
        { skin: 0 },
        { bowel: -0.08262 },
        { lung: 0 },
        { prostate: 0 },
        { breast: -0.15552 },
      ],
    },
  ],
  red_meat: [
    {
      serves: 0,
      risk_ratios: [
        { skin: 0 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
    {
      serves: 7,
      risk_ratios: [
        { skin: 0 },
        { bowel: 0.00455 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0.00381 },
      ],
    },
    {
      serves: 8,
      risk_ratios: [
        { skin: 0 },
        { bowel: 0.0091 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0.00761 },
      ],
    },
    {
      serves: 9,
      risk_ratios: [
        { skin: 0 },
        { bowel: 0.01365 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0.01142 },
      ],
    },
    {
      serves: 10,
      risk_ratios: [
        { skin: 0 },
        { bowel: 0.0182 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0.11801 },
      ],
    },
  ],
  processed_meat: [
    {
      serves: 0,
      risk_ratios: [
        { skin: 0 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
    {
      serves: 1,
      risk_ratios: [
        { skin: 0 },
        { bowel: 0.0155 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0.0175 },
      ],
    },
    {
      serves: 2,
      risk_ratios: [
        { skin: 0 },
        { bowel: 0.0311 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0.035 },
      ],
    },
    {
      serves: 3,
      risk_ratios: [
        { skin: 0 },
        { bowel: 0.0466 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0.0525 },
      ],
    },
    {
      serves: 4,
      risk_ratios: [
        { skin: 0 },
        { bowel: 0.0622 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0.0699 },
      ],
    },
    {
      serves: 5,
      risk_ratios: [
        { skin: 0 },
        { bowel: 0.0777 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0.0874 },
      ],
    },
    {
      serves: 6,
      risk_ratios: [
        { skin: 0 },
        { bowel: 0.0933 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0.1049 },
      ],
    },
    {
      serves: 7,
      risk_ratios: [
        { skin: 0 },
        { bowel: 0.1088 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0.1224 },
      ],
    },
    {
      serves: 8,
      risk_ratios: [
        { skin: 0 },
        { bowel: 0.1243 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0.1399 },
      ],
    },
    {
      serves: 9,
      risk_ratios: [
        { skin: 0 },
        { bowel: 0.1399 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0.1574 },
      ],
    },
    {
      serves: 10,
      risk_ratios: [
        { skin: 0 },
        { bowel: 0.1554 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0.1749 },
      ],
    },
  ],
};

export const risk_ratios_vaping = [
  {
    answer: 'less than 1 year ago',
    risk_ratios: [
      { skin: 0 },
      { bowel: 0 },
      { lung: 0 },
      { prostate: 0 },
      { breast: 0 },
    ],
  },
  {
    answer: '3 years ago',
    risk_ratios: [
      { skin: 0 },
      { bowel: 0 },
      { lung: 0 },
      { prostate: 0 },
      { breast: 0 },
    ],
  },
  {
    answer: '5 years ago',
    risk_ratios: [
      { skin: 0 },
      { bowel: 0 },
      { lung: 0 },
      { prostate: 0 },
      { breast: 0 },
    ],
  },
  {
    answer: '10+ years ago',
    risk_ratios: [
      { skin: 0 },
      { bowel: 0 },
      { lung: 0 },
      { prostate: 0 },
      { breast: 0 },
    ],
  },
];

export const risk_ratios_smoking = [
  {
    answer: 'less than 1 year ago',
    risk_ratios: [
      { skin: -0.143 },
      { bowel: 0.068 },
      { lung: 0.754171 },
      { prostate: 0.152 },
      { breast: 0.041 },
    ],
  },
  {
    answer: '3 years ago',
    risk_ratios: [
      { skin: -0.143 },
      { bowel: 0.068 },
      { lung: 0.587401 },
      { prostate: 0.152 },
      { breast: 0.041 },
    ],
  },
  {
    answer: '5 years ago',
    risk_ratios: [
      { skin: -0.143 },
      { bowel: 0.068 },
      { lung: 0.529958 },
      { prostate: 0.15 },
      { breast: 0.038 },
    ],
  },
  {
    answer: '10 years ago',
    risk_ratios: [
      { skin: -0.143 },
      { bowel: 0.05 },
      { lung: 0.3418785 },
      { prostate: 0.05 },
      { breast: 0.02 },
    ],
  },
  {
    answer: '15 years ago',
    risk_ratios: [
      { skin: -0.143 },
      { bowel: 0.05 },
      { lung: 0.2473755 },
      { prostate: 0.05 },
      { breast: 0.02 },
    ],
  },
  {
    answer: '20 years ago',
    risk_ratios: [
      { skin: -0.143 },
      { bowel: 0.02 },
      { lung: 0.0824585 },
      { prostate: 0.02 },
      { breast: 0.01 },
    ],
  },
  {
    answer: '30 years ago',
    risk_ratios: [
      { skin: -0.143 },
      { bowel: 0.02 },
      { lung: 0.0824585 },
      { prostate: 0.02 },
      { breast: 0.01 },
    ],
  },
  {
    answer: '40+ years ago',
    risk_ratios: [
      { skin: -0.143 },
      { bowel: 0.02 },
      { lung: 0.0305745 },
      { prostate: 0.02 },
      { breast: 0.01 },
    ],
  },
];

export const risk_ratios_alcohol = {
  '10g_per_week': [
    { skin: 0.029 },
    { bowel: 0.029 },
    { lung: 0 },
    { prostate: 0.021 },
    { breast: 0.017 },
  ],
  '20g_per_week': [
    { skin: 0.059 },
    { bowel: 0.059 },
    { lung: 0 },
    { prostate: 0.025 },
    { breast: 0.09 },
  ],
  '30g_per_week': [
    { skin: 0.088 },
    { bowel: 0.088 },
    { lung: 0 },
    { prostate: 0.025 },
    { breast: 0.09 },
  ],
  '40g_per_week': [
    { skin: 0.118 },
    { bowel: 0.118 },
    { lung: 0 },
    { prostate: 0.025 },
    { breast: 0.09 },
  ],
  '50g_per_week': [
    { skin: 0.147 },
    { bowel: 0.147 },
    { lung: 0 },
    { prostate: 0.033 },
    { breast: 0.09 },
  ],
  '60g_per_week': [
    { skin: 0.176 },
    { bowel: 0.176 },
    { lung: 0 },
    { prostate: 0.033 },
    { breast: 0.207 },
  ],
  '70g_per_week': [
    { skin: 0.206 },
    { bowel: 0.206 },
    { lung: 0 },
    { prostate: 0.033 },
    { breast: 0.207 },
  ],
  '80g_per_week': [
    { skin: 0.235 },
    { bowel: 0.235 },
    { lung: 0 },
    { prostate: 0.033 },
    { breast: 0.207 },
  ],
  '90g_per_week': [
    { skin: 0.264 },
    { bowel: 0.264 },
    { lung: 0 },
    { prostate: 0.033 },
    { breast: 0.207 },
  ],
  '100g_per_week': [
    { skin: 0.294 },
    { bowel: 0.294 },
    { lung: 0 },
    { prostate: 0.033 },
    { breast: 0.207 },
  ],
  '110g_per_week': [
    { skin: 0.323 },
    { bowel: 0.323 },
    { lung: 0 },
    { prostate: 0.033 },
    { breast: 0.207 },
  ],
};

export const risk_ratios_physical = {
  exercise_under_300_mins: [
    { skin: 0 },
    { bowel: 0 },
    { lung: 0 },
    { prostate: 0 },
    { breast: 0 },
  ],
  exercise_over_300_mins: [
    { skin: 0.029 },
    { bowel: -0.083 },
    { lung: -0.106 },
    { prostate: -0.033 },
    { breast: -0.09 },
  ],
  sedentary_under_6_hrs: [
    { skin: 0 },
    { bowel: 0 },
    { lung: 0 },
    { prostate: 0 },
    { breast: 0 },
  ],
  sedentary_over_6_hrs: [
    { skin: 0 },
    { bowel: 0.134 },
    { lung: 0.093 },
    { prostate: 0 },
    { breast: 0 },
  ],
};

export const risk_ratios_uv_options = {
  no_pants: [
    { skin: 0.21 },
    { bowel: 0 },
    { lung: 0 },
    { prostate: 0 },
    { breast: 0 },
  ],
  short_pants: [
    { skin: 0.21 },
    { bowel: 0 },
    { lung: 0 },
    { prostate: 0 },
    { breast: 0 },
  ],
  long_pants: [
    { skin: 0 },
    { bowel: 0 },
    { lung: 0 },
    { prostate: 0 },
    { breast: 0 },
  ],
  no_shirt: [
    { skin: 0.21 },
    { bowel: 0 },
    { lung: 0 },
    { prostate: 0 },
    { breast: 0 },
  ],
  short_shirt: [
    { skin: 0.21 },
    { bowel: 0 },
    { lung: 0 },
    { prostate: 0 },
    { breast: 0 },
  ],
  long_shirt: [
    { skin: 0 },
    { bowel: 0 },
    { lung: 0 },
    { prostate: 0 },
    { breast: 0 },
  ],
  no_sunglasses: [
    { skin: 0.21 },
    { bowel: 0 },
    { lung: 0 },
    { prostate: 0 },
    { breast: 0 },
  ],
  sunglasses: [
    { skin: 0 },
    { bowel: 0 },
    { lung: 0 },
    { prostate: 0 },
    { breast: 0 },
  ],
  no_sunscreen: [
    { skin: 0.57 },
    { bowel: 0 },
    { lung: 0 },
    { prostate: 0 },
    { breast: 0 },
  ],
  sunscreen: [
    { skin: 0 },
    { bowel: 0 },
    { lung: 0 },
    { prostate: 0 },
    { breast: 0 },
  ],
  no_shade: [
    { skin: 0.21 },
    { bowel: 0 },
    { lung: 0 },
    { prostate: 0 },
    { breast: 0 },
  ],
  shade: [
    { skin: 0 },
    { bowel: 0 },
    { lung: 0 },
    { prostate: 0 },
    { breast: 0 },
  ],
  no_hat: [
    { skin: 0.21 },
    { bowel: 0 },
    { lung: 0 },
    { prostate: 0 },
    { breast: 0 },
  ],
  broad_hat: [
    { skin: 0 },
    { bowel: 0 },
    { lung: 0 },
    { prostate: 0 },
    { breast: 0 },
  ],
  cap: [
    { skin: 0.21 },
    { bowel: 0 },
    { lung: 0 },
    { prostate: 0 },
    { breast: 0 },
  ],
};
