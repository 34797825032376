import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import './LandingPage.css';
import { Autocomplete, Button, Grow, Paper, TextField } from '@mui/material';
import AustraliaMap from './components/AustraliaMap';
import EmailModal from './components/EmailModal';
import Lifestyle6 from '../other/Lifestyle6';
import CCQ_Logo from '../../../assets/images/CCQ_Blue_Logo.png';
import { generateGuid } from '../../../utils/util';
import { Link, useLocation } from 'react-router-dom';
import { finalResultsObject } from '../../../utils/finalResults';
import { countries } from './components/Countries';
import { TbArrowRight } from 'react-icons/tb';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function LandingPage({
  setActiveView,
  finalResults,
  updateFinalResults,
  setfinalResults,
}) {
  let query = useQuery();
  const [emailReOpen, setEmailReOpen] = useState(false);
  const [error, setError] = useState(false);

  const handleViewChange = async () => {
    //Make country and state mandatory
    if (
      (finalResults.state === '' && finalResults.country === 'Australia') ||
      (finalResults.country !== 'Australia' && finalResults.country === '')
    ) {
      setError(true);
      return;
    }

    //Change view
    setActiveView('Personal');

    //Create user guid
    const finalResultsId = generateGuid();
    var event_code = query.get('event');
    if (event_code === null || event_code === undefined) {
      event_code = '';
    }

    updateFinalResults({
      id: finalResultsId,
      event_code: event_code,
      accepted_disclaimer: true,
    });
  };

  const [australiaStateFull, setAustraliaStateFull] = useState('');
  const [australiaState, setAustraliaState] = useState('');
  const unselectedState = 'Select your state or territory';
  const selectedState = `${finalResults.state}`;

  const australianStates = [
    {
      state_code: 'QLD',
      state: 'Queensland',
    },
    {
      state_code: 'NSW',
      state: 'New South Wales',
    },
    {
      state_code: 'VIC',
      state: 'Victoria',
    },
    {
      state_code: 'TAS',
      state: 'Tasmania',
    },
    {
      state_code: 'SA',
      state: 'South Australia',
    },
    {
      state_code: 'WA',
      state: 'Western Australia',
    },
    {
      state_code: 'NT',
      state: 'Northern Territory',
    },
    {
      state_code: 'ACT',
      state: 'Australian Capital Territory',
    },
  ];

  //If state is found in URL parameters, autoselect the state
  //For example cancer-risk-calculator.org?state=QLD
  useEffect(() => {
    var preselectedState = query.get('state');
    if (preselectedState !== null && preselectedState !== undefined) {
      preselectedState = preselectedState.toUpperCase();
      const stateData = australianStates.find(
        (item) => item.state_code === preselectedState,
      );
      if (stateData) {
        setAustraliaState(preselectedState);
        setAustraliaStateFull(stateData.state);
      }
    }
  }, []);

  useEffect(() => {
    updateFinalResults({
      state_code: australiaState,
      state: australiaStateFull,
      country: 'Australia',
    });
  }, [australiaStateFull]);

  useEffect(() => {
    setfinalResults(finalResultsObject);
    var returning = query.get('returning');
    if (returning === 'true') {
      setEmailReOpen(true);
    }
  }, []);

  const handleCountryChange = (country) => {
    if (country === null) {
      updateFinalResults({
        country: 'Australia',
      });
      return;
    }

    updateFinalResults({
      country: country,
      state_code: '',
      state: '',
    });
    setAustraliaState('');
  };

  useEffect(() => {
    setError(false);
  }, [finalResults.state, finalResults.country]);

  const CancerCouncilPrivacyLinks = (data) => {
    const state = data.selectedState;
    const link = data.hrefLink;
    return (
      <>
        Cancer Council {state}{' '}
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="link "
        >
          Privacy Policies.
        </a>
      </>
    );
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Grow in={true}>
        <div className="landingPageHeader">
          <div
            style={{ marginTop: '-20px' }}
            className="dflex flexwrap gap10 justifycontentstart mr20 mt10 alignitemscenter lifestyle6Main p5"
          >
            <img
              className="crcandlogo"
              style={{ height: 120, marginTop: '-20px' }}
              alt="CCQ"
              src={CCQ_Logo}
            />
            <Lifestyle6 activeColor="all" />
          </div>

          <div className="dflex gap10 flexwrap">
            <h3 className="mb10 mt10 fontweight500 p5">
              Hello and welcome to the{' '}
              <span className="highlighted-text-header">
                Cancer Risk Calculator.
              </span>{' '}
              Over the next few minutes, we'll ask you some questions to help
              you lower your risk of cancer and detect it early. The calculator
              explores different lifestyle factors you can change in your daily
              life, including UV & Sun Exposure, Smoking, Alcohol, Nutrition,
              Weight, Physical Activity, and Screening & Early Detection.
            </h3>
          </div>
          <h3 className="mb20 mt10 fontweight500 p5">
            For each of these Lifestyle6 factors, you'll receive a score out of
            100.{' '}
            <span className="highlighted-text">
              The higher your score, the better you are doing
            </span>{' '}
            at currently reducing your cancer risk and detecting cancer early.
          </h3>

          <Container disableGutters className="country-form">
            <h1 style={{ marginBottom: '0px', fontSize: '30px' }}>
              {australiaState !== '' ? selectedState : unselectedState}
            </h1>
            <div className="dflex alignitemscenter flexwrap justifycontentcenter">
              <AustraliaMap
                updateFinalResults={updateFinalResults}
                finalResults={finalResults}
                australiaState={australiaState}
                australianStates={australianStates}
                setAustraliaState={setAustraliaState}
              />
              <div style={{ marginBottom: '20px' }} className="dflex flexwrap">
                <h2 style={{ margin: 10, fontSize: 22 }}>OR</h2>
                <Autocomplete
                  value={
                    finalResults.country === 'Australia'
                      ? ''
                      : finalResults.country || null
                  }
                  style={{ width: 200 }}
                  defaultValue=""
                  onChange={(e, newValue) => {
                    handleCountryChange(newValue);
                  }}
                  PaperComponent={({ children }) => (
                    <Paper
                      sx={{
                        '& .MuiAutocomplete-listbox': { overflowX: 'hidden' },
                        '.MuiInputBase-input': { fontSize: 13 },
                        '& .MuiAutocomplete-listbox > li::before': {
                          color: 'transparent',
                        },
                      }}
                      className="autocomplete-items"
                    >
                      {children}
                    </Paper>
                  )}
                  className="autocomplete"
                  options={countries}
                  renderInput={(params) => (
                    <TextField
                      InputLabelProps={{ style: { fontSize: 14 } }}
                      sx={{
                        '.MuiInputBase-input': { fontSize: 14 },
                      }}
                      {...params}
                      label="Select Country"
                    />
                  )}
                />
              </div>
            </div>
          </Container>
          <p className="mb5 mt0 p5">
            The Cancer Risk Calculator is a free online tool that is for
            informational and educational purposes only, providing generalised
            recommendations for individuals aged{' '}
            <span className="highlighted-text">18 years and older</span>. It
            does not constitute as medical health advice and does not predict
            whether you will get cancer. Read our{' '}
            <Link
              className="link "
              target="_blank"
              to="https://cancerqld.org.au/about-us/our-disclaimer/"
            >
              Disclaimer.{' '}
            </Link>{' '}
            {australiaState === 'TAS' && (
              <CancerCouncilPrivacyLinks
                selectedState="Tasmania"
                hrefLink="https://www.cancer.org.au/about-us/about-cancer-council/tas/privacy-policy"
              />
            )}
          </p>
          <div className="dflex mt20 justifycontentspacebetween">
            <EmailModal
              emailReOpen={emailReOpen}
              setEmailReOpen={setEmailReOpen}
            />

            <Button
              type="submit"
              onClick={handleViewChange}
              className="nextPrimaryBtn borderRadius8"
              variant="contained"
            >
              Accept & Continue{' '}
              <TbArrowRight style={{ fontSize: 25, marginLeft: 5 }} />
            </Button>
          </div>
          {error && (
            <div className="error-text dflex justifycontentend ">
              <p>Please select a state, territory or country</p>
            </div>
          )}
        </div>
      </Grow>
    </React.Fragment>
  );
}

export default LandingPage;
