import React from 'react';
import {
  FaCheckCircle,
  FaDotCircle,
  FaExclamationCircle,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

const AnswerResponse = ({ item }) => {
  return (
    <>
      <div className="dflex gap10 mt10 alignitemscenter">
        {item.good_response === 'blue' && (
          <FaDotCircle className="answerpoints" style={{ color: '#435685' }} />
        )}
        {item.good_response === true && <FaCheckCircle className="checkIcon" />}
        {item.good_response === false && item.response !== '' && (
          <FaExclamationCircle className="exclamationIcon" />
        )}
        <p className="learnMoreInfo question mt10">{item.response}</p>
      </div>
      <p
        style={{ fontSize: 18, marginLeft: 30 }}
        className="learnMoreRec answerResponse subtext"
      >
        {item.recommendation}{' '}
        {item.question_id === 'bowel_screening' && !item.good_response && (
          <Link
            to="https://www.ncsr.gov.au/information-for-participants/order-a-replacement-bowel-test-kit/"
            target="_blank"
            className="link"
          >
            Order your kit here.
          </Link>
        )}
      </p>
    </>
  );
};

export default AnswerResponse;
