//Following feedback questions created by Prevention & Research Team
//Based off multiple components and constructs
//Questions displayed are randomised based off whether user has provided email or not ie: signed up

// if [id] = "functions_well_integrated.question" then [functions_well_integrated.answer]
// else if [id] = "trustworthy_recommendations.question" then [trustworthy_recommendations.answer]
// else if [id] = "credible_information.question" then [credible_information.answer]
// else if [id] = "appropriate_recommendations.question" then [appropriate_recommendations.answer]
// else if [id] = "intention_reduce_risk.question" then [intention_reduce_risk.answer]
// else if [id] = "language_tone.question" then [language_tone.answer]
// else if [id] = "motivated_reduce_risk.question" then [motivated_reduce_risk.answer]
// else if [id] = "easy_to_use.question" then [easy_to_use.answer]
// else if [id] = "fun_to_use.question" then [fun_to_use.answer]
// else if [id] = "confident_reduce_risk.question" then [confident_reduce_risk.answer]
// else if [id] = "language_tone_recommendations.question" then [language_tone_recommendations.answer]
// else if [id] = "achievable_recommendations.question" then [achievable_recommendations.answer]
// else if [id] = "trustworthy.question" then [trustworthy.answer]
// else if [id] = "credible_recommendations.question" then [credible_recommendations.answer] else null

export const feedbackConfig = {
  not_signed_up: [
    {
      id: 'easy_to_use',
      set: 'A',
      component: 'Calculator',
      construct: 'Usability',
      question: `I thought the Calculator was easy to use`,
    },
    {
      id: 'language_tone',
      set: 'A',
      component: 'Calculator',
      construct: 'Appropriateness',
      question: `I thought the language and tone was appropriate`,
    },
    {
      id: 'motivated_reduce_risk',
      set: 'A',
      component: 'Social Psych',
      construct: 'Motivation',
      question: `The information provided has motivated me to try to reduce my cancer risk`,
    },
    {
      id: 'trustworthy',
      set: 'B',
      component: 'Calculator',
      construct: 'Trustworthiness & Credibility',
      question: `In my opinion, the Calculator is a trustworthy tool`,
    },
    {
      id: 'functions_well_integrated',
      set: 'B',
      component: 'Calculator',
      construct: 'Usability',
      question: `I found the various functions were well integrated`,
    },
    {
      id: 'intention_reduce_risk',
      set: 'B',
      component: 'Social Psych',
      construct: 'Intention',
      question: `I intend to take the necessary steps to reduce my cancer risk`,
    },
    {
      id: 'fun_to_use',
      set: 'C',
      component: 'Calculator',
      construct: 'Usability',
      question: `I thought the Calculator was fun to use`,
    },
    {
      id: 'credible_information',
      set: 'C',
      component: 'Calculator',
      construct: 'Trustworthiness & Credibility',
      question: `In my opinion, the information provided is credible`,
    },
    {
      id: 'confident_reduce_risk',
      set: 'C',
      component: 'Social Psych',
      construct: 'Self-efficacy',
      question: `I am confident that I can take the necessary steps to reduce my cancer risk`,
    },
  ],
  signed_up: [
    {
      id: 'easy_to_use',
      set: 'A',
      component: 'Calculator',
      construct: 'Usability',
      question: `I thought the Calculator was easy to use`,
    },
    {
      id: 'trustworthy',
      set: 'A',
      component: 'Calculator',
      construct: 'Trustworthiness & Credibility',
      question: `In my opinion, the Calculator is a trustworthy tool`,
    },
    {
      id: 'language_tone_recommendations',
      set: 'A',
      component: 'Recommendations',
      construct: 'Appropriateness',
      question: `I thought the language and tone used in the recommendations was appropriate`,
    },
    {
      id: 'motivated_reduce_risk',
      set: 'A',
      component: 'Social Psych',
      construct: 'Motivation',
      question: `The information provided has motivated me to try to reduce my cancer risk`,
    },
    {
      id: 'functions_well_integrated',
      set: 'B',
      component: 'Calculator',
      construct: 'Usability',
      question: `I found the various functions were well integrated`,
    },
    {
      id: 'credible_information',
      set: 'B',
      component: 'Calculator',
      construct: 'Trustworthiness & Credibility',
      question: `In my opinion, the information provided is credible`,
    },
    {
      id: 'appropriate_recommendations',
      set: 'B',
      component: 'Recommendations',
      construct: 'Appropriateness',
      question: `I thought the recommendations were appropriate to help me think about what else I can do to reduce my cancer risk`,
    },
    {
      id: 'intention_reduce_risk',
      set: 'B',
      component: 'Social Psych',
      construct: 'Intention',
      question: `I intend to take the necessary steps to reduce my cancer risk`,
    },
    {
      id: 'fun_to_use',
      set: 'C',
      component: 'Calculator',
      construct: 'Usability',
      question: `I thought the Calculator was fun to use`,
    },
    {
      id: 'trustworthy_recommendations',
      set: 'C',
      component: 'Recommendations',
      construct: 'Trustworthiness & Credibility',
      question: `In my opinion, the information provided as part of the recommendations was trustworthy`,
    },
    {
      id: 'confident_reduce_risk',
      set: 'C',
      component: 'Social Psych',
      construct: 'Self-efficacy',
      question: `I am confident that I can take the necessary steps to reduce my cancer risk`,
    },
    {
      id: 'language_tone',
      set: 'D',
      component: 'Calculator',
      construct: 'Appropriateness',
      question: `I thought the language and tone was appropriate`,
    },
    {
      id: 'achievable_recommendations',
      set: 'D',
      component: 'Recommendations',
      construct: 'Appropriateness',
      question: `I thought the recommendations made in my results are achievable`,
    },
    {
      id: 'credible_recommendations',
      set: 'D',
      component: 'Recommendations',
      construct: 'Trustworthiness & Credibility',
      question: `In my opinion, the information provided as part of the recommendations was credible`,
    },
  ],
};
