import React, { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Container from '@mui/material/Container';
import { GrClose } from 'react-icons/gr';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';

export default function BmiModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const rows = [
    { id: 1, classification: 'Underweight', bmi: 'Below 18.5' },
    { id: 2, classification: 'Healthy Range', bmi: '18.5 - 24.9' },
    { id: 3, classification: 'Overweight', bmi: '25.0 - 29.9' },
    { id: 4, classification: 'Obese I', bmi: '30.0 - 34.9' },
    { id: 5, classification: 'Obese II', bmi: '35.0 - 39.9' },
    { id: 6, classification: 'Obese III', bmi: '40 or more' },
  ];

  return (
    <div className="dflex justifycontentcenter">
      <a onClick={handleOpen} className="link ">
        BMI Classification
      </a>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Container maxWidth="sm" className="modalStyles">
            <GrClose
              style={{
                position: 'absolute',
                right: 20,
                top: 20,
                cursor: 'pointer',
              }}
              onClick={handleClose}
            />
            <h2 className="bmiHeaders">
              Classification of adults according to BMI
            </h2>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 600, fontSize: 16 }}>
                      Classification
                    </TableCell>
                    <TableCell style={{ fontWeight: 600, fontSize: 16 }}>
                      BMI
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.classification}</TableCell>
                      <TableCell>{row.bmi}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <p className="mt20 mb10 subtextBmi">
              {' '}
              Please note the BMI is only an estimate, and does not take into
              account age, gender, ethnicity and body composition. It may not be
              accurate for people with greater muscle mass, older people and
              people who have reduced muscle mass.
            </p>
            <Button onClick={handleClose} fullWidth variant="contained">
              Close
            </Button>
          </Container>
        </Fade>
      </Modal>
    </div>
  );
}
