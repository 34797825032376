import * as React from 'react';
import './socialShare.css';
import { BsTwitter } from 'react-icons/bs';
import { FaFacebook, FaLink, FaLinkedin } from 'react-icons/fa';
import { GrClose, GrGooglePlus } from 'react-icons/gr';
// import { BsTwitterX } from "react-icons/bs";
import { FaXTwitter } from 'react-icons/fa6';

import { RiWhatsappFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { Button, Container, IconButton, Snackbar } from '@mui/material';

export default function SocialShare() {
  const [snackOpen, setSnackOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };

  const withLink = (to, children) => (
    <Link
      className="dflex alignitemscenter justifycontentcenter"
      target="_blank"
      to={to}
    >
      {children}
    </Link>
  );

  const copyUrl = () => {
    const url = 'https://cancer-risk-calculator.org/';
    navigator.clipboard.writeText(url);
    setSnackOpen(true);
  };

  const actions = [
    {
      icon: withLink(
        'https://www.facebook.com/sharer/sharer.php?u=https://cancer-risk-calculator.org/',
        <FaFacebook />,
      ),
      url: 'https://www.facebook.com/sharer/sharer.php?u=https://cancer-risk-calculator.org/',
      name: 'Facebook',
      class: 'facebookShare socialShare',
    },
    {
      icon: withLink(
        'http://twitter.com/share?text=Check out the Cancer Risk Calculator&url=https://cancer-risk-calculator.org/&hashtags=CancerRiskCalculator',
        <FaXTwitter />,
      ),
      url: 'http://twitter.com/share?text=Check out the Cancer Risk Calculator&url=https://cancer-risk-calculator.org/&hashtags=CancerRiskCalculator',
      name: 'Twitter',
      class: 'twitterShare socialShare',
    },
    {
      icon: withLink(
        'https://www.linkedin.com/sharing/share-offsite/?url=https://cancer-risk-calculator.org/',
        <FaLinkedin />,
      ),
      url: 'https://www.linkedin.com/sharing/share-offsite/?url=https://cancer-risk-calculator.org/',
      name: 'LinkedIn',
      class: 'linkedinShare socialShare',
    },
    {
      icon: withLink(
        'https://api.whatsapp.com/send?text=Check out the Cancer Risk Calculator+https://cancer-risk-calculator.org/',
        <RiWhatsappFill />,
      ),
      url: 'https://api.whatsapp.com/send?text=Check out the Cancer Risk Calculator+https://cancer-risk-calculator.org/',
      name: 'WhatsApp',
      class: 'whatsappShare socialShare',
    },
  ];

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        onClick={handleClose}
        aria-label="close"
        color="inherit"
      >
        <GrClose fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const openLink = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Container className="socialMediaContainer flexwrap">
      <h2 className="socialShareHeader">Share the Cancer Risk Calculator</h2>
      {actions.map((action) => (
        <Button
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          className={action.class}
          onClick={() => openLink(action.url)}
        >
          {action.icon}
        </Button>
      ))}

      <Button
        onClick={copyUrl}
        icon={<FaLink />}
        tooltipTitle="Copy Link"
        className="copylinkShare socialShare"
      >
        <FaLink />
      </Button>
      <Snackbar
        open={snackOpen}
        autoHideDuration={2500}
        onClose={handleClose}
        action={action}
        message="Link copied to clipboard"
        className="snackbar"
      />
    </Container>
  );
}
