import React from 'react';
import './Lifestyle6.css';
import Lifestyle6 from './Lifestyle6';

const SectionHeader = ({
  title,
  subtext,
  factor,
  activeColor,
  section,
  subquestion,
}) => {
  return (
    <div className="dflex justifycontentspacebetween headerSection alignitemsstart mt20">
      <div>
        <div className="dflex gap10 alignitemscenter headerSectionDiv">
          {subquestion ? (
            <h2 className={'headerQuestions mt0 mb10'}>{title}</h2>
          ) : (
            <h2 className={' headerTitle mt0 mb5'}>{title}</h2>
          )}
        </div>
        <div className="subtext mt0">{subtext}</div>
      </div>
      <Lifestyle6 factor={factor} section={section} activeColor={activeColor} />
    </div>
  );
};

export default SectionHeader;
