import React, { useState, useEffect } from 'react';
import BarChartEvents from '../charts/BarChartEvents';
import { BsPersonFill } from 'react-icons/bs';
import '../../../App.css';
import '../Events.css';
import PieChartEvent from '../charts/PieChart';

const ScreeningResults = ({
  screeningPersonal,
  screeningDoc,
  screeningRec,
  data,
  calculatePercentage,
  convertToChartData,
  calculateCountsByKey,
}) => {
  //Default answers for each chart, so that even 0% are displayed
  const possibleBowelAnswers = [
    'Yes, at least every 2 years',
    'Yes, but not every 2 years',
    'I regularly screen outside the program, E.g. colonoscopies every 5 years',
    `No, I don't require bowel screening due to medical history`,
    `No, I don't participate in regular bowel screening`,
  ];
  const [bowelScreening, setBowelScreening] = useState([{ x: '', y: 0 }]);
  const [bowelCount, setBowelCount] = useState(0);

  const possibleBreastAnswers = [
    'Yes, at least every 2 years',
    'Yes, but not every 2 years',
    'I regularly screen outside the program',
    `No, I don't require breast screening due to medical history`,
    `No, I don't participate in breast screening`,
  ];
  const [breastScreening, setBreastScreening] = useState([{ x: '', y: 0 }]);
  const [breastCount, setBreastCount] = useState(0);

  const possibleCervicalAnswers = [
    'Yes, at least every 5 years',
    'Yes, but not every 5 years',
    'I regularly screen outside the program',
    `No, I don't require cervical screening due to medical history`,
    `No, I don't participate in cervical screening`,
  ];
  const [cervicalScreening, setCervicalScreening] = useState([{ x: '', y: 0 }]);
  const [cervicalCount, setCervicalCount] = useState(0);

  useEffect(() => {
    const bowelScreening_result = calculatePercentage(
      data,
      'bowel_screening',
      possibleBowelAnswers,
    );
    const bowelScreeningData = convertToChartData(
      bowelScreening_result.percentages,
      'bowel_screening',
    );
    setBowelScreening(bowelScreeningData);
    setBowelCount(bowelScreening_result.totalCount);

    const breastScreening_result = calculatePercentage(
      data,
      'breast_screening',
      possibleBreastAnswers,
    );
    const breastScreeningData = convertToChartData(
      breastScreening_result.percentages,
      'breast_screening',
    );
    setBreastScreening(breastScreeningData);
    setBreastCount(breastScreening_result.totalCount);

    const cervicalScreening_result = calculatePercentage(
      data,
      'cervical_screening',
      possibleCervicalAnswers,
    );
    const cervicalScreeningData = convertToChartData(
      cervicalScreening_result.percentages,
      'cervical_screening',
    );
    setCervicalScreening(cervicalScreeningData);
    setCervicalCount(cervicalScreening_result.totalCount);
  }, [data]);

  const yes_no_labels = ['Yes', 'No'];
  const yes_no_colors = ['#01c2a6', '#f9555a'];

  const colors = ['#01c2a6', '#f9555a'];
  const screeningColors = [
    '#01c2a6',
    '#d8ad39',
    '#01c2a6',
    '#0097ef',
    '#f9555a',
  ];

  return (
    <>
      <div className="dflex flexwrap gap10">
        <div
          style={{ minWidth: 400, height: 'fit-content' }}
          className="paper-container flex1"
        >
          <h1 className="results-question">
            Do you regularly check your skin for moles, freckles or lumps that
            are new or changing in size, colour or shape?
          </h1>
          <PieChartEvent
            height="320"
            width="250"
            labels={yes_no_labels}
            colors={yes_no_colors}
            chartData={screeningPersonal}
          />
        </div>

        <div
          style={{ minWidth: 400, height: 'fit-content' }}
          className="paper-container flex1"
        >
          <h1 className="results-question">
            Do you regularly have your skin checked by a health professional?
          </h1>
          <PieChartEvent
            height="320"
            width="250"
            labels={yes_no_labels}
            colors={yes_no_colors}
            chartData={screeningDoc}
          />
        </div>

        <div
          style={{ minWidth: 400, height: 'fit-content' }}
          className="paper-container flex1"
        >
          <h1 className="results-question">
            Do you follow your health professional's advice related to reducing
            your cancer risk?
          </h1>
          <PieChartEvent
            height="320"
            width="250"
            labels={yes_no_labels}
            colors={yes_no_colors}
            chartData={screeningRec}
          />
        </div>
      </div>

      <div style={{ width: '97%' }} className="paper-container flex1">
        <h1 className="results-question" style={{ height: 40 }}>
          Do you participate in the National Bowel Cancer Screening Program?
          <span className="event-total event-spantotal">
            <BsPersonFill />
            {bowelCount}{' '}
          </span>
        </h1>
        <BarChartEvents colors={screeningColors} chartData={bowelScreening} />
      </div>

      <div style={{ width: '97%' }} className="paper-container flex1">
        <h1 className="results-question" style={{ height: 40 }}>
          Do you participate in the National Breast Cancer Screening Program?
          <span className="event-total event-spantotal">
            <BsPersonFill />
            {breastCount}{' '}
          </span>
        </h1>
        <BarChartEvents colors={screeningColors} chartData={breastScreening} />
      </div>

      <div style={{ width: '97%' }} className="paper-container flex1">
        <h1 className="results-question" style={{ height: 40 }}>
          Do you participate in the National Cervical Screening Program?
          <span className="event-total event-spantotal">
            <BsPersonFill />
            {cervicalCount}{' '}
          </span>
        </h1>
        <BarChartEvents
          colors={screeningColors}
          chartData={cervicalScreening}
        />
      </div>

      <div style={{ marginTop: 30 }} className="results-info-text">
        <h1>Did you know?</h1>
        <li>
          Bowel Screening: 40% of the 6.0 million people invited to bowel screen
          participated (between January 2021 and December 2022).
        </li>
        <li>
          Breast Screening: In 2019–2020, around 1.8 million women participated
          in the BreastScreen Australia. This was 50% of women aged 50–74.
        </li>
        <a
          class="link"
          target="_blank"
          href="https://www.aihw.gov.au/reports/cancer-screening/nbcsp-monitoring-2024/summary"
        >
          Source (NBCSP Report 2024 - AIHW)
        </a>
        <a
          class="link"
          target="_blank"
          href="https://www.aihw.gov.au/reports/cancer-screening/breastscreen-australia-monitoring-report-2022/summary"
        >
          Source (BreastScreen Australia Report 2022 - AIHW)
        </a>
      </div>
    </>
  );
};

export default ScreeningResults;
