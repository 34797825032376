import { Button, CircularProgress, Container, Grow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SectionHeader from '../other/SectionHeader';
import MultiChoiceQuestion from '../../body/MultichoiceQuestion';
import { FaArrowRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import {
  calculateScreeningTotal,
  calculateTotalScore,
} from '../../../utils/calculateScores';
import axios from 'axios';

function Screening({
  setActiveView,
  finalResults,
  updateFinalResults,
  currentScreeningQuestion,
  setCurrentScreeningQuestion,
  configQuestions,
  riskRatios,
  demo,
}) {
  const handleAnswerClick = (questionIndex) => {
    if (currentScreeningQuestion <= questionIndex) {
      setCurrentScreeningQuestion(currentScreeningQuestion + 1);
    }
  };

  const [screeningScore, setScreeningScore] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  // Submit form at end of calculator
  const handleSubmit = async () => {
    try {
      //Submit results
      await axios.post('/api/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(finalResults),
      });

      //After results submitted, redirect to results page
      setTimeout(() => {
        setLoading(false);
        setError(false);
        setActiveView('Default');
        navigate(`/results?user=${finalResults.id}`);
      }, '1500');
    } catch (error) {
      setLoading(false);
      setError(true);
      console.error('Error: ', error);
    }
  };

  // Handle demo submit - do not submit data, just redirect to results page with demo dummy results
  const handleDemoSubmit = async () => {
    setLoading(false);
    setError(false);
    setActiveView('Default');
    navigate(`/results?user=demo-results`);
  };

  useEffect(() => {
    updateFinalResults({ screening_total: screeningScore });
  }, [screeningScore]);

  useEffect(() => {
    const screeningScore = calculateScreeningTotal(finalResults);
    setScreeningScore(screeningScore);
  }, [finalResults]);

  return (
    <>
      <Grow in={true}>
        <Container>
          <SectionHeader
            title="Screening & Early Detection"
            subtext="Getting screened and knowing your body can help detect cancer early and improve cancer treatment."
            activeColor="#7d489c"
            factor="screening"
          />
          {configQuestions
            .filter(
              (item) =>
                item.factor === 'screening' && item.displayed(finalResults),
            )
            .map((question, index) => {
              var questionDisplayed = currentScreeningQuestion >= index;
              if (question.id === 'submit') {
                return (
                  <Grow key={index} in={questionDisplayed}>
                    <Button
                      disabled={loading}
                      variant="contained"
                      className={
                        questionDisplayed
                          ? 'nextBtn submitForm'
                          : ' nextBtn submitForm height0'
                      }
                      color="primary"
                      onClick={() => {
                        setLoading(true);
                        setError(false);
                        demo ? handleDemoSubmit() : handleSubmit();
                      }}
                    >
                      {loading ? (
                        <CircularProgress style={{ color: 'white' }} />
                      ) : (
                        <>
                          Submit{' '}
                          <FaArrowRight
                            style={{ marginTop: '-5px' }}
                            className="ml10"
                          />
                        </>
                      )}
                    </Button>
                  </Grow>
                );
              }
              return (
                <MultiChoiceQuestion
                  key={index}
                  questionDisplayed={questionDisplayed}
                  handleAnswerClick={handleAnswerClick}
                  question={question}
                  questionIndex={index}
                  updateFinalResults={updateFinalResults}
                  finalResults={finalResults}
                  riskRatios={riskRatios}
                  setScreeningScore={setScreeningScore}
                />
              );
            })}
          {error && (
            <h4 style={{ color: '#9f0000', fontSize: 18, marginTop: -10 }}>
              A connection error has occurred. Please check your internet and
              try again.
            </h4>
          )}
          <Button
            variant="contained"
            className="prevBtn"
            onClick={() => {
              setActiveView('Physical');
            }}
          >
            Back
          </Button>
        </Container>
      </Grow>
    </>
  );
}

export default Screening;
