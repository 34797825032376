import React, { useState } from 'react';
import Lifestyle6 from '../../body/other/Lifestyle6';
import BarChartResults from '../charts/BarChart';
import { Button, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { FaExternalLinkAlt } from 'react-icons/fa';
import StackedAreaChart from '../charts/StackedAreaChart';

const CompareResults = ({ finalResults }) => {
  const mobileView = useMediaQuery('(max-width:850px)');
  const [selectedType, setSelectedType] = useState('UV');
  const [show, setShow] = useState(false);

  const onLoad = () => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 1000);
  };
  const changeGraphType = (type) => {
    setSelectedType(type);
  };
  return (
    <div className="mobile5">
      <div className="dflex justifycontentspacebetween m30 ml0 mb0 pl5 pr5">
        <h1 className="viewMoreHeader">Compare Your Results</h1>
        {!mobileView && <Lifestyle6 activeColor="all" />}
      </div>
      <h3 className="pl5 pr5 mb0" style={{ marginTop: -19 }}>
        Hover over to view the average scores for each Lifestyle6 Factor
      </h3>
      <p className="mt0 pl5 pr5" style={{ fontSize: 13 }}>
        These averages are only based on individuals who have taken the
        calculator and does not accurately represent the general population.
      </p>
      <BarChartResults finalResults={finalResults} />

      <h1 className="mb5 pl5 pr5 viewMoreHeader">Lifestyle6 & Ages</h1>
      <h3 className="pl5 pr5" style={{ marginTop: -5, marginBottom: 0 }}>
        Select a factor to see how different age groups compare
      </h3>
      <p className="mt0 pl5 pr5" style={{ fontSize: 13 }}>
        These percentages are only based on individuals who have taken the
        calculator and does not accurately represent the general population.
      </p>
      <div
        style={{ marginBottom: -15 }}
        className="justifycontentend dflex flexwrap viewmore-zoom"
      >
        <Button
          className={
            selectedType === 'UV'
              ? 'activeBtn '
              : '' + ' chartBtn borderRadius8'
          }
          onClick={() => changeGraphType('UV')}
          variant="outlined"
        >
          Sun & UV
        </Button>
        <Button
          className={
            selectedType === 'Nutrition'
              ? 'activeBtn '
              : '' + ' chartBtn borderRadius8'
          }
          onClick={() => changeGraphType('Nutrition')}
          variant="outlined"
        >
          Nutrition
        </Button>
        <Button
          className={
            selectedType === 'SkinCheck'
              ? 'activeBtn '
              : '' + ' chartBtn borderRadius8'
          }
          onClick={() => changeGraphType('SkinCheck')}
          variant="outlined"
        >
          Early Detection
        </Button>
      </div>
      <StackedAreaChart
        selectedType={selectedType}
        finalResults={finalResults}
      />

      <div style={{ marginTop: 50, padding: 5 }}>
        <h1 className="viewMoreHeader cancer-atlas mb0">
          Australian Cancer Atlas
        </h1>
        <h3 style={{ marginTop: '0px' }} className=" mb5">
          What is the Australian Cancer Atlas?
        </h3>
        <p style={{ fontSize: 18 }} className="mt10">
          The Australian Cancer Atlas is an online, interactive platform showing
          how measures relating to cancer diagnosis, survival, recognised risk
          factors, screening, and selected hospital treatment vary by small
          geographical areas across Australia.
        </p>

        <p style={{ fontSize: 18, marginBottom: 0 }} className="mt10">
          {' '}
          Updated and expanded in May 2024, the latest version of the Atlas is
          the result of a collaborative partnership between Cancer Council
          Queensland and Queensland University of Technology.
        </p>
        <Link to="https://atlas.cancer.org.au/" target="_blank">
          <Button fullWidth variant="outlined" className="cancer-atlas-link">
            Explore the Australian Cancer Atlas here{' '}
            <FaExternalLinkAlt className="atlas-external" />{' '}
          </Button>
        </Link>
        <iframe
          src="https://atlas.cancer.org.au/atlas"
          title="Cancer Atlas"
          className={
            show ? 'cancer-atlas-iframe ' : 'cancer-atlas-iframe dnone'
          }
          onLoad={onLoad}
          width="100%"
          height={1000}
          style={{ marginTop: '30px' }}
        ></iframe>
      </div>
    </div>
  );
};

export default CompareResults;
