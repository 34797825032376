import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Calculator from './routes/Calculator';
import Navbar from './components/navbar/Navbar';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Results from './routes/Results';
import Events from './routes/Events';
import Unsubscribed from './routes/Unsubscribed';
import NoPageFound from './routes/404';
import { finalResultsObject } from './utils/finalResults';
import { Button, useMediaQuery } from '@mui/material';
import DemoView from './routes/demo';

//Set theme used in calculator
const theme = createTheme({
  palette: {
    primary: {
      main: '#151e62',
      light: '#3a99d9',
      dark: '#151e62',
    },
    secondary: {
      main: '#ffd200',
      contrastText: '#fff',
    },
    primaryLight: {
      main: '#3a99d9',
    },
  },
});

function App() {
  const mobileView = useMediaQuery('(max-width:850px)');

  //Initalise final results and risk ratios object
  const [finalResults, setfinalResults] = useState(finalResultsObject);
  const [riskRatios, setRiskRatios] = useState({
    uv_park: {
      factor: 'uv',
      risk_ratios: [
        { skin: 1.62 },
        { breast: 0 },
        { lung: 0 },
        { prostate: 0 },
        { bowel: 0 },
      ],
    },
    uv_beach: {
      factor: 'uv',
      risk_ratios: [
        { skin: 1.62 },
        { breast: 0 },
        { lung: 0 },
        { prostate: 0 },
        { bowel: 0 },
      ],
    },
  });
  //Update final answers object each time user selects an answer
  const updateFinalResults = (data, risk_ratios) => {
    setfinalResults({ ...finalResults, ...data });
    setRiskRatios({ ...riskRatios, ...risk_ratios });
  };

  //Set user view to navigate through calculator
  const [activeView, setActiveView] = useState('LandingPage');
  const [hideNav, setHideNav] = useState(false);
  const location = useLocation();
  const pathname = location.pathname;

  //Hide navbar based on page and mobile view
  useEffect(() => {
    if (
      mobileView &&
      activeView === 'LandingPage' &&
      location.pathname !== '/results' &&
      location.pathname !== '/unsubscribe'
    ) {
      setHideNav(true);
    } else {
      setHideNav(false);
    }
  }, [mobileView, activeView, pathname]);

  //Check if Apple user
  useEffect(() => {
    const isAppleDevice = () => {
      return /(iPhone|iPad|iPod)/.test(navigator.userAgent);
    };

    //Apply css that affects Apple displays with the FocoCC font
    const addAppleDeviceClass = () => {
      if (isAppleDevice()) {
        document.body.classList.add('font-synthesis-none');
      }
    };
    addAppleDeviceClass();
    return () => {
      document.body.classList.remove('font-synthesis-none');
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        {!hideNav && <Navbar activeView={activeView} />}
        <Routes>
          <Route
            path="/"
            exact
            element={
              <Calculator
                updateFinalResults={updateFinalResults}
                setfinalResults={setfinalResults}
                finalResults={finalResults}
                riskRatios={riskRatios}
                activeView={activeView}
                setActiveView={setActiveView}
              />
            }
          />
          <Route
            path="/demo"
            setActiveView={setActiveView}
            element={
              <DemoView
                updateFinalResults={updateFinalResults}
                setfinalResults={setfinalResults}
                finalResults={finalResults}
                riskRatios={riskRatios}
                activeView={activeView}
                setActiveView={setActiveView}
              />
            }
          />
          <Route
            path="/results"
            setActiveView={setActiveView}
            element={<Results />}
          />
          <Route path="/events" element={<Events />} />
          <Route path="/unsubscribe" element={<Unsubscribed />} />
          <Route path="*" element={<NoPageFound />} />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
