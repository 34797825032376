export const finalResultsObject = {
  id: '',
  event_code: '',
  accepted_disclaimer: '',
  email_provided: false,
  email_provided_at_results: false,
  uv_total: 100,
  smoking_total: 100,
  alcohol_total: 100,
  nutrition_total: 100,
  physical_total: 100,
  screening_total: 100,
  name: '',
  email: '',
  age: '',
  age_group: '',
  birth_year: '',
  occupation: '',
  state: '',
  state_code: '',
  country: 'Australia',
  birth_country: '',
  postcode: '',
  region: {},
  primary_language: '',
  weight: '',
  height: '',
  waist: '',
  bmi: '',
  sex: {
    answer: '',
  },
  gender: {
    answer: '',
  },
  aboriginal_torres_strait_identity: {
    answer: '',
  },
  uv_sunscreen: {
    answer: '',
  },
  uv_tanning: {
    answer: '',
  },
  uv_outside_duration: {
    answer: '',
  },
  uv_beach: {
    question: `When you're swimming or at the beach, how do you protect your skin?`,
    factor: 'uv',
    answers: {
      shirt: 'no_shirt',
      pants: 'no_pants',
      hat: 'no_hat',
      sunscreen: 'no_sunscreen',
      shade: 'no_shade',
      sunglasses: 'no_sunglasses',
    },
  },
  uv_park: {
    question: `When you're outside, how do you protect your skin?`,
    factor: 'uv',
    answers: {
      shirt: 'no_shirt',
      pants: 'no_pants',
      hat: 'no_hat',
      sunscreen: 'no_sunscreen',
      shade: 'no_shade',
      sunglasses: 'no_sunglasses',
    },
  },
  smoking_status: {
    answer: '',
  },
  smoking_household: {
    answer: '',
  },
  vaping_status: {
    answer: '',
  },
  vaping_household: {
    answer: '',
  },
  alcohol_status: '',
  alcohol_standard_drinks: {
    question_id: `alcohol_standard_drinks`,
    standard_drinks_total: 0,
    factor: 'alcohol',
    answers: {
      beer_schooner: 0,
      beer_pint: 0,
      light_strength_beer: 0,
      mid_strength_beer: 0,
      full_strength_beer: 0,
      white_wine: 0,
      red_wine: 0,
      champagne: 0,
      spirit_shot: 0,
      spirit_premix: 0,
    },
  },
  alcohol_binge: {
    answer: '',
  },
  fruit: {
    question: `In a typical day, how many serves of fruit do you usually have?`,
    question_id: `fruit`,
    factor: 'nutrition',
    answer: 0,
  },
  vegetables: {
    question: `In a typical day, how many serves of vegetables do you usually have?`,
    question_id: `vegetables`,
    factor: 'nutrition',
    answer: 0,
  },
  wholegrains: {
    question: `In a typical day, how many serves of whole grains do you usually have?`,
    question_id: `wholegrains`,
    factor: 'nutrition',
    answer: 0,
  },
  red_meat: {
    question: `In a typical week, how many serves of red meat do you usually have?`,
    question_id: `red_meat`,
    factor: 'nutrition',
    answer: 0,
  },
  processed_meat: {
    question: `In a typical week, how many serves of processed meat do you usually have?`,
    question_id: `processed_meat`,
    factor: 'nutrition',
    answer: 0,
  },
  exercise: {
    question: `In a typical week, how many hours on average do you usually get of:`,
    question_id: `exercise`,
    factor: 'physical',
    total_minutes_combined_exercise: 0,
    answers: {
      light: 0,
      vigorous: 0,
      muscle: 0,
    },
  },
  sedentary: {
    question: `In a typical day, how many hours are you sedentary (inactive)?`,
    question_id: `sedentary`,
    factor: 'physical',
    total_minutes_sedentary: 0,
    answers: {
      sedentary: 0,
    },
  },
};
