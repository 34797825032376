import { Button, Container, Grow, MenuItem, Select } from '@mui/material';
import React from 'react';
import SectionHeader from '../other/SectionHeader';
import MultiChoiceQuestion from '../../body/MultichoiceQuestion';
import { calculateTotalScore } from '../../../utils/calculateScores';
import { risk_ratios_smoking, risk_ratios_vaping } from '../../../utils/config';

function Smoking({
  setActiveView,
  finalResults,
  updateFinalResults,
  currentSmokingQuestion,
  setCurrentSmokingQuestion,
  configQuestions,
  handleCompletedSteps,
  riskRatios,
}) {
  const handleAnswerClick = (questionIndex) => {
    //Display next question
    if (currentSmokingQuestion <= questionIndex) {
      setCurrentSmokingQuestion(currentSmokingQuestion + 1);
    }
  };

  //Total count of questions to display
  const totalQuestionCount = configQuestions.filter(
    (item) => item.factor === 'smoking' && item.displayed(finalResults),
  ).length;

  const checkAllRequired = () => {
    setActiveView('Alcohol');
    handleCompletedSteps('Smoking');
    const score = calculateTotalScore(riskRatios, 'smoking');
    updateFinalResults({ smoking_total: score });
  };

  const nextDisabled =
    currentSmokingQuestion === totalQuestionCount ||
    finalResults.vaping_household.answer !== '';

  //Calculate and add risk ratios for smoking and vaping years since quit
  //Research has decided same risk ratios for both smokers and vapers
  const handleChange = (e) => {
    let answer = e.target.value;
    var object = risk_ratios_smoking.find((item) => item.answer === answer);
    updateFinalResults(
      {
        smoking_status: {
          question_id: 'smoking_status',
          factor: 'smoking',
          question: 'Do you smoke cigarettes?',
          answer: `I quit smoking`,
          answer_info: answer,
          response: `I quit smoking ${answer}`,
          recommendation:
            "Congratulations on quitting! Every year you quit, your risk decreases. Due to your history, there is a risk present and it's important to regularly monitor your health with your doctor.",
          good_response: true,
        },
      },
      {
        smoking_status: {
          question_id: 'smoking_status',
          factor: 'smoking',
          risk_ratios: object.risk_ratios,
        },
      },
    );
  };
  const handleVapeChange = (e) => {
    let answer = e.target.value;
    var object = risk_ratios_vaping.find((item) => item.answer === answer);
    updateFinalResults(
      {
        vaping_status: {
          question_id: 'vaping_status',
          factor: 'smoking',
          question: 'Do you vape e-cigarettes?',
          answer: 'I quit vaping',
          answer_info: answer,
          response: `I quit vaping ${answer}`,
          recommendation: `Congratulations on quitting! It's important to have regular health checks with your doctor as the long-term effects of smoking e-cigarettes are still unknown.`,
          good_response: true,
        },
      },
      {
        vaping_status: {
          question_id: 'vaping_status',
          factor: 'smoking',
          risk_ratios: object.risk_ratios,
        },
      },
    );
  };

  return (
    <>
      <Grow in={true}>
        <Container>
          <SectionHeader
            title="Smoking & Vaping"
            subtext="Let's chat about smoking and vaping behaviours."
            activeColor="#878888"
            factor="smoking"
          />
          {configQuestions
            .filter(
              (item) =>
                item.factor === 'smoking' && item.displayed(finalResults),
            )
            .map((question, index) => {
              const questionDisplayed = currentSmokingQuestion >= index;
              return (
                <>
                  <MultiChoiceQuestion
                    key={index}
                    questionDisplayed={questionDisplayed}
                    handleAnswerClick={handleAnswerClick}
                    question={question}
                    questionIndex={index}
                    updateFinalResults={updateFinalResults}
                    finalResults={finalResults}
                  />
                  {question.id === 'smoking_status' &&
                    finalResults.smoking_status.answer === 'I quit smoking' && (
                      <div className="dropdown-selects">
                        <h3 className="mb10 mt30">
                          How many years ago approximately did you quit smoking?
                        </h3>
                        <Select
                          style={{ height: 50 }}
                          className="mt0  dropdown-selects "
                          sx={{ width: 200, fontSize: 15 }}
                          onChange={handleChange}
                          displayEmpty
                          value={
                            finalResults.smoking_status.answer_info ||
                            'less than 1 year ago'
                          }
                        >
                          {risk_ratios_smoking.map((res, index) => (
                            <MenuItem key={index} value={res.answer}>
                              {res.answer}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    )}
                  {question.id === 'vaping_status' &&
                    finalResults.vaping_status.answer === 'I quit vaping' && (
                      <div className="dropdown-selects">
                        <h3 className="mb10 mt30">
                          How many years ago approximately did you quit vaping?
                        </h3>
                        <Select
                          style={{ height: 50 }}
                          className="mt0"
                          sx={{ width: 200, fontSize: 15 }}
                          onChange={handleVapeChange}
                          displayEmpty
                          value={
                            finalResults.vaping_status.answer_info ||
                            'less than 1 year ago'
                          }
                        >
                          {risk_ratios_vaping.map((res, index) => (
                            <MenuItem key={index} value={res.answer}>
                              {res.answer}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    )}
                </>
              );
            })}

          <div className="dflex mb10 mt30 justifycontentspacebetween">
            <Button
              variant="contained"
              className="prevBtn"
              onClick={() => {
                setActiveView('UV_Park');
              }}
            >
              Back
            </Button>

            <Button
              variant="contained"
              color="primary"
              className={'nextBtn'}
              onClick={checkAllRequired}
              disabled={!nextDisabled}
            >
              Next
            </Button>
          </div>
        </Container>
      </Grow>
    </>
  );
}

export default Smoking;
