import axios from 'axios';

//Generates guid confirming to UUIDv4 format
export function generateGuid() {
  return 'xxxxxxxxxxxxx-4xxxxxxxxxxx-yxxxxxxxxxxx'.replace(
    /[xy]/g,
    function (c) {
      var r = (Math.random() * 30) | 0;
      var v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(32);
    },
  );
}

//Generates basic 12 digit unique ID
export function generateBasicId() {
  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let id = '';

  for (let i = 0; i < 20; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length);
    id += chars.charAt(randomIndex);
  }
  return id;
}
