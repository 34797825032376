import React, { memo } from 'react';
import noneSelectedImg from '../../../../assets/icons/none_selected.png';

import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import './Interactive.css';

const ToggleOption = ({ option, handleChange, setIndex, options }) => {
  return (
    <div className="mb5 toggleContainer">
      <div className="toggleBackground">
        <img
          alt={option.id}
          src={option.noneSelected ? noneSelectedImg : option.icon}
        />
        <h3 style={{ fontSize: 17 }} className="mt10 mb10">
          {option.label}
        </h3>
        <div className="gap10 dflex justifycontentcenter">
          <FaChevronLeft
            className="toggleBtns"
            onClick={() => {
              handleChange(option, setIndex, options.length, 'right');
            }}
          />
          <FaChevronRight
            className="toggleBtns"
            onClick={() =>
              handleChange(option, setIndex, options.length, 'left')
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ToggleOption;
