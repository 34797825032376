import { Button, Container, Grow, Slider, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SectionHeader from '../other/SectionHeader';
import Cyclist from '../../../assets/illustrations/physical/cyclist.png';
import Yoga from '../../../assets/illustrations/physical/yoga.png';
import Weights from '../../../assets/illustrations/physical/weights.png';
import Screen from '../../../assets/illustrations/physical/screen.png';
import { calculateTotalScore } from '../../../utils/calculateScores';
import { risk_ratios_physical } from '../../../utils/config';

function Physical({
  setActiveView,
  finalResults,
  updateFinalResults,
  handleCompletedSteps,
  riskRatios,
}) {
  const [exerciseLight, setExerciseLight] = useState(
    finalResults.exercise.answers.light,
  );
  const [exerciseVigorous, setExerciseVigorous] = useState(
    finalResults.exercise.answers.vigorous,
  );
  const [exerciseMuscle, setExerciseMuscle] = useState(
    finalResults.exercise.answers.muscle,
  );
  const [sedentary, setSedentary] = useState(
    finalResults.sedentary.answers.sedentary,
  );

  const [totalMinsExercise, setTotalMinsExercise] = useState(
    finalResults.exercise.total_minutes_combined_exercise,
  );
  const [totalMinsSedentary, setTotalMinsSedentary] = useState(
    finalResults.sedentary.total_minutes_sedentary,
  );
  const [totalHoursActive, setTotalHoursActive] = useState(0);

  const [changes, setChanges] = useState('');
  const handleChange = (newValue, setIndexType) => {
    setIndexType(newValue);
    setChanges(newValue);
  };

  useEffect(() => {
    //Calculate total minutes of exercise -- double intense + muscle exercise for risk ratios
    var totalMinutes = 0;
    totalMinutes += exerciseLight * 60;
    totalMinutes += exerciseVigorous * 120;
    totalMinutes += exerciseMuscle * 120;
    setTotalMinsExercise(totalMinutes);

    var totalMinutesSedentary = 0;
    totalMinutesSedentary += sedentary * 60;
    setTotalMinsSedentary(totalMinutesSedentary);

    var risk_ratios_sedentary = risk_ratios_physical['sedentary_under_6_hrs'];
    var risk_ratios_exercise = risk_ratios_physical['exercise_under_300_mins'];

    if (totalMinutesSedentary > 360) {
      risk_ratios_sedentary = risk_ratios_physical['sedentary_over_6_hrs'];
    }

    if (totalMinutes >= 300) {
      risk_ratios_exercise = risk_ratios_physical['exercise_over_300_mins'];
    }

    //Check if user is over 65 - if so, reduce total recommended minutes to 150.
    if (finalResults.age >= 65) {
      if (totalMinutes >= 150) {
        risk_ratios_exercise = risk_ratios_physical['exercise_over_300_mins'];
      }
    }

    updateFinalResults(
      {
        exercise: {
          question: `In a typical week, how many hours on average do you usually get of:`,
          question_id: `exercise`,
          factor: 'physical',
          answers: {
            light: exerciseLight,
            vigorous: exerciseVigorous,
            muscle: exerciseMuscle,
          },
          total_minutes_combined_exercise: totalMinutes,
        },
        sedentary: {
          question: `In a typical day, how many hours are you sedentary (inactive)?`,
          question_id: `sedentary`,
          factor: 'physical',
          answers: {
            sedentary: sedentary,
          },
          total_minutes_sedentary: totalMinutesSedentary,
        },
      },
      {
        exercise_risk: {
          question_id: `exercise`,
          factor: 'physical',
          risk_ratios: risk_ratios_exercise,
        },
        sedentary_risk: {
          question_id: `sedentary`,
          factor: 'physical',
          risk_ratios: risk_ratios_sedentary,
        },
      },
    );
  }, [changes]);

  useEffect(() => {
    var totalHours = exerciseLight + exerciseVigorous + exerciseMuscle;
    setTotalHoursActive(totalHours);
  }, [exerciseLight, exerciseVigorous, exerciseMuscle]);

  useEffect(() => {
    if (finalResults.email !== '') {
      updateFinalResults({ email_provided: true });
    }
  }, []);

  const activityOptions = [
    {
      id: 'exercise_light',
      label: 'Light & Moderate Activities',
      example1: `Such as walking, gardening or yoga`,
      exampleImage1: Yoga,
      actualIndex: exerciseLight,
      settingIndex: setExerciseLight,
      max: 10,
      finalResults: finalResults.exercise.answers.light,
      type: 'each week',
    },
    {
      id: 'exercise_vigorous',
      label: 'Vigorous Activities',
      example1: `Such as running, cycling or playing tennis`,
      exampleImage1: Cyclist,
      actualIndex: exerciseVigorous,
      settingIndex: setExerciseVigorous,
      max: 10,
      finalResults: finalResults.exercise.answers.vigorous,
      type: 'each week',
    },
    {
      id: 'exercise_muscle',
      label: 'Muscle Strengthening Activities',
      example1: `Such as weight lifting, pilates or uphill hiking`,
      exampleImage1: Weights,
      actualIndex: exerciseMuscle,
      settingIndex: setExerciseMuscle,
      max: 10,
      finalResults: finalResults.exercise.answers.muscle,
      type: 'each week',
    },
    {
      id: 'sedentary_hours',
      label: 'Sedentary (Inactive)',
      example1: `Such as sitting at work or watching TV`,
      exampleImage1: Screen,
      actualIndex: sedentary,
      settingIndex: setSedentary,
      max: 12,
      finalResults: finalResults.sedentary.answers.sedentary,
      type: 'everyday',
    },
  ];

  const mobileView = useMediaQuery('(max-width:850px)');

  return (
    <>
      <Grow in={true}>
        <Container>
          <SectionHeader
            title="Physical Activity"
            subtext="It's not only about gym sessions, it's about incorporating movement into your life."
            activeColor="#199bd7"
            factor="physical"
          />
          <h3 style={{ marginTop: 20 }}>
            In a typical <span className="importantText">week</span>, how many
            hours on average do you usually get of:
          </h3>

          {activityOptions
            .filter((item) => item.type === 'each week')
            .map((item, index) => {
              return (
                <div key={index} className="mt20 nutritionSection">
                  <div className="dflex flexwrap alignitemscenter justifycontentcenter gap10">
                    <h2 className="dflex mb10 textaligncenter gap10 alignitemscenter">
                      {item.label}{' '}
                      <img
                        style={{ height: 36 }}
                        alt={item.label}
                        src={item.exampleImage1}
                      />
                    </h2>
                  </div>
                  <p className="sliderSubtext dflex flexwrap justifycontentcenter mt0 mb0 alignitemscenter gap10 textaligncenter">
                    {item.example1}
                  </p>

                  <div
                    className={
                      (mobileView ? 'mobileViewStepper ' : '') +
                      'dflex flexwrap mt20 alignitemsnormal justifycontentcenter alignitemscenter'
                    }
                  >
                    <Slider
                      key={'slider_' + item.id}
                      aria-label={item.label}
                      onChange={(e, newValue) =>
                        handleChange(newValue, item.settingIndex)
                      }
                      value={
                        typeof item.actualIndex === 'number'
                          ? item.actualIndex
                          : 0
                      }
                      className="nutritionSliders"
                      valueLabelDisplay="auto"
                      step={1}
                      marks
                      min={0}
                      max={item.max}
                    />
                    <h3 className="mt5">
                      {item.actualIndex === item.max
                        ? `${item.actualIndex}+ hours ${item.type}`
                        : `${item.actualIndex} ${
                            item.actualIndex === 1 ? 'hour' : 'hours'
                          } ${item.type}`}
                    </h3>
                  </div>
                </div>
              );
            })}

          <>
            <p className="mt20 mb0 subtext-gray textaligncenter">
              Approximately
            </p>
            <h3 className="mt0 mb0 textaligncenter">
              {totalHoursActive} {totalHoursActive === 1 ? 'hour' : 'hours'}
              {exerciseLight === 10 ||
              exerciseVigorous === 10 ||
              exerciseMuscle === 10
                ? '+'
                : ''}{' '}
              each week
            </h3>
          </>

          <h3 style={{ marginTop: 15 }}>
            In a typical <span className="importantText">day</span>, how many
            hours on average are you:
          </h3>

          {activityOptions
            .filter((item) => item.type === 'everyday')
            .map((item, index) => {
              return (
                <div key={index} className="mt20 nutritionSection">
                  <div className="dflex flexwrap alignitemscenter justifycontentcenter gap10">
                    <h2 className="dflex mb10 gap10 alignitemscenter">
                      {item.label}
                      <img
                        style={{ height: 30 }}
                        alt={item.label}
                        src={item.exampleImage1}
                      />
                    </h2>
                  </div>
                  <p className="sliderSubtext dflex flexwrap justifycontentcenter mt0 mb0 alignitemscenter gap10 textaligncenter">
                    {item.example1}
                  </p>

                  <div
                    className={
                      (mobileView ? 'mobileViewStepper ' : '') +
                      'dflex flexwrap mt20 alignitemsnormal justifycontentcenter alignitemscenter'
                    }
                  >
                    <Slider
                      key={'slider_' + item.id}
                      aria-label={item.label}
                      onChange={(e, newValue) =>
                        handleChange(newValue, item.settingIndex)
                      }
                      value={
                        typeof item.actualIndex === 'number'
                          ? item.actualIndex
                          : 0
                      }
                      className="nutritionSliders"
                      valueLabelDisplay="auto"
                      step={2}
                      marks
                      min={0}
                      max={item.max}
                    />
                    <h3 className="mt5">
                      {item.actualIndex === item.max
                        ? `${item.actualIndex}+ hours ${item.type}`
                        : `${item.actualIndex} ${
                            item.actualIndex === 1 ? 'hour' : 'hours'
                          } ${item.type}`}
                    </h3>
                  </div>
                </div>
              );
            })}

          <div className="dflex mb10 mt30 justifycontentspacebetween">
            <Button
              variant="contained"
              className="prevBtn"
              onClick={() => {
                setActiveView('Weight');
              }}
            >
              Back
            </Button>

            <Button
              variant="contained"
              color="primary"
              className={'nextBtn'}
              onClick={() => {
                const score = calculateTotalScore(riskRatios, 'physical');
                updateFinalResults({ physical_total: score });
                setActiveView('Screening');
                handleCompletedSteps('Physical');
              }}
            >
              Next
            </Button>
          </div>
        </Container>
      </Grow>
    </>
  );
}

export default Physical;
