import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Button, Grow, MenuItem, Paper } from '@mui/material';

export default function PrimaryLanguage({
  updateFinalResults,
  finalResults,
  questionIndex,
  handleAnswerClick,
  questionDisplayed,
  setError,
  error,
}) {
  const handleLanguageChange = (language, index) => {
    if (language === null) {
      updateFinalResults({
        primary_language: '',
      });
      setError(true);
      return;
    }
    setError(false);

    updateFinalResults({
      primary_language: language,
    });

    const scrollBy = window.scrollY + 300;
    window.scrollTo({ top: scrollBy, behavior: 'smooth' });

    handleAnswerClick(index);
  };

  return (
    <Grow
      className={questionDisplayed ? 'height100' : 'height0 opacity0'}
      in={questionDisplayed}
    >
      <div>
        <h3 className="mt30 mb0">
          What is the main language spoken in your home?
        </h3>
        <div className="mt10 alignitemscenter dflex">
          <Button
            size="large"
            style={{ width: 100 }}
            className={
              'answer-btn ' +
              (finalResults.primary_language === 'English'
                ? 'selected-answer'
                : '')
            }
            onClick={() => {
              handleAnswerClick(questionIndex);
              updateFinalResults({
                primary_language: 'English',
              });
            }}
          >
            English
          </Button>
          OR
          <Autocomplete
            value={
              finalResults.primary_language === 'English'
                ? ''
                : finalResults.primary_language
            }
            PaperComponent={({ children }) => (
              <Paper
                sx={{
                  '& .MuiAutocomplete-listbox': { overflowX: 'hidden' },
                  '.MuiInputBase-input': { fontSize: 14 },
                  '& .MuiAutocomplete-listbox > li::before': {
                    color: 'transparent',
                  },
                }}
                className="autocomplete"
              >
                {children}
              </Paper>
            )}
            style={{ width: 200 }}
            onChange={(e, newValue) =>
              handleLanguageChange(newValue, questionIndex)
            }
            options={languages}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  '.MuiInputBase-input': { fontSize: 14 },
                }}
                InputLabelProps={{
                  style: { fontSize: 14 },
                  autocomplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
                label="Select Language"
              />
            )}
          />
        </div>
      </div>
    </Grow>
  );
}

const languages = [
  'Mandarin',
  'Arabic',
  'Vietnamese',
  'Cantonese',
  'Punjabi',
  'Hindi',
  'Greek',
  'Italian',
  'Spanish',
  'Nepali',
  'Tagalog',
  'French',
  'Bengali',
  'Russian',
  'Indonesian',
  'German',
  'Japanese',
  'Korean',
  'Urdu',
  'Tamil',
  'Filipino',
  'Samoan',
  'Afrikaans',
  'Portuguese',
  'Turkish',
  'Other',
  'Prefer not to say',
];
