import React, { useState } from 'react';
import '../LearnMore.css';
import BmiModal from '../../../body/weight/components/BmiModal';
import WaistModal from '../../../body/weight/components/WaistImageModal';
import { Link } from 'react-router-dom';
import QuestionsWeight from '../../../body/weight/QuestionsWeight';

const WeightAnswers = ({ finalResults, factor }) => {
  const answers = Object.values(finalResults).filter(
    (res) => res.factor === 'nutrition',
  );

  return (
    <div className="subtext">
      <h4 className="learnMoreHeader mt0 mb10">Did you know:</h4>
      <li>
        Overweight and obesity has been found to be a risk factor for 13 types
        of cancers.
      </li>
      <li>
        Having a high body weight, especially around your waist, can lead to
        inflammation. It can also increase the level of harmful chemicals and
        hormones in your body, making it easier for cancer cells to grow.
      </li>
      <li>
        Weight gain can happen because of things out of our control, such as not
        having enough healthy food choices or places to be active. When
        possible, try making healthy choices and stay active to lower your
        cancer risk.
      </li>
      <div className="dflex">
        {finalResults.bmi !== '' && (
          <div className="answerContainerWeight mt20 mb10">
            <h3 className="mb10 textaligncenter">
              Body Mass Index: <br></br>{' '}
              {finalResults.bmi !== '' ? finalResults.bmi : '-'}
            </h3>
            <BmiModal />
          </div>
        )}

        {finalResults.waist !== '' && (
          <div className="answerContainerWeight mt20 mb10">
            <h3 className="mb10 textaligncenter">
              Waist Measurement: <br></br>{' '}
              {finalResults.waist !== '' ? finalResults.waist + 'cm' : '-'}
            </h3>
            <WaistModal />
          </div>
        )}
      </div>

      <div>
        <h4 className="learnMoreHeader mt20 mb10">Tips & Resources:</h4>
        <p className="mb10">
          Making small changes to your diet and lifestyle can lead to a
          healthier weight.
        </p>
        <li>
          Choose whole, unprocessed foods like fruits, vegetables, and lean
          meats. Try to avoid drinks with lots of sugar and processed snacks.
        </li>
        <li>
          Move your body regularly. It doesn't just reduce your cancer risk, it
          can also improve your general health and wellbeing.
        </li>
        <li>
          Make sure you get enough sleep. Not getting enough can change the
          hormones in your body, making you more hungry and less satisfied after
          eating.
        </li>
        <li>
          Pay attention to your diet, sleep, stress and physical activity.
          Remember that weight is not the only marker of health.
        </li>
        <p className="mt10">
          For professional advice, talk to your doctor or an{' '}
          <Link
            className="link "
            target="_blank"
            to="https://member.dietitiansaustralia.org.au/Portal/Portal/Search-Directories/Find-a-Dietitian.aspx"
          >
            Accredited Practising Dietitian.
          </Link>
        </p>
      </div>
    </div>
  );
};

export default WeightAnswers;
