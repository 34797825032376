import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  Container,
  Fade,
  TextField,
  CircularProgress,
} from '@mui/material';
import '../App.css';
import '../components/events/Events.css';
import AverageScores from '../components/events/charts/AverageScores';
import { BsPersonFill } from 'react-icons/bs';
import { TbArrowRight } from 'react-icons/tb';

import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import UvResults from '../components/events/components/UvResults';
import ScreeningResults from '../components/events/components/ScreeningResults';
import NutritionResults from '../components/events/components/NutritionResults';
import PhysicalResults from '../components/events/components/PhysicalResults';
import SmokingResults from '../components/events/components/SmokingResults';
import AlcoholResults from '../components/events/components/AlcoholResults';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Events = () => {
  let query = useQuery();
  const navigate = useNavigate();

  const buttonRef = useRef(null);
  const [code, setCode] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [passwordValid, setPasswordValid] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [codeError, setCodeError] = useState(false);

  const [response, setResponse] = useState([]);
  const [length, setLength] = useState(0);
  const [page, setPage] = React.useState('uv');

  const [screeningPersonal, setScreeningPersonal] = useState([0, 0]);
  const [screeningDoc, setScreeningDoc] = useState([0, 0]);
  const [screeningRec, setScreeningRec] = useState([0, 0]);

  const [smokingHousehold, setSmokingHousehold] = useState([0, 0]);
  const [vapingHousehold, setVapingHousehold] = useState([0, 0]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const [averageScores, setAverageScores] = useState([
    {
      factor: 'UV & Sun Exposure',
      factorSection: 'uv',
      color: '#c9a800',
      total: 0,
    },
    {
      factor: 'Smoking',
      factorSection: 'smoking',
      color: '#878888',
      total: 0,
    },
    {
      factor: 'Alcohol',
      factorSection: 'alcohol',
      color: '#f05029',
      total: 0,
    },
    {
      factor: 'Nutrition',
      factorSection: 'nutrition',
      color: '#73c04b',
      total: 0,
    },
    {
      factor: 'Physical Activity',
      factorSection: 'physical',
      color: '#199bd7',
      total: 0,
    },
    {
      factor: 'Screening & Early Detection',
      factorSection: 'screening',
      color: '#7d489c',
      total: 0,
    },
  ]);

  const checkPass = async () => {
    try {
      const response = await axios.get(`/api/events-pass?pass=${password}`);
      var valid_response = response.data.valid;
      if (valid_response) {
        setPasswordValid(true);
        setPasswordError(false);
        setPassword('');
      } else {
        setPasswordValid(false);
        setPasswordError(true);
        setPassword('');
      }
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  useEffect(() => {
    const handleEnter = (event) => {
      if (event.key === 'Enter' && !passwordValid) {
        buttonRef.current.click();
      }
    };
    window.addEventListener('keydown', handleEnter);
    return () => {
      window.removeEventListener('keydown', handleEnter);
    };
  }, []);

  useEffect(() => {
    checkEvt();
  }, []);

  const checkEvt = async () => {
    const evt_key = query.get('evt');
    if (evt_key === null || evt_key === undefined || evt_key === '') {
      navigate(`/`);
      return;
    }
    try {
      const response = await axios.get(`/api/events?evt=${evt_key}`);
      var valid_response = response.data.valid;
      if (!valid_response) {
        navigate(`/`);
      }
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  //Fetch the data with the provided event code
  const fetchData = async () => {
    if (code === '' && selectedDate === '') {
      setLoading(false);
      setCodeError(true);
      return;
    }
    setCodeError(false);
    setLoading(true);
    const evt_key = query.get('evt');
    setResponse([]);
    try {
      const response = await axios.get(
        `/api/events-data?code=${code}&evt=${evt_key}&date=${selectedDate}`,
      );
      var items = response.data.items;
      if (items.length > 0) {
        setResponse(items);
        setLength(items.length);
      } else {
        setLength(0);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error: ', error);
    }
  };

  //Calculate percentages for bar charts
  const calculatePercentage = (data, key, allPossibleAnswers) => {
    var totalCount = 0;
    var counts = {};

    allPossibleAnswers.forEach((answer) => {
      counts[answer] = 0;
    });

    data.forEach((item) => {
      if (item.hasOwnProperty(key)) {
        const value = item[key];
        if (value !== undefined) {
          counts[value] = (parseInt(counts[value]) || 0) + 1;
          totalCount++;
        }
      }
    });

    const percentages = {};
    Object.keys(counts).forEach((value) => {
      percentages[value] = parseInt((counts[value] / totalCount) * 100);
    });
    return { percentages, totalCount, counts };
  };
  //Convert percentages for to bar chart data
  const convertToChartData = (value) => {
    return Object.keys(value).map((label) => ({
      x: label,
      y: value[label] || 0,
    }));
  };

  // Counts by key
  function calculateCountsByKey(data, key) {
    const counts = {};

    data.forEach((item) => {
      const value = item[key];
      if (value) {
        if (typeof value === 'object') {
          Object.values(value).forEach((val) => {
            counts[val] = (counts[val] || 0) + 1;
          });
        } else {
          counts[value] = (counts[value] || 0) + 1;
        }
      }
    });
    return counts;
  }

  useEffect(() => {
    var totalcount = response.length;
    var uv_total_sum = 0;
    var alcohol_total_sum = 0;
    var smoking_total_sum = 0;
    var nutrition_total_sum = 0;
    var physical_total_sum = 0;
    var screening_total_sum = 0;

    response.forEach((item) => {
      uv_total_sum += parseInt(item.uv_total) || 0;
      alcohol_total_sum += parseInt(item.alcohol_total) || 0;
      smoking_total_sum += parseInt(item.smoking_total) || 0;
      nutrition_total_sum += parseInt(item.nutrition_total) || 0;
      physical_total_sum += parseInt(item.physical_total) || 0;
      screening_total_sum += parseInt(item.screening_total) || 0;
    });

    var uv_avg = parseInt(uv_total_sum / totalcount);
    var alcohol_avg = parseInt(alcohol_total_sum / totalcount);
    var smoking_avg = parseInt(smoking_total_sum / totalcount);
    var nutrition_avg = parseInt(nutrition_total_sum / totalcount);
    var physical_avg = parseInt(physical_total_sum / totalcount);
    var screening_avg = parseInt(screening_total_sum / totalcount);

    setAverageScores((prevData) => {
      return prevData.map((item) => {
        if (item.factorSection === 'uv') {
          return { ...item, total: uv_avg };
        } else if (item.factorSection === 'smoking') {
          return { ...item, total: smoking_avg };
        } else if (item.factorSection === 'alcohol') {
          return { ...item, total: alcohol_avg };
        } else if (item.factorSection === 'nutrition') {
          return { ...item, total: nutrition_avg };
        } else if (item.factorSection === 'physical') {
          return { ...item, total: physical_avg };
        } else if (item.factorSection === 'screening') {
          return { ...item, total: screening_avg };
        } else {
          return item;
        }
      });
    });

    const screeningPersonalCounts = calculateCountsByKey(
      response,
      'check_skin_personal',
    );
    setScreeningPersonal([
      screeningPersonalCounts.Yes || 0,
      screeningPersonalCounts.No || 0,
    ]);

    const screeningDocCounts = calculateCountsByKey(
      response,
      'check_skin_doctor',
    );
    setScreeningDoc([screeningDocCounts.Yes || 0, screeningDocCounts.No || 0]);

    const screeningRecsCounts = calculateCountsByKey(
      response,
      'follow_doctor_recommendations',
    );
    setScreeningRec([
      screeningRecsCounts.Yes || 0,
      screeningRecsCounts.No || 0,
    ]);

    const smokingHouseholdCounts = calculateCountsByKey(
      response,
      'smoking_household',
    );
    const vapingHouseholdCounts = calculateCountsByKey(
      response,
      'vaping_household',
    );

    setSmokingHousehold([
      smokingHouseholdCounts.Yes || 0,
      smokingHouseholdCounts.No || 0,
    ]);
    setVapingHousehold([
      vapingHouseholdCounts.Yes || 0,
      vapingHouseholdCounts.No || 0,
    ]);
  }, [response]);

  return (
    <div className="container-wave">
      <div className="bg-blob"></div>
      <div className="bg-blob two"></div>
      {!passwordValid ? (
        <Container className="pass-container">
          <h2>Events Page Access</h2>
          <div className="dflex gap10 flexwrap">
            <TextField
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              label="Password"
              style={{ marginTop: '3px' }}
              error={passwordError}
              variant="standard"
            />
            <Button
              onClick={checkPass}
              ref={buttonRef}
              style={{ height: 40, marginTop: 11 }}
              variant="outlined"
            >
              <TbArrowRight style={{ fontSize: 25 }} />
            </Button>
          </div>
        </Container>
      ) : (
        <Fade in={true}>
          <Container className="events-container">
            <div className="dflex justifycontentspacebetween alignitemscenter mt20 ml0 mb0">
              <div className="resultsHeader dflex alignitemscenter">
                <h1 style={{ fontSize: 36, marginTop: 5 }}>Average Scores</h1>
                <h2 className="event-total mt30 dflex gap10">
                  {' '}
                  <BsPersonFill />
                  {length}
                </h2>
              </div>
              <div
                style={{ marginTop: '-30px' }}
                className="dflex alignitemcenters gap10 justifycontentend"
              >
                <input
                  className="datetime-input"
                  onChange={(e) => setSelectedDate(e.target.value)}
                  type="date"
                  title="Date"
                />
                <TextField
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  error={codeError}
                  label="Event Code"
                  variant="standard"
                  sx={{ marginTop: '3px' }}
                />
                <Button
                  onClick={fetchData}
                  style={{ height: 40, marginTop: 11 }}
                  variant="outlined"
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress style={{ width: 25, height: 25 }} />
                  ) : (
                    <TbArrowRight style={{ fontSize: 25 }} />
                  )}
                </Button>
              </div>
            </div>

            <div className="dflex flexwrap justifycontentcenter gap10">
              {averageScores.map((item) => {
                if (isNaN(item.total)) {
                  item.total = 0;
                }
                return (
                  <AverageScores
                    factor={item.factor}
                    factorSection={item.factorSection}
                    color={item.color}
                    total={item.total}
                  />
                );
              })}
            </div>

            <div className="change-view-container dflex flexwrap gap10">
              <button
                className={page === 'uv' && ' active '}
                onClick={() => handlePageChange('uv')}
              >
                UV & Sun
              </button>
              <button
                className={page === 'smoking' && ' active '}
                onClick={() => handlePageChange('smoking')}
              >
                Smoking
              </button>
              <button
                className={page === 'alcohol' && ' active '}
                onClick={() => handlePageChange('alcohol')}
              >
                Alcohol
              </button>
              <button
                className={page === 'nutrition' && ' active '}
                onClick={() => handlePageChange('nutrition')}
              >
                Nutrition
              </button>
              <button
                className={page === 'physical' && ' active '}
                onClick={() => handlePageChange('physical')}
              >
                Physical Activity
              </button>
              <button
                className={page === 'screening' && ' active '}
                onClick={() => handlePageChange('screening')}
              >
                Screening & Early Detection
              </button>
            </div>

            {page === 'uv' && (
              <UvResults
                data={response}
                calculateCountsByKey={calculateCountsByKey}
                calculatePercentage={calculatePercentage}
                convertToChartData={convertToChartData}
              />
            )}
            {page === 'smoking' && (
              <SmokingResults
                data={response}
                calculatePercentage={calculatePercentage}
                convertToChartData={convertToChartData}
                smokingHousehold={smokingHousehold}
                vapingHousehold={vapingHousehold}
              />
            )}
            {page === 'alcohol' && (
              <AlcoholResults
                data={response}
                calculateCountsByKey={calculateCountsByKey}
                calculatePercentage={calculatePercentage}
                convertToChartData={convertToChartData}
              />
            )}
            {page === 'physical' && <PhysicalResults data={response} />}
            {page === 'nutrition' && <NutritionResults data={response} />}
            {page === 'screening' && (
              <ScreeningResults
                data={response}
                screeningPersonal={screeningPersonal}
                screeningDoc={screeningDoc}
                screeningRec={screeningRec}
                calculateCountsByKey={calculateCountsByKey}
                calculatePercentage={calculatePercentage}
                convertToChartData={convertToChartData}
              />
            )}
          </Container>
        </Fade>
      )}
    </div>
  );
};

export default Events;
