import { Button, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';

const EnterEmail2 = ({ setNotSignedUp, finalResults, setFinalResults }) => {
  //Email regex and validation
  const [error, setError] = useState(true);
  const [email, setEmail] = useState('');
  const [submitError, setSubmitError] = useState(false);

  var emailRegex = /^\S+@\S+\.\S+$/;

  const handleEmailChange = (e) => {
    const final_email = e.target.value;
    setEmail(final_email);

    if (emailRegex.test(final_email)) {
      setError(false);
      setFinalResults({
        ...finalResults,
        ...{
          email: final_email,
          email_provided: true,
          email_provided_at_results: true,
        },
      });
    } else {
      setError(true);
    }
  };

  const handleNameChange = (e) => {
    const name = e.target.value;
    setFinalResults({
      ...finalResults,
      ...{ name: name },
    });
  };

  const handleSubmit = async () => {
    //Submit and upsert submitted info with email
    try {
      await axios.post('/api/submit-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(finalResults),
      });

      setNotSignedUp(false);
      setSubmitError(false);
    } catch (error) {
      console.error('Error: ', error);
      setSubmitError(true);
    }
  };

  return (
    <div id="enter_email_res" className="enterEmailResults">
      <h2 style={{ marginTop: 10 }}>Enter your email to view more!</h2>
      <p style={{ marginTop: '-15px', marginBottom: '20px' }}>
        View personalised recommendations and comparisons. By providing your
        email, you agree to our{' '}
        <a
          className="link"
          target="_blank"
          href="https://cancerqld.org.au/about-us/our-privacy-policy/general-collection-statement/"
        >
          Privacy Collection Statement.
        </a>
      </p>
      <div className="w100 dflex flexwrap">
        <TextField
          id="email_input_name"
          name="email_input_name"
          onChange={handleNameChange}
          value={finalResults.name}
          label="Name"
          type="text"
          style={{ minWidth: '200px' }}
          className="personal-inputs flex1"
        />
        <TextField
          id="email_input_results"
          name="email_input_results"
          onChange={handleEmailChange}
          value={email}
          label="Email"
          type="email"
          style={{ minWidth: '200px' }}
          className="personal-inputs flex1"
        />
      </div>

      <Button
        onClick={handleSubmit}
        fullWidth
        disabled={error}
        type="button"
        className="mt20 height50"
        variant="contained"
      >
        Submit <FaArrowRight className="ml10" />
      </Button>
      {submitError && (
        <h4 style={{ color: '#9f0000', fontSize: 18 }}>
          An error has occurred. Please check your internet and try again.
        </h4>
      )}
    </div>
  );
};

export default EnterEmail2;
