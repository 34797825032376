import React from 'react';
import Chart from 'react-apexcharts';
import './Results.css';

const SmokingChart = () => {
  const data = [
    {
      name: '',
      data: [100, 28.3, 12, 4.3, 0.1, 0],
    },
    {
      name: '',
      data: [100, 36.9, 20, 8.9, 3.3, 0],
    },
    {
      name: '',
      data: [100, 47.9, 28, 13.9, 7.0, 0],
    },
  ];

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    colors: ['#878888', '#878888', '#878888'],
    stroke: {
      width: [2, 4, 2],
      curve: 'smooth',
      dashArray: [4, 0, 4],
    },
    grid: {
      borderColor: '#555',
      clipMarkers: false,
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    fill: {
      show: false,
      gradient: {
        enabled: false,
        opacityFrom: 0,
        opacityTo: 0.1,
      },
    },
    markers: {
      size: [0, 4, 0],
      colors: ['#878888', '#878888', '#878888'],
      strokeWidth: [0, 1, 0],
    },
    yaxis: {
      title: {
        text: 'Percent of Relative Risk Remaining (%)',
      },
      min: 0,
      max: 100,
    },
    xaxis: {
      categories: [0, 10, 20, 30, 40, 60],
      title: {
        text: 'Years Since Quitting',
      },
    },
  };

  return (
    <div>
      <h4 className="learnMoreHeader mb0 mt30">
        Lung Cancer Risk after Quitting
      </h4>
      <p style={{ marginBottom: -15, marginTop: 5 }}>
        Your risk of lung cancer decreases after quitting smoking
      </p>
      <Chart
        options={options}
        series={data}
        height="350"
        width="100%"
        type="area"
      />
      <p className="mt0 mauto subtextRef">
        Reitsma M, Kendrick P, Anderson K, Arian N, Feldman R, Gakidou E, Gupta
        V (2020). Reexamining Rates of Decline in Lung Cancer Risk after Smoking
        Cessation. A Meta-analysis. Annals of the American Thoracic Society,
        17(9), 1126-1132.
      </p>
    </div>
  );
};
export default SmokingChart;
