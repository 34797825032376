import React from 'react';
import './Lifestyle6.css';
//Blue = Physical Activity #
//Green = Nutrition #
//Purple = Screening and Early Detection #
//Red = Alcohol #
//Yellow = UV #
//Gray = Smoking #

const Lifestyle6 = ({ factor, activeColor, section }) => {
  return (
    <div
      // className={
      //   (section === 'personal' ? ' dnone ' : '') + ' lifestyle6-section '
      // }
      className="lifestyle6-section"
    >
      <h2 className="lifestyleheaderh2">
        LIFESTYLE <span>6</span>
        <br></br>
        <p>Reduce your risk</p>
      </h2>
      <div className="lifestyle-petals">
        <div
          className={
            (factor === 'uv' || activeColor === 'all' ? 'active ' : '') +
            'petal yellow one'
          }
        ></div>
        <div
          style={{ color: activeColor }}
          className={
            (factor === 'smoking' || activeColor === 'all' ? 'active ' : '') +
            'petal gray two'
          }
        ></div>
        <div
          className={
            (factor === 'alcohol' || activeColor === 'all' ? 'active ' : '') +
            'petal red three'
          }
        ></div>
        <div
          className={
            (activeColor === 'all' ||
            factor === 'weight' ||
            factor === 'nutrition'
              ? 'active '
              : '') + 'petal green four'
          }
        ></div>
        <div
          className={
            (activeColor === 'all' ||
            factor === 'physical' ||
            factor === 'weight'
              ? 'active '
              : '') + 'petal five blue'
          }
        ></div>
        <div
          className={
            (factor === 'screening' || activeColor === 'all' ? 'active ' : '') +
            'petal purple six'
          }
        ></div>
      </div>
    </div>
  );
};

export default Lifestyle6;
