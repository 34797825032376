import React, { useState, useEffect } from 'react';
import '../../../App.css';
import '../Events.css';
import LinearGauge from '../charts/LinearGauge';
import SingleStackedBar from '../charts/SingleStackedBar';

import yoga from '../../../../src/assets/illustrations/physical/yoga.png';
import cyclist from '../../../../src/assets/illustrations/physical/cyclist.png';
import weights from '../../../../src/assets/illustrations/physical/weights.png';
import screen from '../../../../src/assets/illustrations/physical/screen.png';

const PhysicalResults = ({ data }) => {
  const [averageScores, setAverageScores] = useState({
    light_exercise: 0,
    vigorous_exercise: 0,
    muscle_exercise: 0,
    sedentary: 0,
    light_exerciseSeries: [],
    vigorous_exerciseSeries: [],
    muscle_exerciseSeries: [],
    sedentarySeries: [],
  });

  function calculateAverage(data) {
    const totalEntries = data.length;
    if (totalEntries === 0) {
      return {
        light_exercise: 0,
        vigorous_exercise: 0,
        muscle_exercise: 0,
        sedentary: 0,
        light_exerciseSeries: [],
        vigorous_exerciseSeries: [],
        muscle_exerciseSeries: [],
        sedentarySeries: [],
      };
    }

    let sumLight = 0,
      sumVigorous = 0,
      sumMuscle = 0,
      sumSedentary = 0;

    const lightCounts = Array.from({ length: 11 }, () => 0);
    const vigorousCounts = Array.from({ length: 11 }, () => 0);
    const muscleCounts = Array.from({ length: 11 }, () => 0);
    const sedentaryCounts = Array.from({ length: 13 }, () => 0);

    data.forEach((entry) => {
      sumLight += entry.light_exercise;
      sumVigorous += entry.vigorous_exercise;
      sumMuscle += entry.mucle_exercise;
      sumSedentary += entry.sedentary;

      lightCounts[entry.light_exercise]++;
      vigorousCounts[entry.vigorous_exercise]++;
      muscleCounts[entry.mucle_exercise]++;
      sedentaryCounts[entry.sedentary]++;
    });

    const lightSeries = generateSeries(lightCounts, 'light');
    const vigorousSeries = generateSeries(vigorousCounts, 'vigorous');
    const muscleSeries = generateSeries(muscleCounts, 'muscle');
    const sedentarySeries = generateSeries(sedentaryCounts, 'sedentary');

    const avgLight = (sumLight / totalEntries).toFixed(1);
    const avgVigorous = (sumVigorous / totalEntries).toFixed(1);
    const avgMuscle = (sumMuscle / totalEntries).toFixed(1);
    const avgSedentary = (sumSedentary / totalEntries).toFixed(1);

    return {
      light_exercise: avgLight,
      vigorous_exercise: avgVigorous,
      muscle_exercise: avgMuscle,
      sedentary: avgSedentary,
      light_exerciseSeries: lightSeries,
      vigorous_exerciseSeries: vigorousSeries,
      muscle_exerciseSeries: muscleSeries,
      sedentarySeries: sedentarySeries,
    };
  }

  function generateSeries(counts, type) {
    const series = counts
      .map((count, index) => {
        if (type === 'sedentary') {
          const indexVal = parseFloat(index);
          // Check if the value is even and only return sedentary labels (every 2 hours)
          if (indexVal % 2 === 0) {
            return {
              name: `${index} hours`,
              data: [count],
            };
          }
        } else {
          return {
            name: `${index} hours`,
            data: [count],
          };
        }
      })
      .filter((series) => series); // Filter out undefined values

    return series;
  }

  useEffect(() => {
    const averageScores = calculateAverage(data);
    setAverageScores(averageScores);
  }, [data]);

  return (
    <>
      <div className="">
        <h2 className="nutrition-main-ques">
          On average, in a typical{' '}
          <span style={{ fontSize: 28 }} className="importantText">
            week
          </span>{' '}
          we get:
        </h2>

        <div className="dflex">
          <div className="flex1 nutrition-res-container">
            <h2 className="nutrition-res-header">
              Light & Moderate Activities{' '}
              <img style={{ height: 36, marginTop: '-6px' }} src={yoga} />
            </h2>
            <div className="w100 dflex gap10 alignitemscenter mauto">
              <h2 className="mt30">0</h2>
              <LinearGauge
                maxNumber="10"
                average={averageScores.light_exercise}
                label="Light & Moderate hours per week"
                recommended=""
                recommendedInt=""
              />
              <h2 className="mt30">10+</h2>
            </div>

            <h2
              style={{ fontSize: 20, marginTop: '-10px' }}
              className="textaligncenter mb0 dnone"
            >
              On average
            </h2>
            <h2 style={{ marginTop: '-5px' }} className=" mt0 textaligncenter">
              <span style={{ fontSize: 26 }} className="importantText">
                {averageScores.light_exercise}
                {averageScores.light_exercise === '10.0' ? '+' : ''} hours
              </span>{' '}
              each week
            </h2>
          </div>

          <div className="flex1 nutrition-res-container">
            <SingleStackedBar
              colors={[
                '#d36a62',
                '#ec9876',
                '#f3bf83',
                '#fecd6c',
                '#92e18b',
                '#8ed0d4',
                '#5cb7fc',
                '#aa9fcf',
                '#92e18b',
                '#8ed0d4',
                '#caf181',
                '#95d9ab',
              ]}
              title="Breakdown of Light & Moderate Activities"
              series={averageScores.light_exerciseSeries}
            />
          </div>
        </div>

        <div className="dflex">
          <div className="flex1 nutrition-res-container">
            <h1 className="nutrition-res-header">
              Vigorous Activities{' '}
              <img style={{ height: 36, marginTop: '-6px' }} src={cyclist} />
            </h1>
            <div className="w100 dflex gap10 alignitemscenter mauto">
              <h2 className="mt30">0</h2>
              <LinearGauge
                maxNumber="10"
                average={averageScores.vigorous_exercise}
                label="Vigorous hours per week"
                recommended=""
                recommendedInt=""
              />
              <h2 className="mt30">10+</h2>
            </div>

            <h2
              style={{ fontSize: 20, marginTop: '-10px' }}
              className="textaligncenter mb0 dnone"
            >
              On average
            </h2>
            <h2 style={{ marginTop: '-5px' }} className=" mt0 textaligncenter">
              <span style={{ fontSize: 26 }} className="importantText">
                {averageScores.vigorous_exercise}
                {averageScores.vigorous_exercise === '10.0' ? '+' : ''} hours
              </span>{' '}
              each week
            </h2>
          </div>

          <div className="flex1 nutrition-res-container">
            <SingleStackedBar
              colors={[
                '#d36a62',
                '#ec9876',
                '#f3bf83',
                '#fecd6c',
                '#92e18b',
                '#8ed0d4',
                '#5cb7fc',
                '#aa9fcf',
                '#92e18b',
                '#8ed0d4',
                '#caf181',
                '#95d9ab',
              ]}
              title="Breakdown of Vigorous Activities"
              series={averageScores.vigorous_exerciseSeries}
            />
          </div>
        </div>

        <div className="dflex">
          <div className="flex1 nutrition-res-container">
            <h1 className="nutrition-res-header">
              Muscle Strengthening Activities
              <img style={{ height: 36, marginTop: '-6px' }} src={weights} />
            </h1>
            <div className="w100 dflex gap10 alignitemscenter mauto">
              <h2 className="mt30">0</h2>
              <LinearGauge
                maxNumber="10"
                average={averageScores.muscle_exercise}
                label="Average Muscle Strengthening hours"
                recommended=""
                recommendedInt=""
              />
              <h2 className="mt30">10+</h2>
            </div>

            <h2
              style={{ fontSize: 20, marginTop: '-10px' }}
              className="textaligncenter mb0 dnone"
            >
              On average
            </h2>
            <h2 style={{ marginTop: '-5px' }} className=" mt0 textaligncenter">
              <span style={{ fontSize: 26 }} className="importantText">
                {averageScores.muscle_exercise}
                {averageScores.muscle_exercise === '12.0' ? '+' : ''} hours
              </span>{' '}
              each week
            </h2>
          </div>

          <div className="flex1 nutrition-res-container">
            <SingleStackedBar
              colors={[
                '#d36a62',
                '#ec9876',
                '#f3bf83',
                '#fecd6c',
                '#92e18b',
                '#8ed0d4',
                '#5cb7fc',
                '#aa9fcf',
                '#92e18b',
                '#8ed0d4',
                '#caf181',
                '#95d9ab',
              ]}
              title="Breakdown of Muscle Strengthening Hours"
              series={averageScores.muscle_exerciseSeries}
            />
          </div>
        </div>

        <h2 className="nutrition-main-ques">
          On average, in a typical{' '}
          <span style={{ fontSize: 28 }} className="importantText">
            day
          </span>{' '}
          we are:
        </h2>

        <div className="dflex">
          <div className="flex1 nutrition-res-container">
            <h1 className="nutrition-res-header">
              Sedentary (Inactive)
              <img style={{ height: 33, marginTop: '-4px' }} src={screen} />
            </h1>
            <div className="w100 dflex gap10 alignitemscenter mauto">
              <h2 className="mt30">0</h2>
              <LinearGauge
                maxNumber="12"
                average={averageScores.sedentary}
                label="Average Sedentary hours"
                recommended=""
                recommendedInt=""
              />
              <h2 className="mt30">12+</h2>
            </div>

            <h2
              style={{ fontSize: 20, marginTop: '-10px' }}
              className="textaligncenter mb0 dnone"
            >
              On average
            </h2>
            <h2 style={{ marginTop: '-5px' }} className=" mt0 textaligncenter">
              <span style={{ fontSize: 26 }} className="importantText">
                {averageScores.sedentary}
                {averageScores.sedentary === '10.0' ? '+' : ''} hours
              </span>{' '}
              each day
            </h2>
          </div>

          <div className="flex1 nutrition-res-container">
            <SingleStackedBar
              colors={[
                '#92e18b',
                '#8ed0d4',
                '#5cb7fc',
                '#aa9fcf',
                '#fecd6c',
                '#f3bf83',
                '#ec9876',
                '#d36a62',
              ]}
              title="Breakdown of Sedentary Hours"
              series={averageScores.sedentarySeries}
            />
          </div>
        </div>

        <div style={{ marginTop: 30 }} className="results-info-text">
          <h1>Did you know?</h1>
          <li>
            It is recommended for adults aged 18-65 to reach at least 300
            minutes of moderate activity per week, and adults aged 65+ to reach
            at least 150 minutes of moderate activity per week.
          </li>
          <li>
            Almost one in four (23.9%) people aged 15 years and over met the
            physical activity guidelines.
          </li>
          <li>
            Nearly half (46.9%) of employed adults aged 18–64 years described
            their day at work as mostly sitting.
          </li>
          <a
            class="link"
            target="_blank"
            href="https://www.abs.gov.au/statistics/health/health-conditions-and-risks/national-health-survey/latest-release"
          >
            Source (National Health Survey 2022 - ABS)
          </a>
        </div>
      </div>
    </>
  );
};

export default PhysicalResults;
