import React from 'react';
import Chart from 'react-apexcharts';

const SingleStackedBar = ({ title, series, colors }) => {
  var options = {
    chart: {
      height: 120,
      type: 'bar',
      stacked: true,
      stackType: '100%',
      toolbar: {
        show: false,
      },
    },
    colors: colors,
    dataLabels: {
      enabled: true,
      formatter: function (value, opts) {
        if (isNaN(value)) {
          value = 0;
        }
        if (value < 3) {
          return '';
        }
        return `${value.toFixed(0)}%`;
      },
      style: {
        colors: ['#000'],
        fontSize: '17px',
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
        trim: false,
      },
    },
    tooltip: {
      enabled: true,
      style: {
        fontSize: '16px',
      },
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        // Calculate the total value of the stack
        var total = series.reduce((acc, curr) => acc + curr[dataPointIndex], 0);
        // Calculate the percentage
        var percentage =
          ((series[seriesIndex][dataPointIndex] / total) * 100).toFixed(1) +
          '%';
        // Get the series label
        var seriesLabel = w.config.series[seriesIndex].name;
        return `<div class="custom-tooltip">
                <p style="background:#e9e9e9;padding:5px;padding-top:10px;padding-left:12px;padding-right:12px;font-weight:bold;">${seriesLabel}</p>
                <p style="padding:5px;text-align:center;font-size:18px;">${percentage}</p>
                </div>`;
      },
    },
  };

  return (
    <div className="w100">
      <h2
        className="mt20 textaligncenter ml20"
        style={{ marginBottom: '-20px', fontSize: 26 }}
      >
        {title}
      </h2>
      <Chart
        options={options}
        series={series}
        height="180"
        width="100%"
        type="bar"
      />
    </div>
  );
};
export default SingleStackedBar;
