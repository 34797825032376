import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { GrClose } from 'react-icons/gr';
import { FaArrowRight } from 'react-icons/fa';
import { Grow, Rating, Slide, TextField, useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import './feedback.css';
import AnimatedCheck from '../../body/other/AnimatedCheck';
import LikertQuestion from './LikertQuestion';
import { feedbackConfig } from './FeedbackConfig';
import axios from 'axios';
import { Link } from 'react-router-dom';

export default function FeedbackModal({ finalResults, setDonationModal }) {
  const [open, setOpen] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [feedbackCharactersLeft, setFeedbackCharactersLeft] = useState(500);
  const [starValue, setStarValue] = useState(0);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [starError, setStarError] = useState(false);
  const [evalQuestions, setEvalQuestions] = useState([]);
  const [comments, setComments] = useState('');
  const [answers, setAnswers] = useState({
    id: finalResults.id,
    sign_up_type: '',
    set: '',
    feedback: '',
  });

  const handleCommentsChange = (event) => {
    var feedbackComments = event.target.value;

    //Max feedback comments 500 characters
    if (feedbackComments.length > 500) {
      //Grab first 500 characters only
      feedbackComments = feedbackComments.substring(0, 500);
    }

    const charsLeft = 500 - feedbackComments.length;
    setFeedbackCharactersLeft(charsLeft);
    setComments(feedbackComments);

    handleChange(
      feedbackComments,
      null,
      'additional_comments',
      'Any additional comments/thoughts',
      'Default',
      'Default',
    );
  };

  //Update feedback object on change
  const handleChange = (
    value,
    score,
    question_id,
    question,
    construct,
    component,
  ) => {
    var updatedProps = {
      [question_id]: {
        question: question,
        answer: value,
        answer_score: score,
        construct: construct,
        component: component,
      },
    };
    setAnswers({
      ...answers,
      feedback: {
        ...answers.feedback,
        ...updatedProps,
      },
      [construct]: score,
      [component]: score,
    });
  };

  useEffect(() => {
    //Check if user has given email to decide what evaluation/feedback questions to display
    if (!finalResults.email_provided) {
      //User hasnt signed up
      var questions = feedbackConfig.not_signed_up;
      //Randomise sets, and pick one from the not_signed_up questions
      var items = ['A', 'B', 'C'];
      setConfig(items, questions, 'not_signed_up');
    } else {
      //User signed up
      var questions = feedbackConfig.signed_up;
      var items = ['A', 'B', 'C', 'D'];
      //Randomise sets, and pick one from the signed_up questions
      setConfig(items, questions, 'signed_up');
    }
  }, []);

  const setConfig = (items, questions, signuptype) => {
    var set = items[Math.floor(Math.random() * items.length)];
    questions = questions.filter((item) => item.set === set);
    setAnswers({
      ...answers,
      id: finalResults.id,
      sign_up_type: signuptype,
      set: set,
    });
    setEvalQuestions(questions);
  };

  //Display feedback popover after 5 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setPopoverOpen(!open);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  //Modal close and opens
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  //Handle feedback form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setStarError(false);
    if (starValue === 0) {
      setStarError(true);
      return;
    }
    submitFeedback();
  };

  //Submit feedback and handle response
  const submitFeedback = async () => {
    setCompleted(true);
    try {
      await axios.post('/api/feedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(answers),
      });
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  const mobileView = useMediaQuery('(max-width:850px)');

  return (
    <>
      <div>
        {' '}
        {!popoverOpen && !mobileView && (
          <div
            onClick={() => {
              setOpen(true);
            }}
            className="popoverFeedback"
            id="leave-feedback"
          >
            Leave Feedback
          </div>
        )}
      </div>

      <Slide
        direction="left"
        style={{ zIndex: 99 }}
        className="popoverFeedback"
        in={popoverOpen}
      >
        <Container>
          <div
            style={{ paddingTop: '10px' }}
            className="closeIconPopover dflex justifycontentstart"
          >
            <GrClose
              onClick={() => {
                setPopoverOpen(false);
              }}
            />
          </div>
          <h3 className="mt10 mb10">Enjoyed the calculator?</h3>
          <div className="dflex flexwrap gap10 justifycontentcenter popoverOptions">
            <Button
              onClick={() => {
                setOpen(true);
                setPopoverOpen(false);
              }}
              variant="contained"
              className="borderRadius8"
            >
              Leave feedback
            </Button>
            <Link
              to="https://donate.cancerqld.org.au/donate-crc"
              target="_blank"
              style={{ width: '100%' }}
            >
              <Button
                fullWidth
                className="donationBtn borderRadius8"
                variant="contained"
              >
                Donate Now
              </Button>
            </Link>
          </div>
        </Container>
      </Slide>
      <Button
        onClick={handleOpen}
        className="borderRadius8"
        variant="contained"
      >
        Leave Feedback
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Fade in={open}>
          <Container maxWidth="md" className="modalStyles">
            <div
              style={{ paddingTop: 20, fontSize: 22 }}
              className="closeIconPopover dflex justifycontentstart"
            >
              <GrClose
                onClick={() => {
                  setOpen(false);
                }}
              />
            </div>
            {completed && (
              <Grow in={completed}>
                <div className="feedbackCompletedMsg">
                  <AnimatedCheck />
                  <h1>Thank-you!</h1>
                  <h2 className="textaligncenter">
                    Your feedback helps us improve our services.
                  </h2>
                  <div className="dflex flexwrap alignitemscenter justifycontentspacebetween">
                    <Button
                      className="w100 mt20"
                      variant="contained"
                      style={{ height: 60 }}
                      onClick={() => setOpen(false)}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </Grow>
            )}
            {!completed && (
              <div className="w100">
                <h1 className="mb5" style={{ marginTop: 30 }}>
                  Your feedback is important to us
                </h1>
                <p className="mt0 mb20">
                  We would love to hear your thoughts on the Cancer Risk
                  Calculator.
                </p>
                <form autoComplete="off" onSubmit={handleSubmit}>
                  {evalQuestions.map((question, index) => {
                    return (
                      <LikertQuestion
                        handleChange={handleChange}
                        question={question}
                        key={index}
                      />
                    );
                  })}

                  {/* Default questions to display every time */}
                  <div className="w100">
                    <div className="mt30">
                      <h3 className="mb0">
                        How would you rate the Calculator overall?
                      </h3>
                      <div className="dflex alignitemscenter feedbackOptions mt10">
                        <Rating
                          size="large"
                          value={starValue}
                          onChange={(e, newValue) => {
                            setStarValue(newValue);
                            setStarError(false);
                            handleChange(
                              e.target.value,
                              null,
                              'overall_rating',
                              'How would you rate the Calculator overall?',
                              'Default',
                              'Default',
                            );
                          }}
                        />
                        {starError && (
                          <p style={{ color: 'red', margin: 0 }}>
                            Please select rating
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="w100 mt30">
                      <h3 className="mb10">
                        Any additional comments you'd like to add?
                      </h3>
                      <TextField
                        id="feedback_open_text"
                        onChange={handleCommentsChange}
                        value={comments}
                        size="large"
                        autoComplete="off"
                        fullWidth
                        multiline
                        placeholder="Enter thoughts or feedback"
                      />
                      <p className="feedbackCharsLeft">
                        {feedbackCharactersLeft} characters
                      </p>
                    </div>

                    <Button
                      className="w100 mt20"
                      variant="contained"
                      type="submit"
                      style={{ height: 55 }}
                    >
                      Submit Feedback <FaArrowRight className="ml10" />
                    </Button>
                    {mobileView && (
                      <Button
                        fullWidth
                        style={{ height: 50 }}
                        onClick={() => setOpen(false)}
                        className="mt20"
                        variant="outlined"
                      >
                        Close
                      </Button>
                    )}
                  </div>
                </form>
              </div>
            )}
          </Container>
        </Fade>
      </Modal>
    </>
  );
}
