//Below calculates an appropriate total risk score out of 100 for each Lifestyle 6 factor
//It is based on the Harvard Cancer Risk Index, looking specifically at the most common cancers in Australia (Breast, Bowel, Skin, Lung and Prostate)
//All risk ratios can either by found at config.js for multichoice options, or the items page for interactive questions (eg: /ParkItems.js)
//The population scores are calculated by summing the most commonly selected answers available in the factor
//Please refer to /Weightings & Scorings docs & the Research Team before making any additional changes

const cancerTypes = ['Skin', 'Bowel', 'Lung', 'Prostate', 'Breast'];
const fixedCancerWeights = {
  skin: 0.2236,
  bowel: 0.2056,
  lung: 0.16709,
  prostate: 0.2185,
  breast: 0.25192,
};

//Uv population scores and min/max objects
const uvPopulationScores = {
  skin: 2.58179,
  bowel: 0,
  lung: 0,
  prostate: 0,
  breast: 0,
};

const uvMin = {
  skin: 0,
  bowel: 0,
  lung: 0,
  prostate: 0,
  breast: 0,
};
const uvMax = {
  skin: 1.5,
  bowel: 0,
  lung: 0,
  prostate: 0,
  breast: 0,
};

//Smoking population scores and min/max objects
const smokingPopulationScores = {
  skin: -0.03575,
  bowel: 0.01425,
  lung: 0.127568,
  prostate: 0.038,
  breast: 0.01025,
};

const smokingMin = {
  skin: 0,
  bowel: 0,
  lung: 0,
  prostate: 0,
  breast: 0,
};
const smokingMax = {
  skin: 0,
  bowel: 0.068,
  lung: 1.0335,
  prostate: 0.152,
  breast: 0.041,
};

//Alcohol population scores and min/max objects
const alcoholPopulationScores = {
  skin: 0.333,
  bowel: 0.286,
  lung: 0,
  prostate: 0.033,
  breast: 0.223,
};
const alcoholMin = {
  skin: 0.03,
  bowel: 0.03,
  lung: -0.05,
  prostate: 0.02,
  breast: 0.02,
};
const alcoholMax = {
  skin: 0.57,
  bowel: 0.54,
  lung: 0,
  prostate: 0.1,
  breast: 0.26,
};

//Physical population scores and min/max objects
const physicalPopulationScores = {
  skin: 0,
  bowel: 0.0925,
  lung: 0.04,
  prostate: -0.0165,
  breast: -0.045,
};
const physicalMin = {
  skin: 0,
  bowel: -0.083,
  lung: -0.106,
  prostate: -0.033,
  breast: -0.09,
};
const physicalMax = {
  skin: 0,
  bowel: 0.134,
  lung: 0.093,
  prostate: 0,
  breast: 0,
};

//Nutrition population scores and min/max objects
const nutritionPopulationScores = {
  skin: 0,
  bowel: -0.12226,
  lung: -0.06885,
  prostate: 0,
  breast: -0.16071,
};

const nutritionMin = {
  skin: 0,
  bowel: -0.17,
  lung: -0.06,
  prostate: 0,
  breast: -0.24,
};
const nutritionMax = {
  skin: 0,
  bowel: 0.17363,
  lung: 0,
  prostate: 0,
  breast: 0.29287,
};
export function calculateTotalScore(riskRatios, factor) {
  var min = {};
  var max = {};
  var populationScores = {};
  if (factor === 'uv') {
    min = uvMin;
    max = uvMax;
    populationScores = uvPopulationScores;
  } else if (factor === 'smoking') {
    min = smokingMin;
    max = smokingMax;
    populationScores = smokingPopulationScores;
  } else if (factor === 'alcohol') {
    min = alcoholMin;
    max = alcoholMax;
    populationScores = alcoholPopulationScores;
  } else if (factor === 'physical') {
    min = physicalMin;
    max = physicalMax;
    populationScores = physicalPopulationScores;
  } else if (factor === 'nutrition') {
    min = nutritionMin;
    max = nutritionMax;
    populationScores = nutritionPopulationScores;
  }
  //Initialise min and max ratios
  let minRatios = {
    skin: 0,
    bowel: 0,
    lung: 0,
    prostate: 0,
    breast: 0,
  };
  let maxRatios = {
    skin: 0,
    bowel: 0,
    lung: 0,
    prostate: 0,
    breast: 0,
  };
  let rangeRatios = {
    skin: 0,
    bowel: 0,
    lung: 0,
    prostate: 0,
    breast: 0,
  };
  let sumOfAnswers = {
    skin: 0,
    bowel: 0,
    lung: 0,
    prostate: 0,
    breast: 0,
  };

  cancerTypes.forEach((cancer, index) => {
    const cancerType = cancer.toLowerCase();
    const minRiskRatio = min[cancerType];
    const maxRiskRatio = max[cancerType];
    const populationScore = populationScores[cancerType];

    //Calculate the min and max ratios for each cancer type
    var minRatio = minRiskRatio / populationScore;
    var maxRatio = maxRiskRatio / populationScore;
    if (isNaN(minRatio) || !isFinite(minRatio)) {
      minRatio = 0;
    }
    if (isNaN(maxRatio) || !isFinite(maxRatio)) {
      maxRatio = 0;
    }
    minRatio = Number(minRatio.toFixed(2));
    maxRatio = Number(maxRatio.toFixed(2));

    minRatios[cancerType] = minRatio;
    maxRatios[cancerType] = maxRatio;
    rangeRatios[cancerType] = Number(maxRatio - minRatio);
  });

  //Calculate the sum of all of selected answer risk ratios across all cancer types
  for (const questionId in riskRatios) {
    const question = riskRatios[questionId];
    if (question.factor === factor) {
      if (Array.isArray(question.risk_ratios)) {
        for (const riskRatio of question.risk_ratios) {
          var cancerType = Object.keys(riskRatio)[0];
          sumOfAnswers[cancerType] += Number(riskRatio[cancerType]);
        }
      }
    }
  }

  //Calculate the raw ratios
  const rawRatios = {};
  for (const cancerType in sumOfAnswers) {
    const populationScore = populationScores[cancerType];
    var rawValue =
      populationScore !== 0 ? sumOfAnswers[cancerType] / populationScore : 0;
    rawValue = Number(rawValue.toFixed(2));
    rawRatios[cancerType] = rawValue;
  }

  //Calculate the normalised ratios
  const normalisedRatios = {};
  for (const cancerType in rawRatios) {
    const rawRatio = rawRatios[cancerType];
    const minRatio = minRatios[cancerType];
    const rangeRatio = rangeRatios[cancerType];

    var normalisedRatio = (rawRatio - minRatio) / rangeRatio;

    if (isNaN(normalisedRatio) || !isFinite(normalisedRatio)) {
      normalisedRatio = 0;
    }
    normalisedRatio = Number(normalisedRatio.toFixed(2));
    normalisedRatios[cancerType] = normalisedRatio;
  }

  //Calculate the weighted raw ratios
  const weightedNormalisedRatios = {};
  for (const cancerType in normalisedRatios) {
    const normalisedRatio = normalisedRatios[cancerType];
    const fixedCancerWeight = fixedCancerWeights[cancerType];
    var weightedNormalisedRatio = normalisedRatio * fixedCancerWeight;

    if (isNaN(weightedNormalisedRatio) || !isFinite(weightedNormalisedRatio)) {
      weightedNormalisedRatio = 0;
    }
    weightedNormalisedRatio = Number(weightedNormalisedRatio.toFixed(2));
    weightedNormalisedRatios[cancerType] = weightedNormalisedRatio;
  }

  //Calculate the total risk score then flip to score out of 100
  var totalRiskScore = 0;
  for (const cancerType in weightedNormalisedRatios) {
    totalRiskScore += parseFloat(weightedNormalisedRatios[cancerType]);
  }

  totalRiskScore = Number(totalRiskScore);
  const totalScore = 1 - totalRiskScore;
  const roundedTotalRiskScore100 = totalRiskScore * 100;
  var totalScore100 = Math.ceil(totalScore * 100);

  if (totalScore100 < 0) {
    totalScore100 = 0;
  }
  if (totalScore100 > 100) {
    totalScore100 = 100;
  }

  //Used for debugging
  var totalObject = {
    min,
    max,
    minRatios,
    maxRatios,
    rangeRatios,
    sumOfAnswers,
    rawRatios,
    normalisedRatios,
    weightedNormalisedRatios,
    totalRiskScore: roundedTotalRiskScore100,
    totalScore: totalScore100,
  };

  return totalScore100;
}

///Calculate screening questions
//Screening questions do not have associated risks with them related to cancer
export function calculateScreeningTotal(finalResults) {
  var screeningCount = 0;
  var screeningScores = 0;
  for (const questionId in finalResults) {
    const question = finalResults[questionId];
    if (question.factor === 'screening') {
      screeningCount += 1;
      if (question.good_response) {
        screeningScores += 100;
      }
    }
  }
  //Divide total score by number of screening questions answered for simple total/100
  let screeningTotal = Math.ceil(screeningScores / screeningCount);
  return screeningTotal;
}
