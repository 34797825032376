import React from 'react';
import { Button } from '@mui/material';
import Chart from 'react-apexcharts';
import './Results.css';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import {
  BiSolidChevronUp,
  BiSolidChevronDown,
  BiSolidCircle,
} from 'react-icons/bi';

const TotalScoreChart = ({
  factor,
  factorSection,
  color,
  total,
  setExpanded,
  expanded,
  notSignedUp,
  compareTotals,
}) => {
  var trackColor = color;
  if (factorSection === 'uv') {
    trackColor = '#ffd500';
  }
  const options = {
    chart: {
      type: 'radialBar',
    },
    parentHeightOffset: 0,

    labels: [factor],
    colors: [trackColor],
    stroke: { lineCap: 'round' },
    grid: {
      padding: {
        top: 0,
        bottom: 0,
      },
      margin: {
        top: 0,
      },
    },
    plotOptions: {
      radialBar: {
        track: {
          className: 'totalscores-radial-track',
        },
        startAngle: 0,
        endAngle: 360,
        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: false,
            color: '#606669',
            fontSize: '23px',
            fontFamily: 'Foco CC Bd',
          },

          value: {
            color: color,
            fontFamily: 'Foco CC Black',
            fontWeight: 700,
            fontSize: '35px',
            marginTop: '-20px',
            show: true,
            offsetY: 5,
            formatter: function (val) {
              return val;
            },
          },
        },
        hollow: {
          margin: 0,
          size: '66%',
          background: '#fff',
          position: 'front',
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.1,
          },
          track: {
            background: '#fff',
            strokeWidth: '70%',
            margin: 0,
            dropShadow: {
              enabled: false,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },
        },
      },
    },
  };

  const series = [total];

  const handleClick = () => {
    const element = document.getElementById(`accordian_${factorSection}`);
    // setExpanded('');
    if (notSignedUp) {
      const element = document.getElementById(`enter_email_res`);
      if (element) {
        const y = element.getBoundingClientRect().top + window.scrollY;
        window.scroll({
          top: y,
          behavior: 'smooth',
        });
      }
    } else {
      setExpanded(factorSection);

      if (element) {
        setTimeout(() => {
          element.scrollIntoView();
        }, '400');
      }
    }
  };

  const currentTotal = `${factorSection}_total`;

  return (
    <div className={' totalScoreSection'}>
      <div style={{ position: 'relative' }}>
        {total === 100 && <FaCheckCircle className="checkIcon totalicons" />}
        {total === 0 && (
          <FaExclamationCircle className="exclamationIcon totalicons" />
        )}
      </div>
      <p style={{ color: color }} className="totalScore100">
        /100
      </p>
      <Chart
        height="180"
        options={options}
        series={series}
        className="dflex justifycontentcenter"
        type="radialBar"
      />
      <div className="totalScoreLabels">
        <div style={{ height: 80 }} className="dflex alignitemscenter">
          <h3 style={{ color: color }}>
            {factorSection === 'uv' ? 'UV & Sun' : factor}{' '}
          </h3>
        </div>
      </div>
      {compareTotals && compareTotals.changes && (
        <div>
          {compareTotals.changes[currentTotal].direction === 'increased' && (
            <h3 className="increase-decrease-scores increase-color">
              <BiSolidChevronUp className="score-chevron increase-color" />
              {compareTotals.changes[currentTotal].positive_int}
            </h3>
          )}
          {compareTotals.changes[currentTotal].direction === 'unchanged' && (
            <h3 className="increase-decrease-scores"></h3>
          )}
          {compareTotals.changes[currentTotal].direction === 'decreased' && (
            <h3 className="increase-decrease-scores decrease-color">
              <BiSolidChevronDown className="score-chevron decrease-color" />
              {compareTotals.changes[currentTotal].positive_int}
            </h3>
          )}
        </div>
      )}
      <Button onClick={handleClick} className={factorSection + ' learnMoreBtn'}>
        Learn More{' '}
      </Button>
    </div>
  );
};
export default TotalScoreChart;
