import React from 'react';
import Chart from 'react-apexcharts';

const BarChartEvent = ({ chartData, colors }) => {
  var series = [
    {
      data: chartData,
    },
  ];

  var options = {
    chart: {
      height: 400,
      type: 'bar',
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true,
        speed: 500,
        animateGradually: {
          enabled: true,
          speed: 500,
        },
        dynamicAnimation: {
          enabled: true,
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      formatter: function (value, opts) {
        if (isNaN(value)) {
          value = 0;
        }
        return `${value}%`;
      },
      offsetY: -30,
      style: {
        fontFamily: 'Foco CC Bd',
        fontSize: '20px',
        colors: ['#304758'],
      },
    },
    grid: {
      show: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        distributed: true,
        borderRadius: 10,
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      },
    },
    tooltip: {
      x: {
        show: true,
      },
      fixed: {
        enabled: false,
      },
      y: {
        show: false,
        formatter: function (value, opts) {
          if (value) {
            return `${value}%`;
          }
        },
        title: {
          formatter: function () {
            return '';
          },
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      style: {
        fontFamily: 'Foco CC Bd',
        fontSize: '28px',
        colors: ['#304758'],
      },
    },
    colors: colors,
    xaxis: {
      labels: {
        rotate: 0,
        showDuplicates: false,
        show: true,
        style: {
          fontFamily: 'Foco CC Bd',
          fontSize: '18px',
          colors: ['#304758'],
        },
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  return (
    <div>
      <Chart
        options={options}
        series={series}
        height="400"
        width="90%"
        type="bar"
      />
    </div>
  );
};
export default BarChartEvent;
