import { Button, Container, Grid, Grow, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Postcode from './components/Postcode';
import BirthYear from './components/BirthYear';
import Email from './components/Email';
import SectionHeader from '../other/SectionHeader';
import Occupation from './components/Occupation';
import PrimaryLanguage from './components/PrimaryLanguage';
import MultiChoiceQuestion from '../../body/MultichoiceQuestion';
import BirthCountry from './components/BirthCountry';

function Personal({
  setActiveView,
  updateFinalResults,
  finalResults,
  setCurrentPersonalQuestion,
  currentPersonalQuestion,
  handleCompletedSteps,
  configQuestions,
}) {
  const handleAnswerClick = (questionIndex) => {
    // Display next question
    if (currentPersonalQuestion <= questionIndex) {
      setCurrentPersonalQuestion(currentPersonalQuestion + 1);
    }
  };

  const [errorText, setErrorText] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [postcodeError, setPostCodeError] = useState(false);
  const [birthyearError, setBirthyearError] = useState(false);
  const [occupationError, setOccupationError] = useState(false);
  const [languageError, setLanguageError] = useState(false);
  const [countryError, setCountryError] = useState(false);

  //Total count of questions to display
  const totalQuestionCount = configQuestions.filter(
    (item) => item.factor === 'personal',
  ).length;

  const checkAllRequired = () => {
    //Check all required have input and no errors
    var errorCheck = false;
    if (
      postcodeError ||
      (finalResults.postcode === '' && finalResults.country === 'Australia')
    ) {
      errorCheck = true;
      setPostCodeError(true);
    }
    if (finalResults.birth_year === '') {
      setBirthyearError(true);
      errorCheck = true;
    }
    if (finalResults.occupation === '') {
      setOccupationError(true);
      errorCheck = true;
    }
    if (finalResults.primary_language === '') {
      setLanguageError(true);
      errorCheck = true;
    }
    if (finalResults.birth_country === '') {
      setCountryError(true);
      errorCheck = true;
    }
    if (emailError) {
      errorCheck = true;
    }

    if (errorCheck) {
      setErrorText(true);
      return;
    }
    setErrorText(false);

    //After all checks, continue to next page
    setActiveView('UV');
    handleCompletedSteps('Personal');
  };

  const nextDisabled =
    currentPersonalQuestion === totalQuestionCount ||
    (finalResults.primary_language !== '' &&
      finalResults.primary_language !== 'English');

  useEffect(() => {
    window.scrollTo({ top: window.scrollY + 400, behavior: 'smooth' });
  }, [finalResults.primary_language]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <>
      <Grow in={true}>
        <Container>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            noValidate
          >
            <SectionHeader
              title="Let's start with some basics."
              subtext={
                <span>
                  We ask personal questions to provide you with better
                  recommendations. By providing your email, you agree to our{' '}
                  <a
                    target="_blank"
                    className="link"
                    href="https://cancerqld.org.au/about-us/our-privacy-policy/general-collection-statement/"
                  >
                    Privacy Collection Statement
                  </a>
                  . Get your results emailed to you!
                </span>
              }
              section="personal"
              activeColor="all"
            />
            <Grid
              className="mt20"
              id="personal_information"
              container
              spacing={2}
            >
              <Grid item xs={12} md={6}>
                <div>
                  <h3 className="mt0 mb10 personal-subheaders">Name</h3>
                  <TextField
                    style={{ width: '100%' }}
                    sx={{
                      '.MuiInputBase-input': { fontSize: 15 },
                    }}
                    InputLabelProps={{ style: { fontSize: 15 } }}
                    id="personal_name"
                    value={finalResults.name}
                    onChange={(e) => {
                      updateFinalResults({ name: e.target.value });
                    }}
                    label="Enter Name"
                    type="text"
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <Email
                  updateFinalResults={updateFinalResults}
                  finalResults={finalResults}
                  error={emailError}
                  setError={setEmailError}
                />
              </Grid>
            </Grid>
            <Grid container className="personal-grid" spacing={2}>
              {finalResults.country === 'Australia' && (
                <Grid item xs={12} md={3}>
                  <Postcode
                    updateFinalResults={updateFinalResults}
                    finalResults={finalResults}
                    error={postcodeError}
                    setError={setPostCodeError}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={3}>
                <BirthYear
                  finalResults={finalResults}
                  updateFinalResults={updateFinalResults}
                  error={birthyearError}
                  setError={setBirthyearError}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Occupation
                  finalResults={finalResults}
                  updateFinalResults={updateFinalResults}
                  error={occupationError}
                  setError={setOccupationError}
                />
              </Grid>
            </Grid>

            <p style={{ marginBottom: '-15px' }}>
              The following questions ask about sex and gender. This will help
              us give you the best advice for cancer screening.
            </p>
            {configQuestions
              .filter(
                (item) =>
                  item.factor === 'personal' && item.displayed(finalResults),
              )
              .map((question, index) => {
                const questionId = question.id;
                const questionDisplayed = currentPersonalQuestion >= index;
                if (question.id === 'primary_language') {
                  return (
                    <PrimaryLanguage
                      handleAnswerClick={handleAnswerClick}
                      questionDisplayed={questionDisplayed}
                      questionIndex={index}
                      finalResults={finalResults}
                      updateFinalResults={updateFinalResults}
                      key={index}
                      error={languageError}
                      setError={setLanguageError}
                    />
                  );
                }
                if (question.id === 'birth_country') {
                  return (
                    <BirthCountry
                      handleAnswerClick={handleAnswerClick}
                      questionDisplayed={questionDisplayed}
                      questionIndex={index}
                      finalResults={finalResults}
                      updateFinalResults={updateFinalResults}
                      key={index}
                      error={countryError}
                      setError={setCountryError}
                    />
                  );
                }

                return (
                  <MultiChoiceQuestion
                    key={index}
                    questionDisplayed={questionDisplayed}
                    handleAnswerClick={handleAnswerClick}
                    question={question}
                    questionIndex={index}
                    updateFinalResults={updateFinalResults}
                    finalResults={finalResults}
                  />
                );
              })}

            <div className="dflex mb10 mt30 justifycontentspaceend">
              <Button
                variant="contained"
                className="nextBtn"
                color="primary"
                onClick={checkAllRequired}
                disabled={!nextDisabled}
              >
                Next
              </Button>
            </div>
            {errorText && (
              <div className="error-text dflex justifycontentend ">
                <p>Please fill all required fields</p>
              </div>
            )}
          </form>
        </Container>
      </Grow>
    </>
  );
}

export default Personal;
