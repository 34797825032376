import React, { useEffect, useState } from 'react';
import './Navbar.css';
import alcohol from '../../assets/icons/alcohol-white.png';
import uv from '../../assets/icons/uv-white.png';
import smoking from '../../assets/icons/smoking-white.png';
import nutrition from '../../assets/icons/nutrition-white.png';
import weight from '../../assets/icons/weight-white.png';
import physical from '../../assets/icons/physical-activity-white.png';
import screening from '../../assets/icons/screening-white.png';

const IconPetalMobile = ({ activeView }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [backgroundFill, setBackgroundFill] = useState('#fbc804');

  const iconsObject = [
    {
      index: 0,
      icon: uv,
      label: 'UV',
      color: '#fbc804',
      size: 'lg',
    },
    {
      index: 1,
      icon: smoking,
      label: 'Smoking',
      color: '#878888',
      size: 'med',
    },
    {
      index: 2,
      icon: alcohol,
      label: 'Alcohol',
      color: '#e5623a',
      size: 'med',
    },
    {
      index: 3,
      icon: nutrition,
      label: 'Nutrition',
      color: '#6bb963',
      size: 'med',
    },
    {
      index: 4,
      icon: weight,
      label: 'Weight',
      color: '#3996d6',
      size: 'sm',
    },
    {
      index: 5,
      icon: physical,
      label: 'Physical',
      color: '#3996d6',
      size: 'med',
    },
    {
      index: 6,
      icon: screening,
      label: 'Screening',
      color: '#604999',
      size: 'sm',
    },
  ];

  const animatedIcons = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % iconsObject.length);
  };

  useEffect(() => {
    const timer = setInterval(animatedIcons, 4000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="header-petal-mobile">
      <svg
        id="crc-blob"
        fill={iconsObject[currentIndex].color}
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 70.84 79.41"
      >
        <g id="Layer_3" data-name="Layer 3">
          <path
            className="cls-1"
            d="m.64,60.86S-11.48-8.28,69.87.83c0,0,14.57,67.32-69.23,60.03Z"
          />
        </g>
      </svg>

      {iconsObject.map((icon, index) => (
        <img
          key={index}
          className={`icon-navbar-petal-mobile ${icon.size} ${
            index === currentIndex ? 'fade-in' : 'fade-out'
          }`}
          src={iconsObject[index].icon}
          alt={`L6 ${index}`}
        />
      ))}
    </div>
  );
};

export default IconPetalMobile;
