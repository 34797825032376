import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import './LearnMore.css';
import { FaChevronDown } from 'react-icons/fa';
import SmokingAnswers from './answerComponents/SmokingAnswers';
import PhysicalAnswers from './answerComponents/PhysicalAnswers';
import WeightAnswers from './answerComponents/WeightAnswers';
import UvAnswers from './answerComponents/UvAnswers';
import AlcoholAnswers from './answerComponents/AlcoholAnswers';
import NutritionAnswers from './answerComponents/NutritionAnswers';
import ScreeningAnswers from './answerComponents/ScreeningAnswers';
import { useMediaQuery } from '@mui/material';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

export default function LearnMoreDropdown({
  expanded,
  handleAccordianChange,
  factor,
  notSignedUp,
  finalResults,
}) {
  const mobileView = useMediaQuery('(max-width:850px)');

  return (
    <Accordion
      className="accordianSection"
      expanded={expanded === factor.factorSection && !notSignedUp}
      onChange={handleAccordianChange(factor.factorSection)}
      id={`accordian_${factor.factorSection}`}
      TransitionProps={{ timeout: { enter: 300, exit: 0 } }}
    >
      <MuiAccordionSummary
        className="accordianPanel"
        expandIcon={<FaChevronDown className="accordianIcon" />}
      >
        <Typography className="accordianTitle">
          {factor.icon} {factor.factor}
        </Typography>
      </MuiAccordionSummary>
      <div className={(mobileView ? 'mobile ' : '') + 'accordianContent'}>
        <div
          style={{ marginBottom: -10 }}
          className="dflex gap10 alignitemscenter"
        >
          <h1 style={{ color: factor.color }} className="mt20">
            {factor.factor}
          </h1>
          <div
            style={{ background: factor.color }}
            className="ml10 petalTitle"
          ></div>
          <h1
            className="totalScoreAccordian"
            style={{ color: factor.color, marginLeft: 'auto' }}
          >
            {factor.total}
            {factor.factor !== 'Weight' ? '/100' : null}
          </h1>
        </div>

        <div className="learnMoreBottom">
          {factor.factorSection === 'uv' && (
            <UvAnswers factor={factor} finalResults={finalResults} />
          )}
          {factor.factorSection === 'smoking' && (
            <SmokingAnswers factor={factor} finalResults={finalResults} />
          )}

          {factor.factorSection === 'physical' && (
            <PhysicalAnswers factor={factor} finalResults={finalResults} />
          )}
          {factor.factorSection === 'weight' && (
            <WeightAnswers factor={factor} finalResults={finalResults} />
          )}
          {factor.factorSection === 'nutrition' && (
            <NutritionAnswers factor={factor} finalResults={finalResults} />
          )}
          {factor.factorSection === 'alcohol' && (
            <AlcoholAnswers factor={factor} finalResults={finalResults} />
          )}
          {factor.factorSection === 'screening' && (
            <ScreeningAnswers factor={factor} finalResults={finalResults} />
          )}
        </div>
      </div>
    </Accordion>
  );
}
