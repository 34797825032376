import React from 'react';
import WaistImg from '../../../../assets/images/WaistMeasure.jpg';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

export default function WaistModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  return (
    <div className="dflex justifycontentcenter">
      <a onClick={handleOpen} className="link ">
        Waist Measurements
      </a>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Container
            maxWidth="md"
            className="modalStyles dflex justifycontentcenter flexwrap waistModal"
          >
            <div>
              <img alt="CCQ Waist Guide" src={WaistImg} />

              <Button
                variant="variant"
                style={{
                  width: '95%',
                  background: 'white',
                  display: 'flex',
                  margin: 'auto',
                }}
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </Container>
        </Fade>
      </Modal>
    </div>
  );
}
