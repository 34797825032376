import {
  Button,
  Container,
  Grow,
  Tab,
  Tabs,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import SectionHeader from '../other/SectionHeader';
import { FaInfoCircle } from 'react-icons/fa';
import QuestionsWeight from './QuestionsWeight';
import { MdMonitorWeight } from 'react-icons/md';
import { TbRulerMeasure } from 'react-icons/tb';
import QuestionsWaist from './QuestionsWaist';

function Weight({
  setActiveView,
  finalResults,
  updateFinalResults,
  handleCompletedSteps,
}) {
  const [bmiPage, setBmiPage] = React.useState(0);

  const handleChange = (event, newValue) => {
    setBmiPage(newValue);
  };

  const mobileView = useMediaQuery('(max-width:850px)');
  const smMobileView = useMediaQuery('(max-width:350px)');

  const nextDisabled = finalResults.bmi !== '' || finalResults.waist !== '';

  return (
    <>
      <Grow in={true}>
        <Container>
          <SectionHeader
            title="BMI & Waist Measurement"
            subtext="Please share your weight status by using the BMI calculator or your waist measurement."
            activeColor="#199bd7"
            factor="weight"
          />

          <Tabs
            variant="fullWidth"
            value={bmiPage}
            onChange={handleChange}
            aria-label="icon label tabs example"
            className="mt20"
          >
            <Tab
              style={{ boxShadow: 'none' }}
              icon={<MdMonitorWeight style={{ fontSize: 27 }} />}
              label={mobileView ? 'BMI' : 'Body Mass Index (BMI)'}
            />
            <Tab
              style={{ boxShadow: 'none' }}
              icon={<TbRulerMeasure style={{ fontSize: 27 }} />}
              label={mobileView ? 'Waist' : 'Waist Measurement'}
            />
          </Tabs>

          <div className="mt30">
            {bmiPage === 0 && (
              <QuestionsWeight
                finalResults={finalResults}
                updateFinalResults={updateFinalResults}
              />
            )}
            {bmiPage === 1 && (
              <QuestionsWaist
                finalResults={finalResults}
                updateFinalResults={updateFinalResults}
              />
            )}
          </div>

          {bmiPage === 0 && (
            <p className=" alignitemscenter gap10 dflex mt30 mb10 weightSubtext">
              {' '}
              <FaInfoCircle
                style={{ color: '#3a99d9', fontSize: 20 }}
                className={mobileView ? 'dnone' : ''}
              />
              Please note the BMI is only an estimate, and does not take into
              account age, gender, ethnicity and body composition.
            </p>
          )}
          {bmiPage === 1 && (
            <p className=" alignitemscenter gap10 dflex mt30 mb10 weightSubtext">
              {' '}
              <FaInfoCircle
                style={{ color: '#3a99d9', fontSize: 25 }}
                className={mobileView ? 'dnone' : ''}
              />
              To measure your waist circumference measurement accurately,
              measure at the narrowest point between the lower rib and the top
              of the hips (the iliac crest) at the end of a normal breath.
            </p>
          )}

          <div className="dflex mb10 mt30 justifycontentspacebetween">
            <Button
              variant="contained"
              className="prevBtn"
              onClick={() => {
                setActiveView('Nutrition');
              }}
            >
              Back
            </Button>

            <div>
              <Button
                variant="contained"
                className="skipBtn mr20 mlauto"
                onClick={() => {
                  updateFinalResults({
                    waist: '',
                    bmi: '',
                    weight: '',
                    height: '',
                  });
                  setActiveView('Physical');
                  handleCompletedSteps('Weight');
                }}
              >
                Skip
              </Button>

              <Button
                variant="contained"
                color="primary"
                className={'nextBtn'}
                disabled={!nextDisabled}
                onClick={() => {
                  setActiveView('Physical');
                  handleCompletedSteps('Weight');
                }}
              >
                Next
              </Button>
            </div>
          </div>
        </Container>
      </Grow>
    </>
  );
}

export default Weight;
