import React, { useState, useEffect } from 'react';
import BarChartEvent from '../charts/BarChart';

import '../../../App.css';
import '../Events.css';
import PieChartEvent from '../charts/PieChart';
// import Slip from '../../../assets/images/CC_Slip_Yellow.png';
// import Slop from '../../../assets/images/CC_Slop_Yellow.png';
// import Slap from '../../../assets/images/CC_Slap_Yellow.png';
// import Seek from '../../../assets/images/CC_Seek_Yellow.png';
// import Slide from '../../../assets/images/CC_Slide_Yellow.png';

import Slip from '../../../assets/icons/CC_Slip.png';
import Slop from '../../../assets/icons/CC_Slop.png';
import Slap from '../../../assets/icons/CC_Slap.png';
import Seek from '../../../assets/icons/CC_Seek.png';
import Slide from '../../../assets/icons/CC_Slide.png';

const UvResults = ({
  data,
  calculatePercentage,
  convertToChartData,
  calculateCountsByKey,
}) => {
  const [uvSunscreen, setUvSunscreen] = useState([{ x: '', y: 0 }]);
  const [uvOutsideDuration, setUvOutsideDuration] = useState([{ x: '', y: 0 }]);
  const [uvTanning, setUvTanning] = useState([{ x: '', y: 0 }]);

  const [uvPark, setUvPark] = useState([]);
  const [uvBeach, setUvBeach] = useState([]);

  const colors = ['#01c2a6', '#0097ef', '#d8ad39', '#f9555a', '#711111'];

  var shirt_labels = ['Long Shirt', 'Short Shirt', 'No shirt/Singlet'];
  var pants_labels = ['Long Pants', 'Shorts', 'Bottoms'];
  var pants_labels_park = ['Long Pants', '3/4 Pants', 'Shorts'];
  var hats_labels = ['Broad Hat', 'Cap', 'No Hat'];
  var sunglasses_labels = ['Sunglasses', 'No Sunglasses'];
  var shade_labels = ['Shade', 'No Shade'];
  var sunscreen_labels = ['Sunscreen', 'No Sunscreen'];
  var yesNoLabels = ['Yes', 'No'];

  var possibleSunscreenAnswers = [
    'Every 2 hours',
    'Every 2-4 hours',
    'Every 4+ hours',
    `I don't slop on sunscreen`,
  ];
  var possibleTanningAnswers = ['Never', 'Rarely', 'Occasionally', `Often`];
  var possibleOutsideAnswers = [
    `Less than 30 minutes`,
    `30-60 minutes`,
    `1-3 hours`,
    `More than 3 hours`,
  ];

  var shirt_data = [
    uvBeach.long_shirt || 0,
    uvBeach.short_shirt || 0,
    uvBeach.no_shirt || 0,
  ];
  var pants_data = [
    uvBeach.long_pants || 0,
    uvBeach.short_pants || 0,
    uvBeach.no_pants || 0,
  ];
  var hats_data = [
    uvBeach.broad_hat || 0,
    uvBeach.cap || 0,
    uvBeach.no_hat || 0,
  ];
  var sunglasses_data = [uvBeach.sunglasses || 0, uvBeach.no_sunglasses || 0];
  var shade_data = [uvBeach.shade || 0, uvBeach.no_shade || 0];
  var sunscreen_data = [uvBeach.sunscreen || 0, uvBeach.no_sunscreen || 0];

  var shirt_data_park = [
    uvPark.long_shirt || 0,
    uvPark.short_shirt || 0,
    uvPark.no_shirt || 0,
  ];
  var pants_data_park = [
    uvPark.long_pants || 0,
    uvPark.short_pants || 0,
    uvPark.no_pants || 0,
  ];
  var hats_data_park = [
    uvPark.broad_hat || 0,
    uvPark.cap || 0,
    uvPark.no_hat || 0,
  ];
  var sunglasses_data_park = [
    uvPark.sunglasses || 0,
    uvPark.no_sunglasses || 0,
  ];
  var shade_data_park = [uvPark.shade || 0, uvPark.no_shade || 0];
  var sunscreen_data_park = [uvPark.sunscreen || 0, uvPark.no_sunscreen || 0];

  const three_colors = ['#01c2a6', '#d8ad39', '#f9555a', '#f9555a'];
  const two_colors = ['#01c2a6', '#f9555a'];

  useEffect(() => {
    const uvSunscreenData = calculatePercentage(
      data,
      'uv_sunscreen',
      possibleSunscreenAnswers,
    );
    const sunscreenChartData = convertToChartData(uvSunscreenData.percentages);
    setUvSunscreen(sunscreenChartData);

    const outsideData = calculatePercentage(
      data,
      'uv_outside_duration',
      possibleOutsideAnswers,
    );
    const outsideChartData = convertToChartData(outsideData.percentages);
    setUvOutsideDuration(outsideChartData);

    const tanningData = calculatePercentage(
      data,
      'uv_tanning',
      possibleTanningAnswers,
    );
    const tanningChartData = convertToChartData(tanningData.percentages);
    setUvTanning(tanningChartData);

    const uv_beach_counts = calculateCountsByKey(data, 'uv_beach');
    setUvBeach(uv_beach_counts);

    const uv_park_counts = calculateCountsByKey(data, 'uv_park');
    setUvPark(uv_park_counts);
  }, [data]);

  return (
    <>
      <div className="dflex flexwrap gap10">
        <div style={{ minWidth: 500 }} className="paper-container flex1">
          <h1 className="results-question">
            When you're outside in the sun, how often do you slop on sunscreen
            (SPF50 or above)?
          </h1>
          <BarChartEvent colors={colors} chartData={uvSunscreen} />
        </div>
      </div>

      <div className="dflex flexwrap gap10">
        <div style={{ minWidth: 500 }} className="paper-container flex1">
          <h1 className="results-question">
            How often do you sunbathe with the intention to get tanned?
          </h1>
          <BarChartEvent colors={colors} chartData={uvTanning} />
        </div>

        <div style={{ minWidth: 500 }} className="paper-container flex1">
          <h1 className="results-question">
            How long do you typically stay in the sun between 10am and 3pm?
          </h1>
          <BarChartEvent colors={colors} chartData={uvOutsideDuration} />
        </div>
      </div>

      {/* Beach and Park Questions */}
      <div className="dflex flexwrap gap10">
        <div style={{ minWidth: 500 }} className="paper-container flex1">
          <h1 style={{ height: 40 }} className="results-question">
            When you're swimming or at the beach, how do you protect your skin?
          </h1>
          <div className="dflex flexwrap gap10">
            <PieChartEvent
              height="320"
              width="250"
              labels={shirt_labels}
              colors={three_colors}
              chartData={shirt_data}
            />
            <PieChartEvent
              height="320"
              width="250"
              labels={pants_labels}
              colors={three_colors}
              chartData={pants_data}
            />
            <PieChartEvent
              height="320"
              width="250"
              labels={hats_labels}
              colors={three_colors}
              chartData={hats_data}
            />
            <PieChartEvent
              height="320"
              width="250"
              labels={sunglasses_labels}
              colors={two_colors}
              chartData={sunglasses_data}
            />
            <PieChartEvent
              height="320"
              width="250"
              labels={sunscreen_labels}
              colors={two_colors}
              chartData={sunscreen_data}
            />
            <PieChartEvent
              height="320"
              width="250"
              labels={shade_labels}
              colors={two_colors}
              chartData={shade_data}
            />
          </div>
        </div>
      </div>

      <div className="dflex flexwrap gap10">
        <div style={{ minWidth: 500 }} className="paper-container flex1">
          <h1 style={{ height: 40 }} className="results-question">
            When you're outside, how do you protect your skin?
          </h1>
          <div className="dflex flexwrap gap10">
            <PieChartEvent
              height="320"
              width="250"
              labels={shirt_labels}
              colors={three_colors}
              chartData={shirt_data_park}
            />
            <PieChartEvent
              height="320"
              width="250"
              labels={pants_labels_park}
              colors={three_colors}
              chartData={pants_data_park}
            />
            <PieChartEvent
              height="320"
              width="250"
              labels={hats_labels}
              colors={three_colors}
              chartData={hats_data_park}
            />
            <PieChartEvent
              height="320"
              width="250"
              labels={sunglasses_labels}
              colors={two_colors}
              chartData={sunglasses_data_park}
            />
            <PieChartEvent
              height="320"
              width="250"
              labels={sunscreen_labels}
              colors={two_colors}
              chartData={sunscreen_data_park}
            />
            <PieChartEvent
              height="320"
              width="250"
              labels={shade_labels}
              colors={two_colors}
              chartData={shade_data_park}
            />
          </div>
        </div>
      </div>

      <div className="results-info-text">
        <h1>Did you know?</h1>
        <li>
          In 2020, approximately only 20% of Queensland adults report they use
          3/5 sun-safe behaviours in summer most of the time (broad-brimmed hat,
          SPF30 or higher sunscreen and sun-safe clothing).
        </li>
        <li>
          In 2020, approximately 50% of Queensland adults were sunburnt in the
          last 12 months.
        </li>
        <a
          class="link"
          target="_blank"
          href="https://www.choreport.health.qld.gov.au/our-lifestyle/sun-safety"
        >
          Source (Chief Health Officer Queensland)
        </a>

        <h1 style={{ marginTop: 30 }}>We Recommend:</h1>
        <p style={{ fontSize: '23px', marginTop: '5px', marginBottom: '-5px' }}>
          Using all 5 sun-safe behaviours while outside when UV is 3 and above.
          This includes:
        </p>
        <div
          style={{
            display: 'flex',
            gap: '10px',
            marginTop: '12px',
            flexWrap: 'wrap',
          }}
        >
          <div className="sunsafe-section">
            <img src={Slip} />
            <p>Slip on protective, sun-safe clothing</p>
          </div>
          <div className="sunsafe-section">
            <img src={Slop} />
            <p>
              Slop on SPF50 or SPF50+, broad-spectrum, water-resistant sunscreen
            </p>
          </div>
          <div className="sunsafe-section">
            <img src={Slap} />
            <p>Slap on a broad-brimmed hat</p>
          </div>
          <div className="sunsafe-section">
            <img src={Seek} />
            <p>Seek shade where possible</p>
          </div>
          <div className="sunsafe-section">
            <img src={Slide} />
            <p>Slide on sunglasses that meet AS 1067 standards</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default UvResults;
