import React from 'react';
import Chart from 'react-apexcharts';
import './Results.css';
import { useMediaQuery } from '@mui/material';

const BarChartResults = ({ finalResults }) => {
  const mobileView = useMediaQuery('(max-width:850px)');

  const options = {
    legend: {
      show: false,
    },
    chart: {
      background: '#fff',
      height: 400,
      toolbar: {
        show: false,
      },
    },
    redrawOnParentResize: true,
    plotOptions: {
      bar: {
        columnWidth: '50%',
        borderRadius: 8,
        horizontal: mobileView ? true : false,
      },
    },
    dataLabels: {
      enabled: true,
    },
    tooltip: {
      shared: true,
      intersect: false,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        var goal = data.goals[0].value;
        return `<div style="padding:5px 10px; font-size:15px">
          <div style="display:flex; align-items:center; gap:5px"><p style="background: ${data.fillColor}; height: 12px; width: 12px; border-radius: 50%" ></p><b>${data.x}:</b> ${data.y}</div>
          <div style="display:flex; align-items:center; gap:5px; margin-top: -10px"><p style="background: ${data.goals[0].strokeColor} ; height: 12px; width: 12px; border-radius: 50%"></p><b>Population Avg:</b> ${goal}</div>
          </div>`;
      },
    },
    xaxis: {
      type: 'category',
      categories: [
        'Sun & UV',
        'Smoking',
        'Alcohol',
        'Nutrition',
        ['Physical', 'Activity'],
        'Screening',
      ],
      labels: {
        enabled: true,
        formatter: function (value) {
          return value;
        },
        style: {
          colors: [],
          fontSize: '17px',
          fontFamily: 'Foco CC Bd',
        },
      },
    },
    yaxis: [
      {
        max: 100,
        min: 0,
      },
    ],
  };

  const series = [
    {
      name: 'Total Score',
      type: 'bar',
      data: [
        {
          name: 'UV',
          x: 'UV',
          y: finalResults.uv_total,
          fillColor: '#c9a800',
          goals: [
            {
              name: 'Average Score',
              value: 67,
              strokeHeight: 12,
              strokeColor: '#150f87',
              strokeLineCap: 'round',
              strokeWidth: 0,
            },
          ],
        },
        {
          x: 'Smoking',
          y: finalResults.smoking_total,
          fillColor: '#878888',
          goals: [
            {
              name: 'Average Score',
              value: 85,
              strokeHeight: 12,
              strokeColor: '#150f87',
              strokeLineCap: 'round',
              strokeWidth: 0,
            },
          ],
        },
        {
          x: 'Alcohol',
          y: finalResults.alcohol_total,
          fillColor: '#f05029',
          goals: [
            {
              name: 'Average Score',
              value: 74,
              strokeHeight: 12,
              strokeColor: '#150f87',
              strokeLineCap: 'round',
              strokeWidth: 0,
            },
          ],
        },
        {
          x: 'Nutrition',
          y: finalResults.nutrition_total,
          fillColor: '#73c04b',
          goals: [
            {
              name: 'Average Score',
              value: 77,
              strokeHeight: 12,
              strokeColor: '#150f87',
              strokeLineCap: 'round',
              strokeWidth: 0,
            },
          ],
        },
        {
          x: 'Physical Activity',
          y: finalResults.physical_total,
          fillColor: '#199bd7',
          goals: [
            {
              name: 'Average Score',
              value: 75,
              strokeHeight: 12,
              strokeColor: '#150f87',
              strokeLineCap: 'round',
              strokeWidth: 0,
            },
          ],
        },
        {
          x: 'Screening & Early Detection',
          y: finalResults.screening_total,
          fillColor: '#7d489c',
          goals: [
            {
              name: 'Average Score',
              value: 66,
              strokeHeight: 12,
              strokeColor: '#150f87',
              strokeLineCap: 'round',
              strokeWidth: 0,
            },
          ],
        },
      ],
    },
  ];

  return (
    <div>
      <Chart
        options={options}
        series={series}
        height="400"
        width="100%"
        type="bar"
      />
    </div>
  );
};
export default BarChartResults;
