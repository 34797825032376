import React from 'react';
import Chart from 'react-apexcharts';
import './Results.css';

const HistoryChart = ({ previousTotals }) => {
  const data = [
    {
      name: 'UV',
      data: previousTotals.map((item) => item.uv_total),
    },
    {
      name: 'Smoking',
      data: previousTotals.map((item) => item.smoking_total),
    },
    {
      name: 'Alcohol',
      data: previousTotals.map((item) => item.alcohol_total),
    },
    {
      name: 'Nutrition',
      data: previousTotals.map((item) => item.nutrition_total),
    },
    {
      name: 'Physical Activity',
      data: previousTotals.map((item) => item.physical_total),
    },
    {
      name: 'Screening',
      data: previousTotals.map((item) => item.screening_total),
    },
  ];

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    legend: {
      height: 28,
      fontSize: '15px',
      position: 'bottom',
      horizontalAlign: 'center',
    },
    colors: ['#fcd207', '#878888', '#f05029', '#73c04b', '#199bd7', '#7d489c'],
    labels: previousTotals.map((item) => item.timestamp_formatted),
    stroke: {
      width: 3,
    },
    grid: {
      borderColor: '#555',
      clipMarkers: false,
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      x: {
        show: true,
      },
      fixed: {
        enabled: false,
      },
      // y: {
      //   formatter: function (value) {
      //     if (value) {
      //       return value;
      //     }
      //   },
      // },
    },
    fill: {
      show: false,
      gradient: {
        enabled: false,
        opacityFrom: 0,
        opacityTo: 0.1,
      },
    },
    markers: {
      size: 4,
      colors: [
        '#fcd207',
        '#878888',
        '#f05029',
        '#73c04b',
        '#199bd7',
        '#7d489c',
      ],
      strokeWidth: 3,
    },
    yaxis: {
      min: 0,
      max: 100,
    },
  };

  return (
    <div style={{ margin: 20 }} className="">
      <h1 style={{ marginTop: 50 }} className="viewMoreHeader mb0">
        Previous Scores
      </h1>
      <h3 style={{ marginTop: -5 }} className=" mb5">
        Re-take the calculator to see how you've improved over time
      </h3>
      <Chart
        options={options}
        series={data}
        height="420"
        width="100%"
        type="area"
      />
    </div>
  );
};
export default HistoryChart;
