import React, { useState, useEffect } from 'react';
import '../../../App.css';
import '../Events.css';
import LinearGauge from '../charts/LinearGauge';
import SingleStackedBar from '../charts/SingleStackedBar';

import apple from '../../../../src/assets/illustrations/nutrition/apple.png';
import Cucumber from '../../../../src/assets/illustrations/nutrition/cucumber.png';
import Bread from '../../../../src/assets/illustrations/nutrition/bread.png';
import Steak from '../../../../src/assets/illustrations/nutrition/steak.png';
import Bacon from '../../../../src/assets/illustrations/nutrition/bacon.png';

const NutritionResults = ({ data }) => {
  const [averageScores, setAverageScores] = useState({
    fruit: 0,
    veg: 0,
    wholegrains: 0,
    redMeat: 0,
    processedMeat: 0,
    fruitSeries: [],
    vegSeries: [],
    wholegrainSeries: [],
    redMeatSeries: [],
    processedMeatSeries: [],
  });

  function calculateAverage(data) {
    const totalEntries = data.length;
    if (totalEntries === 0) {
      return {
        fruit: 0,
        veg: 0,
        wholegrains: 0,
        redMeat: 0,
        processedMeat: 0,
        fruitSeries: [],
        vegSeries: [],
        wholegrainSeries: [],
        redMeatSeries: [],
        processedMeatSeries: [],
      };
    }

    let sumFruit = 0,
      sumVeg = 0,
      sumWholegrains = 0,
      sumRedMeat = 0,
      sumProcessedMeat = 0;

    const fruitCounts = Array.from({ length: 6 }, () => 0);
    const vegCounts = Array.from({ length: 8 }, () => 0);
    const wholegrainCounts = Array.from({ length: 8 }, () => 0);
    const redMeatCounts = Array.from({ length: 11 }, () => 0);
    const processedMeatsCounts = Array.from({ length: 11 }, () => 0);

    data.forEach((entry) => {
      sumFruit += entry.fruit;
      sumVeg += entry.vegetables;
      sumWholegrains += entry.wholegrains;
      sumRedMeat += entry.red_meat;
      sumProcessedMeat += entry.processed_meat;

      fruitCounts[entry.fruit]++;
      vegCounts[entry.vegetables]++;
      wholegrainCounts[entry.wholegrains]++;
      redMeatCounts[entry.red_meat]++;
      processedMeatsCounts[entry.processed_meat]++;
    });

    const fruitSeries = generateSeries(fruitCounts);
    const vegSeries = generateSeries(vegCounts);
    const wholegrainSeries = generateSeries(wholegrainCounts);
    const redMeatSeries = generateSeries(redMeatCounts);
    const processedMeatSeries = generateSeries(processedMeatsCounts);

    const avgFruit = (sumFruit / totalEntries).toFixed(1);
    const avgVeg = (sumVeg / totalEntries).toFixed(1);
    const avgWholegrains = (sumWholegrains / totalEntries).toFixed(1);
    const avgRedMeat = (sumRedMeat / totalEntries).toFixed(1);
    const avgProcessedMeat = (sumProcessedMeat / totalEntries).toFixed(1);

    return {
      fruit: avgFruit,
      veg: avgVeg,
      wholegrains: avgWholegrains,
      redMeat: avgRedMeat,
      processedMeat: avgProcessedMeat,
      fruitSeries: fruitSeries,
      vegSeries: vegSeries,
      wholegrainSeries: wholegrainSeries,
      redMeatSeries: redMeatSeries,
      processedMeatSeries: processedMeatSeries,
    };
  }

  function generateSeries(counts) {
    const series = counts.map((count, index) => {
      return {
        name: `${index} serves`,
        data: [count],
      };
    });

    return series;
  }

  useEffect(() => {
    const averageScores = calculateAverage(data);
    setAverageScores(averageScores);
  }, [data]);

  return (
    <>
      <div className="">
        <h2 className="nutrition-main-ques">
          On average, in a typical{' '}
          <span style={{ fontSize: 28 }} className="importantText">
            day
          </span>{' '}
          we get:
        </h2>

        <div className="dflex">
          <div className="flex1 nutrition-res-container">
            <h2 className="nutrition-res-header">
              Fruits <img style={{ height: 28 }} src={apple} />
            </h2>
            <div className="w100 dflex gap10 alignitemscenter mauto">
              <h2 className="mt30">0</h2>
              <LinearGauge
                maxNumber="5"
                average={averageScores.fruit}
                label="Fruit serves per day"
                recommended="2"
                recommendedInt={2}
              />
              <h2 className="mt30">5+</h2>
            </div>

            <h2
              style={{ fontSize: 20, marginTop: '-10px' }}
              className="textaligncenter mb0 dnone"
            >
              On average
            </h2>
            <h2 style={{ marginTop: '-5px' }} className=" mt0 textaligncenter">
              <span style={{ fontSize: 26 }} className="importantText">
                {averageScores.fruit}
                {averageScores.fruit === '5.0' ? '+' : ''} serves
              </span>{' '}
              each day
            </h2>
          </div>

          <div className="flex1 nutrition-res-container">
            <SingleStackedBar
              colors={[
                '#d36a62',
                '#ec9876',
                '#92e18b',
                '#8ed0d4',
                '#5cb7fc',
                '#aa9fcf',
              ]}
              title="Breakdown of Daily Fruit Intake"
              series={averageScores.fruitSeries}
            />
          </div>
        </div>

        <div className="dflex">
          <div className="flex1 nutrition-res-container">
            <h1 className="nutrition-res-header">
              Vegetables (Non-starchy){' '}
              <img style={{ height: 30 }} src={Cucumber} />
            </h1>
            <div className="w100 dflex gap10 alignitemscenter mauto">
              <h2 className="mt30">0</h2>
              <LinearGauge
                maxNumber="7"
                average={averageScores.veg}
                label="Veg serves per day"
                recommended="5"
                recommendedInt={5}
              />
              <h2 className="mt30">7+</h2>
            </div>

            <h2
              style={{ fontSize: 20, marginTop: '-10px' }}
              className="textaligncenter mb0 dnone"
            >
              On average
            </h2>
            <h2 style={{ marginTop: '-5px' }} className=" mt0 textaligncenter">
              <span style={{ fontSize: 26 }} className="importantText">
                {averageScores.veg}
                {averageScores.veg === '7.0' ? '+' : ''} serves
              </span>{' '}
              each day
            </h2>
          </div>

          <div className="flex1 nutrition-res-container">
            <SingleStackedBar
              colors={[
                '#d36a62',
                '#ec9876',
                '#f3bf83',
                '#fecd6c',
                '#92e18b',
                '#8ed0d4',
                '#5cb7fc',
                '#aa9fcf',
              ]}
              title="Breakdown of Daily Veg Intake"
              series={averageScores.vegSeries}
            />
          </div>
        </div>

        <div className="dflex">
          <div className="flex1 nutrition-res-container">
            <h1 className="nutrition-res-header">
              Wholegrain Foods <img style={{ height: 30 }} src={Bread} />
            </h1>
            <div className="w100 dflex gap10 alignitemscenter mauto">
              <h2 className="mt30">0</h2>
              <LinearGauge
                maxNumber="7"
                average={averageScores.wholegrains}
                label="Wholegrain serves per day"
                recommended="4"
                recommendedInt={4}
              />
              <h2 className="mt30">7+</h2>
            </div>

            <h2
              style={{ fontSize: 20, marginTop: '-10px' }}
              className="textaligncenter mb0 dnone"
            >
              On average
            </h2>
            <h2 style={{ marginTop: '-5px' }} className=" mt0 textaligncenter">
              <span style={{ fontSize: 26 }} className="importantText">
                {averageScores.wholegrains}
                {averageScores.wholegrains === '7.0' ? '+' : ''} serves
              </span>{' '}
              each day
            </h2>
          </div>

          <div className="flex1 nutrition-res-container">
            <SingleStackedBar
              colors={[
                '#d36a62',
                '#ec9876',
                '#f3bf83',
                '#fecd6c',
                '#92e18b',
                '#8ed0d4',
                '#5cb7fc',
                '#aa9fcf',
              ]}
              title="Breakdown of Daily Wholegrain Foods"
              series={averageScores.wholegrainSeries}
            />
          </div>
        </div>

        <h2 className="nutrition-main-ques">
          On average, in a typical{' '}
          <span style={{ fontSize: 28 }} className="importantText">
            week
          </span>{' '}
          we get:
        </h2>

        <div className="dflex">
          <div className="flex1 nutrition-res-container">
            <h1 className="nutrition-res-header">
              Red Meats <img style={{ height: 30 }} src={Steak} />
            </h1>
            <div className="w100 dflex gap10 alignitemscenter mauto">
              <h2 className="mt30">0</h2>
              <LinearGauge
                maxNumber="10"
                average={averageScores.redMeat}
                label="Red meat serves per day"
                recommended="7 or less"
                recommendedInt={7}
              />
              <h2 className="mt30">10+</h2>
            </div>

            <h2
              style={{ fontSize: 20, marginTop: '-10px' }}
              className="textaligncenter mb0 dnone"
            >
              On average
            </h2>
            <h2 style={{ marginTop: '-5px' }} className=" mt0 textaligncenter">
              <span style={{ fontSize: 26 }} className="importantText">
                {averageScores.redMeat}
                {averageScores.redMeat === '10.0' ? '+' : ''} serves
              </span>{' '}
              each week
            </h2>
          </div>

          <div className="flex1 nutrition-res-container">
            <SingleStackedBar
              colors={[
                '#92e18b',
                '#8ed0d4',
                '#6eb9ff',
                '#aa9fcf',
                '#aababd',
                '#e9bae5',
                '#f6d070',
                '#f1a25c',
                '#ef827a',
                '#c14c43',
                '#ab3f33',
              ]}
              title="Breakdown of Weekly Red Meat"
              series={averageScores.redMeatSeries}
            />
          </div>
        </div>

        <div className="dflex">
          <div className="flex1 nutrition-res-container">
            <h1 className="nutrition-res-header">
              Processed Meats <img style={{ height: 30 }} src={Bacon} />
            </h1>
            <div className="w100 dflex gap10 alignitemscenter mauto">
              <h2 className="mt30">0</h2>
              <LinearGauge
                maxNumber="10"
                average={averageScores.processedMeat}
                label="Processed meat serves per day"
                recommended=""
              />
              <h2 className="mt30">10+</h2>
            </div>

            <h2
              style={{ fontSize: 20, marginTop: '-10px' }}
              className="textaligncenter mb0 dnone"
            >
              On average
            </h2>
            <h2 style={{ marginTop: '-5px' }} className=" mt0 textaligncenter">
              <span style={{ fontSize: 26 }} className="importantText">
                {averageScores.processedMeat}
                {averageScores.processedMeat === '10.0' ? '+' : ''} serves
              </span>{' '}
              each week
            </h2>
          </div>

          <div className="flex1 nutrition-res-container">
            <SingleStackedBar
              colors={[
                '#92e18b',
                '#8ed0d4',
                '#6eb9ff',
                '#aa9fcf',
                '#aababd',
                '#e9bae5',
                '#f6d070',
                '#f1a25c',
                '#ef827a',
                '#c14c43',
                '#ab3f33',
              ]}
              title="Breakdown of Weekly Processed Meat"
              series={averageScores.processedMeatSeries}
            />
          </div>
        </div>

        <div style={{ marginTop: 30 }} className="results-info-text">
          <h1>Did you know?</h1>
          <li>
            Approximately only 4.2% of adults meet both fruit and vegetable
            recommendations.
          </li>
          <li>
            More than four in ten (44.1%) adults met the fruit recommendation.
          </li>
          <a
            class="link"
            target="_blank"
            href="https://www.abs.gov.au/statistics/health/health-conditions-and-risks/national-health-survey/latest-release"
          >
            Source (National Health Survey 2022 - ABS)
          </a>
        </div>
      </div>
    </>
  );
};

export default NutritionResults;
