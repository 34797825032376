import React from 'react';
import Chart from 'react-apexcharts';

const LinearGauge = ({
  average,
  maxNumber,
  label,
  recommended,
  recommendedInt,
}) => {
  var series = [
    {
      name: 'Average',
      data: [average],
    },
  ];

  var options = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 1500,
        animateGradually: {
          enabled: true,
          delay: 300,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 1000,
        },
      },
    },
    colors: ['#1e90ff'],
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 10,
      },
    },
    xaxis: {
      categories: [label],
      axisBorder: {
        color: '#8d8d8d',
      },
      max: parseInt(maxNumber),
      min: 0,
      labels: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
        trim: false,
      },
    },

    annotations: {
      xaxis: [
        {
          x: parseInt(recommendedInt),
          borderColor: 'green',
          label: {
            borderColor: 'green',
            style: {
              color: '#fff',
              background: 'green',
              fontSize: 13,
            },
            orientation: 'horizontal',
            text: `${recommended} serves`,
          },
        },
      ],
    },
  };

  return (
    <div className="w100">
      <Chart
        options={options}
        series={series}
        height="75"
        width="100%"
        type="bar"
      />
    </div>
  );
};
export default LinearGauge;
