import React from 'react';
import TextField from '@mui/material/TextField';
import { InputAdornment } from '@mui/material';
import WaistModal from './components/WaistImageModal';
import DressSizeModal from './components/DressSizeModal';

const QuestionsWaist = ({ updateFinalResults, finalResults }) => {
  const handleChange = (key, event) => {
    const value = event.target.value;
    if (value.length > 5) {
      return;
    }
    updateFinalResults({
      [key]: value,
    });
  };

  return (
    <>
      <div className="mt20 mb10 alignitemscenter flexwrap justifycontentcenter dflex">
        <TextField
          sx={{ width: '200px', '.MuiInputBase-input': { fontSize: 15 } }}
          InputLabelProps={{ style: { fontSize: 15 } }}
          InputProps={{
            inputProps: { min: 0, max: 500 },
            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
          }}
          type="number"
          id="waist"
          onChange={(event) => {
            handleChange('waist', event);
          }}
          value={finalResults.waist}
          label="Waist"
        />
      </div>
      <DressSizeModal updateFinalResults={updateFinalResults} />
      <WaistModal />
    </>
  );
};

export default QuestionsWaist;
