import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { GrClose } from 'react-icons/gr';

export default function DressSizeModal({ updateFinalResults }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const womensMeasurements = [
    { size: '4', value: 60 },
    { size: '6', value: 63 },
    { size: '8', value: 65 },
    { size: '10', value: 70 },
    { size: '12', value: 75 },
    { size: '14', value: 80 },
    { size: '16', value: 85 },
    { size: '18', value: 93 },
    { size: '20', value: 109 },
    { size: '22', value: 116 },
    { size: '24', value: 123 },
    { size: '26', value: 130 },
    { size: '28', value: 138 },
  ];
  const mensMeasurements = [
    { size: '28', value: 74 },
    { size: '30', value: 76 },
    { size: '31', value: 79 },
    { size: '32', value: 81 },
    { size: '33', value: 84 },
    { size: '34', value: 86 },
    { size: '35', value: 89 },
    { size: '36', value: 92 },
    { size: '37', value: 94 },
    { size: '38', value: 97 },
    { size: '39', value: 99 },
    { size: '40', value: 102 },
    { size: '41', value: 104 },
  ];

  const updateWaist = (val) => {
    updateFinalResults({
      waist: val,
    });
    handleClose();
  };

  return (
    <div className="dflex justifycontentcenter">
      <a onClick={handleOpen} className="link ">
        Dress Size Guide
      </a>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Container maxWidth="md" className="modalStyles dressSizeModal">
            <GrClose onClick={() => handleClose()} className="modalCloser" />
            <h2 style={{ width: '93%' }} className="textaligncenter mb10">
              Australian Sizes with Waist Measurements (Approximate)
            </h2>
            <div className="dflex justifycontentcenter flexwrap">
              <div className="justifycontentcenter w100 dflex flexwrap">
                <div style={{ width: '45%', minWidth: '170px', flex: 1 }}>
                  <h3 className="mt5 mb5 textaligncenter">
                    Women's Dress Size
                  </h3>
                  <Table className="waist-measurements-table">
                    <TableHead>
                      <TableRow style={{ background: '#696969' }}>
                        <TableCell className="textaligncenter">
                          Dress Size
                        </TableCell>
                        <TableCell className="textaligncenter">
                          Waist (cm)
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {womensMeasurements.map((row) => (
                        <TableRow style={{ height: '35px' }} key={row.size}>
                          <TableCell className="textaligncenter">
                            {row.size}
                          </TableCell>
                          <TableCell className="textaligncenter">
                            <button
                              className="waist-btns"
                              onClick={() => {
                                updateWaist(row.value);
                              }}
                            >
                              {row.value} cm
                            </button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>

                <div style={{ width: '45%', minWidth: '170px', flex: 1 }}>
                  <h3 className="mt5 mb5 textaligncenter">Men's Pants Size</h3>
                  <Table className="waist-measurements-table">
                    <TableHead>
                      <TableRow style={{ background: '#696969' }}>
                        <TableCell className="textaligncenter">
                          Pants Size
                        </TableCell>
                        <TableCell className="textaligncenter">
                          Waist (cm)
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {mensMeasurements.map((row) => (
                        <TableRow style={{ height: '35px' }} key={row.size}>
                          <TableCell className="textaligncenter">
                            {row.size}
                          </TableCell>
                          <TableCell className="textaligncenter">
                            <button
                              className="waist-btns"
                              onClick={() => {
                                updateWaist(row.value);
                              }}
                            >
                              {row.value} cm
                            </button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
            <Button
              variant="contained"
              className="mt20"
              fullWidth
              onClick={handleClose}
            >
              Close
            </Button>
          </Container>
        </Fade>
      </Modal>
    </div>
  );
}
