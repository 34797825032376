import React, { useEffect, useState } from 'react';
import Sunglasses from '../../../../assets/illustrations/beach/sunglasses.png';
import beachBackgroundFemale from '../../../../assets/illustrations/beach/beachbg.png';
import beachBackgroundMale from '../../../../assets/illustrations/beach/male/beachbg.png';
import {
  Button,
  Container,
  Grid,
  Grow,
  Slide,
  useMediaQuery,
} from '@mui/material';
import ToggleOption from './ToggleOptions';
import SectionHeader from '../../other/SectionHeader';
import { Image } from '../../other/Image';
import { calculateTotalScore } from '../../../../utils/calculateScores';
import { options, slideOptionsFemale, slideOptionsMale } from './BeachItems';

const BeachQuestion = ({
  updateFinalResults,
  finalResults,
  setActiveView,
  handleCompletedSteps,
  riskRatios,
}) => {
  const [changedOptions, setChangedOptions] = useState('');
  const [pantsIndex, setPantsIndex] = useState(0);
  const [shirtIndex, setShirtIndex] = useState(0);
  const [sunscreenIndex, setSunscreenIndex] = useState(0);
  const [shadeIndex, setShadeIndex] = useState(0);
  const [hatIndex, setHatIndex] = useState(0);
  const [sunglassesIndex, setSunglassesIndex] = useState(0);
  const containerRef = React.useRef(null);
  const [slideOptions, setSlideOptions] = useState(slideOptionsFemale);
  const mobileView = useMediaQuery('(max-width:850px)');

  useEffect(() => {
    if (finalResults.gender.answer.includes('Male')) {
      setSlideOptions(slideOptionsMale);
    } else {
      setSlideOptions(slideOptionsFemale);
    }
  }, []);

  //Calculate total risk ratios based on all selected options - to add into UV scores
  const calculateTotalRiskRatio = () => {
    let cancers = ['skin', 'breast', 'lung', 'prostate', 'bowel'];
    var risk_ratios = [
      { skin: 0 },
      { breast: 0 },
      { lung: 0 },
      { prostate: 0 },
      { bowel: 0 },
    ];
    cancers.forEach((cancer, index) => {
      var total = 0;
      total += options.pants[pantsIndex].risk_ratios[index][cancer];
      total += options.shirt[shirtIndex].risk_ratios[index][cancer];
      total += options.sunglasses[sunglassesIndex].risk_ratios[index][cancer];
      total += options.sunscreen[sunscreenIndex].risk_ratios[index][cancer];
      total += options.shade[shadeIndex].risk_ratios[index][cancer];
      total += options.hat[hatIndex].risk_ratios[index][cancer];

      if (isNaN(total) || !isFinite(total)) {
        total = 0;
      }
      total = Number(total.toFixed(2));

      risk_ratios[index][cancer] = total;
    });

    return risk_ratios;
  };

  const handleUpdate = () => {
    const risk_ratios = calculateTotalRiskRatio();

    updateFinalResults(
      {
        uv_beach: {
          question_id: 'uv_beach',
          factor: 'uv',
          question: `When you're swimming or at the beach, how do you protect your skin?`,
          answers: {
            shirt: options.shirt[shirtIndex].id,
            pants: options.pants[pantsIndex].id,
            hat: options.hat[hatIndex].id,
            sunglasses: options.sunglasses[sunglassesIndex].id,
            sunscreen: options.sunscreen[sunscreenIndex].id,
            shade: options.shade[shadeIndex].id,
          },
        },
      },
      {
        uv_beach: {
          question_id: 'uv_beach',
          factor: 'uv',
          risk_ratios: risk_ratios,
        },
      },
    );
  };

  const handleChange = (option, setIndex, optionsLength, changeDirection) => {
    setIndex(
      (prevIndex) =>
        (prevIndex + (changeDirection === 'left' ? 1 : -1) + optionsLength) %
        optionsLength,
    );
    setChangedOptions(option);
  };

  useEffect(() => {
    handleUpdate();
  }, [changedOptions]);

  useEffect(() => {
    var currentAnswers = finalResults.uv_beach.answers;

    //Update render if user comes back and forth to question
    for (const category in options) {
      options[category].forEach((item) => {
        if (item.id === currentAnswers.pants && item.category === 'pants') {
          setPantsIndex(item.index);
        } else if (item.id === currentAnswers.hat && item.category === 'hat') {
          setHatIndex(item.index);
        } else if (
          item.id === currentAnswers.sunglasses &&
          item.category === 'sunglasses'
        ) {
          setSunglassesIndex(item.index);
        } else if (
          item.id === currentAnswers.shade &&
          item.category === 'shade'
        ) {
          setShadeIndex(item.index);
        } else if (
          item.id === currentAnswers.shirt &&
          item.category === 'shirt'
        ) {
          setShirtIndex(item.index);
        } else if (
          item.id === currentAnswers.sunscreen &&
          item.category === 'sunscreen'
        ) {
          setSunscreenIndex(item.index);
        }
      });
    }

    updateFinalResults(
      {
        uv_beach: {
          question_id: 'uv_beach',
          factor: 'uv',
          question: `When you're swimming or at the beach, how do you protect your skin?`,
          answers: {
            shirt: currentAnswers.shirt,
            pants: currentAnswers.pants,
            hat: currentAnswers.hat,
            sunglasses: currentAnswers.sunglasses,
            sunscreen: currentAnswers.sunscreen,
            shade: currentAnswers.shade,
          },
        },
      },
      {
        uv_beach: {
          question_id: 'uv_beach',
          factor: 'uv',
          risk_ratios: riskRatios.uv_beach.risk_ratios,
        },
      },
    );
    const element = document.getElementById(`containerMain`);

    // setTimeout(() => {
    //   if (element && mobileView) {
    //     const y = element.getBoundingClientRect().top + 25;
    //     window.scroll({
    //       top: y,
    //       behavior: 'smooth',
    //     });
    //   }
    // }, '500');
  }, []);

  const toggleOptions = [
    {
      id: options.shirt,
      settingIndex: setShirtIndex,
      actualIndex: shirtIndex,
    },
    {
      id: options.pants,
      settingIndex: setPantsIndex,
      actualIndex: pantsIndex,
    },
    {
      id: options.hat,
      settingIndex: setHatIndex,
      actualIndex: hatIndex,
    },
    {
      id: options.sunglasses,
      settingIndex: setSunglassesIndex,
      actualIndex: sunglassesIndex,
    },
    {
      id: options.sunscreen,
      settingIndex: setSunscreenIndex,
      actualIndex: sunscreenIndex,
    },
    {
      id: options.shade,
      settingIndex: setShadeIndex,
      actualIndex: shadeIndex,
    },
  ];

  const checkAllRequired = () => {
    setActiveView('UV_Park');
    handleCompletedSteps('UV_Beach');
    const score = calculateTotalScore(riskRatios, 'uv');
    updateFinalResults({ uv_total: score });
  };
  return (
    <>
      <Grow in={true}>
        <Container id="containerMain" className="mt30">
          <SectionHeader
            title="When you're swimming or at the beach, how do you protect your skin?"
            subtext="For example, when you're at a swimming pool, water park or the beach"
            activeColor="#fcd207"
            factor="uv"
            subquestion="true"
          />

          <Grid className="dflex justifycontentcenter mt30 flexwrap toggleOptionUv">
            {toggleOptions.map((item, index) => {
              return (
                <ToggleOption
                  key={index}
                  option={item.id[item.actualIndex]}
                  handleChange={handleChange}
                  setIndex={item.settingIndex}
                  options={item.id}
                />
              );
            })}
          </Grid>

          <div
            className={
              mobileView
                ? 'zoom50 beachBodySection dflex justifycontentcenter'
                : 'beachBodySection dflex justifycontentcenter'
            }
            ref={containerRef}
          >
            {slideOptions.map((item, index) => {
              return (
                <Slide
                  key={index}
                  in={item.id === finalResults.uv_beach.answers[item.category]}
                  direction={item.direction}
                  container={containerRef.current}
                >
                  <img
                    src={item.src}
                    alt={item.id}
                    className={item.className}
                  />
                </Slide>
              );
            })}

            <Slide
              in={sunglassesIndex == 1}
              direction="down"
              container={containerRef.current}
            >
              {finalResults.gender.answer.includes('Male') ? (
                <img
                  alt="Sunglasses"
                  src={Sunglasses}
                  className="beach_seagull_sunglasses_male"
                />
              ) : (
                <img
                  src={Sunglasses}
                  alt="Sunglasses"
                  className="beach_seagull_sunglasses"
                />
              )}
            </Slide>

            {finalResults.gender.answer.includes('Male') ? (
              <Image classer={'beachBackground'} src={beachBackgroundMale} />
            ) : (
              <Image classer={'beachBackground'} src={beachBackgroundFemale} />
            )}
          </div>

          <div className="dflex mb10 mt30 justifycontentspacebetween">
            <Button
              variant="contained"
              className="prevBtn"
              onClick={() => {
                setActiveView('UV');
              }}
            >
              Back
            </Button>

            <Button
              variant="contained"
              color="primary"
              className={'nextBtn'}
              onClick={checkAllRequired}
            >
              Next
            </Button>
          </div>
        </Container>
      </Grow>
    </>
  );
};

export default BeachQuestion;
