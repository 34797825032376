import React from 'react';
import './Loading.css';
import { Container } from '@mui/material';
const Loading = () => {
  return (
    <Container className="parent-container loading-container " maxWidth="lg">
      <h1>Loading..</h1>
      <div className="dflex justifycontentcenter">
        <div class="container">
          <div class="cube">
            <div class="cube__inner"></div>
          </div>
          <div class="cube">
            <div class="cube__inner"></div>
          </div>
          <div class="cube">
            <div class="cube__inner"></div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Loading;
