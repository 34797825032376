import React, { useEffect, useState } from 'react';
import './alcohol.css';

//Alcohol assets
import whiteWine from '../../../assets/illustrations/alcohol/white_wine.png';
import redWine from '../../../assets/illustrations/alcohol/red_wine.png';
import beer from '../../../assets/illustrations/alcohol/beer_bottle.png';
import premix from '../../../assets/illustrations/alcohol/premix.png';
import pint from '../../../assets/illustrations/alcohol/pint.png';
import schooner from '../../../assets/illustrations/alcohol/schooner.png';
import shot from '../../../assets/illustrations/alcohol/shot.png';
import champagneGlass from '../../../assets/illustrations/alcohol/champagne.png';
import { Grow, useMediaQuery } from '@mui/material';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { risk_ratios_alcohol } from '../../../utils/config';

const StandardDrinks = ({
  updateFinalResults,
  finalResults,
  questionDisplayed,
}) => {
  const mobileView = useMediaQuery('(max-width:850px)');

  const [totalStandardDrinks, setTotalStandardDrinks] = useState(
    finalResults.alcohol_standard_drinks.standard_drinks_total,
  );
  const [totalWhiteWine, setTotalWhiteWine] = useState(
    finalResults.alcohol_standard_drinks.answers.white_wine,
  );
  const [totalRedWine, setTotalRedWine] = useState(
    finalResults.alcohol_standard_drinks.answers.red_wine,
  );
  const [fullStrengthBeer, setFullStrengthBeer] = useState(
    finalResults.alcohol_standard_drinks.answers.full_strength_beer,
  );
  const [midStrengthBeer, setMidStrengthBeer] = useState(
    finalResults.alcohol_standard_drinks.answers.mid_strength_beer,
  );
  const [lightStrengthBeer, setLightStrengthBeer] = useState(
    finalResults.alcohol_standard_drinks.answers.light_strength_beer,
  );
  const [beerSchooner, setBeerSchooner] = useState(
    finalResults.alcohol_standard_drinks.answers.beer_schooner,
  );
  const [beerPint, setBeerPint] = useState(
    finalResults.alcohol_standard_drinks.answers.beer_pint,
  );

  const [spiritShot, setSpiritShot] = useState(
    finalResults.alcohol_standard_drinks.answers.spirit_shot,
  );

  const [spiritPremix, setSpiritPremix] = useState(
    finalResults.alcohol_standard_drinks.answers.spirit_premix,
  );

  const [champagne, setChampagne] = useState(
    finalResults.alcohol_standard_drinks.answers.champagne,
  );

  const drinksOptions = [
    {
      id: 'light_strength_beer',
      label: 'Light Strength Beer',
      src: beer,
      amount: '375ml bottle/can',
      percentage: '2.7%',
      stdDrinks: 0.8,
      drinkIndex: lightStrengthBeer,
      settingIndex: setLightStrengthBeer,
    },
    {
      id: 'mid_strength_beer',
      label: 'Mid Strength Beer',
      src: beer,
      amount: '375ml bottle/can',
      percentage: '3.5%',
      stdDrinks: 1,
      drinkIndex: midStrengthBeer,
      settingIndex: setMidStrengthBeer,
    },
    {
      id: 'full_strength_beer',
      label: 'Full Strength Beer',
      src: beer,
      amount: '375ml bottle/can',
      percentage: '4.8%',
      stdDrinks: 1.4,
      drinkIndex: fullStrengthBeer,
      settingIndex: setFullStrengthBeer,
    },
    {
      id: 'beer_schooner',
      label: 'Schooner of Beer',
      src: schooner,
      amount: '425ml full-strength',
      percentage: '4.8%',
      stdDrinks: 1.6,
      drinkIndex: beerSchooner,
      settingIndex: setBeerSchooner,
    },
    {
      id: 'beer_pint',
      label: 'Pint of Beer',
      src: pint,
      amount: '570ml full-strength',
      percentage: '4.8%',
      stdDrinks: 2.2,
      drinkIndex: beerPint,
      settingIndex: setBeerPint,
    },
    {
      id: 'white_wine',
      label: 'White Wine',
      src: whiteWine,
      amount: '150ml glass',
      percentage: '11.5%',
      stdDrinks: 1.4,
      drinkIndex: totalWhiteWine,
      settingIndex: setTotalWhiteWine,
    },
    {
      id: 'red_wine',
      label: 'Red Wine',
      src: redWine,
      amount: '150ml glass',
      percentage: '13.5%',
      stdDrinks: 1.5,
      drinkIndex: totalRedWine,
      settingIndex: setTotalRedWine,
    },
    {
      id: 'champagne',
      label: 'Champagne',
      src: champagneGlass,
      amount: '150ml glass',
      percentage: '12%',
      stdDrinks: 1.4,
      drinkIndex: champagne,
      settingIndex: setChampagne,
    },
    {
      id: 'spirit_shot',
      label: 'Shot of Spirits',
      src: shot,
      amount: '30ml shot',
      percentage: '40%',
      stdDrinks: 1.0,
      drinkIndex: spiritShot,
      settingIndex: setSpiritShot,
    },
    {
      id: 'spirit_premix',
      label: 'Pre-mix spirits',
      src: premix,
      amount: '375ml bottle',
      percentage: '5.0%',
      stdDrinks: 1.5,
      drinkIndex: spiritPremix,
      settingIndex: setSpiritPremix,
    },
  ];

  const handleChange = (change, option) => {
    option.settingIndex(option.drinkIndex + change);
  };

  useEffect(() => {
    var totalStandards = 0;
    drinksOptions.forEach((item) => {
      const totals = item.stdDrinks * item.drinkIndex;
      totalStandards += totals;
    });
    totalStandards = totalStandards.toFixed(1);
    setTotalStandardDrinks(totalStandards);
  }, [drinksOptions]);

  useEffect(() => {
    //Calculate approximately weekly drinks/grams for risk ratios
    var risk_ratios = [{ skin: 0, breast: 0, lung: 0, prostate: 0, bowel: 0 }];
    var alcohol_frequency = '';
    if (finalResults.alcohol_status.answer) {
      alcohol_frequency = finalResults.alcohol_status.answer;
    }
    //Amount is approx. amount of times user drinks per week
    var amount = 0;
    if (alcohol_frequency.includes('Monthly or less')) {
      amount = 0.3;
    } else if (alcohol_frequency.includes('2-4 times a month')) {
      amount = 0.8;
    } else if (alcohol_frequency.includes('2-3 times a week')) {
      amount = 2.5;
    } else if (alcohol_frequency.includes('Every')) {
      amount = 6;
    }
    var typical_session_grams = totalStandardDrinks * 10;
    var typical_week_grams = (typical_session_grams * amount).toFixed(2);

    //Grab the nearest risk ratio based of amount of alcohol grams drank per week
    if (typical_week_grams <= 10) {
      risk_ratios = risk_ratios_alcohol['10g_per_week'];
    } else if (typical_week_grams <= 20) {
      risk_ratios = risk_ratios_alcohol['20g_per_week'];
    } else if (typical_week_grams <= 30) {
      risk_ratios = risk_ratios_alcohol['30g_per_week'];
    } else if (typical_week_grams <= 40) {
      risk_ratios = risk_ratios_alcohol['40g_per_week'];
    } else if (typical_week_grams <= 50) {
      risk_ratios = risk_ratios_alcohol['50g_per_week'];
    } else if (typical_week_grams <= 60) {
      risk_ratios = risk_ratios_alcohol['60g_per_week'];
    } else if (typical_week_grams <= 70) {
      risk_ratios = risk_ratios_alcohol['70g_per_week'];
    } else if (typical_week_grams <= 80) {
      risk_ratios = risk_ratios_alcohol['80g_per_week'];
    } else if (typical_week_grams <= 90) {
      risk_ratios = risk_ratios_alcohol['90g_per_week'];
    } else if (typical_week_grams <= 100) {
      risk_ratios = risk_ratios_alcohol['100g_per_week'];
    } else if (typical_week_grams > 100) {
      risk_ratios = risk_ratios_alcohol['110g_per_week'];
    }

    //Update risk ratios based on alcohol/gram drank per week
    updateFinalResults(
      {
        alcohol_standard_drinks: {
          factor: 'alcohol',
          question_id: 'alcohol_standard_drinks',
          question:
            'How many standard drinks do you typically have in one sitting?',
          standard_drinks_total: totalStandardDrinks,
          answers: {
            beer_schooner: beerSchooner,
            beer_pint: beerPint,
            light_strength_beer: lightStrengthBeer,
            mid_strength_beer: midStrengthBeer,
            full_strength_beer: fullStrengthBeer,
            white_wine: totalWhiteWine,
            red_wine: totalRedWine,
            champagne: champagne,
            spirit_shot: spiritShot,
            spirit_premix: spiritPremix,
          },
        },
      },
      {
        alcohol_standard_drinks: {
          question_id: 'alcohol_standard_drinks',
          factor: 'alcohol',
          risk_ratios: risk_ratios,
        },
      },
    );
  }, [totalStandardDrinks, finalResults.alcohol_status]);

  return (
    <>
      <Grow
        className={questionDisplayed ? 'height100' : 'height0 opacity0'}
        in={questionDisplayed}
      >
        <div style={{ marginBottom: 40 }} className="mt10">
          <h3 className="mt30">
            How many standard drinks do you typically have in one sitting?
          </h3>

          <p className="mt0 mb0 subtext-gray textaligncenter">Approximately</p>
          <h2 className="mt0 textaligncenter mb30">
            {totalStandardDrinks} standard drinks
          </h2>

          <div className="dflex flexwrap">
            {drinksOptions.map((option, index) => {
              return (
                <div
                  className="toggleBackground drinksContainer"
                  key={index}
                  option={option}
                >
                  {option.drinkIndex > 0 ? (
                    <p className="totalStdDrinks">{option.drinkIndex}</p>
                  ) : (
                    <p className="totalStdDrinks opacity0"></p>
                  )}

                  <img
                    src={option.src}
                    style={{ height: 60 }}
                    className={option.className}
                    alt={option.label}
                  />

                  <h4
                    style={{ marginLeft: '-10px', marginRight: '-10px' }}
                    className="mb10"
                  >
                    {option.label}
                  </h4>
                  <p className="subtext-gray mt0 mb0">
                    {option.amount}, {option.percentage}
                  </p>

                  <p className="subtext-gray mt0 mb0">
                    {option.stdDrinks} standard drinks
                  </p>

                  <div className="mt10 dflex justifycontentcenter">
                    <button
                      className="alignitemscenter dflex justifycontentcenter toggleBtns alcoholToggle"
                      disabled={option.drinkIndex < 1 ? true : false}
                      onClick={() => handleChange(-1, option)}
                    >
                      <FaMinus />
                    </button>
                    <button
                      className="alignitemscenter dflex justifycontentcenter toggleBtns alcoholToggle"
                      disabled={option.drinkIndex > 14 ? true : false}
                      onClick={() => handleChange(1, option)}
                    >
                      <FaPlus />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
          {mobileView && (
            <>
              <p className="mt30 mb0 subtext-gray textaligncenter">
                Approximately
              </p>
              <h2 className="mt0 textaligncenter mb30">
                {totalStandardDrinks} standard drinks
              </h2>
            </>
          )}
        </div>
      </Grow>
    </>
  );
};

export default StandardDrinks;
