import sunscreenIcon from '../../../../assets/icons/sunscreen.png';
import shadeIcon from '../../../../assets/icons/shade.png';
import hatIcon from '../../../../assets/icons/hat.png';
import capIcon from '../../../../assets/icons/cap.png';
import shortsIcon from '../../../../assets/icons/shorts.png';
import medPantsIcon from '../../../../assets/icons/med_pants.png';

import longpantsIcon from '../../../../assets/icons/longpants.png';
import longshirtIcon from '../../../../assets/icons/long_shirt.png';
import shirtIcon from '../../../../assets/icons/short_shirt.png';
import singletIcon from '../../../../assets/icons/singlet.png';
import sunglassesIcon from '../../../../assets/icons/sunglasses.png';

import Sunscreen from '../../../../assets/illustrations/beach/sunscreen50.png';
import Sunglasses from '../../../../assets/illustrations/park/sunglasses.png';
import Hat from '../../../../assets/illustrations/park/hat.png';
import Cap from '../../../../assets/illustrations/park/cap.png';
import Shade from '../../../../assets/illustrations/park/ccq_shade.png';
import shortShirt from '../../../../assets/illustrations/park/short_shirt.png';
import longShirt from '../../../../assets/illustrations/park/long_shirt.png';
import shortPants from '../../../../assets/illustrations/park/short_pants.png';
import longPants from '../../../../assets/illustrations/park/long_pants.png';

import shortShirtFemale from '../../../../assets/illustrations/park/short_shirt_female.png';
import longShirtFemale from '../../../../assets/illustrations/park/long_shirt_female.png';
import shortPantsFemale from '../../../../assets/illustrations/park/short_pants_female.png';
import longPantsFemale from '../../../../assets/illustrations/park/long_pants_female.png';
import HatFemale from '../../../../assets/illustrations/park/hat_female.png';

export const options = {
  pants: [
    {
      id: 'no_pants',
      index: 0,
      category: 'pants',
      icon: `${shortsIcon}`,
      label: 'Shorts',
      noneSelected: false,
      risk_ratios: [
        { skin: 0.21 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
    {
      id: 'short_pants',
      index: 1,
      category: 'pants',
      icon: `${medPantsIcon}`,
      label: '3/4 Pants',
      noneSelected: false,
      risk_ratios: [
        { skin: 0.21 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
    {
      id: 'long_pants',
      index: 2,
      category: 'pants',
      icon: `${longpantsIcon}`,
      label: 'Long Pants',
      noneSelected: false,
      risk_ratios: [
        { skin: 0 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
  ],
  shirt: [
    {
      id: 'no_shirt',
      index: 0,
      category: 'shirt',
      icon: `${singletIcon}`,
      label: 'Singlet',
      class: 'beachBody_shirt',
      direction: 'top',
      noneSelected: false,
      risk_ratios: [
        { skin: 0.21 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
    {
      id: 'short_shirt',
      index: 1,
      category: 'shirt',
      icon: `${shirtIcon}`,
      label: 'Short Shirt',
      class: 'beachBody_shirt',
      direction: 'top',
      noneSelected: false,
      risk_ratios: [
        { skin: 0.21 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
    {
      id: 'long_shirt',
      index: 2,
      category: 'shirt',
      icon: `${longshirtIcon}`,
      label: 'Long Shirt',
      noneSelected: false,
      risk_ratios: [
        { skin: 0 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
  ],
  sunglasses: [
    {
      id: 'no_sunglasses',
      index: 0,
      category: 'sunglasses',
      icon: `${sunglassesIcon}`,
      label: 'No Sunnies',
      class: 'beachBody_sunnies',
      direction: 'top',
      noneSelected: true,
      risk_ratios: [
        { skin: 0.21 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
    {
      id: 'sunglasses',
      index: 1,
      category: 'sunglasses',
      icon: `${sunglassesIcon}`,
      class: 'beachBody_sunnies',
      direction: 'top',
      noneSelected: false,
      label: 'Sunnies',
      risk_ratios: [
        { skin: 0 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
  ],

  sunscreen: [
    {
      id: 'no_sunscreen',
      index: 0,
      category: 'sunscreen',
      icon: `${sunscreenIcon}`,
      label: 'No Sunscreen',
      class: 'beach_sunscreen',
      direction: 'top',
      noneSelected: true,
      risk_ratios: [
        { skin: 0.57 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
    {
      id: 'sunscreen',
      index: 1,
      category: 'sunscreen',
      icon: `${sunscreenIcon}`,
      class: 'beach_sunscreen',
      direction: 'top',
      noneSelected: false,
      label: 'Sunscreen',
      risk_ratios: [
        { skin: 0 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
  ],
  shade: [
    {
      id: 'no_shade',
      index: 0,
      category: 'shade',
      icon: `${shadeIcon}`,
      label: 'No Shade',
      class: 'beachBody_sunscreen',
      direction: 'top',
      noneSelected: true,
      risk_ratios: [
        { skin: 0.21 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
    {
      id: 'shade',
      index: 1,
      category: 'shade',
      icon: `${shadeIcon}`,
      class: 'beachBody_sunscreen',
      direction: 'top',
      noneSelected: false,
      label: 'Shade',
      risk_ratios: [
        { skin: 0 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
  ],
  hat: [
    {
      id: 'no_hat',
      index: 0,
      category: 'hat',
      icon: ``,
      label: 'No Hat',
      class: 'beachBody_sunnies',
      direction: 'top',
      noneSelected: true,
      risk_ratios: [
        { skin: 0.21 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
    {
      id: 'broad_hat',
      index: 1,
      category: 'hat',
      icon: `${hatIcon}`,
      class: 'beachBody_sunnies',
      direction: 'top',
      noneSelected: false,
      label: 'Broad Hat',
      risk_ratios: [
        { skin: 0 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
    {
      id: 'cap',
      index: 2,
      category: 'hat',
      icon: `${capIcon}`,
      class: 'beachBody_sunnies',
      direction: 'top',
      noneSelected: false,
      label: 'Cap',
      risk_ratios: [
        { skin: 0.21 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
  ],
};

export const slideOptionsMale = [
  {
    id: 'short_shirt',
    src: shortShirt,
    category: 'shirt',
    className: 'park_shirt_short',
    direction: 'left',
  },
  {
    id: 'long_shirt',
    src: longShirt,
    category: 'shirt',
    className: 'park_shirt_long',
    direction: 'left',
  },
  {
    id: 'short_pants',
    src: shortPants,
    category: 'pants',
    className: 'park_pants_short',
    direction: 'left',
  },
  {
    id: 'long_pants',
    src: longPants,
    category: 'pants',
    className: 'park_long_pants',
    direction: 'left',
  },
  {
    id: 'sunglasses',
    src: Sunglasses,
    category: 'sunglasses',
    className: 'park_sunglasses',
    direction: 'down',
  },
  {
    id: 'broad_hat',
    src: Hat,
    category: 'hat',
    className: 'park_hat',
    direction: 'down',
  },
  {
    id: 'cap',
    src: Cap,
    category: 'hat',
    className: 'park_cap',
    direction: 'down',
  },
  {
    id: 'sunscreen',
    src: Sunscreen,
    category: 'sunscreen',
    className: 'park_sunscreen',
    direction: 'right',
  },
  {
    id: 'shade',
    src: Shade,
    category: 'shade',
    className: 'park_shade',
    direction: 'left',
  },
];

export const slideOptionsFemale = [
  {
    id: 'short_shirt',
    src: shortShirtFemale,
    category: 'shirt',
    className: 'park_shirt_short_female',
    direction: 'left',
  },
  {
    id: 'long_shirt',
    src: longShirtFemale,
    category: 'shirt',
    className: 'park_shirt_long_female',
    direction: 'left',
  },
  {
    id: 'short_pants',
    src: shortPantsFemale,
    category: 'pants',
    className: 'park_pants_short_female',
    direction: 'left',
  },
  {
    id: 'long_pants',
    src: longPantsFemale,
    category: 'pants',
    className: 'park_long_pants_female',
    direction: 'left',
  },
  {
    id: 'sunglasses',
    src: Sunglasses,
    category: 'sunglasses',
    className: 'park_sunglasses_female',
    direction: 'down',
  },
  {
    id: 'broad_hat',
    src: HatFemale,
    category: 'hat',
    className: 'park_hat_female',
    direction: 'down',
  },
  {
    id: 'cap',
    src: Cap,
    category: 'hat',
    className: 'park_cap_female',
    direction: 'down',
  },
  {
    id: 'sunscreen',
    src: Sunscreen,
    category: 'sunscreen',
    className: 'park_sunscreen',
    direction: 'right',
  },
  {
    id: 'shade',
    src: Shade,
    category: 'shade',
    className: 'park_shade_female',
    direction: 'left',
  },
];
