import React from 'react';
import Chart from 'react-apexcharts';

const TreeMap = ({ chartData }) => {
  const series = [
    {
      data: chartData,
    },
  ];

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '35px',
      },
      formatter: function (val, opt) {
        return val;
      },
    },
    plotOptions: {
      treemap: {
        distributed: true,
        enableShades: false,
        dataLabels: {
          format: 'scale',
        },
      },
    },
  };

  return (
    <div>
      <Chart
        options={options}
        series={series}
        height="450"
        width="100%"
        type="treemap"
      />
    </div>
  );
};

export default TreeMap;
