import React, { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { GrClose } from 'react-icons/gr';
import { TextField } from '@mui/material';
import AnimatedCheck from '../../other/AnimatedCheck';
import axios from 'axios';

export default function TransitionsModal({ emailReOpen, setEmailReOpen }) {
  const handleOpen = () => {
    setEmailReOpen(true);
    setEmail('');
  };
  const handleClose = () => setEmailReOpen(false);

  const [error, setError] = useState(false);
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);

  //Email regex and validation
  var emailRegex = /^\S+@\S+\.\S+$/;

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setEmail(email);

    if (emailRegex.test(email)) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const handleSubmit = async (e) => {
    var payload = { user_email: email };
    setEmailSent(true);
    try {
      await axios.post('/api/resend-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        variant="outlined"
        className="borderRadius8"
        style={{ fontSize: 11 }}
      >
        Returning?
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={emailReOpen}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        {!emailSent ? (
          <Fade in={emailReOpen}>
            <Container maxWidth="sm" className="modalStyles">
              <GrClose
                style={{
                  position: 'absolute',
                  right: 20,
                  top: 20,
                  cursor: 'pointer',
                }}
                onClick={handleClose}
              />
              <h2 className="mb0">View your previous results</h2>
              <p className="mt10 mb30">
                Enter your previously used email address and we will send you a
                link to view your previous results.
              </p>
              <form
                inputMode="disabled"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <TextField
                  id="email_modal"
                  name="email"
                  value={email}
                  onChange={handleEmailChange}
                  autoComplete="off"
                  fullWidth
                  label="Email"
                />
              </form>
              <Button
                className="w100 mt20"
                onClick={handleSubmit}
                disabled={!error}
                variant="contained"
                type="button"
              >
                Send Link
              </Button>
            </Container>
          </Fade>
        ) : (
          <Container maxWidth="sm" className="textaligncenter modalStyles">
            <GrClose
              style={{
                position: 'absolute',
                right: 20,
                top: 20,
                cursor: 'pointer',
              }}
              onClick={handleClose}
            />
            <AnimatedCheck />
            <h2 className="mb20">
              Check your email for a link to your previous results
            </h2>
            <Button variant="contained" fullWidth onClick={handleClose}>
              Close
            </Button>
          </Container>
        )}
      </Modal>
    </>
  );
}
