import React, { useState, useEffect } from 'react';
import BarChartEvents from '../charts/BarChart';
import '../../../App.css';
import '../Events.css';
import PieChartEvent from '../charts/PieChart';

const SmokingResults = ({
  data,
  calculatePercentage,
  convertToChartData,
  smokingHousehold,
  vapingHousehold,
}) => {
  //Default answers for each chart, so that even 0% are displayed
  const possibleSmokingAnswers = [
    'No, never smoked',
    'I quit smoking',
    'Yes, occasionally',
    `Yes, regularly`,
  ];
  const [smokingStatus, setSmokingStatus] = useState([{ x: '', y: 0 }]);

  const possibleVapingAnswers = [
    'No, never vaped',
    'I quit vaping',
    'Yes, occasionally',
    `Yes, regularly`,
  ];
  const [vapingStatus, setVapingStatus] = useState([{ x: '', y: 0 }]);

  useEffect(() => {
    const smokingData = calculatePercentage(
      data,
      'smoking_status',
      possibleSmokingAnswers,
    );
    const smokingChartData = convertToChartData(smokingData.percentages);
    setSmokingStatus(smokingChartData);

    const vapingData = calculatePercentage(
      data,
      'vaping_status',
      possibleVapingAnswers,
    );
    const vapingChartData = convertToChartData(vapingData.percentages);
    setVapingStatus(vapingChartData);
  }, [data]);

  const smokingStatusColors = [
    '#01c2a6',
    '#0097ef',
    '#d8ad39',
    '#ec9876',
    '#f9555a',
  ];

  return (
    <>
      <div style={{ width: '97%' }} className="dflex paper-container">
        <div style={{ width: '70%' }}>
          <h1 className="results-question" style={{ height: 40 }}>
            Do you smoke cigarettes?
          </h1>
          <BarChartEvents
            colors={smokingStatusColors}
            chartData={smokingStatus}
          />
        </div>

        <div style={{ width: '30%' }}>
          <h1 className="results-question" style={{ height: 40 }}>
            Is your household smoke-free?
          </h1>
          <PieChartEvent
            height="400"
            width="300"
            labels={['Yes', 'No']}
            colors={['#01c2a6', '#f9555a']}
            chartData={smokingHousehold}
          />
        </div>
      </div>
      <div style={{ width: '97%' }} className="dflex paper-container">
        <div style={{ width: '70%' }}>
          <h1 className="results-question" style={{ height: 40 }}>
            Do you vape e-cigarettes?
          </h1>
          <BarChartEvents
            colors={smokingStatusColors}
            chartData={vapingStatus}
          />
        </div>

        <div style={{ width: '30%' }}>
          <h1 className="results-question" style={{ height: 40 }}>
            Is your household vape-free?
          </h1>
          <PieChartEvent
            height="400"
            width="300"
            labels={['Yes', 'No']}
            colors={['#01c2a6', '#f9555a']}
            chartData={vapingHousehold}
          />
        </div>
      </div>

      <div style={{ marginTop: 30 }} className="results-info-text">
        <h1>Did you know?</h1>
        <li>
          One in ten (10.6%) adults were current daily smokers in 2022, this
          rate has steadily declined from 22.4% in 2001.
        </li>
        <li>Nearly three in five (58.3%) adults have never smoked.</li>
        <li>
          One in seven (14.4%) adults have used e-cigarette or vaping devices.
        </li>
        <a
          class="link"
          target="_blank"
          href="https://www.abs.gov.au/statistics/health/health-conditions-and-risks/national-health-survey/latest-release"
        >
          Source (National Health Survey 2022 - ABS)
        </a>
      </div>
    </>
  );
};

export default SmokingResults;
