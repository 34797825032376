import React, { useState } from 'react';
import '../LearnMore.css';
import AnswerResponse from './AnswerResponse';
import ScreeningPrograms from '../answerComponents/ScreeningPrograms';
import { Link } from 'react-router-dom';
import PdfModal from '../PdfModal';

const ScreeningAnswers = ({ finalResults, factor }) => {
  const answers = Object.values(finalResults).filter(
    (res) => res.factor === 'screening',
  );
  const [openModal, setOpen] = useState('');

  const handleOpen = (id) => {
    setOpen(id);
  };
  const handleClose = () => {
    setOpen(null);
  };
  const additionalResources = [
    {
      id: 'spot_difference',
      url: `https://cancerqld.org.au/content/resources/library/Spot%20the%20difference%20FLYER.pdf`,
    },
    {
      id: 'bowel',
      url: `https://cancerqld.org.au/content/resources/library/Lifestyle_6_Factsheet_Bowel_cancer_screening_A4.pdf`,
    },
    {
      id: 'cervical',
      url: `https://cancerqld.org.au/content/resources/library/Lifestyle_6_Factsheet_Cervical_cancer_screening_A4.pdf`,
    },
    {
      id: 'breast',
      url: `https://cancerqld.org.au/content/resources/library/Lifestyle_6_Factsheet_Breast_Awareness_A4.pdf`,
    },
    {
      id: 'cervical_screening_video',
      url: `https://www.youtube.com/embed/CRNI-vyLjIw`,
    },
    {
      id: 'lgbtqi',
      url: `https://www.cancervic.org.au/downloads/resources/booklets/LGBTQI-People-and-Cancer.pdf`,
    },
  ];

  return (
    <div className="subtext">
      <h4 className="learnMoreHeader mt0 mb10">Did you know:</h4>
      <li>
        Cancer screening is one of the most effective ways to detect early signs
        of cancer. Cancer screening saves lives.
      </li>
      <li>
        Screening and tests can find cancer in its early stages and even detect
        cells before they turn into cancer. Treatment has been found to be more
        effective when cancer is found early.{' '}
      </li>
      <li>
        Knowing your body plays an important role in detecting cancer early.
        Remember to check your body regularly for any unusual changes or
        symptoms, and talk with doctor if you notice anything that doesn't seem
        right to you.
      </li>
      <li>
        From 1 July 2024, the National Bowel Cancer Screening Program's entry
        age has been lowered from 50 to 45. Eligible people aged 45 to 49 can{' '}
        <Link
          to="https://www.ncsr.gov.au/information-for-participants/order-a-replacement-bowel-test-kit/"
          target="_blank"
          className="link"
        >
          request their first free kit now.
        </Link>
      </li>
      <div className="answersSection">
        <h4 className="learnMoreHeader mt20 mb0">Your Answers:</h4>
        {answers.map((item) => {
          return (
            <>
              <AnswerResponse item={item} />
            </>
          );
        })}
      </div>

      <div>
        <h4 className="learnMoreHeader mt30 mb10">Tips & Resources:</h4>
        <li>
          Check your body regularly for any freckles, moles or lumps that are{' '}
          <span className="highlighted-text">new or changing</span> in size,
          shape and/or colour. Also check for sores that do not heal over 4-6
          weeks. Read our{' '}
          <span onClick={() => handleOpen('spot_difference')} className="link">
            Spot the Difference
          </span>{' '}
          fact sheet to learn more.
        </li>
        <li>
          Self-collection is now available for Cervical Screening tests. Check
          out the{' '}
          <span
            onClick={() => handleOpen('cervical_screening_video')}
            className="link"
          >
            National Cervical Screening video
          </span>{' '}
          to learn more.
        </li>
        <li>
          Some common cancers such as prostate, lung, liver and skin do not have
          a national screening program. It’s important to know your body and
          talk with your doctor if you notice anything unusual.
        </li>
        <li>
          Research has found that LGBTQI+ people often delay or avoid screening.
          Read more here{' '}
          <Link
            target="_blank"
            to="https://www.cancervic.org.au/downloads/resources/booklets/LGBTQI-People-and-Cancer.pdf"
            className="link"
          >
            LGBTQI+ People & Cancer.
          </Link>{' '}
        </li>
        {additionalResources.map((resource, index) => (
          <PdfModal
            open={openModal === resource.id}
            url={resource.url}
            handleClose={handleClose}
          />
        ))}

        <ScreeningPrograms handleOpen={handleOpen} />
      </div>
    </div>
  );
};

export default ScreeningAnswers;
