import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const PieChartEvent = ({ chartData, labels, colors, height, width }) => {
  var options = {
    chart: {
      type: 'pie',
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true,
        speed: 500,
        animateGradually: {
          enabled: true,
          speed: 500,
        },
        dynamicAnimation: {
          enabled: true,
        },
      },
    },
    labels: labels,
    legend: {
      show: true,
      position: 'top',
      fontSize: '18px',
      fontFamily: 'Foco CC Bd',
      height: 54,
    },
    colors: colors,
  };

  return (
    <div
      style={{ marginTop: '10px', marginBottom: '-50px' }}
      className="dflex justifycontentcenter flex1"
    >
      <Chart
        options={options}
        series={chartData}
        height={height}
        width={width}
        type="pie"
      />
    </div>
  );
};
export default PieChartEvent;
