import React, { useState, useEffect } from 'react';
import '../assets/AnimatedWave2.css';
import LandingPage from '../components/body/landingpage/LandingPage.js';
import Personal from '../components/body/personal/Personal';
import UV from '../components/body/uv/uv';
import { Button, Container, useMediaQuery } from '@mui/material';
import StepperProgress from '../components/body/stepper/Stepper';
import Smoking from '../components/body/smoking/Smoking';
import Alcohol from '../components/body/alcohol/Alcohol';
import Nutrition from '../components/body/nutrition/Nutrition';
import Weight from '../components/body/weight/Weight';
import Physical from '../components/body/physical/Physical';
import ParkQuestion from '../components/body/uv/components/UvPark';
import Screening from '../components/body/screening/Screening';
import { configQuestionsObject } from '../utils/config';
import BeachQuestion from '../components/body/uv/components/UvBeach';
import LandingPageMobile from '../components/body/landingpage/LandingPageMobile';

function Calculator({
  finalResults,
  setfinalResults,
  riskRatios,
  updateFinalResults,
  activeView,
  setActiveView,
}) {
  const [completedSteps, setCompletedSteps] = useState([]);
  const mobileView = useMediaQuery('(max-width:850px)');

  const handleCompletedSteps = (completedStep) => {
    var arr = [...completedSteps];
    arr.push(completedStep);
    setCompletedSteps(arr);
  };

  const [currentPersonalQuestion, setCurrentPersonalQuestion] = useState(0);
  const [currentSmokingQuestion, setCurrentSmokingQuestion] = useState(0);
  const [currentUvQuestion, setCurrentUvQuestion] = useState(0);
  const [currentAlcoholQuestion, setCurrentlAlcoholQuestion] = useState(0);
  const [currentScreeningQuestion, setCurrentScreeningQuestion] = useState(0);
  const [configQuestions, setConfigQuestions] = useState(configQuestionsObject);

  //Insert GTM, Hotjar, and GA4 for user tracking
  useEffect(() => {
    window.scrollTo({ top: 0, behaviour: 'smooth' });
  }, [activeView]);

  const renderView = () => {
    switch (activeView) {
      case 'LandingPage':
        return mobileView ? (
          <LandingPageMobile
            finalResults={finalResults}
            setfinalResults={setfinalResults}
            updateFinalResults={updateFinalResults}
            setActiveView={setActiveView}
          />
        ) : (
          <LandingPage
            finalResults={finalResults}
            setfinalResults={setfinalResults}
            updateFinalResults={updateFinalResults}
            setActiveView={setActiveView}
          />
        );
      case 'Personal':
        return (
          <Personal
            setActiveView={setActiveView}
            updateFinalResults={updateFinalResults}
            finalResults={finalResults}
            currentPersonalQuestion={currentPersonalQuestion}
            setCurrentPersonalQuestion={setCurrentPersonalQuestion}
            configQuestions={configQuestions}
            handleCompletedSteps={handleCompletedSteps}
          />
        );
      case 'UV':
        return (
          <UV
            setActiveView={setActiveView}
            updateFinalResults={updateFinalResults}
            finalResults={finalResults}
            currentUvQuestion={currentUvQuestion}
            setCurrentUvQuestion={setCurrentUvQuestion}
            configQuestions={configQuestions}
            handleCompletedSteps={handleCompletedSteps}
          />
        );
      case 'UV_Park':
        return (
          <ParkQuestion
            riskRatios={riskRatios}
            finalResults={finalResults}
            updateFinalResults={updateFinalResults}
            setActiveView={setActiveView}
            handleCompletedSteps={handleCompletedSteps}
          />
        );
      case 'UV_Beach':
        return (
          <BeachQuestion
            riskRatios={riskRatios}
            finalResults={finalResults}
            updateFinalResults={updateFinalResults}
            setActiveView={setActiveView}
            handleCompletedSteps={handleCompletedSteps}
          />
        );
      case 'Smoking':
        return (
          <Smoking
            setActiveView={setActiveView}
            updateFinalResults={updateFinalResults}
            finalResults={finalResults}
            currentSmokingQuestion={currentSmokingQuestion}
            setCurrentSmokingQuestion={setCurrentSmokingQuestion}
            configQuestions={configQuestions}
            handleCompletedSteps={handleCompletedSteps}
            riskRatios={riskRatios}
          />
        );
      case 'Alcohol':
        return (
          <Alcohol
            finalResults={finalResults}
            updateFinalResults={updateFinalResults}
            setActiveView={setActiveView}
            configQuestions={configQuestions}
            setCurrentlAlcoholQuestion={setCurrentlAlcoholQuestion}
            currentAlcoholQuestion={currentAlcoholQuestion}
            handleCompletedSteps={handleCompletedSteps}
            riskRatios={riskRatios}
          />
        );
      case 'Nutrition':
        return (
          <Nutrition
            finalResults={finalResults}
            updateFinalResults={updateFinalResults}
            setActiveView={setActiveView}
            handleCompletedSteps={handleCompletedSteps}
            riskRatios={riskRatios}
          />
        );
      case 'Weight':
        return (
          <Weight
            finalResults={finalResults}
            updateFinalResults={updateFinalResults}
            setActiveView={setActiveView}
            handleCompletedSteps={handleCompletedSteps}
          />
        );
      case 'Physical':
        return (
          <Physical
            finalResults={finalResults}
            updateFinalResults={updateFinalResults}
            setActiveView={setActiveView}
            handleCompletedSteps={handleCompletedSteps}
            riskRatios={riskRatios}
          />
        );
      case 'Screening':
        return (
          <Screening
            finalResults={finalResults}
            configQuestions={configQuestions}
            currentScreeningQuestion={currentScreeningQuestion}
            setCurrentScreeningQuestion={setCurrentScreeningQuestion}
            updateFinalResults={updateFinalResults}
            setActiveView={setActiveView}
            handleCompletedSteps={handleCompletedSteps}
            riskRatios={riskRatios}
            demo={false}
          />
        );
      default:
        return (
          <LandingPage
            finalResults={finalResults}
            updateFinalResults={updateFinalResults}
            setActiveView={setActiveView}
          />
        );
    }
  };

  return (
    <div className="container-wave">
      <div className="bg-blob"></div>
      <div className="bg-blob two"></div>
      {mobileView && activeView === 'LandingPage' ? null : (
        <StepperProgress
          setActiveStep={setActiveView}
          completedSteps={completedSteps}
          activeStep={activeView}
        />
      )}

      <Container
        className={
          mobileView && activeView === 'LandingPage'
            ? ' mobile-bg '
            : ' parent-container ' + ` leftBorder ` + activeView
        }
        maxWidth="lg"
      >
        {renderView()}
      </Container>
    </div>
  );
}

export default Calculator;
