import React, { useState } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Link,
  pdf,
} from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer';
import { Button, CircularProgress } from '@mui/material';
import '../../../App.css';
import CRC_Header from '../../../assets/images/CRC_Header.png';
import PDFFront from '../../../assets/images/PDF-Front.png';
import SlipSlopSlap from '../../../assets/icons/Slip.png';
import Lifestyle6 from '../../../assets/icons/Lifestyle6.png';
import SupportServices from '../../../assets/icons/cancerservices.png';

import petal from '../../../assets/icons/petal-lightblue.png';
import UvTitle from '../../../assets/icons/UVTitle.png';
import smokingTitle from '../../../assets/icons/SmokingTitle.png';
import alcoholTitle from '../../../assets/icons/alcoholTitle.png';
import nutritionTitle from '../../../assets/icons/nutritionTitle.png';
import weightTitle from '../../../assets/icons/weightTitle.png';
import physicalTitle from '../../../assets/icons/physicalTitle.png';
import screeningTitle from '../../../assets/icons/ScreeningTitle.png';
import { BsFillCloudDownloadFill } from 'react-icons/bs';

import robotoItalic from './fonts/robotoItalic.ttf';
import roboto400 from './fonts/roboto400.ttf';
import roboto500 from './fonts/roboto500.ttf';
import roboto700 from './fonts/roboto700.ttf';
import { saveAs } from 'file-saver';

//Build PDF with users results
const MyDoc = ({ results, timestamp }) => {
  Font.register({
    family: 'Roboto',
    fonts: [
      {
        src: robotoItalic,
        fontStyle: 'italic',
      },
      {
        src: roboto400,
        fontWeight: '400',
      },
      {
        src: roboto500,
        fontWeight: '500',
      },
      {
        src: roboto700,
        fontWeight: '700',
      },
    ],
  });

  const styles = StyleSheet.create({
    page: {
      fontFamily: 'Roboto',
      backgroundColor: '#ffffff',
      padding: '10px',
      fontSize: '10px',
    },
    question: {
      fontFamily: 'Roboto',
      fontWeight: '500',
      padding: '8px',
      fontSize: '12px',
      backgroundColor: '#fbfbfb',
    },
    answer: {
      fontFamily: 'Roboto',
      paddingTop: '4px',
      paddingLeft: '10px',
      fontSize: '11px',
      fontWeight: '400',
    },
    recommendation: {
      fontFamily: 'Roboto',
      paddingTop: '4px',
      fontSize: '9px',
      paddingLeft: '10px',
      fontStyle: 'italic',
    },
    dateText: {
      fontFamily: 'Roboto',
      fontWeight: 600,
      position: 'absolute',
      right: 20,
      fontSize: 19,
      color: '#000000',
    },
  });

  const uvAnswers = Object.values(results).filter((res) => res.factor === 'uv');

  const smokingAnswers = Object.values(results).filter(
    (res) => res.factor === 'smoking',
  );

  const alcoholAnswers = Object.values(results).filter(
    (res) => res.factor === 'alcohol',
  );

  const screeningAnswers = Object.values(results).filter(
    (res) => res.factor === 'screening',
  );

  const resultsUrl = `https://cancer-risk-calculator.org/results?user=${results.id}`;

  // Function to transform answer keys
  function transformAnswer(answerKey) {
    var answer = answerKey;
    if (answer === 'no_pants') {
      answer = 'shorts';
    } else if (answer === 'no_shirt') {
      answer = 'No shirt / Singlet';
    }
    // Split the key by underscores, capitalize each word, and join them back
    return answer
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
  return (
    <Document>
      <Page size="A4" style={styles.frontPage}>
        <Text style={[styles.dateText, { bottom: 45 }]}>{timestamp}</Text>
        <Text style={[styles.dateText, { bottom: 20 }]}>
          <Link style={{ position: 'absolute' }} src={resultsUrl}>
            <Text
              style={[
                styles.dateText,
                { bottom: 20, fontSize: '16px', color: '#1a67b9' },
              ]}
            >
              Open Results
            </Text>
          </Link>
        </Text>
        <Image src={PDFFront} style={{ zIndex: '-1', height: '100%' }} />
      </Page>

      <Page style={styles.page}>
        <Image src={CRC_Header} style={{ width: 300, margin: 5 }} />
        <Image
          src={Lifestyle6}
          style={{ height: 55, position: 'absolute', top: 10, right: 10 }}
        />
        <Image
          src={petal}
          style={{ height: 70, position: 'absolute', bottom: 0, left: 0 }}
        />

        <View style={{ padding: 10, marginTop: 10 }}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingBottom: 5,
            }}
          >
            <Image src={UvTitle} style={{ width: '190px' }} />
            <Text
              style={{
                fontSize: 12,
                fontFamily: 'Roboto',
                fontWeight: 900,
                marginTop: 8,
              }}
            >
              {results.uv_total}/100
            </Text>
          </View>

          {uvAnswers.map((item) => {
            return (
              <View
                key={item.answer}
                style={{ fontSize: 11, marginLeft: 5, marginBottom: 10 }}
              >
                <Text style={styles.question}>{item.question}</Text>
                <View
                  style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}
                >
                  {item.question_id === 'uv_park' ||
                  item.question_id === 'uv_beach' ? (
                    Object.keys(item.answers).map((key) => {
                      return (
                        <Text style={styles.answer}>
                          <Text>•</Text> {transformAnswer(item.answers[key])}
                        </Text>
                      );
                    })
                  ) : (
                    <Text style={styles.answer}>{item.answer}</Text>
                  )}
                </View>
              </View>
            );
          })}
          <View
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
              marginTop: 5,
            }}
          >
            <Image
              src={SlipSlopSlap}
              style={{ width: '350px', marginTop: 5 }}
            />
          </View>
        </View>

        <View style={{ padding: 10, marginTop: 5 }}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingBottom: 5,
            }}
          >
            <Image
              src={smokingTitle}
              style={{ width: '110px', paddingLeft: 5 }}
            />
            <Text
              style={{
                fontSize: 12,
                fontFamily: 'Roboto',
                fontWeight: 900,
                marginTop: 8,
              }}
            >
              {results.smoking_total}/100
            </Text>
          </View>

          {smokingAnswers.map((item) => {
            return (
              <View
                key={item.answer}
                style={{ fontSize: 11, marginLeft: 5, marginBottom: 10 }}
              >
                <Text style={styles.question}>{item.question}</Text>
                <Text style={styles.answer}>
                  {item.answer}{' '}
                  {item.answer.includes('quit') ? (
                    <Text>{item.answer_info}</Text>
                  ) : null}
                </Text>
              </View>
            );
          })}
        </View>
      </Page>

      <Page style={styles.page}>
        <Image src={CRC_Header} style={{ width: 300, margin: 5 }} />
        <Image
          src={Lifestyle6}
          style={{ height: 55, position: 'absolute', top: 10, right: 10 }}
        />
        <Image
          src={petal}
          style={{ height: 70, position: 'absolute', bottom: 0, left: 0 }}
        />

        <View style={{ padding: 10, marginTop: 10 }}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingBottom: 5,
            }}
          >
            <Image
              src={alcoholTitle}
              style={{ height: '20px', paddingLeft: 5 }}
            />
            <Text
              style={{
                fontSize: 12,
                fontFamily: 'Roboto',
                fontWeight: 900,
                marginTop: 8,
              }}
            >
              {results.alcohol_total}/100
            </Text>
          </View>

          {results.alcohol_status.answer === 'Never' ? (
            <View
              key={results.alcohol_status.answer}
              style={{ fontSize: 11, marginLeft: 5, marginBottom: 10 }}
            >
              <Text style={styles.question}>
                How often do you have a drink containing alcohol?
              </Text>
              <Text style={styles.answer}>Never</Text>
            </View>
          ) : (
            alcoholAnswers.map((item) => (
              <View
                key={item.answer}
                style={{ fontSize: 11, marginLeft: 5, marginBottom: 10 }}
              >
                <Text style={styles.question}>{item.question}</Text>
                {item.question_id === 'alcohol_standard_drinks' ? (
                  <Text style={styles.answer}>
                    Approximately {item.standard_drinks_total} standard drinks
                  </Text>
                ) : (
                  <Text style={styles.answer}>{item.answer}</Text>
                )}
              </View>
            ))
          )}
        </View>

        <View style={{ padding: 10, marginTop: 10 }}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingBottom: 5,
            }}
          >
            <Image
              src={nutritionTitle}
              style={{ height: '20px', paddingLeft: 5 }}
            />
            <Text
              style={{
                fontSize: 12,
                fontFamily: 'Roboto',
                fontWeight: 900,
                marginTop: 8,
              }}
            >
              {results.nutrition_total}/100
            </Text>
          </View>

          <Text style={[styles.question]}>
            In a typical day, how many serves do you usually have?
          </Text>
          <View style={{ display: 'flex', gap: '10px' }}>
            <Text style={styles.answer}>
              • Fruits: {results.fruit.answer}
              {results.fruit.answer === 5 && '+'} serves / day
            </Text>
            <Text style={styles.answer}>
              • Vegetables (Non-starchy): {results.vegetables.answer}
              {results.vegetables.answer === 7 && '+'} serves / day
            </Text>
            <Text style={styles.answer}>
              • Wholegrain Foods: {results.wholegrains.answer}
              {results.wholegrains.answer === 7 && '+'} serves / day
            </Text>
          </View>

          <Text style={[styles.question, { marginTop: 10 }]}>
            In a typical week, how many serves do you usually have?
          </Text>
          <View style={{ display: 'flex', gap: '10px' }}>
            <Text style={styles.answer}>
              • Red Meat: {results.red_meat.answer}
              {results.red_meat.answer === 10 && '+'} serves / week
            </Text>
            <Text style={styles.answer}>
              • Processed Meat: {results.processed_meat.answer}
              {results.processed_meat.answer === 10 && '+'} serves / week
            </Text>
          </View>
        </View>

        <View style={{ padding: 10, marginTop: 10 }}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Image
              src={weightTitle}
              style={{ height: '20px', paddingLeft: 5 }}
            />
          </View>
          {results.bmi === '' && results.waist === '' ? (
            <Text
              style={[
                styles.answer,
                { marginBottom: 10, backgroundColor: 'white' },
              ]}
            >
              BMI/Waist Measurement not provided
            </Text>
          ) : (
            <>
              {results.bmi !== '' && (
                <Text
                  style={[
                    styles.answer,
                    { marginBottom: 10, backgroundColor: 'white' },
                  ]}
                >
                  • Body Mass Index (BMI): {results.bmi}
                </Text>
              )}
              {results.waist !== '' && (
                <Text style={[styles.answer, { backgroundColor: 'white' }]}>
                  • Waist Measurement: {results.waist}cm
                </Text>
              )}
            </>
          )}
        </View>
      </Page>

      <Page style={styles.page}>
        <Image src={CRC_Header} style={{ width: 300, margin: 5 }} />
        <Image
          src={Lifestyle6}
          style={{ height: 55, position: 'absolute', top: 10, right: 10 }}
        />
        <Image
          src={petal}
          style={{ height: 70, position: 'absolute', bottom: 0, left: 0 }}
        />

        <View style={{ padding: 10, marginTop: 10 }}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingBottom: 5,
            }}
          >
            <Image
              src={physicalTitle}
              style={{ height: '20px', paddingLeft: 5 }}
            />
            <Text
              style={{
                fontSize: 12,
                fontFamily: 'Roboto',
                fontWeight: 900,
                marginTop: 8,
              }}
            >
              {results.physical_total}/100
            </Text>
          </View>

          <View style={{ fontSize: 11, marginLeft: 5, marginBottom: 10 }}>
            <Text style={[styles.question]}>
              In a typical week, how many hours on average do you usually get
              of:
            </Text>
            <View style={{ display: 'flex', gap: '10px' }}>
              <Text style={styles.answer}>
                • Light & Moderate Activities: {results.exercise.answers.light}
                {results.exercise.answers.light === 10 && '+'} hours / week
              </Text>
              <Text style={styles.answer}>
                • Vigorous Activities: {results.exercise.answers.vigorous}
                {results.exercise.answers.vigorous === 10 && '+'} hours / week
              </Text>
              <Text style={styles.answer}>
                • Muscle Strengthening Activities:{' '}
                {results.exercise.answers.muscle}
                {results.exercise.answers.muscle === 10 && '+'} hours / week
              </Text>
            </View>

            <Text style={[styles.question, { marginTop: 10 }]}>
              In a typical day, how many hours are you sedentary (inactive)?
            </Text>
            <View
              style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}
            >
              <Text style={styles.answer}>
                • Sedentary (Inactivity): {results.sedentary.answers.sedentary}
                {results.sedentary.answers.sedentary === 12 && '+'} hours / day
              </Text>
            </View>
          </View>
        </View>

        <View style={{ padding: 10, marginTop: 10 }}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingBottom: 5,
            }}
          >
            <Image
              src={screeningTitle}
              style={{ height: '23px', paddingLeft: 5 }}
            />
            <Text
              style={{
                fontSize: 12,
                fontFamily: 'Roboto',
                fontWeight: 900,
                marginTop: 8,
              }}
            >
              {results.screening_total}/100
            </Text>
          </View>

          {screeningAnswers.map((item) => {
            return (
              <View
                key={item.answer}
                style={{ fontSize: 11, marginLeft: 5, marginBottom: 10 }}
              >
                <Text style={styles.question}>{item.question}</Text>
                <Text style={styles.answer}>{item.answer}</Text>
              </View>
            );
          })}
        </View>
      </Page>

      <Page size="A4" style={styles.frontPage}>
        <Image
          src={SupportServices}
          style={{ height: '100%', width: '100%' }}
        />
      </Page>
    </Document>
  );
};

const PdfResults = ({ results, timestamp }) => {
  const fileName = `CalculatorResults_${timestamp}.pdf`;
  const [loading, setLoading] = useState(false);

  //Download PDF
  const handleDownload = async () => {
    setLoading(true);
    try {
      const blob = await pdf(
        <MyDoc results={results} timestamp={timestamp} />,
      ).toBlob();
      saveAs(blob, fileName);
    } catch (error) {
      console.error('Error downloading PDF:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ marginTop: '18px', marginBottom: '-20px' }}>
      <Button
        variant="contained"
        id="download_pdf"
        className="downloadPdfBtn borderRadius8"
        onClick={handleDownload}
        disabled={loading}
      >
        {loading ? (
          <CircularProgress
            id="download-pdf-results"
            style={{ color: 'white' }}
          /> // Show CircularProgress while loading
        ) : (
          <>
            <BsFillCloudDownloadFill style={{ marginRight: '10px' }} /> Download
            Results
          </>
        )}
      </Button>
    </div>
  );
};

export default PdfResults;
