import { CircularProgress, TextField } from '@mui/material';
import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { Autocomplete, Paper } from '@mui/material';

//Throttle calls to backend when searching for suburb/postcode with debounce
const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};

const Postcode = ({ finalResults, updateFinalResults, error, setError }) => {
  const [regions, setRegions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const debounceRef = useRef(null);

  const debouncedSearch = useRef(
    debounce((term) => {
      fetchData(term);
    }, 300),
  ).current;

  useEffect(() => {
    return () => clearTimeout(debounceRef.current);
  }, []);

  const fetchData = async (query) => {
    var regionOptions = [];
    const searchQuery = { postcode: query.toString() };
    try {
      const response = await axios.get(`/postcodes`, { params: searchQuery });
      var results = response.data.possible_postcodes;
      results.forEach((item) => {
        regionOptions.push({
          label: `${item.locality}, ${item.state} ${item.postcode}`,
          state: item.state,
          postcode_clean: item.postcode,
          locality: item.locality,
          sa3name: item.sa3name,
          sa4name: item.sa4name,
          region_type: item.region_type,
          longitude: item.longitude,
          latitude: item.latitude,
        });
      });
      setRegions(regionOptions);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleInputChange = async (e) => {
    const searchTerm = e.target.value;
    updateFinalResults({ postcode: searchTerm });
    setSearchTerm(searchTerm);
    setLoading(true);

    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(() => {
      debouncedSearch(searchTerm);
    }, 300);
  };

  const handleBlur = (e) => {
    const postcode = finalResults.postcode;
    const regionObject = finalResults.region;
    //Check postcode and region not empty
    if (isEmpty(regionObject) || postcode === '') {
      setError(true);
      return;
    }
  };

  function isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  const handlePostcodeSelect = (region) => {
    //Update with region info on select of suburb
    if (region) {
      setSearchTerm(region.label);
      setError(false);
      updateFinalResults({
        postcode: `${region.locality}, ${region.state} ${region.postcode_clean}`,
        region: region,
      });
    } else {
      setSearchTerm('');
      setRegions([]);
      setError(true);
      updateFinalResults({ postcode: '', region: {} });
    }
  };

  return (
    <>
      <h3 className="mb10 personal-subheaders">Postcode / Suburb</h3>
      <Autocomplete
        freeSolo
        fullWidth
        filterOptions={(x) => x}
        value={searchTerm || ''}
        PaperComponent={({ children }) => (
          <Paper
            sx={{
              '& .MuiAutocomplete-listbox': { overflowX: 'hidden' },
              '.MuiInputBase-input': { fontSize: 14 },
              '& .MuiAutocomplete-listbox > li::before': {
                color: 'transparent',
              },
            }}
            className="autocomplete"
          >
            {children}
          </Paper>
        )}
        onChange={(e, item) => handlePostcodeSelect(item)}
        options={regions}
        getOptionLabel={(option) => option.label || finalResults.postcode || ''}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              '.MuiInputBase-input': { fontSize: 15 },
            }}
            onChange={handleInputChange}
            onBlur={handleBlur}
            InputLabelProps={{ style: { fontSize: 15 } }}
            error={error}
            label="Postcode or Suburb*"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default Postcode;
