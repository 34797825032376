import { CircularProgress, Container, Fade, Modal } from '@mui/material';
import React, { useState } from 'react';
import { GrClose } from 'react-icons/gr';
import './ImageCarousel.css';
import '../../body/other/Loading.css';
const PdfModal = ({ open, handleClose, url }) => {
  const [loaded, setLoaded] = useState(true);
  return (
    <Modal open={open} onClose={handleClose} closeAfterTransition>
      <Fade className="modalStylesPdf" in={open}>
        <Container>
          <div
            style={{ zoom: 1.5 }}
            className="dflex justifycontentend mt10 mb10"
          >
            <GrClose className="cursor-hover" onClick={handleClose} />
          </div>
          {!loaded && (
            <Container className="loading-container " maxWidth="lg">
              <h1>Loading..</h1>
              <div
                style={{ marginTop: 40 }}
                className="dflex justifycontentcenter"
              >
                <div class="container">
                  <div class="cube">
                    <div class="cube__inner"></div>
                  </div>
                  <div class="cube">
                    <div class="cube__inner"></div>
                  </div>
                  <div class="cube">
                    <div class="cube__inner"></div>
                  </div>
                </div>
              </div>
            </Container>
          )}
          <iframe
            width="100%"
            height="94%"
            src={url}
            allowfullscreen
            className={loaded ? 'iframe-pdf' : 'visiblehidden iframe-pdf'}
            onLoad={() => setLoaded(true)}
          ></iframe>
        </Container>
      </Fade>
    </Modal>
  );
};

export default PdfModal;
