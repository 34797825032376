import React, { useState, useEffect } from 'react';
import BarChartEvents from '../charts/BarChart';
import Treemap from '../charts/Treemap';
import '../../../App.css';
import '../Events.css';

import red_wine from '../assets/red_wine.png';
import white_wine from '../assets/white_wine.png';
import beer_bottle from '../assets/full_strength_beer.png';
import champagne from '../assets/champagne.png';
import spirit_shot from '../assets/spirit_shot.png';
import spirit_premix from '../assets/spirit_premix.png';
import beer_schooner from '../assets/beer_schooner.png';

const AlcoholResults = ({ data, calculatePercentage, convertToChartData }) => {
  //Default answers for each chart, so that even 0% are displayed
  const possibleAlcoholAnswers = [
    'Never',
    'Monthly or less',
    '2-4 times a month',
    `2-3 times a week`,
    `Every day/Almost every day`,
  ];
  const [alcoholStatus, setAlcoholStatus] = useState([{ x: '', y: 0 }]);
  const [drinksMap, setDrinksMap] = useState([{ x: '', y: 0 }]);

  const [standardDrinksAvg, setStandardDrinksAvg] = useState(0);
  const [topThreeDrinks, setTopThreeDrinks] = useState([]);

  useEffect(() => {
    var totalcount = data.length;
    const alcoholData = calculatePercentage(
      data,
      'alcohol_status',
      possibleAlcoholAnswers,
    );
    const alcoholChartData = convertToChartData(alcoholData.percentages);
    setAlcoholStatus(alcoholChartData);

    //Calculate average standard drinks total
    var totalStdDrinks = 0;
    data.forEach((item) => {
      totalStdDrinks += parseFloat(item.standard_drinks_total) || 0;
    });
    var totalStdDrinksAvg = parseFloat(totalStdDrinks / totalcount);

    if (totalcount === 0) {
      totalStdDrinksAvg = 0;
    }
    setStandardDrinksAvg(totalStdDrinksAvg);

    //Calculate counts for treemap
    const alcoholCounts = {};
    data.forEach((entry) => {
      const alcoholTypes = entry.alcohol_types;
      for (const type in alcoholTypes) {
        if (alcoholTypes[type] > 0) {
          alcoholCounts[type] = (alcoholCounts[type] || 0) + alcoholTypes[type];
        }
      }
    });

    const finalChartData = [];
    for (const type in alcoholCounts) {
      var color = '#3396F7';
      if (type.includes('beer')) color = '#895728';
      if (type.includes('full_strength')) color = '#026f1b';
      if (type.includes('schooner')) color = '#ed9800';
      if (type.includes('pint')) color = '#d3b430';
      if (type.includes('white_wine')) color = '#b39b5c';
      if (type.includes('red_wine')) color = '#c1002b';
      if (type.includes('champagne')) color = '#d5cd63';
      if (type.includes('shot')) color = '#4992af';
      if (type.includes('premix')) color = '#ff6480';

      var formattedLabel = '';
      if (type) {
        formattedLabel = type
          .replace(/_/g, ' ')
          .replace(/\b\w/g, (char) => char.toUpperCase());
      }

      finalChartData.push({
        x: formattedLabel,
        y: alcoholCounts[type],
        fillColor: color,
      });
    }
    setDrinksMap(finalChartData);

    //Get top three selected drinks
    const sortedDrinks = Object.entries(alcoholCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 3);

    // Format and set top three drinks
    const formattedTopThree = sortedDrinks.map((drink) => {
      const formattedLabel = drink[0]
        .replace(/_/g, ' ')
        .replace(/\b\w/g, (char) => char.toUpperCase());
      return {
        type: drink[0],
        label: formattedLabel,
        count: drink[1],
      };
    });
    setTopThreeDrinks(formattedTopThree);
  }, [data]);

  const alcoholStatusColors = [
    '#01c2a6',
    '#0097ef',
    '#d8ad39',
    '#ec9876',
    '#f9555a',
  ];

  const Badge = ({ type, label, index }) => {
    let badgeImage;
    if (index === 0) {
      badgeImage = <h2 className="badge-place">1</h2>;
    } else if (index === 1) {
      badgeImage = <h2 className="badge-place">2</h2>;
    } else if (index === 2) {
      badgeImage = <h2 className="badge-place">3</h2>;
    }

    let drinkImage;
    if (type.includes('beer')) drinkImage = beer_bottle;
    if (type.includes('schooner')) drinkImage = beer_schooner;
    if (type.includes('pint')) drinkImage = beer_schooner;
    if (type.includes('white_wine')) drinkImage = white_wine;
    if (type.includes('red_wine')) drinkImage = red_wine;
    if (type.includes('champagne')) drinkImage = champagne;
    if (type.includes('shot')) drinkImage = spirit_shot;
    if (type.includes('premix')) drinkImage = spirit_premix;

    return (
      <div className="badge">
        {badgeImage}
        <img src={drinkImage} alt={label} className="badge-image" />
        <h2 className="badge-title">{label}</h2>
      </div>
    );
  };

  return (
    <>
      <div style={{ width: '97%' }} className="paper-container flex1">
        <h1 className="results-question" style={{ height: 40 }}>
          How often do you have a drink containing alcohol?
        </h1>
        <BarChartEvents
          colors={alcoholStatusColors}
          chartData={alcoholStatus}
        />
      </div>

      <div style={{ width: '97%' }} className="paper-container">
        <h1 className="results-question textaligncenter" style={{ height: 40 }}>
          How many standard drinks do we typically have in one sitting?
        </h1>
        <h1
          style={{ fontSize: 45, color: '#292929' }}
          className="textaligncenter mt10 mb0"
        >
          {standardDrinksAvg.toFixed(1)} standard drinks
        </h1>

        <h1
          className="results-question textaligncenter mt30"
          style={{ height: 40 }}
        >
          Our top chosen drinks are:
        </h1>
        <div className="dflex flexwrap justifycontentcenter mt10 mb10">
          {topThreeDrinks.map((drink, index) => (
            <Badge
              key={drink.type}
              type={drink.type}
              label={drink.label}
              index={index}
            />
          ))}
        </div>
      </div>

      <div style={{ width: '97%' }} className="paper-container">
        <h1
          className="results-question mt10"
          style={{ height: 40, marginBottom: '-20px' }}
        >
          What are the most commonly chosen drinks?
        </h1>
        <Treemap chartData={drinksMap} />
      </div>

      <div style={{ marginTop: 30 }} className="results-info-text">
        <h1>Did you know?</h1>
        <li>
          Australian alcohol guidelines recommend to have no more than 10
          standard drinks a week and no more than 4 standard drinks a day.
        </li>
        <li>
          More than one in four adults exceeded the Australian Adult Alcohol
          Guideline in 2022.
        </li>
        <li>
          More than one in three young adults aged 18–24 years exceeded the
          guideline.
        </li>
        <a
          class="link"
          target="_blank"
          href="https://www.abs.gov.au/statistics/health/health-conditions-and-risks/national-health-survey/latest-release"
        >
          Source (National Health Survey 2022 - ABS)
        </a>
      </div>
    </>
  );
};

export default AlcoholResults;
