import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { Autocomplete, MenuItem, Paper } from '@mui/material';

export default function Occupation({
  updateFinalResults,
  finalResults,
  error,
  setError,
}) {
  const handleChange = (value) => {
    if (value === null) {
      setError(true);
      updateFinalResults({ occupation: '' });
      return;
    }
    setError(false);

    updateFinalResults({ occupation: value });
  };

  const occupations = [
    'Accounting',
    'Administration & Office',
    'Advertising, Arts & Media',
    'Banking & Financial',
    'Call Centre & Customer Support',
    'CEO & General Management',
    'Community Services & Development',
    'Construction',
    'Consulting & Strategy',
    'Design & Architecture',
    'Education & Training',
    'Engineering',
    'Farming, Animals & Conservation',
    'Government & Defence',
    'Healthcare & Medical',
    'Hospitality & Tourism',
    'Human Resources & Recruitment',
    'Information Technology',
    'Insurance',
    'Legal',
    'Manufacturing & Logistics',
    'Marketing & Communications',
    'Mining, Resources & Energy',
    'Real Estate & Property',
    'Retail & Consumer Products',
    'Sales',
    'Self Employed',
    'Sports & Recreation',
    'Trades & Services',
    'Student',
    'Unemployed',
    'Retired',
    'Other',
    'Prefer not to say',
  ];

  return (
    <>
      <h3 className="mb10 personal-subheaders">Occupation / Industry</h3>
      <Autocomplete
        fullWidth
        value={finalResults.occupation || ''}
        PaperComponent={({ children }) => (
          <Paper
            sx={{
              '& .MuiAutocomplete-listbox': { overflowX: 'hidden' },
              '.MuiInputBase-input': { fontSize: 14 },
              '& .MuiAutocomplete-listbox > li::before': {
                color: 'transparent',
              },
            }}
            className="autocomplete"
          >
            {children}
          </Paper>
        )}
        onChange={(e, newValue) => handleChange(newValue)}
        options={occupations}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              '.MuiInputBase-input': { fontSize: 15 },
            }}
            InputLabelProps={{ style: { fontSize: 15 } }}
            error={error}
            label="Select *"
          />
        )}
      />
    </>
  );
}
