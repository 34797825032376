import React, { useState } from 'react';
import shortsIcon from '../../../../../src/assets/icons/shorts.png';
import singletIcon from '../../../../../src/assets/icons/singlet.png';
import shirtIcon from '../../../../../src/assets/icons/short_shirt.png';
import longShirt from '../../../../../src/assets/icons/long_shirt.png';
import sunscreen from '../../../../../src/assets/icons/sunscreen.png';
import shade from '../../../../../src/assets/icons/shade.png';
import long_pants from '../../../../../src/assets/icons/longpants.png';
import pantsIcon from '../../../../../src/assets/icons/med_pants.png';
import sunglasses from '../../../../../src/assets/icons/sunglasses.png';
import PdfModal from '../PdfModal';

import hat from '../../../../../src/assets/icons/hat.png';
import cap from '../../../../../src/assets/icons/cap.png';
import bottoms from '../../../../../src/assets/icons/bottoms.png';
import '../LearnMore.css';
import AnswerResponse from './AnswerResponse';
import SlipSlopSlapModal from '../SlipSlopSlapModal';

import Slop from '../../../../../src/assets/icons/CC_Slop.png';
import Slip from '../../../../../src/assets/icons/CC_Slip.png';
import Seek from '../../../../../src/assets/icons/CC_Seek.png';
import Slide from '../../../../../src/assets/icons/CC_Slide.png';
import Slap from '../../../../../src/assets/icons/CC_Slap.png';
import { Link } from 'react-router-dom';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';

const UvAnswers = ({ finalResults, factor }) => {
  const uvAnswers = Object.values(finalResults).filter(
    (res) => res.factor === 'uv',
  );
  const [openModal, setOpen] = useState(null);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(null);
  };
  return (
    <div className="subtext mb0 ">
      <h4 className="learnMoreHeader mt0 mb10">Did you know:</h4>
      <li>In Australia, sunburn can occur in as little as 10 minutes.</li>
      <li>
        Almost all skin cancer is due to{' '}
        <span className="highlighted-text">over exposure</span> to the sun and
        Ultraviolet Radiation (UV).
      </li>
      <li>
        You cannot see or feel UV, and it can even be high on cool and cloudy
        days. Remember to think UV, not heat.
      </li>
      <li>
        Protecting your skin from UV is important, especially during childhood
        and adolescence.
      </li>

      <div className="answersSection">
        <h4 className="learnMoreHeader mt20 mb0">Your Answers:</h4>
        {uvAnswers
          .filter((item) => item.factor === 'uv')
          .map((item) => {
            if (
              item.question_id === 'uv_park' ||
              item.question_id == 'uv_beach'
            ) {
              return (
                <>
                  <p className="learnMoreInfo uvQuesContainer question mt20 mb5 ml20">
                    {item.question}
                  </p>
                  <p
                    style={{ marginLeft: 20 }}
                    className="learnMoreInfo uvQuesContainerIcons answer"
                  >
                    <div
                      style={{ gap: '18px' }}
                      className="dflex flexwrap uvQuesResults"
                    >
                      {/* Shirt Answers */}
                      {item.answers.shirt === 'no_shirt' && (
                        <p className="bgRed learnMoreIcons">
                          <FaExclamationCircle className="exclamationIcon uvResponseIcons" />
                          <img alt="Singlet" src={singletIcon} />
                        </p>
                      )}
                      {item.answers.shirt === 'short_shirt' && (
                        <p className="bgOrange learnMoreIcons">
                          <FaExclamationCircle
                            style={{ color: '#c49b00' }}
                            className="exclamationIcon uvResponseIcons"
                          />
                          <img alt="Short Shirt" src={shirtIcon} />
                        </p>
                      )}
                      {item.answers.shirt === 'long_shirt' && (
                        <p className="bgGreen learnMoreIcons">
                          <FaCheckCircle className="checkIcon uvResponseIcons" />
                          <img alt="Long Shirt" src={longShirt} />
                        </p>
                      )}

                      {/* Pants Answers */}
                      {item.answers.pants === 'no_pants' &&
                        item.question_id === 'uv_beach' && (
                          <p className="bgRed learnMoreIcons">
                            <FaExclamationCircle className="exclamationIcon uvResponseIcons" />
                            <img
                              style={{ zoom: 1.3, padding: 4 }}
                              src={bottoms}
                              alt="Bottoms"
                            />
                          </p>
                        )}
                      {item.answers.pants === 'no_pants' &&
                        item.question_id === 'uv_park' && (
                          <p className="bgRed learnMoreIcons">
                            <FaExclamationCircle className="exclamationIcon uvResponseIcons" />
                            <img src={shortsIcon} alt="Shorts" />
                          </p>
                        )}

                      {item.answers.pants === 'short_pants' && (
                        <p className="bgOrange learnMoreIcons">
                          <FaExclamationCircle
                            style={{ color: '#c49b00' }}
                            className="exclamationIcon uvResponseIcons"
                          />
                          <img src={pantsIcon} alt="Pants" />
                        </p>
                      )}
                      {item.answers.pants === 'long_pants' && (
                        <p className="bgGreen learnMoreIcons">
                          <FaCheckCircle className="checkIcon uvResponseIcons" />
                          <img
                            style={{ zoom: 1.5, padding: 2 }}
                            src={long_pants}
                            alt="Long Pants"
                          />
                        </p>
                      )}
                      {item.answers.hat === 'no_hat' && (
                        <p className="bgRed learnMoreIcons">
                          <FaExclamationCircle className="exclamationIcon uvResponseIcons" />
                          <img
                            style={{ zoom: 1.5, padding: 2 }}
                            alt="Hat"
                            src={hat}
                          />
                        </p>
                      )}
                      {item.answers.hat === 'cap' && (
                        <p className="bgOrange learnMoreIcons">
                          <FaExclamationCircle
                            style={{ color: '#c49b00' }}
                            className="exclamationIcon uvResponseIcons"
                          />
                          <img
                            style={{ zoom: 1.3, padding: 4 }}
                            alt="Cap"
                            src={cap}
                          />
                        </p>
                      )}
                      {item.answers.hat === 'broad_hat' && (
                        <p className="bgGreen learnMoreIcons">
                          <FaCheckCircle className="checkIcon uvResponseIcons" />
                          <img
                            style={{ zoom: 1.5, padding: 2 }}
                            alt="Hat"
                            src={hat}
                          />
                        </p>
                      )}

                      {item.answers.sunscreen === 'no_sunscreen' && (
                        <p className="bgRed learnMoreIcons">
                          <FaExclamationCircle className="exclamationIcon uvResponseIcons" />
                          <img src={sunscreen} alt="Sunscreen" />
                        </p>
                      )}

                      {item.answers.sunscreen === 'sunscreen' && (
                        <p className="bgGreen learnMoreIcons">
                          <FaCheckCircle className="checkIcon uvResponseIcons" />
                          <img src={sunscreen} alt="Sunscreen" />
                        </p>
                      )}

                      {item.answers.shade === 'no_shade' && (
                        <p className="bgRed learnMoreIcons">
                          <FaExclamationCircle className="exclamationIcon uvResponseIcons" />
                          <img src={shade} alt="Shade" />
                        </p>
                      )}

                      {item.answers.shade === 'shade' && (
                        <p className="bgGreen learnMoreIcons">
                          <FaCheckCircle className="checkIcon uvResponseIcons" />
                          <img src={shade} alt="Shade" />
                        </p>
                      )}

                      {item.answers.sunglasses === 'no_sunglasses' && (
                        <p className="bgRed learnMoreIcons">
                          <FaExclamationCircle className="exclamationIcon uvResponseIcons" />
                          <img
                            style={{ zoom: 1.3, padding: 4 }}
                            src={sunglasses}
                            alt="Sunglasses"
                          />
                        </p>
                      )}

                      {item.answers.sunglasses === 'sunglasses' && (
                        <p className="bgGreen learnMoreIcons">
                          <FaCheckCircle className="checkIcon uvResponseIcons" />
                          <img
                            style={{ zoom: 1.3, padding: 4 }}
                            src={sunglasses}
                            alt="Sunglasses"
                          />
                        </p>
                      )}
                    </div>
                  </p>
                </>
              );
            }

            return <AnswerResponse item={item} />;
          })}
        <p className="mt20 ml20" style={{ fontSize: 16 }}>
          Aim for all 5 sun-protective behaviours to best protect your skin!
        </p>
      </div>
      <div>
        <h4 className="learnMoreHeader mt30 mb5">Tips & Resources:</h4>
        <li>
          Set up daily UV alerts with the{' '}
          <Link
            target="_blank"
            className="link "
            to="https://www.cancer.org.au/cancer-information/causes-and-prevention/sun-safety/be-sunsmart/sunsmart-app"
          >
            SunSmart App.
          </Link>{' '}
          Practise the five sun-protective behaviours when the UV is 3 and
          above.
        </li>
        <li>
          Avoid solariums and tanning. Solariums can produce UV radiation up to
          six times as strong as the midday summer sun.
        </li>
        <li>
          Don't forget about protecting your skin on cloudy days. UV rays can go
          through clouds even on overcast days.
        </li>
        <li>
          Apply SPF50 or SPF50+ sunscreen 20 minutes before going outside.
          Remember to re-apply every 2 hours. Read our factsheet on how to{' '}
          <span onClick={handleOpen} className="link ">
            correctly apply sunscreen.
          </span>{' '}
        </li>
        <PdfModal
          open={openModal}
          url="https://cancerqld.org.au/content/resources/library/correct%20Sunscreen%20Application.pdf"
          handleClose={handleClose}
        />
      </div>

      <h2
        style={{ fontSize: 24, color: '#00257b' }}
        className="mt30 dflex justifycontentcenter fontBlack slipSlopHeader"
      >
        Slip, Slop, Slap, Seek & Slide
      </h2>
      <div className="dflex flexwrap slipSlopSlapContainer gap10 justifycontentcenter mb20 mt10">
        <img style={{ height: 80 }} alt="Slip" src={Slip} />
        <img style={{ height: 70 }} alt="Slop" src={Slop} />
        <img style={{ height: 80 }} alt="Slap" src={Slap} />
        <img style={{ height: 65 }} alt="Seek" src={Seek} />
        <img style={{ height: 80 }} alt="Slide" src={Slide} />
      </div>
      <SlipSlopSlapModal />
    </div>
  );
};

export default UvAnswers;
