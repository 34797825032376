import React from 'react';
import Chart from 'react-apexcharts';

const BarChartEvents = ({ chartData, colors }) => {
  var series = [
    {
      data: chartData,
    },
  ];
  var options = {
    chart: {
      height: 400,
      type: 'bar',
      width: 300,
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true,
        speed: 500,
        animateGradually: {
          enabled: true,
          speed: 500,
        },
        dynamicAnimation: {
          enabled: true,
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      formatter: function (value, opts) {
        if (isNaN(value)) {
          value = 0;
        }
        return `${value}%`;
      },
      offsetX: 40,
      style: {
        fontFamily: 'Foco CC Bd',
        fontSize: '22px',
        colors: ['#304758'],
      },
    },
    grid: {
      show: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true,
        borderRadius: 10,
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      },
    },
    tooltip: {
      x: {
        show: true,
      },
      fixed: {
        enabled: false,
      },
      y: {
        show: false,
        formatter: function (value, opts) {
          if (value) {
            return `${value}%`;
          }
        },
        title: {
          formatter: function () {
            return '';
          },
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      labels: {
        show: true,
        maxWidth: 450,
        minWidth: 450,
        style: {
          fontSize: '18px',
          fontFamily: 'Foco CC Bd',
          fontWeight: '600',
        },
      },
    },
    colors: colors,
    xaxis: {
      labels: {
        formatter: function (value) {
          if (value) {
            return value;
          }
        },
        show: true,
        maxWidth: 400,
        style: {
          fontFamily: 'Foco CC Bd',
          fontSize: '18px',
          fontWeight: '600',
          colors: ['#304758'],
        },
      },
      tooltip: {
        enabled: false,
      },
    },
  };
  return (
    <div style={{ marginBottom: '-15px' }}>
      <Chart
        options={options}
        series={series}
        height="460"
        width="100%"
        type="bar"
      />
    </div>
  );
};
export default BarChartEvents;
