import sunscreenIcon from '../../../../assets/icons/sunscreen.png';
import shadeIcon from '../../../../assets/icons/shade.png';
import hatIcon from '../../../../assets/icons/hat.png';
import capIcon from '../../../../assets/icons/cap.png';
import longpantsIcon from '../../../../assets/icons/longpants.png';
import longshirtIcon from '../../../../assets/icons/long_shirt.png';
import shirtIcon from '../../../../assets/icons/short_shirt.png';
import sunglassesIcon from '../../../../assets/icons/sunglasses.png';
import singletIcon from '../../../../assets/icons/singlet.png';
import shortsIcon from '../../../../assets/icons/shorts.png';
import speedos from '../../../../assets/icons/bottoms.png';
//Beach assets
import Sunscreen from '../../../../assets/illustrations/beach/sunscreen50.png';
import Sunglasses from '../../../../assets/illustrations/beach/sunglasses.png';
import Hat from '../../../../assets/illustrations/beach/beach_hat.png';
import Cap from '../../../../assets/illustrations/beach/beach_cap.png';
import Shade from '../../../../assets/illustrations/beach/beach_shade.png';
import shortShirt from '../../../../assets/illustrations/beach/shirt_short.png';
import longShirt from '../../../../assets/illustrations/beach/shirt_long.png';
import shortPants from '../../../../assets/illustrations/beach/pants_short.png';
import longPants from '../../../../assets/illustrations/beach/pants_long.png';

//Male beach assets
import HatMale from '../../../../assets/illustrations/beach/male/broad_hat.png';
import SunglassesMale from '../../../../assets/illustrations/park/sunglasses.png';
import CapMale from '../../../../assets/illustrations/park/cap.png';
import shortShirtMale from '../../../../assets/illustrations/beach/male/short_shirt.png';
import longShirtMale from '../../../../assets/illustrations/beach/male/long_shirt.png';
import shortPantsMale from '../../../../assets/illustrations/beach/male/short_pants.png';
import longPantsMale from '../../../../assets/illustrations/beach/male/pants.png';

export const options = {
  pants: [
    {
      id: 'no_pants',
      index: 0,
      category: 'pants',
      icon: `${speedos}`,
      label: 'Bottoms',
      noneSelected: false,
      risk_ratios: [
        { skin: 0.21 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
    {
      id: 'short_pants',
      index: 1,
      category: 'pants',
      icon: `${shortsIcon}`,
      src: shortPants,
      direction: 'left',
      label: 'Shorts',
      noneSelected: false,
      risk_ratios: [
        { skin: 0.21 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
    {
      id: 'long_pants',
      index: 2,
      category: 'pants',
      icon: `${longpantsIcon}`,
      label: 'Long Pants',
      noneSelected: false,
      risk_ratios: [
        { skin: 0 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
  ],
  shirt: [
    {
      id: 'no_shirt',
      index: 0,
      category: 'shirt',
      icon: `${singletIcon}`,
      label: 'Singlet/No Shirt',
      direction: 'top',
      noneSelected: false,
      risk_ratios: [
        { skin: 0.21 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
    {
      id: 'short_shirt',
      index: 1,
      category: 'shirt',
      icon: `${shirtIcon}`,
      label: 'Short Shirt',
      direction: 'top',
      noneSelected: false,
      risk_ratios: [
        { skin: 0.21 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
    {
      id: 'long_shirt',
      index: 2,
      category: 'shirt',
      icon: `${longshirtIcon}`,
      label: 'Long Shirt',
      noneSelected: false,
      risk_ratios: [
        { skin: 0 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
  ],
  sunglasses: [
    {
      id: 'no_sunglasses',
      index: 0,
      category: 'sunglasses',
      icon: ``,
      label: 'No Sunnies',
      direction: 'top',
      noneSelected: true,
      risk_ratios: [
        { skin: 0.21 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
    {
      id: 'sunglasses',
      index: 1,
      category: 'sunglasses',
      icon: `${sunglassesIcon}`,
      direction: 'top',
      noneSelected: false,
      label: 'Sunnies',
      risk_ratios: [
        { skin: 0 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
  ],

  sunscreen: [
    {
      id: 'no_sunscreen',
      index: 0,
      category: 'sunscreen',
      icon: ``,
      label: 'No Sunscreen',
      direction: 'top',
      noneSelected: true,
      risk_ratios: [
        { skin: 0.57 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
    {
      id: 'sunscreen',
      index: 1,
      category: 'sunscreen',
      icon: `${sunscreenIcon}`,
      direction: 'top',
      noneSelected: false,
      label: 'Sunscreen',
      risk_ratios: [
        { skin: 0 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
  ],
  shade: [
    {
      id: 'no_shade',
      index: 0,
      category: 'shade',
      icon: ``,
      label: 'No Shade',
      direction: 'top',
      noneSelected: true,
      risk_ratios: [
        { skin: 0.21 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
    {
      id: 'shade',
      index: 1,
      category: 'shade',
      icon: `${shadeIcon}`,
      direction: 'top',
      noneSelected: false,
      label: 'Shade',
      risk_ratios: [
        { skin: 0 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
  ],
  hat: [
    {
      id: 'no_hat',
      index: 0,
      category: 'hat',
      icon: ``,
      label: 'No Hat',
      direction: 'top',
      noneSelected: true,
      risk_ratios: [
        { skin: 0.21 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
    {
      id: 'broad_hat',
      index: 1,
      category: 'hat',
      icon: `${hatIcon}`,
      direction: 'top',
      noneSelected: false,
      label: 'Broad Hat',
      risk_ratios: [
        { skin: 0 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
    {
      id: 'cap',
      index: 2,
      category: 'hat',
      icon: `${capIcon}`,
      direction: 'top',
      noneSelected: false,
      label: 'Cap',
      risk_ratios: [
        { skin: 0.21 },
        { bowel: 0 },
        { lung: 0 },
        { prostate: 0 },
        { breast: 0 },
      ],
    },
  ],
};

export const slideOptionsFemale = [
  {
    id: 'short_shirt',
    src: shortShirt,
    category: 'shirt',
    className: 'beach_shirt_short',
    direction: 'left',
  },
  {
    id: 'long_shirt',
    src: longShirt,
    category: 'shirt',
    className: 'beach_shirt_long',
    direction: 'left',
  },
  {
    id: 'short_pants',
    src: shortPants,
    category: 'pants',
    className: 'beach_short_pants',
    direction: 'left',
  },
  {
    id: 'long_pants',
    src: longPants,
    category: 'pants',
    className: 'beach_long_pants',
    direction: 'left',
  },
  {
    id: 'sunglasses',
    src: Sunglasses,
    category: 'sunglasses',
    className: 'beach_sunglasses',
    direction: 'down',
  },
  {
    id: 'broad_hat',
    src: Hat,
    category: 'hat',
    className: 'beach_hat',
    direction: 'down',
  },
  {
    id: 'cap',
    src: Cap,
    category: 'hat',
    className: 'beach_cap',
    direction: 'down',
  },
  {
    id: 'sunscreen',
    src: Sunscreen,
    category: 'sunscreen',
    className: 'beach_sunscreen',
    direction: 'right',
  },
  {
    id: 'shade',
    src: Shade,
    category: 'shade',
    className: 'beach_shade',
    direction: 'right',
  },
];

export const slideOptionsMale = [
  {
    id: 'short_shirt',
    src: shortShirtMale,
    category: 'shirt',
    className: 'beach_shirt_short_male',
    direction: 'left',
  },
  {
    id: 'long_shirt',
    src: longShirtMale,
    category: 'shirt',
    className: 'beach_shirt_long_male',
    direction: 'left',
  },
  {
    id: 'short_pants',
    src: shortPantsMale,
    category: 'pants',
    className: 'beach_short_pants_male',
    direction: 'left',
  },
  {
    id: 'long_pants',
    src: longPantsMale,
    category: 'pants',
    className: 'beach_long_pants_male',
    direction: 'left',
  },
  {
    id: 'sunglasses',
    src: SunglassesMale,
    category: 'sunglasses',
    className: 'beach_sunglasses_male',
    direction: 'down',
  },
  {
    id: 'broad_hat',
    src: HatMale,
    category: 'hat',
    className: 'beach_hat_male',
    direction: 'down',
  },
  {
    id: 'cap',
    src: CapMale,
    category: 'hat',
    className: 'beach_cap_male',
    direction: 'down',
  },
  {
    id: 'sunscreen',
    src: Sunscreen,
    category: 'sunscreen',
    className: 'beach_sunscreen',
    direction: 'right',
  },
  {
    id: 'shade',
    src: Shade,
    category: 'shade',
    className: 'beach_shade',
    direction: 'right',
  },
];
