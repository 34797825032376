import React, { useEffect } from 'react';
import CCQ_Logo from '../../assets/images/CCQ_Blue_Logo.png';
import CCQ_Logo_Mobile from '../../assets/images/CCQ_Daffodil.png';
import '../../App.css';
import './Navbar.css';
import { Button, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import IconPetal from './IconBlobPetal';

const Header = ({ activeView }) => {
  const mobileView = useMediaQuery('(max-width:500px)');

  return (
    <div sx={{ flexGrow: 1 }} className="app-header">
      <div className="navbar-logo alignitemscenter">
        <Link to="https://cancerqld.org.au/" target="_blank">
          <img className="ccq-logo" alt="CCQ" src={CCQ_Logo} />
        </Link>
        <IconPetal activeView={activeView} />

        {mobileView ? (
          <h1 className="navbar-header">Cancer Risk Calculator</h1>
        ) : (
          <Link
            reloadDocument
            style={{ textDecoration: 'none', color: '#00026e' }}
            to="/"
          >
            <h1 className="navbar-header">Cancer Risk Calculator</h1>
          </Link>
        )}
      </div>
      <div className="headerBtns">
        <Link
          target="_blank"
          to="https://cancerqld.org.au/get-support/direct-support/call-for-support/"
        >
          <Button
            style={{ fontWeight: 700 }}
            variant="outlined"
            className="borderRadius8"
          >
            13 11 20
          </Button>
        </Link>

        <Link
          target="_blank"
          to="https://cancerqld.org.au/get-support/direct-support/chat/"
        >
          <Button variant="contained" className="borderRadius8">
            Chat online
          </Button>
        </Link>

        <Link target="_blank" to="https://donate.cancerqld.org.au/donate-crc">
          <Button
            style={{ color: 'white' }}
            className="borderRadius8"
            color="primaryLight"
            variant="contained"
          >
            Donate Now
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default Header;
