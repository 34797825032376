import React from 'react';
import Chart from 'react-apexcharts';
import './Results.css';

//This data is pulled from Cancer Risk Calculator 1.0 questions pre Jan 2024
//Data points found in CRC Cancer Risk Score Power BI Report
const StackedAreaChart = ({ finalResults, selectedType }) => {
  const dataSets = {
    UV: {
      data: [
        {
          name: '18-24',
          data: [47, 31, 30, 41, 81],
        },
        {
          name: '25-39',
          data: [56, 45, 37, 65, 88],
        },
        {
          name: '40-49',
          data: [58, 51, 53, 77, 87],
        },
        {
          name: '50-64',
          data: [60, 47, 61, 78, 88],
        },
        {
          name: '65-74',
          data: [67, 39, 67, 83, 86],
        },
        {
          name: '75+',
          data: [43, 23, 43, 51, 55],
        },
      ],
      colors: [
        '#10ac84',
        '#778beb',
        '#f19066',
        '#f5cd79',
        '#3dc1d3',
        '#f78fb3',
      ],
      labels: [
        ['Wears Protective', ' Clothes'],
        ['Applies Sunscreen', ' every 2 hours'],
        'Wears Broad Hat',
        'Wears Sunglasses',
        'Seeks Shade',
      ],
    },
    Nutrition: {
      data: [
        {
          name: '18-24',
          data: [30, 5, 95, 50],
        },
        {
          name: '25-39',
          data: [37, 6, 97, 59],
        },
        {
          name: '40-49',
          data: [53, 8, 98, 57],
        },
        {
          name: '50-64',
          data: [61, 13, 98, 66],
        },
        {
          name: '65-74',
          data: [66, 13, 98, 70],
        },
        {
          name: '75+',
          data: [42, 12, 81, 53],
        },
      ],
      colors: [
        '#10ac84',
        '#778beb',
        '#f19066',
        '#f5cd79',
        '#3dc1d3',
        '#f78fb3',
      ],
      labels: [
        ['Eats 2+ serves', 'fruit /day'],
        ['Eats 5+ serves', 'veg /day'],
        ['Eats < 7 serves', 'red meat /week'],
        ['Eats < 1 serves', 'processed meat /week'],
      ],
    },
    SkinCheck: {
      data: [
        {
          name: '18-24',
          data: [null, null, null, 51, 16],
        },
        {
          name: '25-39',
          data: [null, null, 76, 70, 42],
        },
        {
          name: '40-49',
          data: [null, null, 82, 74, 55],
        },
        {
          name: '50-64',
          data: [70, 82, 81, 77, 56],
        },
        {
          name: '65-74',
          data: [83, 84, 80, 90, 76],
        },
        {
          name: '75+',
          data: [null, null, null, 86, 79],
        },
      ],
      colors: [
        '#10ac84',
        '#778beb',
        '#f19066',
        '#f5cd79',
        '#3dc1d3',
        '#f78fb3',
      ],
      labels: [
        ['Regular', 'Bowel Screening'],
        ['Regular', 'Breast Screening (F)'],
        ['Regular', 'Cervical Screening (F)'],
        ['Regularly checks own', 'skin for changes'],
        ['Regularly checks/talks with', 'healthcare professional'],
      ],
    },
  };

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    legend: {
      height: 28,
      fontSize: '15px',
      position: 'bottom',
      horizontalAlign: 'center',
    },
    colors: dataSets[selectedType].colors,
    labels: dataSets[selectedType].labels,
    stroke: {
      width: 3,
    },
    grid: {
      borderColor: '#555',
      clipMarkers: false,
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      x: {
        show: true,
      },
      fixed: {
        enabled: false,
      },
      y: {
        formatter: function (value) {
          if (value) {
            return value + '%';
          }
        },
      },
    },
    markers: {
      size: 4,
      colors: dataSets[selectedType].colors,
      strokeWidth: 3,
    },
    yaxis: {
      min: 0,
      max: 100,
    },
  };

  return (
    <div style={{ margin: 20 }} className="">
      {selectedType === 'UV' && (
        <Chart
          options={options}
          series={dataSets[selectedType].data}
          height="450"
          width="100%"
          type="area"
        />
      )}
      {selectedType === 'Nutrition' && (
        <Chart
          options={options}
          series={dataSets[selectedType].data}
          height="450"
          width="100%"
          type="area"
        />
      )}
      {selectedType === 'SkinCheck' && (
        <Chart
          options={options}
          series={dataSets[selectedType].data}
          height="450"
          width="100%"
          type="area"
        />
      )}
    </div>
  );
};
export default StackedAreaChart;
