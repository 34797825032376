import React, { useState } from 'react';
import { Button, Container, Fade, Modal } from '@mui/material';
import { useEffect } from 'react';
import '../feedback/feedback.css';
import CCQ_Logo from '../../../assets/images/CCQ_Blue_Logo.png';
import donationImage from '../../../assets/images/donation-image.png';
import { GrClose } from 'react-icons/gr';
import { Link } from 'react-router-dom';

export default function DonationModal() {
  const [open, setOpen] = useState(null);

  //Display donation modal afer 30 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true);
    }, 30000);
    // return () => clearTimeout(timer);
  }, []);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Fade in={open}>
          <Container maxWidth="md" className="modalStyles">
            <div
              style={{ paddingTop: 20 }}
              className="closeIconPopover dflex justifycontentstart"
            >
              <GrClose
                onClick={() => {
                  setOpen(false);
                }}
              />
            </div>
            <div className="w100">
              <img
                className="ccq-logo donation-logo"
                alt="CCQ"
                src={CCQ_Logo}
              />
              <h1 className="mb0 mt20 donationTitle">
                Together, we can prevent 1 in 3 cancers
              </h1>
              <h2 className="mt5 mb30 donationSubtext">
                We are a not-for-profit. Your generosity means we can prevent
                cancers, research more effective treatments, and truly support
                those touched by cancer.
              </h2>
            </div>

            <div className="dflex flexwrap justifycontentcenter gap10">
              <img
                src={donationImage}
                style={{
                  maxWidth: '100%',
                  marginBottom: '30px',
                  maxHeight: '165px',
                }}
              />
            </div>

            <div className="mt0 servicesBtns">
              <Link
                to="https://donate.cancerqld.org.au/donate-crc"
                target="_blank"
              >
                <Button
                  fullWidth
                  className="donationBtn height50"
                  variant="contained"
                >
                  Donate Now
                </Button>
              </Link>
              <Button
                onClick={() => {
                  setOpen(false);
                }}
                className="height50 mt10"
                variant="outlined"
                fullWidth
              >
                Close
              </Button>
            </div>
          </Container>
        </Fade>
      </Modal>
    </>
  );
}
