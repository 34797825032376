import React, { useEffect, useState } from 'react';
import { Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import emailIcon from '../../src/assets/icons/email-icon.png';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

//Unsubscribe page for one click unsub functionality from completion email
const Unsubscribed = () => {
  const navigate = useNavigate();
  let query = useQuery();

  async function unsubscribeUser() {
    const userId = query.get('user');
    var data = {
      user_id: userId,
    };

    try {
      const response = await axios.post('/api/unsubscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (response.data.success === 'redirect') {
        //User id not found, so redirect to home page
        navigate('/');
      }
    } catch (error) {
      console.error('Error: ', error);
    }
  }

  useEffect(() => {
    unsubscribeUser();
  }, []);

  //One click unsubscribe from completion emails
  return (
    <div className="container-wave">
      <div className="bg-blob"></div>
      <div className="bg-blob two"></div>
      <Container className="parent-container textaligncenter" maxWidth="lg">
        <img
          src={emailIcon}
          style={{ height: '60px', margin: 'auto', marginTop: '20px' }}
          className="dflex justifycontentcenter"
        />
        <h1 className="mb0" style={{ color: '#00026e' }}>
          Successfully Unsubscribed
        </h1>
        <h2 className="donationSubtext">
          Unsubscribed from Cancer Risk Calculator email communication.{' '}
        </h2>
      </Container>
    </div>
  );
};

export default Unsubscribed;
