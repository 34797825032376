import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function LikertQuestion({ question, handleChange }) {
  const updateFeedback = (e, score) => {
    handleChange(
      e.target.value,
      score,
      question.id,
      question.question,
      question.construct,
      question.component
    );
  };
  return (
    <div>
      <h3 className="mb5 mt20">{question.question}</h3>
      <RadioGroup
        row
        defaultValue="top"
        name={question.question}
        className="likert-scale"
      >
        <FormControlLabel
          value="Strongly Disagree"
          onClick={(e) => updateFeedback(e, 1)}
          control={<Radio required />}
          label="Strongly Disagree"
          className="rating-label-add"
          labelPlacement="bottom"
        />
        <FormControlLabel
          value="Disagree"
          onClick={(e) => updateFeedback(e, 2)}
          control={<Radio required />}
          label="Disagree"
          className="rating-label-add"
          labelPlacement="bottom"
        />
        <FormControlLabel
          value="Somewhat Agree"
          onClick={(e) => updateFeedback(e, 3)}
          control={<Radio required />}
          label="Somewhat Agree"
          className="rating-label-add"
          labelPlacement="bottom"
        />
        <FormControlLabel
          value="Agree"
          onClick={(e) => updateFeedback(e, 4)}
          control={<Radio required />}
          label="Agree"
          className="rating-label-add"
          labelPlacement="bottom"
        />
        <FormControlLabel
          value="Strongly Agree"
          onClick={(e) => updateFeedback(e, 5)}
          control={<Radio required />}
          className="rating-label-add"
          label="Strongly Agree"
          labelPlacement="bottom"
        />
      </RadioGroup>
    </div>
  );
}
