import { Container, Fade, Modal } from '@mui/material';
import React from 'react';
import { GrClose } from 'react-icons/gr';

const ScoresFaqModal = () => {
  const [infoModalOpen, setInfoModalOpen] = React.useState(false);
  const handleOpen = () => {
    setInfoModalOpen(true);
  };

  return (
    <div className="dflex justifycontentend mt20 mb0">
      <a onClick={handleOpen} className="subtext link mb0 ">
        How are my scores calculated?
      </a>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={infoModalOpen}
        onClose={() => setInfoModalOpen(false)}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={infoModalOpen}>
          <Container
            maxWidth="md"
            className="modalStyles dflex justifycontentcenter"
          >
            <GrClose
              onClick={() => setInfoModalOpen(false)}
              className="modalCloser"
            />
            <div>
              <h2 className="mb10">What does the calculator measure?</h2>
              <p className="mt10">
                The Cancer Risk Calculator looks at ways in which you are
                currently reducing your cancer risk and detecting cancer early.
                It is based on six modifiable lifestyle factors, including: UV &
                Sun Exposure, Smoking, Alcohol consumption, Nutriton, Physical
                Activity and Screening & Early Detection.
              </p>
              <p>
                It is a free online tool that is for individuals aged 18 years
                and older, and is for{' '}
                <span className="highlighted-text">
                  informational and educational purposes only.
                </span>{' '}
                It does not constitute as medical health advice and does not
                predict whether an individual will get cancer. Talk with your
                doctor to better understand your cancer-related risks.
              </p>
              <h2 className="mb10">How are my scores calculated?</h2>
              <p className="mt10">
                The scores are primarily based on the Harvard Cancer Risk index
                (1). It is in relation to the most common cancers diagnosed in
                Australia, which are breast, bowel, skin, lung and prostate
                cancer. The scores have been rescaled to be out of 100 for
                interpretation. The majority of the cancer risk estimates for
                these modifiable behaviours were based on meta-analyses on the
                topic. <br></br> Some factors and questions, such as screening
                and early detection, were not based on cancer risk estimates, as
                these behaviours are related to detecting cancer early. All
                scores provided are for entertainment purposes only, and do not
                serve as predictive indicators for an individual's risk of
                developing cancer or detecting it early.
              </p>
              <p style={{ fontSize: 13 }}>
                1. Colditz GA, Atwood KA, Emmons K, Monson RR, Willett WC,
                Trichopoulos D, et al. Harvard Report on Cancer Prevention
                Volume 4: Harvard Cancer Risk Index. Cancer Causes Control. 2000
                Jul 1;11(6):477–88.
              </p>
            </div>
          </Container>
        </Fade>
      </Modal>
    </div>
  );
};

export default ScoresFaqModal;
