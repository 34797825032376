import { Button, Container, Grow } from '@mui/material';
import React from 'react';
import SectionHeader from '../other/SectionHeader';
import StandardDrinks from './StandardDrinks';
import MultiChoiceQuestion from '../../body/MultichoiceQuestion';
import { calculateTotalScore } from '../../../utils/calculateScores';

function Alcohol({
  setActiveView,
  finalResults,
  updateFinalResults,
  currentAlcoholQuestion,
  setCurrentlAlcoholQuestion,
  configQuestions,
  handleCompletedSteps,
  riskRatios,
}) {
  const handleAnswerClick = (questionIndex) => {
    //Display next question
    if (currentAlcoholQuestion <= questionIndex) {
      setCurrentlAlcoholQuestion(currentAlcoholQuestion + 2);
    }
  };

  const checkAllRequired = () => {
    setActiveView('Nutrition');
    handleCompletedSteps('Alcohol');
    const score = calculateTotalScore(riskRatios, 'alcohol');

    if (finalResults.alcohol_status.answer === 'Never') {
      updateFinalResults({
        alcohol_total: 100,
        alcohol_binge: {
          answer: '',
        },
      });
    } else if (
      finalResults.alcohol_standard_drinks.standard_drinks_total > 13
    ) {
      updateFinalResults({ alcohol_total: 0 });
    } else if (
      finalResults.alcohol_standard_drinks.standard_drinks_total > 10
    ) {
      updateFinalResults({ alcohol_total: 10 });
    } else {
      updateFinalResults({ alcohol_total: score });
    }
  };

  const nextDisabled =
    finalResults.alcohol_status.answer === 'Never' ||
    (finalResults.alcohol_status !== '' &&
      finalResults.alcohol_binge.answer !== '');

  return (
    <>
      <Grow in={true}>
        <Container>
          <SectionHeader
            title="Alcohol Consumption"
            subtext="Heading to the pub after work? The following questions will help determine drinking consumption."
            activeColor="#f05029"
            factor="alcohol"
          />

          {configQuestions
            .filter((item) => item.factor === 'alcohol')
            .map((question, index) => {
              const questionDisplayed = currentAlcoholQuestion >= index;

              if (
                (question.id === 'alcohol_standard_drinks' ||
                  question.id === 'alcohol_binge') &&
                finalResults.alcohol_status.answer === 'Never'
              ) {
                return;
              }

              if (question.id === 'alcohol_standard_drinks') {
                return (
                  <StandardDrinks
                    questionDisplayed={questionDisplayed}
                    questionIndex={index}
                    finalResults={finalResults}
                    updateFinalResults={updateFinalResults}
                    key={index}
                  />
                );
              }

              return (
                <MultiChoiceQuestion
                  key={index}
                  questionDisplayed={questionDisplayed}
                  handleAnswerClick={handleAnswerClick}
                  question={question}
                  questionIndex={index}
                  updateFinalResults={updateFinalResults}
                  finalResults={finalResults}
                />
              );
            })}

          {finalResults.alcohol_status.answer === `Never` && (
            <Grow in={true}>
              <p style={{ fontSize: 20 }}>Select next to continue</p>
            </Grow>
          )}

          <div className="dflex mb10 mt30 justifycontentspacebetween">
            <Button
              variant="contained"
              className="prevBtn"
              onClick={() => {
                setActiveView('Smoking');
              }}
            >
              Back
            </Button>

            <Button
              variant="contained"
              color="primary"
              className={'nextBtn'}
              onClick={checkAllRequired}
              disabled={!nextDisabled}
            >
              Next
            </Button>
          </div>
        </Container>
      </Grow>
    </>
  );
}

export default Alcohol;
