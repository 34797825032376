import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import './StepProgressBar.css';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { FaCheck } from 'react-icons/fa';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';

import { BsFillPersonFill } from 'react-icons/bs';

import {
  Box,
  Container,
  MobileStepper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import physicalIcon from '../../../assets/icons/physical-activity-white.png';
import screeningIcon from '../../../assets/icons/screening-white.png';
import nutritionIcon from '../../../assets/icons/nutrition-white.png';
import weightIcon from '../../../assets/icons/weight-white.png';
import alcoholIcon from '../../../assets/icons/alcohol-white.png';
import uvIcon from '../../../assets/icons/uv-white.png';
import smokingIcon from '../../../assets/icons/smoking-white.png';

const ColorlibConnector = styled(StepConnector)(({ theme, factorcolor }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: `linear-gradient(45deg, #f9c900 , ${factorcolor} 60%)`,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: `linear-gradient(45deg, #f9d335, #f9d335)`,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(
  ({ theme, ownerState, factorcolor }) => ({
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 44,
    height: 44,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      backgroundImage: `linear-gradient(45deg, ${factorcolor}, ${factorcolor})`,
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
      backgroundImage: 'linear-gradient( 45deg, #f9c900, #edbf00)',
    }),
  }),
);

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;
  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <FaCheck className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

function ColorlibStepIcon(props) {
  var { active, completed, className } = props;

  const icons = {
    1: {
      id: 'Personal',
      icon: <BsFillPersonFill style={{ fontSize: 25 }} />,
      color: '#009bdc',
    },
    2: {
      id: 'UV',
      // icon: <BsFillSunFill style={{ fontSize: 23 }} />,
      icon: (
        <img src={uvIcon} alt="UV" style={{ height: 28, marginTop: '-1px' }} />
      ),
      color: '#e7bb00',
    },
    3: {
      id: 'Smoking',
      //icon: <MdSmokeFree style={{ fontSize: 27, marginTop: '-2px' }} />,
      icon: (
        <img
          src={smokingIcon}
          alt="Smoking"
          style={{ height: 28, marginTop: '-1px' }}
        />
      ),
      color: '#878888',
    },
    4: {
      id: 'Alcohol',
      //icon: <RiBeerFill style={{ fontSize: 25, marginLeft: '4px' }} />,
      icon: (
        <img
          src={alcoholIcon}
          alt="Alcohol"
          style={{ height: 28, marginTop: '-1px' }}
        />
      ),
      color: '#f05029',
    },
    5: {
      id: 'Nutrition',
      //icon: <MdFastfood style={{ fontSize: 25, marginLeft: '0px' }} />,
      icon: <img src={nutritionIcon} alt="Nutrition" style={{ height: 28 }} />,
      color: '#73c04b',
    },
    6: {
      id: 'Weight',
      // icon: <MdMonitorWeight style={{ fontSize: 24 }} />,
      icon: (
        <img
          src={weightIcon}
          alt="Weight"
          style={{ height: 22, marginTop: '-3px' }}
        />
      ),
      color: '#199bd7',
    },
    7: {
      id: 'Physical',
      // icon: <MdSportsTennis style={{ fontSize: 24 }} />,
      icon: (
        <img
          src={physicalIcon}
          alt="Physical Activity"
          style={{ height: 24, marginTop: '-2px' }}
        />
      ),
      color: '#199bd7',
    },
    8: {
      id: 'Screening',
      // icon: <BiSolidSpa style={{ fontSize: 23 }} />,
      icon: <img src={screeningIcon} alt="Screening" style={{ width: 23 }} />,
      color: '#7d489c',
    },
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active, completed }}
      className={className}
      factorcolor={icons[String(props.icon)].color}
      // style={completed ? { background: icons[String(props.icon)].color } : null}
    >
      {completed ? (
        <FaCheck className="completedStep QontoStepIcon-completedIcon" />
      ) : (
        icons[String(props.icon)].icon
      )}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * @default false
   */
  completed: PropTypes.bool,

  icon: PropTypes.node,
  completedSteps: PropTypes.string,
};

const steps = [
  { id: 'Personal', color: '#009bdc', label: 'Personal' },
  { id: 'UV', color: '#fbc804', label: 'UV & Sun' },
  { id: 'Smoking', color: '#878888', label: 'Smoking' },
  { id: 'Alcohol', color: '#e5623a', label: 'Alcohol' },
  { id: 'Nutrition', color: '#73c04b', label: 'Nutrition' },
  { id: 'Weight', color: '#199bd7', label: 'Weight' },
  { id: 'Physical', color: '#199bd7', label: 'Physical Activity' },
  { id: 'Screening', color: '#7d489c', label: 'Screening' },
];

export default function CustomizedSteppers({
  activeStep,
  setActiveStep,
  completedSteps,
}) {
  var activeStepIndex = steps.findIndex((step) => step.id === activeStep);

  //Keep UV step selected for all uv-related questions
  if (activeStep === 'UV_Beach' || activeStep === 'UV_Park') {
    activeStepIndex = 1;
  }
  //Allow user to click between completed steps
  const handleStepClick = (stepId) => {
    // if (activeStepIndex < 0 || stepId === 'Personal') return;
    if (activeStepIndex < 0) return;
    const stepIndex = steps.findIndex((step) => step.id === stepId);
    if (completedSteps.includes(steps[stepIndex].id)) {
      setActiveStep(stepId);
    }
  };

  const mobileView = useMediaQuery('(max-width:850px)');

  //Mobile and browser progress stepper
  return (
    <Container className="stepper-section" disableGutters>
      {mobileView ? (
        <Box>
          <MobileStepper
            activeStep={activeStepIndex}
            variant="progress"
            position="static"
            steps={steps.length}
            className="mobileStepper"
          ></MobileStepper>
          <Typography variant="caption" component="div" color="text.secondary">
            {
              <h3 className="stepperMobileText">
                {activeStepIndex > -1
                  ? `${activeStepIndex} of ${steps.length}`
                  : '0/8'}
              </h3>
            }
          </Typography>
        </Box>
      ) : (
        <Stepper
          alternativeLabel
          activeStep={activeStepIndex}
          connector={
            <ColorlibConnector
              factorcolor={
                activeStepIndex > 0 ? steps[activeStepIndex].color : ''
              }
            />
          }
        >
          {steps.map((step, index) => (
            <Step
              key={step.id}
              completed={
                completedSteps.includes(step.id) &&
                step.id !== steps[activeStepIndex].id
              }
            >
              <StepLabel
                onClick={() => handleStepClick(step.id)}
                className={'stepper-label  stepper-buttons'}
                StepIconComponent={ColorlibStepIcon}
              >
                {mobileView ? null : step.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
    </Container>
  );
}
