import React from 'react';
import '../LearnMore.css';
import Apple from '../../../../assets/illustrations/nutrition/apple.png';
import Cucumber from '../../../../assets/illustrations/nutrition/cucumber.png';
import Bread from '../../../../assets/illustrations/nutrition/bread.png';
import Steak from '../../../../assets/illustrations/nutrition/steak.png';
import Bacon from '../../../../assets/illustrations/nutrition/bacon.png';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
const NutritionAnswers = ({ finalResults, factor }) => {
  const answers = Object.values(finalResults).filter(
    (res) => res.factor === 'nutrition',
  );
  return (
    <div className="subtext">
      <h4 className="learnMoreHeader mt0 mb10">Did you know:</h4>
      <li>
        Our food choices play an important role in cancer prevention. A diet
        rich in fruits, vegetables and whole grains gives our body the nutrients
        it needs and helps reduce our cancer risk.
      </li>
      <li>
        Eating less red meat and processed meats has also been found to help
        reduce our cancer risk.
      </li>
      <li>
        Ultra-processed foods are high in energy, salt, sugar, and low in
        nutrients. They should only be eaten sometimes and in small amounts.
        Eating too many chips, chocolate and fast food can mean we don't get
        enough healthy foods in our diet. It can also lead to high body weight,
        which is a risk factor for some cancers.
      </li>
      <li>
        Nutrition, physical activity and weight are all interconnected when it
        comes to reducing your cancer risk.
      </li>

      <div className="">
        <h4 className="learnMoreHeader mt20 mb0">Your Answers:</h4>

        <p style={{ marginLeft: 20 }} className="learnMoreInfo answer">
          <div className="dflex justifycontentcenter flexwrap gap10">
            <div className="answerContainer">
              {finalResults.fruit.answer >= 2 ? (
                <FaCheckCircle className="resultCardIcon checkIcon" />
              ) : (
                <FaExclamationCircle className="resultCardIcon exclamationIcon" />
              )}
              <img src={Apple} alt="Apple" className="nutritionIcons bgGray" />
              <h5>Fruits</h5>
              <p className="mb5 fontWeightBold">
                {finalResults.fruit.answer}
                {finalResults.fruit.answer === 5 && '+'} serves / day
              </p>
              <p className="italic smallText mb0">Aim for 2 or more</p>
            </div>

            <div className="answerContainer">
              {finalResults.vegetables.answer >= 5 ? (
                <FaCheckCircle className="resultCardIcon checkIcon" />
              ) : (
                <FaExclamationCircle className="resultCardIcon exclamationIcon" />
              )}
              <img
                src={Cucumber}
                alt="Cucumber"
                className="nutritionIcons bgGray"
              />
              <h5>Vegetables</h5>
              <p className="mb5 fontWeightBold">
                {finalResults.vegetables.answer}
                {finalResults.vegetables.answer === 7 && '+'} serves / day
              </p>
              <p className="italic smallText mb0">Aim for 5 or more</p>
            </div>

            <div className="answerContainer">
              {finalResults.wholegrains.answer >= 6 ? (
                <FaCheckCircle className="resultCardIcon checkIcon" />
              ) : (
                <FaExclamationCircle className="resultCardIcon exclamationIcon" />
              )}
              <img src={Bread} alt="Bread" className="nutritionIcons bgGray" />
              <h5>Wholegrain Foods</h5>
              <p className="mb5 fontWeightBold">
                {finalResults.wholegrains.answer}
                {finalResults.wholegrains.answer === 7 && '+'} serves / day
              </p>
              <p className="italic smallText mb0">Aim for 4 or more</p>
            </div>

            <div className="answerContainer">
              {finalResults.red_meat.answer <= 7 ? (
                <FaCheckCircle className="resultCardIcon checkIcon" />
              ) : (
                <FaExclamationCircle className="resultCardIcon exclamationIcon" />
              )}
              <img src={Steak} alt="Steak" className="nutritionIcons bgGray" />
              <h5>Red Meats</h5>
              <p className="mb5 fontWeightBold">
                {finalResults.red_meat.answer}
                {finalResults.red_meat.answer === 10 && '+'} serves / week
              </p>
              <p className="italic smallText mb0">Aim for 7 or less</p>
            </div>

            <div className="answerContainer">
              {finalResults.processed_meat.answer < 1 ? (
                <FaCheckCircle className="resultCardIcon checkIcon" />
              ) : (
                <FaExclamationCircle className="resultCardIcon exclamationIcon" />
              )}
              <img src={Bacon} alt="Bacon" className="nutritionIcons bgGray" />
              <h5>Processed Meats</h5>
              <p className="mb5 fontWeightBold">
                {finalResults.processed_meat.answer}
                {finalResults.processed_meat.answer === 10 && '+'} serves / week
              </p>
              <p className="italic smallText mb0">Try to limit intake</p>
            </div>
          </div>
        </p>
      </div>

      <div>
        <h4 className="learnMoreHeader mt30 mb10">Tips & Resources:</h4>
        <p className="mt10 mb5">
          Making healthier food choices can be quick and easy. Some tips
          include:
        </p>
        <li>
          Practise some 'simple swaps'. For lunch, choose a chicken salad
          sandwich on wholemeal bread over a bacon burger.
        </li>
        <li>
          Plan your lunches and snacks ahead of time. This can help you avoid
          choosing unhealthy options when you're in a rush.
        </li>
        <li>
          Legumes such as peas, beans and lentils can be a nutritious
          plant-based option. They are high in fibre and are a great source of
          protein. Try replacing mince with lentils in your next spaghetti
          bolognese.
        </li>
        <li>
          Why not try some canned vegetables or frozen fruit (no added sugar)
          options. They can help you reach your 2 and 5 serving goal without
          breaking the bank.
        </li>
        <li>
          Explore the{' '}
          <Link
            className="link "
            target="_blank"
            to="https://www.eatforhealth.gov.au/nutrition-calculators/average-recommended-number-serves-calculator"
          >
            EatForHealth
          </Link>{' '}
          calculator to find the ideal serving sizes for you.
        </li>
        <p className="mt10">
          For professional advice on diet and nutrition, talk to your doctor or
          an{' '}
          <Link
            className="link "
            target="_blank"
            to="https://member.dietitiansaustralia.org.au/Portal/Portal/Search-Directories/Find-a-Dietitian.aspx"
          >
            Accredited Practising Dietitian.
          </Link>
        </p>{' '}
      </div>
    </div>
  );
};

export default NutritionAnswers;
