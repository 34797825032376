import React from 'react';
import yoga from '../../../../assets/illustrations/physical/yoga.png';
import weights from '../../../../assets/illustrations/physical/weights.png';
import cyclist from '../../../../assets/illustrations/physical/cyclist.png';
import screen from '../../../../assets/illustrations/physical/screen.png';
import '../LearnMore.css';
import { Link } from 'react-router-dom';

const PhysicalAnswers = ({ finalResults, factor }) => {
  return (
    <div className="subtext">
      <div>
        <h4 className="learnMoreHeader mt0 mb10">Did you know:</h4>
        <li>
          Physical activity is not only about planned workouts. It is about
          moving your body and reducing your sitting time.
        </li>
        <li>
          Keeping active can strengthen your body and immune system, which can
          help to fight off cancer.
        </li>
        <li>
          Chronic inflammation has been linked to cancer. Staying active can
          help to lower inflammation levels and reduce your cancer risk.
        </li>
        <li>
          Physical activity is good at any age. It is recommended for
          individuals over 65 to reach at least 150 minutes of moderate
          intensity activity per week.
        </li>
      </div>

      <div>
        <h4 className="learnMoreHeader mt20 mb5">Your Answers:</h4>
        <p style={{ marginLeft: 20 }} className="learnMoreInfo answer">
          <div className="dflex flexwrap gap10 justifycontentcenter">
            <div className="answerContainer physicalAnswer">
              <img alt="Yoga" src={yoga} className="physicalIcons bgGray" />
              <h5>Light & Moderate</h5>
              <p className="fontWeightBold">
                {finalResults.exercise.answers.light}
                {finalResults.exercise.answers.light === 10 && '+'} hours / week
              </p>
            </div>

            <div className="answerContainer physicalAnswer">
              <img
                alt="Cycling"
                src={cyclist}
                className="physicalIcons bgGray"
              />
              <h5>Vigorous Activities</h5>
              <p className="fontWeightBold">
                {finalResults.exercise.answers.vigorous}
                {finalResults.exercise.answers.vigorous === 10 && '+'} hours /
                week
              </p>
            </div>

            <div className="answerContainer physicalAnswer">
              <img alt="Gym" src={weights} className="physicalIcons bgGray" />
              <h5>Muscle Strengthening</h5>
              <p className="fontWeightBold">
                {finalResults.exercise.answers.muscle}
                {finalResults.exercise.answers.muscle === 10 && '+'} hours /
                week
              </p>
            </div>

            <div className="answerContainer physicalAnswer">
              <img src={screen} alt="TV" className="physicalIcons bgGray" />
              <h5>Sedentary (Inactive)</h5>
              <p className="fontWeightBold">
                {finalResults.sedentary.answers.sedentary}
                {finalResults.sedentary.answers.sedentary === 12 && '+'} hours /
                day
              </p>
            </div>
          </div>
        </p>

        <div className="dflex gap10 mt10 alignitemscenter">
          <p className="learnMoreInfo mt10">
            For physical activity to be{' '}
            <span className="highlighted-text">protective against cancer,</span>{' '}
            {finalResults.age < 65 ? (
              <>
                it is recommended to reach at least 300 minutes of moderate
                activity per week, or 150 minutes of vigorous or strength-based
                activity per week. Limit your sedentary time when you can!
              </>
            ) : (
              //Display less minutes for people over 65 years of age
              <>
                it is recommended to reach at least 150 minutes of moderate
                activity per week, or 75 minutes of vigorous or strength-based
                activity per week. Limit your sedentary time when you can!
              </>
            )}
          </p>
        </div>
      </div>

      <div>
        <h4 className="learnMoreHeader mt30 mb10">Tips & Resources:</h4>
        <p className="mt10">Some ideas to add more movement into your life:</p>
        <li>
          Exercise shouldn't feel like a chore. Find things you enjoy such as
          dancing, swimming or playing social sports.
        </li>
        <li>
          Start small and gradually increase. Try not to overwhelm yourself with
          big goals.
        </li>
        <li>
          Make it competitive! Use technology to challenge others in fun
          activities. For example, using fitness trackers.
        </li>
        <li>
          Reward yourself and celebrate your milestones. Try not to be hard on
          yourself if you are less active on a particular week.
        </li>
        <p className="mt10">Some tips to reduce your sitting/sedentary time:</p>
        <li>
          Create opportunities to take breaks to stand up and walk around. For
          example, when the phone rings, or to fill up your water bottle.
        </li>
        <li>
          Set a 'Get up and move' reminder on your phone or smart watch to
          remember to move regularly.
        </li>
        <li>
          Consider a standing desk if you work in an office. Try to incorporate
          standing breaks during your virtual meetings.
        </li>
      </div>
      <p className="learnMoreInfo mt20">
        For professional advice, talk to your doctor or an{' '}
        <Link
          className="link "
          target="_blank"
          to="https://www.essa.org.au/Shared_Content/Smart-Suite/Smart-Maps/Public/Find-an-AEP.aspx?WebsiteKey=b4460de9-2eb5-46f1-aeaa-3795ae70c687"
        >
          Accredited Exercise Physiologist.
        </Link>
      </p>
    </div>
  );
};

export default PhysicalAnswers;
