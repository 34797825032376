import { Button, Container, Grow, Slide, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SectionHeader from '../other/SectionHeader';
import MultiChoiceQuestion from '../../body/MultichoiceQuestion';
import { GrClose } from 'react-icons/gr';
import { BsInfoCircleFill } from 'react-icons/bs';

function Uv({
  setActiveView,
  finalResults,
  updateFinalResults,
  currentUvQuestion,
  setCurrentUvQuestion,
  configQuestions,
  handleCompletedSteps,
}) {
  const handleAnswerClick = (questionIndex) => {
    // Display next question
    if (currentUvQuestion <= questionIndex) {
      setCurrentUvQuestion(currentUvQuestion + 1);
    }
  };
  //Total count of questions to display
  const totalQuestionCount = configQuestions.filter(
    (item) => item.factor === 'uv',
  ).length;

  const checkAllRequired = () => {
    setActiveView('UV_Beach');
    handleCompletedSteps('UV');
  };

  const [popoverOpen, setPopoverOpen] = useState(true);

  //Display feedback popover after 2 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setPopoverOpen(!popoverOpen);
    }, 12000);
  }, []);

  const nextDisabled = currentUvQuestion === totalQuestionCount;
  const mobileView = useMediaQuery('(max-width:850px)');

  return (
    <>
      <Slide
        direction="left"
        style={{ zIndex: 3 }}
        className={`popoverInformation ${mobileView ? 'dnone' : ''}`}
        in={popoverOpen}
      >
        <div>
          <h4
            className="mb5"
            style={{ fontSize: 17, marginTop: 5, fontWeight: 500, padding: 4 }}
          >
            <BsInfoCircleFill
              style={{
                fontSize: 15,
                color: '#3aa5ff',
                marginRight: 5,
                marginTop: 10,
              }}
            />
            As you go through the questions, think about where you might be,
            like at home, work, or doing outdoor activities.
          </h4>
        </div>
      </Slide>
      <Grow in={true}>
        <Container>
          <SectionHeader
            title="UV & Sun Exposure"
            subtext="It's important to remember the sun-protective behaviours when you're outside to best protect your skin."
            factor="uv"
            activeColor="#e5be00"
          />

          {configQuestions
            .filter((item) => item.factor === 'uv')
            .map((question, index) => {
              const questionDisplayed = currentUvQuestion >= index;
              return (
                <MultiChoiceQuestion
                  key={index}
                  questionDisplayed={questionDisplayed}
                  handleAnswerClick={handleAnswerClick}
                  question={question}
                  questionIndex={index}
                  updateFinalResults={updateFinalResults}
                  finalResults={finalResults}
                />
              );
            })}

          <div className="dflex mb10 mt30 justifycontentspacebetween">
            <Button
              variant="contained"
              color="primary"
              className={'nextBtn'}
              onClick={checkAllRequired}
              disabled={!nextDisabled}
            >
              Next
            </Button>
          </div>
        </Container>
      </Grow>
    </>
  );
}

export default Uv;
