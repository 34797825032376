import React from 'react';
import { Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import '../App.css';

const NoPageFound = () => {
  const navigate = useNavigate();
  return (
    <div className="container-wave">
      <div className="bg-blob"></div>
      <div className="bg-blob two"></div>
      <Container className="parent-container" maxWidth="lg">
        <h1 style={{ color: '#00026e' }}>404 - Page not found</h1>
        <h2>Sorry, we couldn't find the page you're looking for.</h2>

        <div className="dflex  mt30">
          <Button onClick={() => navigate('/')} variant="contained">
            Take the Cancer Risk Calculator
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default NoPageFound;
